@import "@fortawesome/fontawesome-free/css/all.css";

.tu-error-message {
  color: red;
  font-size: 12px;
}

.tu-primbtn[disabled],
.tu-secbtn[disabled],
.tu-yellowbtn[disabled],
.tu-primbtn-lg[disabled],
.tu-primbtn-sm[disabled],
.tu-primbtn-icon[disabled] {
  background-color: #6a307d !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.DatePicker_icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.resend_button {
  color: #6a307d;
  background: 0;
}

.user_profile_layout .tu-inputicon span,
.user_profile_layout .tu-inputiconbtn span {
  right: unset;
  font-size: 16px;
  position: relative;
  line-height: 1;
  color: #999999;
}

.user_profile_layout .tu-profilewrapper .tu-boxwrapper {
  margin: 0;
}

.tu-contact-box .tu-placeholderholder .tu-placeholder {
  display: flex !important;
  align-items: center;
}

.calendar {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #eeeeee;
}

.calendar-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.calendar-header div {
  padding: 12px;
  position: relative;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #7d306a;
  color: #fff;
  font-weight: bold;
}

.calendar-body {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.calendar-body .slot {
  padding: 12px;
  position: relative;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  height: 60px;
}

.booking-count {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 20px;
  color: black;
}

.calendar-body .active {
  color: #7d307d;
  font-weight: bold;
  border-color: #7d307d;
  background-color: #7d307d11;
}

.calendar-body .available {
  border-color: #17843f;
  color: #17843f;
  font-weight: bold;
  background-color: #17843f11;
}

.calendar-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  /* padding-bottom: 15px; */
}
.radio_wrapper {
  height: 48px;
}

.mdl .modal-dialog {
  max-width: 680px;
}

.pt-0 {
  padding-top: 15px !important;
}

.pt-15 {
  padding-top: 15px;
}

.edu .accordion-item {
  border: none;
}

.edu .accordion-button {
  padding: 0px;
  border: none;
}

.edu .accordion-body {
  padding: 0px;
}

.edu .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.edu .tu-accodion-holder {
  border: none;
}

.edu .accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
}

.edu .tu-expwrapper {
  width: 100%;
  margin-right: 15px;
}

.subjectData .css-3iigni-container {
  width: 100%;
}

.subjectData .css-1h01tm3-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.subjectData .css-1h01tm3-Input {
  height: 38px;
}

.handle-remove-btn {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  width: 25px;
  background: #ccc;
  height: 25px;
}

.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide.is-active,
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 1px solid #000 !important;
}

.hide-arrows .splide__arrow {
  display: none;
}

.tu-savedwrapper {
  display: flex;
  background: #ffffff;
  align-items: center;
  padding: 19px 20px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tu-savedinfo {
  display: flex;
  align-items: center;
  flex: 0 0 60%;
}

.tu-savebtns {
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 0 0 40%;
}

.tu-savedinfo figure {
  max-width: 50px;
  margin: 0 20px 0 0;
}

.tu-savedinfo figure img {
  border-radius: 50%;
  width: 100%;
}

.tu-savedtites p {
  margin: 0;
  color: #1c1c1c;
}

.tu-savedtites h4 {
  margin: 0;
  font-weight: 600;
  color: #1c1c1c;
  align-items: center;
}

.remove-bookmark-icon {
  background: 0;
  margin-right: 15px;
}

.tu-media-gallery .splide__list {
  align-items: center;
}

.tu-media-gallery .tu-sync__content {
  text-align: center;
}

.tu-listing-tutors .tu-listinginfo_btn {
  padding: 20px 2px 19px;
}

.bookingData > li > a {
  color: #999999;
  display: block;
  font-weight: 600;
  padding: 2px 11px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.Booking-header div {
  position: relative;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #7d306a;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.slotData {
  position: relative;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  height: 32px;
  margin-bottom: 7px;
}

.secondary-color {
  background-color: #999999; /* Replace with your desired secondary color */
  color: white;
}
/** ===== Search Listting ====== **/

.remove-btn {
  background: none;
  margin-left: 10px;
}
/* Default one-column layout */
.one-column .tu-listinginfo {
  display: block;
}

/* Two-column layout when icon is clicked */
.two-column .tu-listinginfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.tu-listinginfo_price_grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}
.tu-freelanceremptylist {
  padding: 50px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-freelanceremptylist .tu-freelanemptytitle {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

/** ====== search listing view ===== **/
.tu-view-media .splide__list {
  align-items: center !important;
}

.tu-view-media .splide__slide {
  text-align: center !important;
}

/** ========== home page ===== **/
.tu-banner {
  background-color: aliceblue;
}
.homepage-search .css-19bb58m {
  height: 45px !important;
}
.homepage-search .css-19bb58m input {
  height: 45px !important;
}
/* .sidebar-select .css-19bb58m {
  height: 39px !important;
} */

.css-b62m3t-container{
  width: 100%!important;
}
.homepage-selecte-category .css-b62m3t-container {
  width: 73% !important;
}
/* .homepage-banner-search .tu-appendinput {
  background-color: #6A307D  !important;
} */

.homepage-banner-search .tu-appendinput {
  height: 75px !important;
}
.homepage-banner-search .tu-searcbar {
  background-color: white;
}
.homepage-banner-search .tu-appendinput .tu-inputicon .form-control {
  padding-right: 0px !important;
}
.homepage-banner-search input {
  height: 55px !important;
  border-radius: 0px !important;
}
.homepage-banner-selecte-category {
  background-color: white !important;
  height: 55px !important;
  width: 100%;
}
.homepage-banner-selecte-category .css-13cymwt-control {
  border: none;
}

.homepage-banner-selecte-category .css-qbdosj-Input {
  height: 45px !important;
}
.homepage-banner-selecte-category .css-1u9des2-indicatorSeparator {
  width: 0px !important;
}
.homepage-banner-selecte-category .tu-primbtn-lg {
  margin-top: 4px;
}
.homepage-banner-selecte-category .css-b62m3t-container {
  width: 100% !important;
}
.homepage-banner-selecte-category .css-t3ipsp-control:hover {
  border-color: transparent !important;
  outline: none;
}
.homepage-banner-selecte-category .css-t3ipsp-control:focus {
  border-color: transparent !important;
  outline: none !important;
}
.homepage-banner-selecte-category .css-19bb58m {
  height: 51px !important;
}
.homepage-banner-selecte-category .css-qbdosj-Input input {
  height: 45px !important;
}
/** ===== react-select ===== **/

.css-t3ipsp-control {
  border-color: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border-color: none !important;
}
.css-qbdosj-Input input {
  height: 32px !important;
}

/** ========== student-bookings ===== **/
.booking-box .tu-box {
  padding: 0px !important;
}


.w-40{
  width: 40% !important;
}
.w-60{
  width: 60% !important;
}

.booking-filter-box span {
  padding-top: 10px !important;
  font-weight: bolder;
}

.tu-bookingperson {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 14px;
}

.tu-bookingperson figure {
  margin: 0 10px 0 0;
  flex: none;
}
.tu-bookingperson figure img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
}
.tu-bookername {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tu-bookername h4 {
  margin: 0;
  font-weight: 600;
  align-items: center;
}
.tu-bookername h4 .tu-tagstatus {
  margin: 0 0 0 10px;
  font-size: 14px;
  /* font-size: initial; */
}
.tu-bookername h4 .tu-name{
  font-size: 20px;
}
.tu-tagstatus {
  background: #ffffff;
  border: 1.5px solid #dddddd;
  border-radius: 15px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: inline-block;
  padding: 0.5px 6.5px;
}
.tu-tagongoing {
  border-color: #22c55e !important;
}
.tu-bookername span {
  font-size: x-large;
  font-weight: 600;
  color: #1c1c1c;
}
.tu-bookingonfo {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tu-bookingonfo li {
  display: flex;
  flex-wrap: nowrap;
  gap: 25px;
  list-style-type: none;
  line-height: 30px !important;
}
.tu-bookingonfo li ul {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.tu-bookingonfo > li span {
  min-width: 190px;
  display: inline-block;
  color: #1c1c1c;
}
.tu-bookingonfo > li h6 {
  margin: 0;
  font-weight: 600;
  color: #1c1c1c;
}
.tu-bookingonfo > li h6 + a {
  margin-top: 2px;
  cursor: pointer;
}
.tu-bookingonfo > li > a {
  color: #1da1f2;
  font: 500 0.875rem/1.7142857143em "Outfit", sans-serif;
}
.tu-btnlist {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.tu-droplist {
  display: flex;
  justify-content: end;
  position: relative;
}
.tu-droplist:before {
  right: 20px;
  z-index: 1;
  color: black;
  font-size: 20px;
  content: "\e9c0";
  line-height: 40px;
  position: absolute;
  font-family: icomoon;
  pointer-events: none;
}

.tu-droplist > a {
  border-radius: 3px;
  background: #ffffff;
  align-items: center;
  display: inline-flex;
  color: #1c1c1c;
  border: 2px solid #dddddd;
  padding: 5px 54px 5px 24px;
  font: 600 1rem/1.625em "Outfit", sans-serif;
}
.tu-dropdownlist {
  padding: 0;
  margin: 0;
  top: 100%;
  width: 100%;
  z-index: 111;
  background: #ffffff;
  position: absolute;
  border: 1px solid #dddddd;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
  list-style: none;
}
.tu-dropdownlist li {
  margin: 10px;
  list-style-type: none;
}
.tu-dropdownlist li a {
  width: 100%;
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 3px;
  display: inline-block;
  color: #1c1c1c;
  font: 600 0.9375rem/1.6em "Open Sans", sans-serif;
}
.tu-bookingwrapper .tu-btnlist .tu-btnorangesm {
  padding: 0;
  min-width: 40px;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
}
.tu-bookingwrapper .tu-btnlist .tu-btnorangesm i {
  color: white;
  display: block;
  line-height: inherit;
}
.tu-droplist + .tu-btnorangesm {
  margin-left: 10px;
}
.tu-bookingwrapper {
  padding: 30px;
  position: relative;
  width: 100%;
}
.tu-bookingwrapper + .tu-bookingwrapper {
  box-shadow: inset 0px 2px 0px #eeeeee;
}
.tu-btnorangesm {
  border-color: #dddddd;
  min-width: 44px;
  padding: 0 8px;
  background: #f97316;
}
.bg-lightgreen {
  width: 90px;
  color: #18b99b;
  font-size: 40px;
  line-height: 90px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: inline-block;
  background: rgba(24, 185, 155, 0.1);
}
.bg-lightgreen i {
  display: block;
  line-height: inherit;
  float: none !important;
  margin-top: 0 !important;
}
.tu-alertpopup {
  text-align: center;
}
.tu-popimghead {
  display: flex;
  align-items: center;
}
.tu-popimghead img {
  flex: none;
  max-width: 50px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.booking-slot-details .modal-header h5 {
  font-weight: 600;
  margin-bottom: 0;
  color: #1c1c1c;
}
.booking-slot-details .modal-body {
  padding: 30px !important;
  position: relative;
}
.tu-bookedslotwrapper {
  max-height: 500px;
  overflow: auto;
}
.mCustomScrollbar {
  touch-action: pinch-zoom;
}
.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}
.tu-uploadprofile .tu-bookedslots:first-child {
  margin-top: 10px;
}
.tu-bookedslots {
  margin: 15px 0 30px;
  width: 100%;
}
.tu-checkout:last-child {
  padding-bottom: 0;
}
.tu-checkout {
  margin: 0;
  width: 100%;
  padding: 0 0 30px;
}
.tu-checkoutvtwo li:nth-child(odd) {
  padding: 9px 20px;
  border-radius: 4px;
  background-color: #f7f8fc;
  border: 1px solid #eeeeee;
}
.tu-checkoutvtwo li:nth-child(even) {
  border: 0;
  border-radius: 4px;
  padding: 10px 20px;
  background-color: #ffffff;
}
.tu-checkout li {
  align-items: center;
  justify-content: space-between;
}
.review-popup .tu-formspacebtw {
  justify-content: end !important;
}

.submission_container {
  max-height: 500px;
}
.tu-popimghead img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.submission_created {
  font-size: 12px;
  color: #999999;
}

.show_more_btn {
  background: 0;
  color: #1da1f2;
  font: 500 0.875rem/1.7142857143em "Outfit", sans-serif;
}

.tutor_view_submission {
  color: #1da1f2;
}

.upload_submission_btn {
  padding: 0;
  border-radius: 50%;
  width: 40px;
  cursor: pointer;
}

.tu-primbtn.upload_submission_btn::before {
  background: none;
}

.homepage_search_btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.homepage-banner-search .tu-appendinput .tu-inputicon {
  padding-right: 120px;
}

/** =========== transaction-state ========= **/
.transaction-state .tu-incomedetails {
  display: flex;
  margin: -5px -5px 40px;
  padding: 0;
  flex-wrap: wrap;
}
.transaction-state .tu-incomedetails li {
  padding: 5px;
  width: 50%;
}
.transaction-state .tu-incomeitem {
  padding: 16px 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.transaction-state .tu-incomeprice {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.transaction-state .tu-incomeprice > span {
  margin: 0 10px 0 0;
  flex: none;
}
.transaction-state .tu-incomeprice h5 {
  margin: 0;
  width: 100%;
  color: #1c1c1c;
  font: 700 1.125rem / 1.4444444444em "Outfit", sans-serif;
  padding-right: 10px;
}
.transaction-state .tu-incomeprice h5 span {
  display: inline-block;
  font: 400 0.9375rem / 1.6em "Open Sans", sans-serif;
  color: #999999;
  margin: 0 0 0 10px;
}
.transaction-state .tu-incomeicon {
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(24, 185, 155, 0.1);
  align-items: center;
  justify-content: center;
}
.transaction-state .tu-incomeicon i {
  display: inline-block;
  color: #18b99b;
  font-size: 20px;
}
.transaction-state .icon-pie-chart:before {
  content: "\e9bd";
}
.transaction-state .tu-incomeprice a {
  flex: none;
  float: right;
  font-weight: 500;
  font: 400 0.875rem / 1.5714285714em "Outfit", sans-serif;
  display: flex;
  flex-wrap: wrap;
  color: #1da1f2;
  align-items: center;
}
.transaction-state .tu-incomeprice a i {
  font-size: 18px;
  color: #1da1f2;
  vertical-align: middle;
  display: inline-block;
  margin: 0 0 0 5px;
}
.transaction-state .icon-rotate-cw:before {
  content: "\e9cc";
}
.transaction-state .tu-bgpurp {
  background: rgba(115, 87, 251, 0.1);
}
.transaction-state .tu-colorpurp {
  color: #7357fb !important;
}
.transaction-state .tu-bgred {
  background: rgba(255, 97, 103, 0.1);
}

.transaction-state .tu-colorblue {
  color: #309cff !important;
}
.transaction-state .tu-bgblue {
  background: rgba(48, 156, 255, 0.1);
}

/** ============ user-calendar ============ **/
.user-calendar .rbc-calendar {
  height: 700px !important;
  width: 100% !important;
}
.user-calendar .rbc-timeslot-group {
  min-height: 100px !important;
}
.booking-modal li {
  display: flex;
  gap: 5px !important;
}

.progress.profile_progess {
  border-radius: 0;
  height: auto;
}

.progress.profile_progess .progress-bar {
  background-color: #6a307d;
  font: 700 1rem / 1.625em "Outfit", sans-serif;
}

.tu-listinginfo_postcode span {
  font: 400 0.8125rem / 1.6923076923em "Open Sans", sans-serif;
}

.at-emojibar {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin: 0px;
  position: absolute;
  left: 11px;
  height: calc(100% - 2px);
  width: 58px;
  cursor: pointer;
  border-right: 1px solid rgb(221, 221, 221);
  justify-content: center;
}

.emoji {
  width: 100%;
  height: 100%;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.tu-processing-img {
  width: 100%;
  max-width: 50%;
  align-self: flex-start;
}

.faq-section .accordion-button::after {
  right: 15px;
  content: "\e9c0";
  font-size: 24px;
  line-height: 18px;
  position: absolute;
  color: #1c1c1c;
  font-family: "icomoon" !important;
}

.faq-section .accordion-button[aria-expanded="true"]:after {
  content: "\e9a3";
}

.faq-section .custom-accordion {
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-active-icon: none;
}

.faq-section .accordion-button {
  padding-right: 32px;
  background-color: #f7f8fc;
}
.faq-section .accordion-body {
  background-color: #f7f8fc;
}

.faq-section .accordion-item {
  border-bottom: 0;
  margin-bottom: 20px;
}

.faq-section .accordion-button:focus {
  box-shadow: none;
}

.tu-theme-box {
  padding: 29px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
}

.rc-time-picker-panel-narrow {
  width: 114px !important;
  max-width: 114px !important;
}
.tu-asidebox figure img {
  height: 150px;
  object-fit: cover;
}

.tu-custom-footer .tu-footerlist li {
  flex: 0 0 20%;
}

.tu-afterlogin .profile-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.available_dropdown_btn {
  background: 0;
  color: #000 !important;
  font-weight: 600;
  background: transparent !important;
  border: none !important;
}

.available_dropdown_btn.dropdown-toggle::after {
  display: none;
}

.available_dropdown_btn:hover {
  color: inherit;
  background: inherit;
}

.available_dropdown_btn .dropdown-item.active,
.available_dropdown_btn .dropdown-item:active {
  color: #000 !important;
  text-decoration: none;
  background-color: transparent;
}

.word-break-format {
  word-break: break-word;
}

.react-datepicker__header__dropdown {
  display: flex;
}

.form-not-clickable {
  pointer-events: none;
}

.dolar-label {
  height: 48px;
  width: 45px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  padding: 15px 0px 12px 15px !important;
}

/* ==== rc-time-picker ===== */
.rc-time-picker-panel-inner {
  top: 59px !important;
}
.rc-time-picker-panel-input-wrap {
  display: none;
}
.rc-time-picker-panel-combobox {
  width: 200px !important;
}
.rc-time-picker-panel-select {
  width: 100px !important;
  max-height: 190px !important;
}
.rc-time-picker-panel-select li {
  padding: 0 0 0 35px !important;
}
.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background: none;
}

.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background-color: #ef4444;
}

.tu-categories_content .homepage-category-image figure {
  position: relative;
  padding-bottom: 130%;
  overflow: hidden;
  margin: 0;
}
.tu-categories_content .homepage-category-image figure img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  object-fit: cover;
}

.notification-wrapper .tu-box {
  margin: 10px;
  padding-top: 10px !important;
}

.tu-left-banner-logo {
  max-height: 40px;
  object-fit: cover;
  width: 160px;
}

.tu-left-main-banner {
  width: 100%;
  max-width: 500px;
}
.tu-left-main-banner img {
  object-fit: cover;
  max-height: 318px;
  width: 100%;
  max-width: 500px;
}

.splide:not(.is-overflow) .splide__pagination {
  display: flex !important;
}
.tu-bannerv1_img img {
  width: 100%;
  max-height: 600px;
  max-width: 600px;
  object-fit: cover;
}
.tu-betterresult figure img {
  max-height: 450px;
  width: 100%;
  object-fit: cover;
}
.tu-processing-holder {
  width: 100%;
  display: flex;
  align-items: center;
  background-size: 50%;
  background-color: #ffffff;
  background-position: left;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.tu-howit-steps_content figure {
  position: relative;
  padding-bottom: 65%;
  overflow: hidden;
}
.tu-howit-steps_content figure img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tu-media-gallery .splide__pagination__page.is-active{
  background: #6A307D;
}

.tu-primbtn, .tu-secbtn, .tu-yellowbtn, .tu-primbtn-lg, .tu-primbtn-sm, .tu-primbtn-icon{
  padding: 7px 20px;
  min-height: 40px;
  text-align: center;
}
.tu-primbtn::before, .tu-secbtn::before, .tu-yellowbtn::before, .tu-primbtn-lg::before, .tu-primbtn-sm::before, .tu-primbtn-icon::before{
  top: 0;
  left: 0;
}
.upload_submission_btn{
  height: 40px!important;
  padding: 0 !important;
}
.upload_submission_btn i{
  line-height: 40px;
}

.tu-languages li:last-child::after {
  content: '';
}
.single-profile .tu-detailitem{
  padding-bottom: 5px;
  display: flex;
  gap: 5px;
  align-items: start;
}
.single-profile .tu-languages{
  gap: 0 8px;
}

.single-profile .tu-detailitem h6 {
  min-width: 150px;
  margin: 0;
}

.single-profile  .tu-tutorreview{
  margin: 0 0 5px 0;
}
.single-profile .tu-protutordetail h5{
  margin: 0 0 5px 0;
}
.single-profile .tu-profileview {
  align-items: flex-start;
}
.single-profile .tu-profileview figure img{
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.tutor-schedule .tu-formarea_list li a.myschedule-slot-active{
  background-color: #6A307D;
}
.tutor-schedule .tu-formarea_list li a.myschedule-slot-active h6{
  color: #fff;
}
.delete_slot{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 68, 68,0.8);
  justify-content: center;
  align-items: center;
}

.tutor-schedule .tu-formarea_list li a.myschedule-slot:hover .delete_slot{
  display: flex;
}

.tutor-schedule .tu-formarea_list .delete_slot span {
  font-size: 26px;
  color: #ffffff;
  cursor: pointer;
}
.tutor-schedule .tu-formarea_list .delete_slot span i{
  line-height: 50px;
}
.tu-primbtn .icon-arrow-right:before, .tu-secbtn .icon-arrow-right:before, .tu-yellowbtn .icon-arrow-right:before, .tu-primbtn-lg .icon-arrow-right:before, .tu-primbtn-sm .icon-arrow-right:before, .tu-primbtn-icon .icon-arrow-right:before{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  min-height: 30px!important;
}


.rbc-time-view .rbc-allday-cell{
  display: none;
}

.user-calendar .rbc-day-slot .rbc-event{
  left: 5px!important;
}

.user-calendar .rbc-day-slot .rbc-event-content {
  text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 100px !important;
    font-size: 14px;
}
.user-calendar .rbc-day-slot .rbc-event-label{
  display: none;
}

/* .rbc-header:nth-child(2){
  display: none;
}
.rbc-agenda-time-cell{
  display: none;
} */


/* Responsive */

@media (max-width: 1199px) {
  .tu-banner_title {
    margin-right: 10px;
  }
  .tu-banner_title h1 {
    font-size: 46px;
  }
  .tu-banner-info {
    font-size: 46px;
    min-height: 50px;
  }
  .homepage-banner-search .tu-primbtn-orange {
    padding: 0 12px;
  }
  .tu-headerbtn {
    padding: 10px;
  }
  .edu .accordion-button{
    align-items: flex-start;
  }
  .tutor-schedule .tu-formarea_list li a h6{
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 991px) {
  .tu-custom-footer .tu-footerlist li {
    flex: 0 0 25%;
  }
  .tu-login-left_title h2 {
    font-size: 26px;
  }
  .tu-login-left_title span {
    font-size: 22px;
  }
  .tu-login-left figure {
    margin: 30px 0 0 0;
  }
  .tu-login-left_title {
    padding-top: 30px;
  }
  .tu-processing-holder {
    background: 0 !important;
  }
 
}

@media (max-width: 767px) {
  .tu-custom-footer .tu-footerlist li {
    flex: 0 0 33%;
  }
  .tu-featureitem,
  .tu-categories_content {
    max-width: 336px;
    margin: 0 auto;
  }
  .tu-savedinfo figure{
    margin: 0 0 10px 0;
  }
  .tu-savedwrapper{
    flex-wrap: wrap;
  }
  .tu-savedinfo{
    flex: 0 0 100%;
    flex-wrap: wrap;
  }
  .tu-savedtites{
    width: 100%;
  }
  .tu-savebtns{
    flex: 0 0 100%;
    margin-top: 10px;
    justify-content: flex-start;
  }
  .booking_page .tu-boxsm{
    flex-wrap: wrap;
  }
  
  .tu-bookername span.tu-tagstatus{
    font-size: 14px;
  }
  .booking_page .tu-bookername{
    flex-wrap: wrap;
    gap: 5px;
  }
  .tu-boxsmtitle .booking-title{
    width: 100%!important;
  }
  
  
  
}
@media (max-width: 575px) {
  .tu-custom-footer .tu-footerlist li {
    flex: 0 0 100%;
  }
  .tu-login-left_title h2,
  .tu-maintitle h2 {
    font-size: 24px;
  }
  .tu-login-left_title span {
    font-size: 18px;
  }
  .tu-login-right_title h3 {
    font-size: 22px;
  }
  .tu-banner {
    padding: 40px 0;
  }
  .tu-banner_title h1 {
    font-size: 34px;
  }
  .tu-bannerinfo {
    font-size: 30px;
    line-height: 40px;
    min-height: 50px;
  }
  .tu-banner_title p {
    font-size: 14px;
  }
  .faq-section .accordion-header {
    line-height: 22px;
  }
  .faq-section .accordion-body {
    font-size: 14px;
    line-height: 20px;
  }
  .tu-policy .tu-theme-box {
    padding: 10px;
  }
  .assignment_box.booking-box .tu-bookername{
    flex-direction: column-reverse;
    margin-top: -25px;
  }

  .booking-box .tu-bookingonfo li{
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 10px;
  }
  .booking-box .tu-bookingonfo > li span{
    min-width: 100%;
  }
  .booking-box .tu-bookingonfo li p{
    font-size: 14px;
    line-height: 20px;
  }
  .tu-bookingwrapper{
    padding: 30px 15px;
  }
  .tu-bookername h4{
    font-size: 20px;
  }
  .user_profile_layout .tu-btnarea-two{flex-direction: column-reverse;
    align-items: center;
    gap: 5px}
    .tu-bookingperson{
      flex-wrap: wrap;
    }
}

@media (max-width: 480px) {
  .homepage_search_btn {
    position: relative;
    transform: unset;
    top: 5px;
    right: 0;
    width: 100%;
  }
  .homepage-banner-search .tu-appendinput .tu-inputicon {
    padding-right: 0;
  }
  .homepage-banner-search .tu-appendinput {
    height: auto !important;
  }
  .homepage-banner-search .tu-primbtn-orange {
    padding: 5px 15px;
    height: auto;
  }
  .tu-header nav strong {
    width: auto;
  }
  .tu-header nav{
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 375px) {
  .radio_wrapper{
    height: auto;
  }
  .radio_wrapper .tu-radiosm{
    width: 100%;
  }
  .tu-bookername h4 .tu-tagstatus {
  margin : 0;
  }
  .tu-bookername h4 .tu-name{
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
  }
  .booking-filter-box{
    flex-wrap: wrap;
  }
  .booking-filter-box span{
    display: inline-block;
    width: 100%;
  }
}

.unread-conversation {
  margin-left: 10px;
  color: #fff !important;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 56px;
  background: #808;
}
