@font-face {
    font-family: 'guppy-icomoon';
    src:  url('fonts/icomoon.eot?u5uh34');
    src:  url('fonts/icomoon.eot?u5uh34#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?u5uh34') format('truetype'),
      url('fonts/icomoon.woff?u5uh34') format('woff'),
      url('fonts/icomoon.svg?u5uh34#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="guppy-"], [class*=" guppy-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'guppy-icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .guppy-activity:before {
    content: "\e900";
  }
  .guppy-airplay:before {
    content: "\e901";
  }
  .guppy-alert-circle:before {
    content: "\e902";
  }
  .guppy-alert-octagon:before {
    content: "\e903";
  }
  .guppy-alert-triangle:before {
    content: "\e904";
  }
  .guppy-align-center:before {
    content: "\e905";
  }
  .guppy-align-justify:before {
    content: "\e906";
  }
  .guppy-align-left:before {
    content: "\e907";
  }
  .guppy-align-right:before {
    content: "\e908";
  }
  .guppy-anchor:before {
    content: "\e909";
  }
  .guppy-aperture:before {
    content: "\e90a";
  }
  .guppy-archive:before {
    content: "\e90b";
  }
  .guppy-arrow-down:before {
    content: "\e90c";
  }
  .guppy-arrow-down-circle:before {
    content: "\e90d";
  }
  .guppy-arrow-down-left:before {
    content: "\e90e";
  }
  .guppy-arrow-down-right:before {
    content: "\e90f";
  }
  .guppy-arrow-left:before {
    content: "\e910";
  }
  .guppy-arrow-left-circle:before {
    content: "\e911";
  }
  .guppy-arrow-right:before {
    content: "\e912";
  }
  .guppy-arrow-right-circle:before {
    content: "\e913";
  }
  .guppy-arrow-up:before {
    content: "\e914";
  }
  .guppy-arrow-up-circle:before {
    content: "\e915";
  }
  .guppy-arrow-up-left:before {
    content: "\e916";
  }
  .guppy-arrow-up-right:before {
    content: "\e917";
  }
  .guppy-at-sign:before {
    content: "\e918";
  }
  .guppy-award:before {
    content: "\e919";
  }
  .guppy-bar-chart:before {
    content: "\e91a";
  }
  .guppy-bar-chart-2:before {
    content: "\e91b";
  }
  .guppy-battery:before {
    content: "\e91c";
  }
  .guppy-battery-charging:before {
    content: "\e91d";
  }
  .guppy-bell:before {
    content: "\e91e";
  }
  .guppy-bell-off:before {
    content: "\e91f";
  }
  .guppy-bluetooth:before {
    content: "\e920";
  }
  .guppy-bold:before {
    content: "\e921";
  }
  .guppy-book:before {
    content: "\e922";
  }
  .guppy-book-open:before {
    content: "\e924";
  }
  .guppy-bookmark:before {
    content: "\e923";
  }
  .guppy-box:before {
    content: "\e925";
  }
  .guppy-briefcase:before {
    content: "\e926";
  }
  .guppy-calendar:before {
    content: "\e927";
  }
  .guppy-camera:before {
    content: "\e928";
  }
  .guppy-camera-off:before {
    content: "\e929";
  }
  .guppy-cast:before {
    content: "\e92a";
  }
  .guppy-check:before {
    content: "\e92b";
  }
  .guppy-check-circle:before {
    content: "\e92c";
  }
  .guppy-check-square:before {
    content: "\e92d";
  }
  .guppy-chevron-down:before {
    content: "\e92e";
  }
  .guppy-chevron-left:before {
    content: "\e92f";
  }
  .guppy-chevron-right:before {
    content: "\e930";
  }
  .guppy-chevron-up:before {
    content: "\e935";
  }
  .guppy-chevrons-down:before {
    content: "\e931";
  }
  .guppy-chevrons-left:before {
    content: "\e932";
  }
  .guppy-chevrons-right:before {
    content: "\e933";
  }
  .guppy-chevrons-up:before {
    content: "\e934";
  }
  .guppy-chrome:before {
    content: "\e936";
  }
  .guppy-circle:before {
    content: "\e937";
  }
  .guppy-clipboard:before {
    content: "\e938";
  }
  .guppy-clock:before {
    content: "\e939";
  }
  .guppy-cloud:before {
    content: "\e93a";
  }
  .guppy-cloud-drizzle:before {
    content: "\e93b";
  }
  .guppy-cloud-lightning:before {
    content: "\e93c";
  }
  .guppy-cloud-off:before {
    content: "\e93d";
  }
  .guppy-cloud-rain:before {
    content: "\e93e";
  }
  .guppy-cloud-snow:before {
    content: "\e93f";
  }
  .guppy-code:before {
    content: "\e940";
  }
  .guppy-codepen:before {
    content: "\e941";
  }
  .guppy-codesandbox:before {
    content: "\e942";
  }
  .guppy-coffee:before {
    content: "\e943";
  }
  .guppy-columns:before {
    content: "\e944";
  }
  .guppy-command:before {
    content: "\e945";
  }
  .guppy-compass:before {
    content: "\e946";
  }
  .guppy-copy:before {
    content: "\e947";
  }
  .guppy-corner-down-left:before {
    content: "\e948";
  }
  .guppy-corner-down-right:before {
    content: "\e949";
  }
  .guppy-corner-left-down:before {
    content: "\e94a";
  }
  .guppy-corner-left-up:before {
    content: "\e94b";
  }
  .guppy-corner-right-down:before {
    content: "\e94c";
  }
  .guppy-corner-right-up:before {
    content: "\e94d";
  }
  .guppy-corner-up-left:before {
    content: "\e94e";
  }
  .guppy-corner-up-right:before {
    content: "\e94f";
  }
  .guppy-cpu:before {
    content: "\e950";
  }
  .guppy-credit-card:before {
    content: "\e951";
  }
  .guppy-crop:before {
    content: "\e952";
  }
  .guppy-crosshair:before {
    content: "\e953";
  }
  .guppy-database:before {
    content: "\e954";
  }
  .guppy-delete:before {
    content: "\e955";
  }
  .guppy-disc:before {
    content: "\e956";
  }
  .guppy-divide:before {
    content: "\e957";
  }
  .guppy-divide-circle:before {
    content: "\e958";
  }
  .guppy-divide-square:before {
    content: "\e959";
  }
  .guppy-dollar-sign:before {
    content: "\e95a";
  }
  .guppy-double-check:before {
    content: "\ea1e";
  }
  .guppy-download:before {
    content: "\e95b";
  }
  .guppy-download-cloud:before {
    content: "\e95c";
  }
  .guppy-dribbble:before {
    content: "\e95d";
  }
  .guppy-droplet:before {
    content: "\e95e";
  }
  .guppy-edit:before {
    content: "\e95f";
  }
  .guppy-edit-2:before {
    content: "\e960";
  }
  .guppy-edit-3:before {
    content: "\e961";
  }
  .guppy-external-link:before {
    content: "\e962";
  }
  .guppy-eye:before {
    content: "\e963";
  }
  .guppy-eye-off:before {
    content: "\e964";
  }
  .guppy-facebook:before {
    content: "\e965";
  }
  .guppy-fast-forward:before {
    content: "\e966";
  }
  .guppy-feather:before {
    content: "\e967";
  }
  .guppy-figma:before {
    content: "\e968";
  }
  .guppy-file:before {
    content: "\e969";
  }
  .guppy-file-minus:before {
    content: "\e96a";
  }
  .guppy-file-plus:before {
    content: "\e96b";
  }
  .guppy-file-text:before {
    content: "\e96c";
  }
  .guppy-film:before {
    content: "\e96d";
  }
  .guppy-filter:before {
    content: "\e96e";
  }
  .guppy-flag:before {
    content: "\e96f";
  }
  .guppy-folder:before {
    content: "\e970";
  }
  .guppy-folder-minus:before {
    content: "\e971";
  }
  .guppy-folder-plus:before {
    content: "\e972";
  }
  .guppy-framer:before {
    content: "\e973";
  }
  .guppy-frown:before {
    content: "\e974";
  }
  .guppy-gift:before {
    content: "\e975";
  }
  .guppy-git-branch:before {
    content: "\e976";
  }
  .guppy-git-commit:before {
    content: "\e977";
  }
  .guppy-git-merge:before {
    content: "\e97a";
  }
  .guppy-git-pull-request:before {
    content: "\e97b";
  }
  .guppy-github:before {
    content: "\e978";
  }
  .guppy-gitlab:before {
    content: "\e979";
  }
  .guppy-globe:before {
    content: "\e97c";
  }
  .guppy-grid:before {
    content: "\e97d";
  }
  .guppy-hard-drive:before {
    content: "\e97e";
  }
  .guppy-hash:before {
    content: "\e97f";
  }
  .guppy-headphones:before {
    content: "\e980";
  }
  .guppy-heart:before {
    content: "\e981";
  }
  .guppy-help-circle:before {
    content: "\e982";
  }
  .guppy-hexagon:before {
    content: "\e983";
  }
  .guppy-home:before {
    content: "\e984";
  }
  .guppy-image:before {
    content: "\e985";
  }
  .guppy-inbox:before {
    content: "\e986";
  }
  .guppy-info:before {
    content: "\e987";
  }
  .guppy-instagram:before {
    content: "\e988";
  }
  .guppy-italic:before {
    content: "\e989";
  }
  .guppy-key:before {
    content: "\e98a";
  }
  .guppy-layers:before {
    content: "\e98b";
  }
  .guppy-layout:before {
    content: "\e98c";
  }
  .guppy-life-buoy:before {
    content: "\e98d";
  }
  .guppy-link:before {
    content: "\e98e";
  }
  .guppy-link-2:before {
    content: "\e98f";
  }
  .guppy-linkedin:before {
    content: "\e990";
  }
  .guppy-list:before {
    content: "\e991";
  }
  .guppy-loader:before {
    content: "\e992";
  }
  .guppy-lock:before {
    content: "\e993";
  }
  .guppy-log-in:before {
    content: "\e994";
  }
  .guppy-log-out:before {
    content: "\e995";
  }
  .guppy-mail:before {
    content: "\e996";
  }
  .guppy-map:before {
    content: "\e997";
  }
  .guppy-map-pin:before {
    content: "\e998";
  }
  .guppy-maximize:before {
    content: "\e999";
  }
  .guppy-maximize-2:before {
    content: "\e99a";
  }
  .guppy-meh:before {
    content: "\e99b";
  }
  .guppy-menu:before {
    content: "\e99c";
  }
  .guppy-message-circle:before {
    content: "\e99d";
  }
  .guppy-message-square:before {
    content: "\e99e";
  }
  .guppy-mic:before {
    content: "\e99f";
  }
  .guppy-mic-off:before {
    content: "\e9a0";
  }
  .guppy-minimize:before {
    content: "\e9a1";
  }
  .guppy-minimize-2:before {
    content: "\e9a2";
  }
  .guppy-minus:before {
    content: "\e9a3";
  }
  .guppy-minus-circle:before {
    content: "\e9a4";
  }
  .guppy-minus-square:before {
    content: "\e9a5";
  }
  .guppy-monitor:before {
    content: "\e9a6";
  }
  .guppy-moon:before {
    content: "\e9a7";
  }
  .guppy-more-horizontal:before {
    content: "\e9a8";
  }
  .guppy-more-vertical:before {
    content: "\e9a9";
  }
  .guppy-mouse-pointer:before {
    content: "\e9aa";
  }
  .guppy-move:before {
    content: "\e9ab";
  }
  .guppy-music:before {
    content: "\e9ac";
  }
  .guppy-navigation:before {
    content: "\e9ad";
  }
  .guppy-navigation-2:before {
    content: "\e9ae";
  }
  .guppy-octagon:before {
    content: "\e9af";
  }
  .guppy-package:before {
    content: "\e9b0";
  }
  .guppy-paperclip:before {
    content: "\e9b1";
  }
  .guppy-pause:before {
    content: "\e9b2";
  }
  .guppy-pause-circle:before {
    content: "\e9b3";
  }
  .guppy-pen-tool:before {
    content: "\e9b4";
  }
  .guppy-percent:before {
    content: "\e9b5";
  }
  .guppy-phone:before {
    content: "\e9b6";
  }
  .guppy-phone-call:before {
    content: "\e9b7";
  }
  .guppy-phone-forwarded:before {
    content: "\e9b8";
  }
  .guppy-phone-incoming:before {
    content: "\e9b9";
  }
  .guppy-phone-missed:before {
    content: "\e9ba";
  }
  .guppy-phone-off:before {
    content: "\e9bb";
  }
  .guppy-phone-outgoing:before {
    content: "\e9bc";
  }
  .guppy-pie-chart:before {
    content: "\e9bd";
  }
  .guppy-play:before {
    content: "\e9be";
  }
  .guppy-play-circle:before {
    content: "\e9bf";
  }
  .guppy-plus:before {
    content: "\e9c0";
  }
  .guppy-plus-circle:before {
    content: "\e9c1";
  }
  .guppy-plus-square:before {
    content: "\e9c2";
  }
  .guppy-pocket:before {
    content: "\e9c3";
  }
  .guppy-power:before {
    content: "\e9c4";
  }
  .guppy-printer:before {
    content: "\e9c5";
  }
  .guppy-radio:before {
    content: "\e9c6";
  }
  .guppy-refresh-ccw:before {
    content: "\e9c7";
  }
  .guppy-refresh-cw:before {
    content: "\e9c8";
  }
  .guppy-repeat:before {
    content: "\e9c9";
  }
  .guppy-rewind:before {
    content: "\e9ca";
  }
  .guppy-rotate-ccw:before {
    content: "\e9cb";
  }
  .guppy-rotate-cw:before {
    content: "\e9cc";
  }
  .guppy-rss:before {
    content: "\e9cd";
  }
  .guppy-save:before {
    content: "\e9ce";
  }
  .guppy-scissors:before {
    content: "\e9cf";
  }
  .guppy-search:before {
    content: "\e9d0";
  }
  .guppy-send:before {
    content: "\e9d1";
  }
  .guppy-server:before {
    content: "\e9d2";
  }
  .guppy-settings:before {
    content: "\e9d3";
  }
  .guppy-share:before {
    content: "\e9d4";
  }
  .guppy-share-2:before {
    content: "\e9d5";
  }
  .guppy-shield:before {
    content: "\e9d6";
  }
  .guppy-shield-off:before {
    content: "\e9d7";
  }
  .guppy-shopping-bag:before {
    content: "\e9d8";
  }
  .guppy-shopping-cart:before {
    content: "\e9d9";
  }
  .guppy-shuffle:before {
    content: "\e9da";
  }
  .guppy-sidebar:before {
    content: "\e9db";
  }
  .guppy-skip-back:before {
    content: "\e9dc";
  }
  .guppy-skip-forward:before {
    content: "\e9dd";
  }
  .guppy-slack:before {
    content: "\e9de";
  }
  .guppy-slash:before {
    content: "\e9df";
  }
  .guppy-sliders:before {
    content: "\e9e0";
  }
  .guppy-smartphone:before {
    content: "\e9e1";
  }
  .guppy-smile:before {
    content: "\e9e2";
  }
  .guppy-speaker:before {
    content: "\e9e3";
  }
  .guppy-square:before {
    content: "\e9e4";
  }
  .guppy-star:before {
    content: "\e9e5";
  }
  .guppy-stop-circle:before {
    content: "\e9e6";
  }
  .guppy-sun:before {
    content: "\e9e7";
  }
  .guppy-sunrise:before {
    content: "\e9e8";
  }
  .guppy-sunset:before {
    content: "\e9e9";
  }
  .guppy-tablet:before {
    content: "\e9ea";
  }
  .guppy-tag:before {
    content: "\e9eb";
  }
  .guppy-target:before {
    content: "\e9ec";
  }
  .guppy-terminal:before {
    content: "\e9ed";
  }
  .guppy-thermometer:before {
    content: "\e9ee";
  }
  .guppy-thumbs-down:before {
    content: "\e9ef";
  }
  .guppy-thumbs-up:before {
    content: "\e9f0";
  }
  .guppy-toggle-left:before {
    content: "\e9f1";
  }
  .guppy-toggle-right:before {
    content: "\e9f2";
  }
  .guppy-tool:before {
    content: "\e9f3";
  }
  .guppy-trash:before {
    content: "\e9f4";
  }
  .guppy-trash-2:before {
    content: "\e9f5";
  }
  .guppy-trello:before {
    content: "\e9f6";
  }
  .guppy-trending-down:before {
    content: "\e9f7";
  }
  .guppy-trending-up:before {
    content: "\e9f8";
  }
  .guppy-triangle:before {
    content: "\e9f9";
  }
  .guppy-truck:before {
    content: "\e9fa";
  }
  .guppy-tv:before {
    content: "\e9fb";
  }
  .guppy-twitch:before {
    content: "\e9fc";
  }
  .guppy-twitter:before {
    content: "\e9fd";
  }
  .guppy-type:before {
    content: "\e9fe";
  }
  .guppy-umbrella:before {
    content: "\e9ff";
  }
  .guppy-underline:before {
    content: "\ea00";
  }
  .guppy-unlock:before {
    content: "\ea01";
  }
  .guppy-upload:before {
    content: "\ea02";
  }
  .guppy-upload-cloud:before {
    content: "\ea03";
  }
  .guppy-user:before {
    content: "\ea04";
  }
  .guppy-user-check:before {
    content: "\ea05";
  }
  .guppy-user-minus:before {
    content: "\ea06";
  }
  .guppy-user-plus:before {
    content: "\ea07";
  }
  .guppy-user-x:before {
    content: "\ea09";
  }
  .guppy-users:before {
    content: "\ea08";
  }
  .guppy-video:before {
    content: "\ea0a";
  }
  .guppy-video-off:before {
    content: "\ea0b";
  }
  .guppy-voicemail:before {
    content: "\ea0c";
  }
  .guppy-volume:before {
    content: "\ea0d";
  }
  .guppy-volume-1:before {
    content: "\ea0e";
  }
  .guppy-volume-2:before {
    content: "\ea0f";
  }
  .guppy-volume-x:before {
    content: "\ea10";
  }
  .guppy-watch:before {
    content: "\ea11";
  }
  .guppy-wifi:before {
    content: "\ea12";
  }
  .guppy-wifi-off:before {
    content: "\ea13";
  }
  .guppy-wind:before {
    content: "\ea14";
  }
  .guppy-x:before {
    content: "\ea15";
  }
  .guppy-x-circle:before {
    content: "\ea16";
  }
  .guppy-x-octagon:before {
    content: "\ea17";
  }
  .guppy-x-square:before {
    content: "\ea18";
  }
  .guppy-youtube:before {
    content: "\ea19";
  }
  .guppy-zap:before {
    content: "\ea1a";
  }
  .guppy-zap-off:before {
    content: "\ea1b";
  }
  .guppy-zoom-in:before {
    content: "\ea1c";
  }
  .guppy-zoom-out:before {
    content: "\ea1d";
  }
  