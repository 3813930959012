:root {
  --primguppycolor: #ff7300;
  --secguppycolor: #0a0f26;
  --terguppycolor: #999999;
}

@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Urbanist:wght@400;500;700&display=swap);
.at-inner-loaader,
.at-userbar_loader.at-inner-loaader {
  height: 134px;
  bottom: 0;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 59.37%);
  z-index: 1;
}

.at-inner-loaader i {
  width: 24px;
  height: 24px;
  border: 3px solid #ddd;
  border-right-color: #fff;
  border-radius: 50px 50px 50px 50px;
  -webkit-animation: spinericon 1s linear infinite;
  animation: spinericon 1s linear infinite;
}

.at-chatsidebar_float .at-chatloader span {
  left: 49px;
  top: 0;
  position: absolute;
  -webkit-animation: at-chatloader 0.78125s linear infinite;
  animation: at-chatloader 0.78125s linear infinite;
  background: var(--terguppycolor);
  width: 4.8px;
  height: 24px;
  border-radius: 1.5px;
  transform-origin: 1px 44px;
  display: block;
}

.at-userpost_profile {
  margin: 0;
  flex: none;
  width: 44px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.at-userpost_profile + .at-userbar_title {
  margin-left: 15px;
}

.at-userpost_profile img:not(.at-postuserimg) {
  width: 100%;
  max-width: 36px;
  margin-top: auto;
  border-radius: 50%;
  margin-left: auto;
}

.at-chat_previewfloat
  .at-userpost_profile
  img:not(.at-postuserimg) {
  max-width: 34px;
}

.at-chat_previewfloat .at-postuserimg {
  max-width: 18px;
  height: 18px;
}

.at-chat_previewfloat .at-statusoffline:after {
  width: 10px;
  height: 10px;
}

.at-chat_previewfloat .at-groupuser_img img {
  margin: 0;
  width: 24px;
  height: 24px;
}

.at-postuserimg {
  margin: 0;
  top: 0;
  left: 2px;
  z-index: 1;
  height: 20px;
  max-width: 20px;
  border-radius: 50%;
  position: absolute;
  vertical-align: top;
  display: inline-block;
  outline: 4px solid #fff;
}

.at-postuserimg img {
  max-width: 30.5px;
  border: 4px solid #fff;
}

.at-statusonline:after {
  background: #22c55e;
}

.at-statusoffline:after,
.at-statusonline:after {
  z-index: 1;
  right: -3px;
  content: "";
  bottom: -3px;
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #f7f7f7;
}

.at-statusoffline:after {
  background: #ddd;
}

.at-empty-conversation {
  text-align: center;
  background-color: #f7f7f7;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.at-chatsidebar_float .at-empty-conversation i {
  color: var(--primguppycolor);
  font-size: 32px;
  display: block;
  margin-bottom: 20px;
}

.at-empty-conversation span {
  color: var(--terguppycolor);
  display: block;
  font: 500 18px/30px var(--primchatfont);
}

.gp-closechat {
  margin-left: auto;
  color: #fff;
  font-size: 28px;
  text-decoration: none;
}

.gp-userbar_title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
}

.gp-userbar_title > em {
  display: block;
  margin: 0 0 2px;
  color: #3c57e5;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  font-style: normal;
}

.gp-userbar_title h3 {
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}

.gp-userbar_title > p {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
  line-height: 22px;
  color: var(--secwhatsappcolor);
}

.gp-floatchat_title-right a i {
  display: block;
}

.gp-userbar_title > p span {
  margin: 0;
  line-height: 22px;
  color: var(--terwhatsappcolor);
}

.gp-userbar_title > p em {
  font-style: normal;
  line-height: 22px;
}

.gp-whatsappbtn {
  display: flex;
  align-items: center;
  border-radius: 60px;
  padding: 8.5px 14.5px;
  border: 1.5px solid var(--primguppycolor);
}

.gp-whatsappbtn svg path {
  fill: var(--primguppycolor);
}

.gp-whatsappbtn svg {
  margin-right: 10px;
}

.gp-whatsappbtn span {
  color: var(--secwhatsappcolor);
  font: 700 13px/20px var(--primchatfont);
}

.gp-userlist_tab {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.gp-inner-loaader {
  bottom: 0;
}

.gp-sidebarhead {
  z-index: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  background: var(--primguppycolor);
}

.gp-sidebarhead > img {
  margin-right: 10px;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
}

.gp-whatsappsidepopup {
  position: relative;
  z-index: 9999999;
  color: var(--secwhatsappcolor);
  font: 400 14px/26px var(--terwhatsappcolor);
}

.gp-sidebarhead > h2 {
  color: #fff !important;
  letter-spacing: 0.5px;
  font-size: 22px;
  margin: 0;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 0;
  text-overflow: ellipsis;
}

.gp-userlist_tab li.gp-userbar:last-child {
  margin-bottom: 40px;
}

.gp-sidebarhead > a:hover {
  color: #fff;
}

.gp-sidebarhead_search {
  width: 100%;
  position: relative;
  padding: 20px;
  background: #fff;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
}

.gp-sidebarhead_search h3 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 10px;
}

.gp-sidebarhead_search .gp-form-group i {
  position: absolute;
  left: 18px;
  font-size: 18px;
  color: var(--terwhatsappcolor);
}

.gp-sidebarhead_search .gp-form-group {
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.gp-sidebarhead_search .gp-form-group input {
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 3px;
}

.gp-userlist_tab ul {
  height: calc(100% - 116px);
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.gp-userlist_tab li {
  list-style-type: none;
  width: 100%;
  margin: 0;
}

.gp-userbar {
  padding: 20px !important;
  cursor: pointer;
}

.gp-userbar,
.gp-userbar_profile {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.gp-userbar_profile {
  flex: none;
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0;
  justify-content: center;
  background: #f7f7f7;
  position: relative;
}

.gp-userbar_profile img {
  width: 100%;
  margin: 0 auto;
  height: 50px;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50px !important;
}

.gp-userstatus {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
}

.gp-userstatus:before {
  content: "";
  margin: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.gp-userbar_profile .gp-userstatus:before {
  margin: 0;
  width: 14px;
  height: 14px;
  border: 3px solid #fff;
}

.gp-userstatus.online:before {
  background-color: #22c55e;
}

.gp-userstatus.offline:before {
  background-color: #ddd;
}

.gp-userstatus.away:before {
  background-color: #eab308;
}

.gp-userstatus.notify:before {
  background-color: #ff7300;
}

.gp-userbar.gp-notavailable {
  mix-blend-mode: luminosity;
}

.gp-userbar_right {
  text-align: right;
  margin-left: auto;
  flex: none;
  padding-left: 10px;
}

.gp-userbar_right a {
  text-decoration: none !important;
}

.gp-userbar_right > span {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: var(--terwhatsappcolor);
}

.gp-userlist_tab li + li {
  border-top: 1px solid #ddd;
}

@media (max-width: 420px) {
  .gp-whatsappbtn {
    padding: 13.5px;
  }

  .gp-whatsappbtn span {
    display: none;
  }

  .gp-whatsappbtn svg {
    margin: 0;
  }
}

.gp-willrespond {
  background: #f7f7f7;
  box-shadow: inset 0 -1px 0 #eee;
  padding: 10px 20px;
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.gp-willrespond span {
  color: #999;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  font-family: var(--secchatfont);
  width: 100%;
  text-align: center;
}

.gp-users-list:after {
  position: absolute;
  content: "";
  right: 48px;
  display: inline-block;
  z-index: 1111;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  box-shadow: inset 1px -1px 0 #eee;
}

.gp-users-list .gp-sidebarhead_search {
  box-shadow: inset 0 -1px 0 #eee;
  border: 0;
}

.gp-users-list .gp-conversation-list {
  padding: 20px;
  height: calc(100% - 235px);
}

.gp-users-list .gp-chat_sidebar {
  border-radius: 0 0 10px 10px;
  display: block !important;
}

.gp-users-list .gp-conversation-list li {
  border-radius: 0 10px 10px 0;
  border: 1px solid #eee;
  padding: 16px 20px !important;
  box-shadow: 0 4px 6px transparent, inset 3px 0 0 #25d366;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(-10px);
  position: relative;
}

.gp-users-list.active {
  max-height: 600px;
  transition: all 0.5s;
}

.gp-conversation-list.active li {
  opacity: 1;
  transform: translateY(0);
}

.gp-conversation-list.active li:first-of-type {
  transition-delay: 0.3s;
}

.gp-conversation-list.active li:nth-of-type(2) {
  transition-delay: 0.6s;
}

.gp-conversation-list.active li:nth-of-type(3) {
  transition-delay: 0.7s;
}

.gp-conversation-list.active li:nth-of-type(4) {
  transition-delay: 0.8s;
}

.gp-conversation-list.active li:nth-of-type(5) {
  transition-delay: 0.9s;
}

.gp-users-list .gp-conversation-list li:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07), inset 3px 0 0 #25d366;
}

.gp-users-list .gp-conversation-list li + li {
  margin: 10px 0 0;
}

.gp-users-list .gp-userbar_title h3 {
  font-size: 16px;
  line-height: 19px;
}

.gp-users-list .gp-userbar_title > em {
  display: block;
  margin: 0 0 2px;
  color: #3c57e5;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}

.gp-users-list .gp-userbar_title > p span {
  font-size: 13px;
  margin: 2px 0 0;
  line-height: 18px;
  font-weight: 600;
  font-family: var(--secchatfont);
}

.gp-users-list .gp-whatsapp-user .gp-userbar_right .gp-whatsappbtn {
  background: var(--primguppycolor);
  width: 44px;
  border-radius: 50%;
  line-height: 44px;
  height: 44px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gp-users-list .gp-whatsapp-user .gp-userbar_right .gp-whatsappbtn svg {
  margin: 0;
}

.gp-users-list .gp-whatsappbtn svg path {
  fill: #fff;
}

.gp-users-list
  .gp-conversation-list
  li.gp-notavailable
  .gp-userbar_right
  .gp-whatsappbtn {
  background: #fff;
  border: 2px solid #eee;
}

.gp-users-list .gp-conversation-list li.gp-notavailable {
  background: #f7f7f7;
  mix-blend-mode: normal;
  box-shadow: 0 4px 6px transparent, inset 3px 0 0 #999;
}

.gp-users-list .gp-conversation-list li.gp-notavailable figure {
  mix-blend-mode: luminosity;
}

.gp-singleimg.gp-notavailable img {
  filter: grayscale(1);
}

.gp-users-list .gp-conversation-list li.gp-notavailable:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07), inset 3px 0 0 #999;
}

.gp-users-list .gp-conversation-list li.gp-notavailable svg path {
  fill: #999;
}

.gp-users-list .gp-userstatus.offline:before {
  background-color: #999;
}

.gp-users-list .gp-userbar_title {
  margin-left: 10px;
}

.gp-users-list .gp-conversation-list li.gp-notavailable .gp-userbar_title > em {
  color: #3c57e5;
  font-family: var(--secchatfont);
}

.gp-users-list .gp-sidebarhead {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px 16px 67px;
  border-radius: 10px 10px 0 0;
}

.gp-users-list .gp-sidebarhead svg {
  position: absolute;
  left: 0;
  opacity: 0.5;
}

.gp-users-list .gp-sidebarhead > h2 {
  margin: 0 0 4px;
  font-size: 20px;
  line-height: 22px;
  white-space: normal;
}

.gp-users-list .gp-sidebarhead p {
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  font-weight: 400;
  width: 90%;
  margin: 0;
}

.gp-users-list .gp-sidebarhead p span {
  font-weight: 700;
}

.gp-chatsidebar_float.gp-users-list {
  max-width: 400px;
  border-radius: 10px;
  top: 20vh;
  height: auto;
  bottom: 146px;
}

.gp-users-list .gp-userlist_tab {
  height: calc(100% - 55px);
}

.gp-users-list .gp-whatsappchatfloat {
  width: 100%;
  height: 100%;
}

.gp-users-list .gp-userlist_tab li.gp-userbar:last-child {
  margin-bottom: 0;
}

.gp-chatsidebar_float:not(.gp-widgetclose)
  + .gp-chatfloatholder
  .gp-chatfloat.gp-layouttwo {
  visibility: visible;
  opacity: 1;
  display: block;
  height: 100%;
}

.gp-chatfloat.gp-layouttwo > span a {
  text-decoration: none;
  width: 67px;
  height: 67px;
}

.gp-chatfloat.gp-layouttwo > span a i {
  color: #fff;
}

.gp-chatfloatholder.gp-floatholdertwo {
  right: 40px;
}

.gp-floatholdertwo .gp-floatchat_content,
.gp-floatholdertwo .gp-floatchat_titlev2 {
  border-radius: 20px 20px 0 0;
}

.gp-floatholdertwo .gp-floatchat_titlev2 .gp-singleimg .gp-userstatus {
  outline: 3px solid #128c7e;
  border-width: 0;
}

.gp-floatholdertwo .gp-floatchat {
  right: 450px;
}

.gp-layout-one .gp-conversation-list {
  padding: 0;
  height: calc(100% - 175px);
}

.gp-layout-one .gp-conversation-list::-webkit-scrollbar {
  width: 3px;
}

.at-empty-conversation svg {
  margin: 0 0 20px;
}

.gp-layout-one .gp-users-list .gp-conversation-list li + li {
  margin: 0;
  bottom: 0;
  border-radius: 0;
}

.gp-layout-one .gp-users-list .gp-conversation-list li.gp-notavailable {
  box-shadow: none;
  border-radius: 0;
}

.gp-layout-one .gp-users-list .gp-conversation-list li {
  padding: 20px !important;
  box-shadow: none;
  border-radius: 0;
  z-index: 1;
}

.gp-layout-one .gp-users-list .gp-sidebarhead {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px 16px 52px;
}

.gp-layout-one .gp-users-list .gp-sidebarhead > h2 {
  margin: 0;
}

.gp-layout-one
  .gp-users-list
  .gp-conversation-list
  li.gp-notavailable
  .gp-userbar_right
  .gp-whatsappbtn {
  background: #fff;
}

.gp-needchat {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}

.gp-needchat svg {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
}

.gp-needchat h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #fff !important;
}

.gp-layout-one .gp-chatfloatholder {
  bottom: 0;
  right: 20px;
}

.gp-layout-one .gp-chatsidebar_float.gp-users-list {
  top: 20vh;
  height: auto;
  bottom: 73px;
}

.gp-opnchatbox .gp-whatsappside-responsive.gp-opnchatbox .gp-chat_sidebar {
  display: block !important;
}

.gp-whatsappside-responsive:not(.gp-layout-one)
  .gp-users-list.gp-chatsidebar_float.gp-users-list {
  max-width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 99999;
}

.gp-whatsappside-responsive:not(.gp-layout-one) .gp-users-list .gp-sidebarhead {
  border-radius: 0;
}

.gp-whatsappside-responsive .gp-layout-one .gp-chatsidebar_float.gp-users-list {
  right: 20px;
  width: 90%;
}

.gp-layout-one .gp-whatsappside-responsive.gp-opnchatbox .gp-chat_sidebar {
  display: block !important;
}

.gp-whatsappside-responsive .gp-users-list:after {
  position: absolute;
  content: "";
  right: 4px;
  bottom: -7px;
}

.gp-whatsappside-responsive .gp-users-list .gp-sidebarhead {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px 16px 50px;
}

.gp-whatsappside-responsive:not(.gp-layout-one) .gp-sidebarhead > h2 {
  margin: 0 0 4px;
  font-size: 16px;
  line-height: 17px;
}

.whatsappside-responsive .gp-users-list .gp-conversation-list {
  height: calc(100% - 265px);
}

.gp-closeremovechat {
  position: absolute;
  right: 20px;
  text-decoration: none;
  display: inline-block;
  top: 20px;
}

.gp-closeremovechat i {
  color: #fff;
  font-size: 25px;
}

.gp-layout-one.gp-layout-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

.gp-layout-one.gp-whatsappside-responsive .gp-users-list:after {
  position: absolute;
  content: "";
  right: var(--widgetright);
  bottom: -7px;
}

.gp-users-list.gp-chatsidebar-widgetclose.gp-chatsidebar_float {
  opacity: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.gp-layout-one.gp-opnchatbox .gp-users-list {
  display: none;
}

.gp-layout-one.gp-whatsappside-responsive .gp-floatchat {
  right: 20px;
}

.gp-whatsappside-responsive
  .gp-whatsappchat-responsive
  .gp-message {
  max-width: 290px;
}

.wpguppy-rtl.gp-layout-one .gp-needchat svg {
  margin-left: 10px;
  margin-right: 0;
}

.wpguppy-rtl.gp-layout-one .gp-users-list {
  left: 37px;
  right: auto;
}

.wpguppy-rtl.gp-layout-one .gp-users-list:after {
  right: auto;
  left: var(--widgetright);
}

.wpguppy-rtl.gp-layout-one.gp-whatsappside-responsive .gp-floatchat {
  left: 20px;
  right: auto;
}

.wpguppy-rtl.gp-layout-one .gp-iconclosered {
  justify-content: flex-start;
}

.wpguppy-rtl .gp-chatsidebar_float.gp-users-list {
  right: auto;
  left: 20px;
}

.wpguppy-rtl .gp-floatholdertwo .gp-floatchat {
  left: 450px;
  right: auto;
}

.wpguppy-rtl.gp-layout-one.gp-whatsappside-responsive .gp-chatfloatholder {
  right: auto;
  left: 44px;
}

.wpguppy-rtl .gp-users-list .gp-sidebarhead svg {
  right: 20px;
  left: auto;
  right: 0;
  transform: rotate(180deg);
}

.gp-layout-one.wpguppy-rtl .gp-floatchat {
  right: auto;
  left: 443px;
}

.gp-layout-one.wpguppy-rtl .gp-widgetclose ~ .gp-chatfloatholder .gp-floatchat {
  right: auto;
  left: 299px;
}

.wpguppy-rtl .gp-users-list .gp-sidebarhead {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 67px 16px 20px;
}

.wpguppy-rtl .gp-floatholdertwo .gp-floatchat_title h2 {
  padding-left: 20px;
  padding-right: 0;
}

.wpguppy-rtl .gp-users-list .gp-closeremovechat {
  left: 20px;
  right: auto;
}

.wpguppy-rtl
  .gp-users-list
  .gp-whatsappchat-responsive
  .gp-userinfo_title_img {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.wpguppy-rtl .gp-users-list .gp-backtolist {
  margin-left: 15px;
  margin-right: 0;
  transform: rotate(180deg);
}

.gp-layout-one .gp-widgetclose ~ .gp-chatfloatholder .gp-floatchat {
  right: 275px;
}

@media (max-width: 480px) {
  .gp-users-list .gp-userbar_title > em,
  .gp-users-list .gp-userbar_title > p span {
    font-size: 12px;
  }

  .gp-layout-one.gp-whatsappside-responsive .gp-chatsidebar_float {
    max-width: 340px;
  }

  .gp-layout-one .gp-users-list .gp-sidebarhead > h2 {
    font-size: 16px;
  }

  .gp-layout-one .gp-users-list .gp-conversation-list li {
    padding: 10px !important;
  }

  .gp-layout-one .gp-userbar_title h3 {
    font-size: 16px;
    line-height: 17px;
  }

  .gp-layout-one .gp-userbar_profile img {
    height: 40px;
  }

  .gp-layout-one .gp-userbar_profile {
    max-width: 40px;
    height: 40px;
  }

  .gp-layout-one.gp-whatsappside-responsive .gp-floatchat {
    width: calc(100% - 40px);
  }

  .wpguppy-rtl.gp-layout-one.gp-whatsappside-responsive .gp-floatchat {
    left: 20px;
    right: auto;
  }
}

@media (max-width: 360px) {
  .gp-layout-one.gp-whatsappside-responsive .gp-floatchat {
    max-width: 290px;
  }

  .gp-layout-one .gp-conversation-list {
    padding: 0;
    height: calc(100% - 172px);
  }

  .at-chatsidebar_float {
    max-width: 296px;
  }

  .wpguppy-rtl.gp-layout-one .gp-users-list {
    left: 10px;
    right: auto;
    max-width: 290px;
  }

  .gp-sidebarhead_search {
    padding: 10px;
  }

  .gp-layout-one.gp-whatsappside-responsive .gp-chatsidebar_float {
    max-width: 290px;
  }
}

.at-chat_sidebar_footer {
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 9;
  width: 70px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  line-height: 30px;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: flex-start;
  border-right: 1px solid #eee;
  transition: width 0.5s ease;
}

.at-chat .at-chat_sidebar_footer {
  scrollbar-width: none;
}

.at-chat_sidebar_footer:hover {
  width: 270px;
}

.at-chat_sidebar_footer:hover .at-sidebar-item {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.at-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  border-left: 0;
  max-width: 200px;
  width: 200px;
  visibility: hidden;
  position: absolute;
  left: 60px;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}

.at-sidebar-item h6 {
  display: inline-flex;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600 !important;
  text-transform: inherit !important;
  letter-spacing: 0.5px;
  margin: 0;
}

.at-sidebar-item h5 {
  margin: 0;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-sidebar-item span {
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--primchatfont);
}

.at-chat_sidebar_footer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.at-chat_sidebar_footer .at-nestedsetting {
  padding: 0;
}

.at-chat_sidebar_footer > li {
  width: auto;
  border: none;
  box-shadow: inset 0 -1px 0 #eee;
}

.at-chat_sidebar_footer > li,
.at-nestedsetting > li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  background: #fff;
}

.at-nestedsetting > li {
  width: 260px;
}

.at-nestedsetting > li.active a:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
}

.at-chat_sidebar_footer > li:last-child {
  height: 100%;
}

.at-chat_sidebar_footer li.at-hasimage a {
  padding: 8px 10px !important;
}

.at-chat_sidebar_footer li > a {
  font-size: 20px;
  color: #999;
  display: inline-flex;
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
}

.at-chat_sidebar_footer li a svg {
  margin: 20px;
}

.at-messanger-chat .at-chat_sidebar_footer li a svg {
  margin: 20px 25px;
}

.at-chat_sidebar_footer li:last-child {
  display: flex;
  align-items: flex-end;
  height: 60px;
  margin-top: auto;
  border-top: 1px solid #eee;
}

.at-chat_sidebar_footer li > a > i {
  padding: 20px;
  display: inline-block;
}

.at-messanger-chat .at-chat_sidebar_footer > li > a {
  width: auto;
}

.at-chat_sidebar_footer a img {
  max-width: 44px;
  border-radius: 50%;
  height: 44px;
}

.at-chat_sidebar_footer > li.active,
.at-nestedsetting li.active {
  position: relative;
  box-shadow: inset 3px 0 0 var(--primguppycolor), inset 0 -1px 0 #eee;
}

.at-nestedsetting li.active {
  border-top: 1px solid #eee;
  box-shadow: inset 3px 0 0 #ff7300, inset 0 0 0 #eee;
}

.at-chat_sidebar_footer > li.active a {
  color: var(--secguppycolor);
}

.at-chat_sidebar_footer a .at-notify {
  pointer-events: none;
  top: -10px;
  left: 100%;
  position: absolute;
  margin-left: -10px;
  box-shadow: 0 0 0 3px var(--secguppycolor);
  font-family: var(--secchatfont);
}

.at-nestedsetting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  transition: all 0.4s ease-in-out;
  background: #fff;
}

.at-nestedsetting li {
  box-shadow: inset 0 1px 0 #eee;
}

.at-nestedsetting li.active a {
  color: var(--secguppycolor);
}

.at-messanger-chat .at-chat_sidebar_footer li.at-hasimage {
  justify-content: flex-start;
}

.at-messanger-chat .at-chat_sidebar_footer li,
.at-messanger-chat .at-nestedsetting > li {
  width: 70px;
}

.at-messanger-chat .at-chat_sidebar_footer li a,
.at-messanger-chat .at-nestedsetting > li a {
  padding: 0;
  width: 70px;
}

.at-messanger-chat .at-chat_sidebar_footer:hover li,
.at-messanger-chat .at-nestedsetting:hover li {
  width: 270px;
}

.at-messanger-chat .at-nestedsetting li {
  width: 70px;
}

.at-chat_sidebar_footer .at-hasimage .at-sidebar-item {
  padding: 0 10px;
}

.at-chat_sidebar_footer > li {
  width: 270px;
}

.at-messanger-chat .at-chat_sidebar_footer a i {
  padding: 20px 25px;
}

.at-messanger-chat .at-sidebar-item {
  left: 70px;
}

.at-responmenu {
  display: flex;
  width: 100%;
}

.at-responsivelist {
  display: flex;
  flex-direction: column;
  width: 60px;
}

.at-messanger-chat .at-responsivelist {
  width: 70px;
}

.at-imgresponsivearea {
  display: flex;
  padding: 14px 20px;
  align-items: flex-start;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.at-imgresponsivearea > a {
  padding: 0 !important;
}

.at-imgresponsivearea a {
  display: flex;
  padding: 0 !important;
}

.at-imgresponsivearea .at-sidebar-item {
  padding: 0;
  border: 0;
  flex-direction: column;
  margin: 10px 0 0;
  position: relative;
  align-items: flex-start;
  left: 0;
  max-width: 100%;
  width: 100%;
}

.at-responsivelist a {
  font-size: 20px;
  color: #999;
  display: inline-flex;
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 -1px 0 #eee;
  padding: 18px 20px;
}

.at-responsivelist a:focus {
  box-shadow: inset 0 -1px 0 #eee !important;
}

.at-sidebarshow .at-menuicon {
  background: #f7f8fc !important;
}

.at-menuicon {
  box-shadow: inset 0 -1px 0 #eee;
}

.at-chat_sidebar_footer .at-listonrespon {
  box-shadow: none;
}

.at-sidebarshow {
  width: 260px !important;
}

.at-messanger-chat .at-chat_sidebar_footer:hover {
  width: 270px;
}

@media (max-width: 767px) {
  .at-imgresponsivearea {
    padding: 7px 20px;
  }

  .gp-chat_previewfloat .at-imgresponsivearea {
    flex-direction: column;
    justify-content: center;
  }

  .gp-chat_previewfloat
    .at-imgresponsivearea
    .at-sidebar-item {
    margin-left: 0;
  }

  .at-chat_sidebar_footer:hover {
    width: 60px;
  }

  .at-messanger-chat .at-chat_sidebar_footer:hover {
    width: 70px;
  }

  .at-messanger-chat .at-imgresponsivearea a {
    border: 0 !important;
  }

  .at-messanger-chat .at-userchat_tab a {
    padding: 14px 10px;
  }

  .at-messanger-chat
    .at-userlist_tab
    .at-sidebarhead_searchv2 {
    padding: 9.5px 0;
  }

  .at-chat_sidebar_footer .at-sidebar-item {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .at-imgresponsivearea .at-sidebar-item {
    margin: 10px 0 0 10px;
  }
}

@media (max-width: 480px) {
  .at-imgresponsivearea {
    padding: 7px 20px;
  }

  .at-empty-conversation i {
    font-size: 27px;
  }
}

.conversation-list {
  height: 600px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.at-userbar {
  padding: 14px 20px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.at-userbar.active .at-userbar_profile,
.at-userbar:hover .at-userbar_profile {
  background-color: unset;
}

.at-userbar.active,
.at-userbar:hover {
  background: #f7f8fc;
}

.at-userbar_profile {
  flex: none;
  width: 100%;
  max-width: 40px;
  height: 40px;
  border-radius: 50px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.at-userbar_profile .at-userstatus {
  right: 0;
  bottom: 0;
  z-index: 0;
  position: absolute;
}

.at-userbar_profile .at-userstatus:before {
  margin: 0;
  width: 8px;
  height: 8px;
  outline: 2px solid #fff;
}

.at-userbar.active .at-userbar_profile .at-userstatus:before,
.at-userbar:hover .at-userbar_profile .at-userstatus:before {
  border-color: #f7f7f7;
}

.at-userbar_profile .at-userstatus:before {
  background: #ddd;
}

.at-userbar_profile img {
  width: 100%;
  margin: 0 auto;
  height: 44px;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50px !important;
}

.at-userbar_profile + .at-userbar_title {
  margin-left: 12px;
}

.at-userbar_title,
.at-userbar_title h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-userbar_title h3 {
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  font-weight: 600 !important;
}

.at-chat_previewfloat .at-userbar_title h3 {
  font-size: 15px;
  line-height: 21px;
}

.at-userbar_title span {
  font-size: 14px;
  line-height: 22px;
  display: block;
  letter-spacing: 0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-userbar_title span i {
  font-size: 16px;
  color: var(--terguppycolor);
  margin-right: 5px;
  line-height: 22px;
  display: inline-block;
  vertical-align: middle;
}

.at-userbar_right {
  display: inline-flex;
  text-align: right;
  margin-left: auto;
  flex: none;
  padding-left: 10px;
}

.at-userbar_right em {
  margin-left: 8px;
}

.at-userbar_right a {
  text-decoration: none !important;
}

.at-userbar_right > span {
  display: block;
  font-size: 13px;
  line-height: 22px;
  color: var(--terguppycolor);
}

.at-userbar:hover .at-disabled {
  background-color: #fff;
}

.at-chatsidebar_float .at-userlist_tab {
  padding-left: 60px;
}

.at-guppysidepopup .at-userlist_tab li.at-userbar:last-child {
  margin-bottom: 20px;
}

.at-chatsidebar_float .at-userlist_tab .at-empty-conversation > span {
  font-size: 16px;
  line-height: 24px;
}

.mCSB_inside > .mCSB_container {
  margin: 0;
}

.at-sidebarhead {
  z-index: 0;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.at-sidebarhead > img {
  margin-right: 10px;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
}

.at-sidebarhead > h2 {
  color: #fff !important;
  letter-spacing: 0.5px;
  font-size: 22px;
  margin: 0;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 0;
  text-overflow: ellipsis;
}

.at-sidebarhead_menu {
  margin-left: auto;
  position: relative;
  display: inline-flex;
  padding-left: 20px;
}

.at-sidebarhead_menu > a {
  color: #fff;
  font-size: 28px;
  display: inline-block;
  text-decoration: none !important;
}

.at-sidebarhead_menu .at-uploadoption {
  bottom: auto;
  top: 100%;
  right: 0;
}

.at-sidebarhead > a:hover {
  color: #fff;
}

.at-overlay:before {
  content: "";
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  height: calc(100vh - 150px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.at-dropbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
  border: 1.5px dashed #ddd;
  border-radius: 3px;
  flex-wrap: wrap;
  background: transparent;
}

.at-dropbox input {
  display: none;
}

.at-dropbox label {
  margin: 0;
  font: 500 14px/24px var(--primchatfont);
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
  max-width: 180px;
  cursor: copy;
}

.at-dropbox label span {
  color: #3c57e5;
}

.at-dropboxempty {
  border: 0;
  margin-top: 10px;
  min-height: 194px;
}

.at-dropboxempty i {
  padding: 0;
  color: #ddd;
  font-size: 38px;
  margin: 0 0 10px;
  background-color: transparent;
}

.at-dropboxempty label {
  font-size: 15px;
  line-height: 24px;
  color: var(--terguppycolor);
  max-width: none;
  cursor: auto;
  font-weight: 700;
}

.at-viewuploadimg {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.at-viewuploadimg img {
  flex: none;
  border-radius: 5px 5px 0 0;
  height: 300px;
  border-radius: 5px;
  width: 100%;
  margin: 0 0 10px;
}

.at-uploadarea .at-btnareasetting {
  margin: 10px 0 0;
}

.at-removeuploadimg {
  line-height: 20px;
  background: #ef4444;
  padding: 10px 20px;
  letter-spacing: 0.5px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: var(--primchatfont);
  font-weight: 700;
  border-radius: 0 0 10px 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}

.at-uploadarea h6 {
  font-weight: 500 !important;
  font-size: 14px;
  text-transform: none;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
}

.at-removeuploadimg:focus,
.at-removeuploadimg:hover {
  color: #fff;
}

.at-chat .at-removeuploadimg:focus {
  background: #ef4444 !important;
}

.at-chat_sidebarsettingtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: #fff;
  box-shadow: inset 0 -1px 0 #eee;
}

.at-chat_sidebarsettingtitle h2 {
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
}

.at-chat_sidebarsettingtitle > a {
  margin: 0 -4px 0 16px;
  font-size: 28px;
  color: var(--secguppycolor);
  display: inline-flex;
  text-decoration: none;
}

.at-chat_sidebarsettingtitle > a:hover {
  color: var(--secguppycolor);
}

.at-sidebarhead .at-chat_sidebarsetting {
  width: 100%;
  padding-left: 60px;
}

.at-sidebarhead .at-chat_profilesettingopen {
  transform: translateX(0);
}

.at-chat_mutenotify {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.at-chat_mutenotify.at-disabled {
  background: transparent;
  opacity: 0.5;
}

.at-chat_mutenotify input {
  display: none;
}

.at-chat_mutenotify input:checked + label {
  color: var(--terguppycolor);
}

.at-chat_mutenotify input:checked + label i {
  background-color: #f7f7f7;
  color: var(--terguppycolor);
}

.at-chat_mutenotify input:checked + label i:before {
  content: "î¨";
}

.at-chat_mutenotify input + label {
  align-items: center;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
  width: 100%;
  cursor: pointer;
  margin: 0 !important;
  display: flex !important;
  flex-wrap: wrap;
  padding: 0 !important;
}

.at-chat_mutenotify label span {
  align-items: center;
  flex: auto;
  height: 48px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
}

.at-chat_mutenotify label i {
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  flex: none;
  border-radius: 3px;
  background-color: var(--primguppycolor);
  font-size: 27px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
}

.at-menue-options .at-guppylogout {
  display: flex;
  align-items: center;
  padding: 26px 30px;
  width: 100%;
  border-top: 0;
}

.at-menue-options .at-guppylogout a {
  display: inline-flex;
  color: #ef4444 !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent !important;
  letter-spacing: 0.5px;
  align-items: center;
  font: 500 16px/32px var(--primchatfont);
  width: 100%;
}

.at-guppylogout a i {
  color: #ef4444 !important;
  font-size: 22px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.at-cropping-popup .at-modaldialog {
  max-width: 700px;
}

.at-guppytooltip {
  position: relative;
  display: inline-block;
}

.at-guppytooltip > em {
  left: 0;
  width: auto;
  right: auto;
  margin-left: 0;
  border-radius: 4px 4px 4px 0;
  margin-bottom: 10px;
  bottom: 100%;
  transform: scale(0.5);
  position: absolute;
  color: #fff;
  background: var(--secguppycolor);
  padding: 5px 12px;
  letter-spacing: 0.5px;
  opacity: 0;
  visibility: hidden;
  font: 700 14px/22px var(--primchatfont);
  transition: all 0.3s ease-in-out;
}

.at-guppytooltip > em:after {
  left: 0;
  right: auto;
  border-left: 0;
  border-right: 10px solid transparent;
  width: 0;
  height: 0;
  border-top: 8px solid var(--secguppycolor);
  content: "";
  position: absolute;
  top: 100%;
}

.at-guppytooltip:hover em {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.at-chat_formwrapper {
  padding: 10px 20px;
}

.at-btn.at-btnsm {
  background: #22c55e !important;
  height: 32px;
  font-size: 12px;
  line-height: 20px;
  min-width: 115px;
  font-weight: 700;
  padding: 0 5px;
}

.at-btn.at-btnplain {
  height: 32px;
  background: #ef4444 !important;
  min-width: 115px;
  font: 700 12px var(--primchatfont);
  padding: 0 15px;
}

.at-btn.at-btnplain i {
  display: inline-block;
  margin: 0 0 0 6px;
  font-size: 12px;
}

.at-btnareasetting {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
}

.at-btn-block {
  width: 100%;
}

.at-rightswitcharea,
.at-switchbtn {
  display: flex;
  align-items: center;
}

.at-rightswitcharea i {
  display: flex;
  align-self: center;
  color: #999;
  font-size: 21px;
  margin: 0 13px 0 0;
}

.at-switchbtn input[type="checkbox"] {
  position: relative;
  width: 38px;
  height: 20px;
  border: 1.5px solid #ddd;
  -webkit-appearance: none;
  background: #fff;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  transition: background 0.3s linear;
}

.at-switchbtn input[type="checkbox"]:before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: -4px;
  left: -5px;
  border-radius: 20px;
  background-color: #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s;
}

.at-switchbtn input[type="checkbox"]:after {
  opacity: 0 !important;
}

.at-switchbtn input[type="checkbox"]:checked {
  background-color: #fff;
  border: 1.5px solid #4dcf7d;
}

.at-switchbtn input[type="checkbox"]:checked:before {
  left: 15px;
  background-color: #4dcf7d;
}

.at-dropboxv2 .at-viewuploadimg {
  width: 100%;
  flex-direction: row;
}

.at-dropboxv2 .at-viewuploadimg img {
  margin: 0 10px 0 0;
}

.at-form-group .at-dropboxv2 {
  padding: 10px;
}

.at-dropboxv2 .at-viewuploadimg .at-uploadarea h6 {
  text-align: left;
  margin: 0;
}

.at-dropboxv2 .at-viewuploadimg .at-btnareasetting {
  justify-content: flex-start;
}

.sv-newslots {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  border: 0 !important;
  padding: 20px;
  width: 100%;
}

.sv-newslots svg {
  position: absolute;
  fill: transparent;
  stroke: #ddd;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: visible;
  z-index: -1;
  padding: 0 1px;
  width: 100%;
  height: 100%;
}

.sv-newslots svg rect {
  stroke-width: 1px;
  stroke-dasharray: 10, 10;
  rx: 10px;
}

.sv-newslots .at-viewuploadimg img {
  max-width: 90px;
  height: 90px;
}

.at-settingbtns {
  justify-content: space-between;
  display: flex;
}

.at-settingbtns .at-rightswitcharea {
  max-width: 49%;
  background: #fff;
  border: 1.5px solid #ddd;
  border-radius: 4px;
  padding: 5px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.at-settingbtns .at-rightswitcharea i {
  margin-bottom: 6px !important;
  font-size: 24px;
  margin: 0;
}

.at-logout i {
  color: #ef4444;
}

.at-mute i {
  color: #22c55e;
}

.at-muted i {
  color: #999;
}

.at-onlineusers {
  margin: 0;
  display: flex;
  overflow: auto;
  margin: 0 -20px;
  list-style: none;
  padding: 0;
  height: auto !important;
  width: calc(100% + 40px);
  flex-direction: row !important;
}

.at-chat_previewfloat .at-onlineusers > li:first-child {
  margin-left: 0;
}

.at-onlineusers::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.at-onlineusers > li {
  border: 0;
  flex: none;
  display: block;
  cursor: pointer;
  max-width: 82px;
  list-style-type: none;
  padding: 0 !important;
  background: transparent;
  margin-bottom: 0 !important;
}

.at-onlineusers > li:first-child {
  margin-left: 20px;
}

.at-onlineusers > li:last-child {
  margin-right: 20px;
}

.at-onlineusers .at-userstatus {
  right: 0;
  bottom: 0;
  border: 2px solid #fff;
}

.at-onlineusers .at-userstatus:before {
  width: 8px;
  height: 8px;
}

.at-onlineusers figure {
  max-width: 44px;
  position: relative;
  margin: 0 auto !important;
}

.at-onlineusers h3 {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 0;
  overflow: hidden;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 7px;
}

.at-onlineusers .at-singleimg img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-messanger-chat .at-onlineusers .at-singleimg img {
  width: 44px;
  height: 44px;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-recentonline {
  width: 100%;
  position: relative;
  padding: 20px;
  background: #fff;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  box-shadow: inset 0 -1px 0 0 #eee, inset 0 0 0 #eee;
}

.at-recentonline h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: inherit;
  margin-bottom: 10px;
  margin-top: 0;
}

.at-chat_sidebar .at-recentonline {
  box-shadow: inset 0 -1px 0 0 #eee, inset 0 0 0 #eee;
}

.user-list {
  height: 600px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.at-userlist_tab {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.at-userlist_tab .at-userlist_tab {
  padding-bottom: 0;
  padding-left: 0;
}

.at-userchat_tab + .at-userlist_tab ul {
  height: 100%;
}

.at-userlist_tab ul {
  background: #fff;
  height: 100%;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}

.at-userlist_tab .at-userchat_tab + .at-sidebarhead_search + ul,
.at-userlist_tab .at-userchat_tab + ul {
  height: calc(100% - 168px);
}

.at-userlist_tab li {
  list-style-type: none;
  width: 100% !important;
  margin: 0;
}

.at-guppysidepopup .at-userlist_tab li.at-userbar:last-child {
  margin-bottom: 0;
}

.at-userlist_tab .at-empty-conversation {
  width: 100%;
  background: transparent;
  z-index: -2;
}

.at-userlist_tab .at-empty-conversation > span {
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.at-userlist_tab li.at-inner-loaader {
  width: calc(100% - 80px) !important;
}

.at-chat_sidebar {
  position: relative;
  flex: 0 0 28%;
  max-width: 28%;
  border-right: 1px solid #eee;
}

.at-chat_sidebar:before {
  width: 100%;
  position: absolute;
  content: "";
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.at-ovelay:before {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.6s ease-in-out;
}

.at-unknownuser .at-backtolist {
  align-self: flex-start;
}

.at-unknownuser {
  height: 100vh;
  padding-bottom: 21px !important;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}

.at-unknownuser .at-message {
  padding: 20px;
  max-width: 100%;
}

.at-unknownuser .at-messages {
  padding-left: 0;
  padding-right: 0;
  margin: 0 !important;
  padding-top: 30px;
}

.at-alert {
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  max-width: 528px;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
}

.at-alert i {
  color: var(--terguppycolor);
  font-size: 54px;
}

.at-alert h2 {
  margin: 0;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.5px;
}

.at-alert p {
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
  margin: 0 0 16px;
}

.at-alert > a {
  margin-top: 20px;
}

.at-alert > .at-closepopup {
  margin: 0;
  top: 20px;
  right: 20px;
  position: absolute;
}

.at-alert > .at-closepopup i {
  font-size: 24px;
}

.at-chat480 .at-unknownuser {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.at-chat480 .at-unknownuser .at-messages {
  padding-right: 0;
  padding-left: 0;
}

.at-chat480 .at-unknownuser .at-message {
  max-width: 100%;
}

.at-chat_sidebarsetting {
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.at-chat_sidebarsettingopen {
  transform: translateX(0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.at-chat_sidebarsettingcontent {
  padding: 26px 30px;
}

.at-chat_sidebarsettingcontent h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-chat_sidebarsettingcontent > a {
  color: #3c57e5 !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: block;
  transition: none;
}

.at-chat_sidebarsettingcontent > a.at-danger {
  color: #ef4444 !important;
}

.at-chat_sidebarsettingcontent .at-themeform {
  padding-top: 10px;
}

.at-chat_sidebarsettingcontent .at-btnlist {
  margin: 0;
}

.at-chat_sidebarsettingcontent .at-btnlist li {
  width: 100% !important;
  padding: 0 !important;
}

.at-chat_sidebarsettingcontent
  ~ .at-chat_sidebarsettingcontent {
  border-top: 1px solid #ddd;
}

.at-chat_sidebarsettingarea {
  height: calc(100vh - 91px);
  overflow-y: auto;
  background: #fff;
}

.at-gallerylistwrap {
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}

.at-gallerylistwrap h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-gallerylist {
  margin: -5px;
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.at-gallerylist li {
  margin: 0;
  list-style: none;
  padding: 5px !important;
  width: 33.333% !important;
}

.at-gallerylist li:not(.at-loadmorecontent) a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  min-height: 73.33px;
  max-height: 73.33px;
  border: 1px solid #ddd;
  color: var(--primguppycolor);
}

.at-gallerylist li.at-loadmorecontent {
  width: 100% !important;
  margin-top: 10px;
}

.at-gallerylist li a i {
  display: inline-block;
  background: #fff;
  text-align: center;
  font-size: 24px;
}

.at-gallerylist li a img {
  max-width: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border-radius: 5px;
  max-height: 70px;
  width: 100%;
}

.at-danger {
  color: #ef4444 !important;
}

.at-imgdownloader {
  outline: none !important;
  text-decoration: none !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-flex;
  vertical-align: middle;
  color: #3c57e5 !important;
  align-items: center;
}

.at-imgdownloader.at-disable-btn,
.at-imgdownloader {
  background-color: transparent !important;
}

.at-imgdownloader .at-msgload {
  margin: 0;
  position: static;
  display: inline-block;
  margin-right: 10px;
}

.at-imgdownloader .at-msgload i {
  border-right-color: #fff;
  display: block;
  width: 20px;
  height: 20px;
}

.at-chat_sidebarsettingcontent.at-chatgroup-sidebar {
  padding: 30px 0;
}

.at-userinfo_title_img img {
  display: block;
  width: 100%;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50% !important;
}

.at-replay_message {
  background-color: #f7f7f7;
  padding: 10px 20px;
  margin: 0 130px 10px 70px;
  border-radius: 10px;
}

.at-replay_message .at-message-qoute {
  margin: 0;
}

.at-message-qoute_content {
  padding: 14px;
  background: #fff;
  border-radius: 3px;
}

.at-message-qoute_content .at-sendfile {
  background: transparent;
  padding: 0;
}

.at-message-qoute .at-sendfile span {
  font-size: 12px;
  line-height: 16px;
  display: block;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.at-chat
  .at-whatsappchat-responsive
  .at-replay_msg
  .at-form-replay {
  height: 40px;
  padding: 6px 20px;
  min-height: 40px !important;
}

.at-replay {
  background-color: #fff;
  position: relative;
  margin-top: auto;
  padding: 20px;
  margin: 0;
}

.at-replay_content,
.at-replay_msg {
  display: flex;
  align-items: center;
}

.at-replay_msg {
  flex: auto;
  padding: 0 10px;
  position: relative;
}

.at-replay_msg .at-sendmsg {
  margin-left: 10px;
  border-radius: 3px;
}

.at-chat .at-replay_msg textarea.at-form-control {
  resize: none;
  height: 60px;
  max-height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 60px !important;
}

.at-chat .at-replay_msg textarea.at-form-control::-moz-placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-chat .at-replay_msg textarea.at-form-control::placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-replay_msg > input::-moz-placeholder {
  color: var(--terguppycolor);
}

.at-replay_msg > input::placeholder {
  color: var(--terguppycolor);
}

.at-replay_msg > a:not(.at-sendmsg) {
  position: absolute;
  right: 91px;
}

.at-replay_msg > a:not(.at-sendmsg) img {
  display: block;
}

.at-replay_msg > a:not(.at-sendmsg) + input {
  padding-right: 60px;
}

.at-replay_audio,
.at-replay_upload,
.at-sendmsg-responsive {
  flex: none;
  position: relative;
}

.at-uploadoption_open {
  z-index: 9999;
}

.at-replay_audio:hover > a,
.at-replay_upload:hover > a,
.at-sendmsg-responsive:hover {
  background: #f7f7f7;
}

.at-replay_audio > a,
.at-replay_upload > a,
.at-sendmsg-responsive {
  font-size: 26px;
  color: var(--terguppycolor) !important;
  border-radius: 3px;
  line-height: 58px;
  width: 60px;
  display: block;
  text-align: center;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}

.at-sendmsg-responsive i {
  display: block;
  line-height: inherit;
}

.at-replay_audio > a:focus,
.at-replay_upload > a:focus,
.at-sendmsg-responsive:focus {
  text-decoration: none !important;
  background: transparent !important;
  outline: none !important;
}

.at-replay_audio.at-startrecording a,
.at-replay_audio:focus a {
  color: #fff !important;
  background-color: var(--primguppycolor) !important;
  border-color: var(--primguppycolor);
}

.at-recorddisabled a {
  pointer-events: none;
  color: var(--terguppycolor) !important;
  background: #fff !important;
  border-color: #ddd !important;
}

.at-recorddisabled a i:before {
  content: "\e9a0";
}

.at-autoreplay {
  box-shadow: inset 0 -1px 0 #eee;
  display: flex;
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #f7f7f7;
  padding-left: 20px;
  z-index: 8;
  padding-bottom: 20px;
  width: 100%;
}

.at-autoreplay span {
  cursor: pointer;
  margin: 10px;
  background: #fff;
  display: inline-block;
  padding: 6px 20px;
  border-radius: 60px;
  font-size: 15px;
  border: 1px solid #ddd;
  line-height: 26px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-sendmsg {
  flex: none;
  width: 60px;
  line-height: 60px;
  display: inline-block;
  background-color: var(--primguppycolor);
  text-align: center;
  font-size: 22px;
  color: #fff;
  border-radius: 0 3px 3px 0;
}

.at-sendmsg:focus,
.at-sendmsg:hover {
  color: #fff;
  text-decoration: none !important;
  outline: none !important;
  background: var(--primguppycolor) !important;
}

.at-uploadoption {
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: none;
  position: absolute;
  bottom: 100%;
  width: 240px !important;
  z-index: 99;
  font: 500 16px/32px var(--primchatfont);
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
  padding: 0;
  margin: 0;
}

.at-uploadoption_open .at-uploadoption {
  display: flex;
  flex-wrap: wrap;
}

.at-uploadoption li {
  line-height: inherit;
  list-style-type: none;
  margin: 0;
  width: 100% !important;
  padding: 0 !important;
}

.at-uploadoption li:not(:last-child) {
  border-bottom: 1px solid #ddd !important;
}

.at-uploadoption input[type="file"] {
  display: none;
}

.at-uploadoption a,
.at-uploadoption label {
  cursor: pointer;
  margin: 0;
  align-items: center;
  font-weight: 500;
  padding: 10px 20px;
  display: flex;
  font-size: 16px;
  color: var(--secguppycolor) !important;
  transition: all 0.3s ease-in-out;
}

.at-uploadoption a:hover,
.at-uploadoption label:hover {
  background-color: #f7f7f7;
}

.at-uploadoption a:focus,
.at-uploadoption label:focus {
  outline: none !important;
  text-decoration: none !important;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  background: transparent !important;
}

.at-uploadoption a i,
.at-uploadoption label i {
  color: var(--terguppycolor);
  font-size: 18px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.at-replay_disable {
  pointer-events: none;
}

.at-replay_disable .at-sendmsg {
  background-color: #ddd;
}

.at-replay_disable .at-replay_audio.at-startrecording a,
.at-replay_disable .at-replay_audio:focus a {
  color: var(--terguppycolor) !important;
  background-color: #fff;
  border-color: #ddd;
}

.at-replay_disable .at-replay_msg {
  filter: grayscale(1);
}

.at-replay_audio > a {
  position: relative;
  z-index: 1;
}

.at-replay_audio a:after {
  top: 50%;
  left: 50%;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50px;
  background: hsla(0, 0%, 100%, 0.7);
  -webkit-animation: sonarWavea 1s linear infinite;
  animation: sonarWavea 1s linear infinite;
  z-index: -1;
  margin: -20px 0 0 -20px;
  opacity: 0;
  visibility: hidden;
}

.at-startrecording a i:before {
  content: "\e9b2";
}

.at-startrecording a:after {
  opacity: 1;
  visibility: visible;
}

.at-typing {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
}

.at-typing:empty {
  display: none;
}

.at-typing em:not(.at-typing-name) {
  max-width: 20px;
  margin-left: 8px;
  display: inline-block;
}

.at-typing-name {
  margin-right: 5px;
  font-style: normal;
  color: var(--primguppycolor);
}

@-webkit-keyframes sonarWavea {
  0% {
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes sonarWavea {
  0% {
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.at-messageoption {
  top: 10px;
  right: 18px;
  position: absolute;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.at-message:hover .at-messageoption,
.at-messageoption.at-messageoption_open {
  transform: scale(1);
}

.at-messageoption_btn {
  display: block;
  font-size: 18px;
  color: var(--terguppycolor);
  transition: all 0.3s ease-in-out;
}

.at-messageoption_btn:focus,
.at-messageoption_btn:hover {
  color: var(--terguppycolor);
  text-decoration: none !important;
  background: transparent !important;
  outline: none !important;
}

.at-messageoption_open .at-messageoption_list {
  display: block;
}

.at-messageoption_list {
  padding: 0;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  width: 240px !important;
  z-index: 99;
  left: 0;
  font: 500 16px/32px var(--primchatfont);
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
  margin: 0;
}

.at-messageoption_list li {
  line-height: inherit;
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  width: 100% !important;
}

.at-messageoption_list li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.at-messageoption_list a {
  cursor: pointer;
  margin: 0;
  align-items: center;
  font-weight: 500;
  padding: 10px 20px;
  display: flex;
  color: var(--secguppycolor);
  transition: all 0.3s ease-in-out;
  outline: none !important;
  background: #fff !important;
  text-decoration: none !important;
}

.at-messageoption_list a:hover {
  background-color: #f7f7f7 !important;
}

.at-messageoption_list a i {
  color: var(--terguppycolor);
  font-size: 18px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.vueAudioBetter {
  margin: 0 !important;
  box-shadow: none !important;
  background-image: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 330px !important;
  overflow: visible !important;
}

.at-messages:not(.at-message_sender) .at-msgload {
  display: none;
}

.at-msgload {
  position: absolute;
  left: -30px;
  top: 50%;
  margin-top: -10px;
}

.at-msgload i {
  width: 20px;
  height: 20px;
  border: 3px solid #ddd;
  border-right-color: #f7f7f7;
  border-radius: 50px 50px 50px 50px;
  -webkit-animation: spinericon 1s linear infinite;
  animation: spinericon 1s linear infinite;
  display: block;
  line-height: 20px;
}

.at-messages {
  width: 100%;
  float: left;
  padding: 3px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.at-messages:last-child .at-messageoption_list {
  top: auto;
  bottom: 100%;
}

.at-message {
  max-width: 75%;
  word-break: break-word;
  position: relative;
  color: var(--secguppycolor);
  border-radius: 0 20px 20px 20px;
  background-color: #fff;
  padding: 19px 20px;
  display: inline-block;
  margin: 0;
  font-size: 15px;
  line-height: 28px;
}

.at-message:hover .at-messageoption_btn {
  transform: scale(1);
}

.at-messagetext {
  padding: 19px 44px 19px 20px;
}

.at-messagetext > div:not(.at-messageoption) {
  margin-right: -24px;
}

.at-message > a {
  text-decoration: none;
  border: none;
  outline: none;
  color: #3c57e5;
}

.at-message_sender {
  align-items: flex-end;
}

.at-message_sender .at-message-qoute + .at-sendfile {
  float: right;
}

.at-messages:last-child {
  padding-bottom: 15px;
  margin-top: auto;
}

.at-message_sender .at-message {
  border-radius: 20px 20px 0 20px;
}

.at-message_sender .at-message_time svg {
  margin-right: 7px;
}

.at-message_sender .at-message_time:before {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 10px;
  content: "î¤«";
  font-family: guppy-icomoon;
  line-height: 15px;
  display: inline-block;
}

.at-resmsg:before,
.at-seenmsg:before {
  content: "\ea1e" !important;
  font-size: 12px !important;
}

.at-seenmsg:before {
  color: #3c57e5;
}

.at-message_sender .at-messageoption_list {
  left: auto;
  right: 0;
}

.at-message-qoute + .at-messageoption {
  top: 30px;
  right: 37px;
}

.at-chat480 .at-msgload {
  left: -20px;
  margin-top: -7px;
}

.at-chat480 .at-msgload i {
  width: 14px;
  height: 14px;
}

.at-audiosendfile {
  padding: 19px 20px;
}

.at-message_imgs {
  margin: -10px;
}

.at-message_imgs li {
  padding: 10px;
  min-width: 140px;
}

.video-js {
  overflow: hidden;
}

.at-messagewrap {
  display: flex;
  overflow: auto;
  min-height: 280px;
  padding: 10px 10px 0;
  flex-direction: column;
}

.at-messagewrap > div {
  margin-top: auto;
}

.at-messagewrap .at-alert {
  margin: auto;
}

.at-messagewrap .at-userbar_loader {
  background: transparent;
  position: relative;
  height: auto;
  padding: 20px 0 30px;
}

.at-chat_messages {
  background-color: #f7f7f7;
  flex-direction: column;
  flex: 0 0 72%;
  max-width: 72%;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.at-chat_messagesslide {
  flex: 0 0 calc(72% - 300px);
  max-width: calc(72% - 300px);
}

.at-unknownuser {
  padding-left: 30px;
  padding-right: 30px;
}

.at-loadermsg {
  display: flex;
  align-items: center;
  padding: 20px;
  color: var(--terguppycolor);
  height: auto;
  bottom: auto;
}

.at-loadermsg + .at-message_time {
  display: none;
}

.at-loadermsg i {
  font-size: 24px;
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.at-chatseparation {
  width: 100%;
  position: relative;
  margin: 10px 0;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.at-chatseparation:before {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  background: #ddd;
}

.at-chatseparation span {
  color: var(--terguppycolor);
  background: #f7f7f7;
  padding: 0 20px;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.at-modal {
  z-index: 10501;
  display: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.at-modal,
.at-modal:before {
  position: fixed;
  top: 0;
  left: 0;
}

.at-modal:before {
  content: "";
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.at-modalopen {
  display: flex;
}

.at-modaldialog {
  width: 100%;
  margin: auto;
  display: flex;
  padding: 30px 0;
  max-width: 528px;
  align-items: center;
}

.at-chat575 .at-modaldialog {
  max-width: 100%;
}

.at-chat575 .at-modaldialog,
.at-guppyside-responsive .at-modaldialog {
  padding-left: 15px;
  padding-right: 15px;
}

.at-modaldialog .at-alert {
  max-width: none;
  width: 100%;
}

.at-fadin {
  opacity: 1;
}

.at-alert .at-themeform {
  text-align: left;
}

.at-chat textarea.at-form-control {
  resize: none;
  padding-top: 12px;
}

.at-chat .at-dropdownholder .at-form-control {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.at-dropdownholder .at-form-control > span,
.at-placeholder {
  color: var(--terguppycolor);
  font: 400 14px/24px var(--secchatfont);
}

.at-dropdownholder .at-form-control > span:not(.at-placeholder) {
  color: var(--secguppycolor);
}

.at-dropdown {
  margin: 0;
  padding: 0;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 99;
  left: 0;
  font: 500 14px/2em Urbanist, sans-serif;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
  max-height: 200px;
  overflow-y: auto;
}

.at-dropdown li {
  line-height: inherit;
  list-style-type: none;
  outline: none;
}

.at-dropdown a {
  cursor: pointer;
  margin: 0;
  align-items: center;
  font-weight: 500;
  padding: 6px 20px;
  display: flex;
  color: #0a0f26;
  transition: all 0.3s ease-in-out;
}

.at-dropdown a:hover {
  background-color: #f7f7f7 !important;
}

.at-dropdown a:focus {
  outline: none !important;
}

.at-dropdown a i {
  color: var(--terguppycolor);
  font-size: 18px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.at-dropdown.at-uploadoption_open {
  display: flex;
  flex-wrap: wrap;
}

.at-dropcontentholder {
  text-align: left;
  padding: 20px;
  background: #fff !important;
}

.at-dropboxuploader {
  flex-direction: column;
  text-align: center;
}

.at-dropboxuploader,
.at-dropcontent {
  display: flex;
  align-items: center;
  width: 100%;
}

.at-dropcontent {
  flex-flow: row wrap;
}

.at-dropcontent img {
  margin-right: 10px;
  border-radius: 10px;
  flex: none;
}

.at-dropcontent .at-removedrop {
  font-size: 20px;
  color: #ef4444;
  margin-left: auto;
}

.at-droptitle {
  flex: auto;
}

.at-droptitle > span {
  font: 500 0.875rem/1.5714285714em Urbanist, sans-serif;
  letter-spacing: 0.5px;
  display: block;
}

.at-progressbarholder {
  position: relative;
  height: 3px;
  margin-top: 10px;
}

.at-progressbar {
  width: 100%;
  background-color: #f7f7f7;
}

.at-progressbar,
.at-progressbar_fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  border-radius: 60px;
}

.at-progressbar_fill {
  background-color: #ff7300;
}

.at-dropboxuploader i {
  line-height: 60px;
  width: 60px;
  text-align: center;
  background: #f7f7f7;
  border-radius: 10px;
  font-size: 28px;
  color: var(--terguppycolor);
  margin-bottom: 10px;
  display: inline-block;
}

.at-creategroup .at-dropbox {
  border-radius: 4px;
  padding: 30px 20px;
}

.at-makeadmin {
  flex: none;
  margin-left: auto;
  padding: 3px 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  letter-spacing: 0.01em;
  font-family: var(--secchatfont);
  color: #999 !important;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: none;
}

input[type="checkbox"]:checked + .at-groupuserbar_content .at-makeadmin {
  display: inline-flex;
  visibility: visible;
  opacity: 1;
}

.at-makeadmin i {
  margin-right: 2px;
  font-weight: 700;
}

.at-makeadmin:not(.at-makeadmin-check) {
  opacity: 0;
  visibility: hidden;
}

.at-groupuserbar_content:hover .at-makeadmin:not(.at-makeadmin-check) {
  opacity: 1;
  visibility: visible;
}

.at-makeadmin-check {
  background: #22c55e;
  border-color: #22c55e;
  color: #fff !important;
}

.at-makeadmin-check:before {
  content: "\e92b";
  font-family: guppy-icomoon;
  color: #fff;
  margin-right: 4px;
}

.at-dropboxuploader img {
  margin: 0 0 10px;
}

.at-creategroup .at-form-group .at-btnlist {
  width: auto;
  box-shadow: inset 0 1px 0 #ddd;
  margin: 0 -30px -10px -30px;
  padding: 10px 20px 0;
}

.at-btnlist .at-groupuserbar_content {
  padding: 0 0 0 34px !important;
}

.at-btnlist .at-groupuserbar_title {
  white-space: pre-wrap;
  letter-spacing: 0.01em;
  font: 400 16px/26px var(--secchatfont);
}

.at-btnlist .at-groupuserbar:hover,
.at-btnlist .at-groupuserbar_content:hover {
  background: transparent;
}

.at-btnlist .at-groupuserbar_content:after {
  left: 0;
}

.at-creategroup .at-popupcontent {
  background: transparent;
}

.at-creategroup .at-themeform {
  background-color: #fff;
}

.at-assignlist-wrap .at-form-search {
  margin: 0 0 10px;
}

.at-form-search {
  position: relative;
  display: flex;
  align-items: center;
}

.at-form-search i {
  position: absolute;
  left: 18px;
  font-size: 18px;
  color: var(--terguppycolor);
}

.at-chat .at-form-search .at-form-control {
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 40px;
  outline: none;
}

.at-admin-leavegroup .at-themeform fieldset {
  margin: -5px;
}

.at-admin-leavegroup .at-themeform fieldset .at-form-group {
  padding: 5px;
}

.at-assignlist {
  margin: 0;
  padding: 0;
  max-height: 350px;
  overflow: auto;
}

.at-assignlist li {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.at-assignlist li:hover {
  background: #f7f7f7;
}

.at-assignlist .at-makeadmin {
  margin-left: auto;
  opacity: 1;
  visibility: visible;
  display: inline-block;
  padding: 3px 6px;
}

.at-assignlist figure {
  margin: 0;
  display: flex;
  align-items: center;
}

.at-assignlist figure img {
  max-width: 30px;
  border-radius: 50%;
  max-height: 30px;
  margin-right: 10px;
}

.at-assignlist figure figcaption {
  font: 700 14px/26px var(--primchatfont);
  color: var(--secguppycolor);
}

.at-leavebtn {
  background-color: #ef4444 !important;
}

.at-uploadimg {
  display: flex;
  min-width: 200px;
  min-height: 200px;
  max-height: 400px;
  align-items: center;
  padding-bottom: 40px;
  justify-content: center;
}

.at-uploadimg img {
  border-radius: 5px 5px 0 0;
}

@media (max-width: 480px) {
  .at-creategroup .at-form-group .at-btnlist {
    padding: 10px 10px 0;
    margin: 0 -15px -10px;
  }
}

:root {
  --whatsapp-bgcolor: #075e54;
}

.at-chat h1,
.at-chat h2,
.at-chat h3,
.at-chat h4,
.at-chat h5,
.at-chat h6 {
  font-weight: 700;
  font-family: var(--primchatfont);
  color: var(--secguppycolor);
}

.at-chat,
.at-chat *,
.at-chat :after,
.at-chat :before {
  box-sizing: border-box;
}

.at-chat_sidebar ::-webkit-scrollbar-track {
  background: #fff !important;
}

.at-chat_profile::-webkit-scrollbar-track,
.conversation-list::-webkit-scrollbar-track,
ul.user-list::-webkit-scrollbar-track {
  background: #fff !important;
}

.at-chat:not(.at-guppysidepopup) {
  z-index: 1;
  float: left;
  width: 100%;
  display: flex;
  height: 100vh;
  flex: 0 0 100%;
  overflow: hidden;
  position: relative;
  -ms-flex: 0 0 100%;
  border-radius: 10px;
  -webkit-box-flex: 0;
  background-color: #fff;
  max-width: 100% !important;
  color: var(--secguppycolor);
  font: 400 14px/26px var(--secchatfont);
  box-shadow: 0 0 0 rgba(40, 41, 61, 0.04), 0 0 10px rgba(96, 97, 112, 0.16);
}

.at-chat.at-messanger-chat {
  border-radius: 0 !important;
}

.at-chat:not(.at-guppysidepopup) > .at-chat_messages,
.at-chat:not(.at-guppysidepopup) > .at-chat_sidebar {
  max-height: 100vh;
}

.logged-in.admin-bar .at-chat .vib-close {
  top: 32px;
}

.logged-in.admin-bar
  .at-chat:not(.at-guppysidepopup)
  .at-sidebarhead
  .at-chat_sidebarsettingarea {
  height: calc(100vh - 150px);
}

.at-guppysidepopup {
  position: relative;
  z-index: 9999999;
  color: var(--secguppycolor);
  font: 400 14px/26px var(--secchatfont);
}

.at-btn-blocked,
.at-btn-resend,
.at-btn-respond,
.at-btn-sm {
  position: relative;
  align-items: center;
  justify-content: center;
  color: #fff;
  font: 700 14px/30px var(--primchatfont);
  padding: 0 10px;
  min-width: 80px;
  background: #22c55e;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
}

.at-btn-blocked:focus,
.at-btn-blocked:hover,
.at-btn-resend:focus,
.at-btn-resend:hover,
.at-btn-respond:focus,
.at-btn-respond:hover,
.at-btn-sm:hover {
  color: #fff;
}

.at-btn-respond:focus,
.at-btn-sm:focus {
  text-decoration: none !important;
  outline: none !important;
}

.at-btn-respond:focus {
  background-color: var(--primguppycolor) !important;
}

.at-btn-resend {
  background-color: #3c57e5;
}

.at-btn-respond {
  background-color: var(--primguppycolor);
  color: #fff !important;
}

.at-disabled {
  background: #f7f7f7;
  cursor: default;
  pointer-events: none;
}

.at-disabled,
.at-disabled:focus,
.at-disabled:hover {
  color: var(--terguppycolor);
}

.at-disabled:focus {
  background-color: #f7f7f7 !important;
  outline: none !important;
  color: var(--terguppycolor) !important;
  text-decoration: none !important;
}

.at-btn-blocked {
  background: #ef4444;
}

.at-chat * {
  scrollbar-width: thin;
}

.at-chat ::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.at-chat ::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0;
  border-radius: 60px;
  background-color: var(--primguppycolor);
  border: 5px solid var(--primguppycolor);
}

.at-chat :hover::-webkit-scrollbar-thumb {
  visibility: visible;
  opacity: 1;
}

.at-chat [class*="guppy-"]:before {
  transition: none;
}

.at-chat a,
.at-chat a:hover {
  text-decoration: none;
  outline: none;
}

.at-chat a {
  text-decoration: none !important;
}

.at-alert > a.at-guppy-removemodal,
.at-chat_sidebar_footer a,
.at-chat_sidebarsettingcontent > a,
.at-chat_sidebarsettingtitle > a,
.at-sidebarhead_search h3 a,
.at-sidebarhead a:focus,
.at-userinfo_settings a:focus {
  outline: none !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.at-chat .at-form-control,
.at-chat input[type="color"],
.at-chat input[type="date"],
.at-chat input[type="datetime-local"],
.at-chat input[type="datetime"],
.at-chat input[type="email"],
.at-chat input[type="month"],
.at-chat input[type="number"],
.at-chat input[type="password"],
.at-chat input[type="search"],
.at-chat input[type="tel"],
.at-chat input[type="text"],
.at-chat input[type="time"],
.at-chat input[type="url"],
.at-chat input[type="week"],
.at-chat select,
.uneditable-input {
  outline: none !important;
  padding: 0 20px;
  height: 44px;
  color: var(--secguppycolor);
  font: 16px/26px var(--secchatfont);
  border-radius: 3px;
  background-clip: unset;
  caret-color: var(--secguppycolor);
  letter-spacing: 0.01em;
  width: 100%;
  border: 1px solid #ddd;
  margin: 0;
  background-color: #fff !important;
  box-shadow: none;
  font-weight: 400 !important;
}

.at-chat .at-form-control::-moz-placeholder,
.at-chat input[type="color"]::-moz-placeholder,
.at-chat input[type="date"]::-moz-placeholder,
.at-chat input[type="datetime-local"]::-moz-placeholder,
.at-chat input[type="datetime"]::-moz-placeholder,
.at-chat input[type="email"]::-moz-placeholder,
.at-chat input[type="month"]::-moz-placeholder,
.at-chat input[type="number"]::-moz-placeholder,
.at-chat input[type="password"]::-moz-placeholder,
.at-chat input[type="search"]::-moz-placeholder,
.at-chat input[type="tel"]::-moz-placeholder,
.at-chat input[type="text"]::-moz-placeholder,
.at-chat input[type="time"]::-moz-placeholder,
.at-chat input[type="url"]::-moz-placeholder,
.at-chat input[type="week"]::-moz-placeholder {
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--terguppycolor) !important;
}

.at-chat .at-form-control::placeholder,
.at-chat input[type="color"]::placeholder,
.at-chat input[type="date"]::placeholder,
.at-chat input[type="datetime-local"]::placeholder,
.at-chat input[type="datetime"]::placeholder,
.at-chat input[type="email"]::placeholder,
.at-chat input[type="month"]::placeholder,
.at-chat input[type="number"]::placeholder,
.at-chat input[type="password"]::placeholder,
.at-chat input[type="search"]::placeholder,
.at-chat input[type="tel"]::placeholder,
.at-chat input[type="text"]::placeholder,
.at-chat input[type="time"]::placeholder,
.at-chat input[type="url"]::placeholder,
.at-chat input[type="week"]::placeholder {
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--terguppycolor) !important;
}

.at-btn {
  position: relative;
  align-items: center;
  justify-content: center;
  height: 44px;
  font: 700 16px var(--primchatfont);
  color: #fff !important;
  outline: none !important;
  border-radius: 4px;
  padding: 0 20px;
  min-width: 160px;
  background-color: var(--primguppycolor) !important;
  overflow: hidden;
  z-index: 0;
  letter-spacing: 0.01em;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  border: 0;
  cursor: pointer;
}

.at-btnv2 {
  border: 2px solid #ddd;
  background-color: #fff !important;
  color: var(--terguppycolor) !important;
  transition: all 0.3s ease-in-out !important;
}

.at-btnv2:focus,
.at-btnv2:hover {
  background-color: #f7f7f7 !important;
  color: var(--terguppycolor) !important;
}

.at-btnlist li {
  list-style: none;
  width: 50% !important;
  padding: 5px !important;
  margin: 0;
}

.at-btnlist .at-btn {
  height: 40px;
  min-width: auto;
  width: 100%;
  text-decoration: none;
}

.at-btnlist a.at-btn:focus,
.at-btnlist a.at-btn:hover {
  color: #fff;
}

.at-form-group .at-btnlist .at-btn {
  height: 40px;
  transition: none;
  padding: 0 10px;
}

.at-chat #reportchat textarea.at-form-control {
  height: 140px;
  display: flex;
}

.at-form-group .at-btnlist .at-btn:not[disabled="disabled"] {
  cursor: pointer;
}

.at-form-group .at-btnlist {
  padding: 0;
}

#reportuser .at-form-group .at-btnlist {
  width: auto;
}

.at-btnlist {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.at-form-group {
  position: relative;
  width: 100%;
  padding: 7px;
}

.at-themeform fieldset {
  margin: -7px;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.at-formtitle {
  font: 600 15px/23px var(--primchatfont);
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
  margin: 0 0 6px;
  display: block;
}

.at-important:after {
  content: "*";
  color: #ef4444;
}

.at-message_time {
  display: block;
  font-size: 13px;
  margin-top: 6px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: var(--terguppycolor);
}

.at-chatblockuser span:not(.at-message_time),
.at-message,
.at-userbar_loader span {
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-none {
  display: none !important;
}

.at-chat input[type="number"] {
  -moz-appearance: textfield;
}

.at-chat .at-replay_msg input {
  border-radius: 3px 0 0 3px;
  width: 100%;
  height: 60px;
  outline: none !important;
}

.at-message + .at-message {
  margin-top: 15px;
}

.at-message_gallery .at-messageoption_open {
  z-index: 2;
}

.at-messageoption_open {
  z-index: 1;
}

.at-message .at-message-qoute_content {
  padding: 10px 14px;
  background-color: #f7f7f7;
  padding-right: 43px !important;
}

.at-message .at-reply-video {
  padding-right: 10px !important;
}

.vue2leaflet-map {
  z-index: 1;
}

.at-message-qoute_content .vue2leaflet-map {
  width: 180px !important;
  height: 90px !important;
}

.at-messages .at-message-qoute_content .vue2leaflet-map {
  margin-right: -33px;
}

.at-message .at-message-qoute_content .video-player .video-js {
  width: 260px;
  height: 150px;
}

.at-chat1440 .at-chat_sidebar {
  flex: 0 0 380px;
  max-width: 380px;
}

.at-chat420 .at-chat_sidebarsetting,
.at-chat480 .at-chat_sidebarsetting,
.at-chat575 .at-chat_sidebarsetting,
.at-chat640 .at-chat_sidebarsetting,
.at-chat991 .at-chat_sidebarsetting,
.at-chat1080 .at-chat_sidebarsetting,
.at-chat1199 .at-chat_sidebarsetting,
.at-chat1440 .at-chat_sidebarsetting {
  position: absolute;
}

.at-chat1440 .at-chat_messages {
  flex: 0 0 calc(100% - 380px);
  max-width: calc(100% - 380px);
}

.at-chat480 .at-form-group .at-btnlist li {
  width: 100% !important;
}

.at-chat1199 .at-message_imgs li,
.at-chat1440 .at-message_imgs li {
  min-width: 120px;
}

.at-chat1199 .at-message_gallery .at-messageoption:before,
.at-chat1440 .at-message_gallery .at-messageoption:before {
  width: 100px;
}

.at-chat1199 .at-message_imgs figure,
.at-chat1440 .at-message_imgs figure {
  width: 100px;
  height: 100px;
}

.at-chat1199 .at-chat_sidebar {
  flex: 0 0 350px;
  max-width: 350px;
}

.at-chat1199 .at-message {
  max-width: 85%;
}

.at-chat1199 .at-chat_messages {
  flex: 0 0 calc(100% - 350px);
  max-width: calc(100% - 350px);
}

.at-chat1080 .at-message_imgs {
  margin: -5px;
}

.at-chat1080 .at-message_imgs li {
  min-width: 130px;
  padding: 5px;
}

.at-chat1080 .at-chat_messages {
  flex: 0 0 calc(100% - 350px);
  max-width: calc(100% - 350px);
}

.at-chat991 .at-chat_messages,
.at-chat991 .at-chat_sidebar {
  flex: 0 0 100%;
  max-width: 100%;
}

.at-chat_messages {
  display: none;
}

.at-opnchatbox .at-chat_messages {
  display: flex;
}

.at-chat991.at-opnchatbox .at-chat_sidebar,
.at-guppyside-responsive.at-opnchatbox .at-chat_sidebar {
  display: none;
}

.at-chat991.at-opnchatbox .at-chat_messages {
  display: flex;
}

.at-chat991 .at-userinfo {
  padding-left: 13px;
}

.at-chat991 .at-chat > .at-chat_sidebarsetting .at-chat_sidebarsettingarea {
  height: calc(100vh - 91px);
  padding-bottom: 20px;
}

.at-chat991 .at-floatchat {
  right: 20px;
}

.at-chat640 .at-userinfo_title_name p span:nth-last-child(2) {
  display: none;
}

.at-chat640 .leaflet-container {
  width: 300px !important;
}

.at-chat640 .at-message-qoute_content .vue2leaflet-map {
  width: 180px !important;
}

.at-chat575 .at-userinfo_title_name p span:nth-last-child(3) {
  display: none;
}

.at-chat575 .at-replay_message {
  margin-right: 0;
  margin-left: 0;
}

.at-chat480 .at-userinfo_title_name p span:nth-last-child(4),
.at-chat575 .at-message_imgs li:nth-last-child(2) {
  display: none;
}

.at-chat480 .at-chat_sidebar_footer a .at-notify {
  padding: 0 6px;
  line-height: 17px;
  font-size: 10px;
}

.at-chat480 .at-userinfo {
  padding-right: 15px;
  padding-left: 8px;
}

.at-chat480 .at-userlist .at-userbar_profile {
  margin-right: 0;
}

.at-chat480 .at-groupuser_twoplus,
.at-chat480 .at-userinfo_title > a {
  margin-right: 10px !important;
}

.at-chat480 .at-messanger-chat .at-chat_profile {
  padding: 20px 20px 20px 10px;
}

.at-chat480 .at-alert,
.at-chat480 .at-messages,
.at-chat480 .at-replay,
.at-chat480 .at-replay_message {
  padding-right: 15px;
  padding-left: 15px;
}

.at-chat480 .at-message {
  max-width: 100%;
}

.at-chat480 .at-sendfile {
  padding-right: 39px;
  padding-left: 15px;
}

.at-chat480 .at-sendfile .at-messageoption {
  right: 10px;
}

.at-chat480 .at-insidearrow {
  padding-right: 34px;
}

.at-chat480 .at-locationmap .at-messageoption {
  right: 36px;
}

.at-chat480 .at-message-qoute_content .at-sendfile {
  padding-right: 0;
  padding-left: 0;
}

.at-chat480 .at-locationmap + .at-messageoption {
  right: 81px;
}

.at-chat480 .at-message_video + .at-messageoption {
  right: 31px;
}

.at-chat480 .at-message_imgs li:nth-last-child(3) {
  display: none;
}

.at-chat480 .at-message_imgs + .at-messageoption {
  right: 36px;
}

.at-chat480 .at-msg_imgarrowreply .at-messageoption {
  right: 29px;
}

.at-chatfloat .at-userchat_tab-noti {
  top: 0;
  right: 0;
  position: absolute;
  z-index: 9999;
}

.at-chat480 .at-chatfloat {
  bottom: 9px;
  right: 9px;
}

.at-chat480 .at-floatchat {
  right: 10px;
  width: 300px;
}

.at-chat480 .at-floatchat ~ .at-floatchat {
  transform: translateX(0);
  margin-right: 0;
}

.at-chat480 .at-replay_message .at-message-qoute_content .at-sendfile {
  padding-right: 14px;
  padding-left: 14px;
}

.at-chat480 .at-replay_audio > a,
.at-chat480 .at-replay_upload > a,
.at-chat480 .at-sendmsg-responsive {
  width: auto;
  border: 0;
  font-size: 22px;
  background-color: transparent;
}

.at-chat480 .at-replay_audio > a:hover,
.at-chat480 .at-replay_upload > a:hover {
  background-color: transparent;
}

.at-chat480 .at-sendmsg:focus,
.at-chat480 .at-sendmsg:hover {
  background: transparent !important;
}

.at-chat480 .at-replay_audio.at-startrecording a,
.at-chat480 .at-replay_audio:focus a {
  background-color: transparent !important;
  border-color: transparent;
  color: var(--terguppycolor) !important;
}

.at-chat480.at-chat .at-replay_msg input {
  padding-right: 68px;
}

.at-chat420 .at-replay_msg input {
  border-radius: 3px;
}

.at-chat420 .at-message_imgs {
  margin: -5px;
}

.at-chat420 .at-message_imgs li {
  padding: 5px !important;
}

.at-chat420 .at-message_imgs figure {
  max-width: 115px;
}

.at-chat420 .leaflet-container {
  height: 150px !important;
  width: 210px !important;
}

.at-chat420 .at-reply-location .leaflet-container {
  width: 150px !important;
  height: 100px !important;
}

.at-chat420 .at-remove-quotes {
  font-size: 20px;
}

.at-chat420 .at-messagewrap {
  padding: 0;
}

.at-chat420 .at-message-qoute .at-message_imgs figure {
  max-width: 50px;
  max-height: 50px;
}

.at-chat420 .at-msg_imgarrowreply .at-messageoption {
  right: 24px;
}

.at-chat420 .at-messagemap .at-messageoption_list {
  right: -60px !important;
}

.at-chat420 .at-floatchat_content .at-replay_msg > a:not(.at-sendmsg) {
  right: 21px;
}

.at-chat420 .at-whatsappchat .at-replay_msg > a:not(.at-sendmsg) {
  right: 60px;
}

.at-chat420 .at-floatchat_content .at-replay_msg input {
  padding-right: 52px;
}

@media (max-width: 480px) {
  .at-alert,
  .at-popuptitle + * {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.at-disable-btn {
  position: relative;
  pointer-events: none;
  color: var(--terguppycolor) !important;
  background-color: #f7f7f7 !important;
}

.at-disable-btn:before {
  content: "";
  display: inline-flex;
  margin-right: 10px;
  -webkit-animation: spinericon 2s linear infinite;
  animation: spinericon 2s linear infinite;
  transition: none;
  border: 3px solid #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-right-color: transparent;
}

.at-bgdanger.at-disable-btn,
.at-bgsuccess.at-disable-btn,
.at-btn-blocked.at-disable-btn {
  background-color: #f7f7f7 !important;
}

.at-btn-sm.at-disable-btn {
  color: var(--terguppycolor);
}

.at-bgdanger {
  background-color: #ef4444 !important;
}

.at-bgsuccess {
  background-color: #22c55e !important;
}

.at-anchor {
  font: 700 16px/24px var(--primchatfont);
  letter-spacing: 0.01em;
  display: inline-flex;
}

.at-anchor,
.at-anchor:focus,
.at-anchor:hover {
  color: var(--terguppycolor);
}

.at-notify {
  font-weight: 600;
  padding: 4.5px 8px;
  line-height: 12px;
  font-size: 12px;
  color: #fff;
  border-radius: 60px;
  text-align: center;
  background-color: #ef4444;
  font-style: normal;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.at-infotolltip {
  position: relative;
  margin-left: 6px;
  z-index: 1;
}

.at-infotolltip i {
  display: block;
  font-size: 15px;
  line-height: inherit;
}

.at-chat_sidebar_footer a:hover .at-infotolltip,
.at-infotolltip i:hover + em {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.at-chat_sidebar_footer li:not(:last-child) .at-infotolltip {
  top: auto;
  left: 60px;
  bottom: 0;
  right: auto;
  margin-left: 0;
  border-radius: 4px;
}

.at-chat_sidebar_footer .at-infotolltip:after,
.at-infotolltip em:after {
  width: 0;
  height: 0;
  border-top: 8px solid var(--secguppycolor);
  border-left: 10px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
}

.at-chat_sidebar_footer li:not(:last-child) .at-infotolltip:after {
  border-left: 0;
  left: -9px;
  right: auto;
  border-top: 12px solid transparent;
  border-right: 9px solid var(--secguppycolor);
  border-bottom: 14px solid transparent;
  top: 3px;
}

.at-userlist_tab li:first-child .at-infotolltip em {
  top: 100%;
  bottom: auto;
  border-radius: 4px 0 4px 4px;
  margin-bottom: 0;
  margin-top: 18px;
}

.at-userlist_tab li:first-child .at-infotolltip em:after {
  border-bottom: 8px solid var(--secguppycolor);
  border-left: 10px solid transparent;
  border-top: 0;
  top: auto;
  bottom: 100%;
}

.at-blockuser {
  position: relative;
}

.at-blockuser > i {
  z-index: 1;
  color: #ef4444;
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: -4px;
  left: -4px;
}

.at-chatsidebar_float .at-blockuser > i {
  font-size: 15px;
}

.at-blockuser > i:after {
  position: absolute;
  background-color: #fff;
  content: "";
  width: 85%;
  height: 85%;
  left: 1px;
  border-radius: 50%;
  z-index: -1;
  top: 1px;
}

.at-message-qoute {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 25px;
  margin: 0 0 10px;
  justify-content: flex-start;
}

.at-messages .at-message-qoute .at-messageoption {
  top: auto;
  right: 17px;
  position: relative;
  margin-left: -18px;
}

.at-messages .at-reply-video + .at-messageoption {
  align-self: flex-start;
  top: 20px;
  right: 26px;
}

.at-messages .at-reply-video + .at-messageoption .at-messageoption_btn {
  color: #fff;
}

.at-message_sender .at-message-qoute {
  justify-content: flex-end;
}

.at-message-qoute .at-message_imgs {
  margin: -5px;
}

.at-message-qoute .at-message_imgs li {
  padding: 5px !important;
  min-width: auto !important;
  max-width: 100% !important;
}

.at-message-qoute .at-message_imgs figure,
.at-message-qoute .at-message_imgs figure img {
  max-width: 50px;
  max-height: 50px;
  background-color: #fff;
}

.at-message-qoute .at-message_imgs figure span {
  font-size: 16px;
}

.at-message-qoute:before {
  content: "î¥Š";
  text-align: left;
  min-width: 25px;
  font-size: 16px;
  font-family: guppy-icomoon;
  color: var(--terguppycolor);
}

.at-message-qoute > span:not(.at-remove-quotes) {
  padding: 10px 14px;
  display: block;
  letter-spacing: 0.5px;
  font-size: 12px;
  line-height: 28px;
  background-color: #f7f7f7;
  border-radius: 3px;
}

.at-remove-quotes {
  margin-left: auto;
  cursor: pointer;
  font-size: 30px;
  padding: 0;
  color: var(--terguppycolor);
}

.at-message-qoute > span .at-sendfile {
  padding: 4px 0;
}

.at-message-qoute .at-sendfile i {
  font-size: 14px;
  margin-right: 10px;
  line-height: 20px;
}

.at-groupusersearch {
  display: flex;
  align-items: center;
}

.at-groupusersearch i {
  position: absolute;
  left: 30px;
  font-size: 18px;
  color: var(--terguppycolor);
}

.at-chat .at-groupusersearch i + input {
  padding-left: 45px;
}

.at-chat991 .at-message_gallery,
.at-chat1080 .at-message_gallery {
  max-width: 100%;
}

.at-chat640 .at-message_imgs figure {
  width: 90px;
  height: 90px;
}

.at-chat640 .at-message_imgs li {
  min-width: 110px;
}

.at-chat640 .at-message_gallery .at-messageoption:before {
  width: 90px;
}

.at-chat575 .video-js {
  width: 300px;
  height: 150px;
}

.at-chat420 .video-player > div {
  width: 220px;
}

.at-chat420 .at-message_sender .leaflet-container {
  margin-left: auto;
}

.at-chat420 .at-message_gallery > h5 {
  width: 100%;
}

.at-chat420
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message_gallery {
  align-items: flex-start;
}

.at-chat420 .at-message_gallery {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.at-chat480 .at-sendpdffile .at-messageoption {
  right: 10px;
}

.at-chat480 .at-video-message .at-messageoption {
  right: 37px;
}

.at-chat480 .at-video-message .at-message-qoute .at-messageoption {
  right: 27px;
}

.at-chat420 .vueAudioBetter {
  height: 54px !important;
  width: 230px !important;
  align-items: flex-start !important;
  flex-wrap: wrap;
}

.at-chat420 .at-message-qoute_content .vueAudioBetterr {
  width: 160px !important;
}

.at-chat420 .at-message-qoute_content .vueAudioBetter {
  width: 75% !important;
}

.at-chat420 .operate {
  width: 100%;
}

.at-chat420 .vueAudioBetter .slider {
  width: 75% !important;
  margin-top: 7px;
  margin-left: 4px;
}

.at-chat420 .slider .thunk {
  top: -8px !important;
}

.at-chat420 .at-messageoption_list {
  width: 190px !important;
  left: -50px;
}

.at-chat420 .at-message_imgs figure {
  max-width: 70px;
  max-height: 70px;
}

.at-chat420 .at-message_gallery .at-messageoption:before {
  width: 70px;
}

.at-chat420 .at-message_imgs li {
  min-width: auto;
  max-width: 100% !important;
}

.at-chat420 .at-message_sender .at-messageoption_list {
  left: auto;
  right: -20px;
}

.at-select {
  align-items: center;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.at-select:before {
  right: 20px;
  z-index: 1;
  content: "î¤®";
  position: absolute;
  font-size: 18px;
  pointer-events: none;
  font-family: guppy-icomoon;
  color: var(--terguppycolor);
}

.at-alert .at-btn:focus,
.at-alert .at-btn:hover {
  color: #fff;
  text-decoration: none !important;
}

.at-deletemsg {
  color: var(--terguppycolor);
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  padding-right: 20px;
  border: 1px solid #ddd;
}

.at-deletemsg i {
  color: #ddd;
  font-size: 22px;
  margin-right: 10px;
}

.at-sendpdffile {
  padding: 20px;
}

.at-reply-location .vue2leaflet-map {
  z-index: 0;
}

.at-reply-location .vue2leaflet-map:before {
  left: 0;
  top: 0;
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  content: "";
  width: 100%;
  height: 100%;
}

.at-messages .at-message-qoute .at-reply-location + .at-messageoption {
  top: 18px;
  right: 26px;
  align-self: flex-start;
}

.at-reply-location + .at-messageoption .at-messageoption_btn {
  color: #fff !important;
}

.at-message .at-reply-images {
  padding-right: 14px !important;
}

.at-messages .at-reply-images + .at-messageoption {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 0;
  position: absolute;
  right: 14px;
  top: 10px;
}

.at-messages .at-reply-images + .at-messageoption > a {
  color: #fff;
  margin-top: 0;
  margin-left: 0;
}

.at-messages:not(.at-message_sender) .at-reply-images + .at-messageoption {
  top: -10px;
  right: 14px;
  margin-left: -20px;
  position: relative;
}

.at-messages.at-inner-loaader {
  background: transparent;
  position: relative;
  height: auto;
  bottom: auto;
  padding: 20px 0 27px;
  align-items: center;
}

.at-message.at-loadermsg {
  display: flex;
  align-items: center;
}

.at-message.at-loadermsg i {
  font-size: 24px;
  display: inline-block;
  margin-right: 10px;
}

.at-messages.at-inner-loaader + .at-messages .at-chatseparation {
  margin-top: 0;
}

.at-chatseparation {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.at-chatseparation span {
  position: relative;
  font: 400 14px/28px Open Sans, sans-serif;
  letter-spacing: 0.5px;
  color: #999;
  padding: 10px 20px;
  background-color: #f7f7f7;
}

.at-chatseparation:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #ddd;
}

.at-inner-loaader span {
  height: 64px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  line-height: 64px;
  background: #fff;
  font-size: 24px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-message .at-message-qoute_content .at-sendfile {
  padding-right: 0;
}

.at-modal .at-disable-btn:focus {
  color: var(--terguppycolor);
}

.at-chat480 .video-js {
  width: 280px;
  height: 150px;
}

.at-chat480 .at-message-qoute .video-js {
  width: 200px !important;
  height: 100px !important;
}

.at-chat420 .at-replay_message .at-message-qoute_content .vue2leaflet-map {
  width: 190px !important;
  height: 100px !important;
}

.at-chat h1:not(.site-title):before,
.at-chat h2:before {
  display: none !important;
}

.at-chat640 .at-message-qoute .at-message_imgs li {
  max-width: inherit !important;
}

.at-chat640 .at-replay_message {
  margin: 0 0 10px;
}

.at-messagetext .linkified {
  color: #3c57e5 !important;
  outline: none !important;
}

.at-message img.emoji,
.at-message img.wp-smiley {
  vertical-align: middle !important;
  width: 24px !important;
  height: 24px !important;
  margin: 0 0.1em !important;
}

.at-message .at-message-qoute_content > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.at-messages:not(.at-message_sender) .at-chat-msg-220 .at-messageoption_list {
  left: auto;
  right: 100%;
  margin-right: -30px;
}

.at-chat420 #reportuser .at-btnlist li {
  width: 100% !important;
}

.at-chat420 #reportuser .at-btnlist {
  width: auto !important;
}

.at-sidbarwrapper {
  display: flex;
  padding-bottom: 0;
  flex-direction: column;
  height: 100vh;
  box-shadow: 1px 0 0 #eee;
}

.at-groupuser_twoplus {
  display: flex;
  flex: none;
  flex-wrap: wrap;
  -moz-column-gap: 4px;
  column-gap: 4px;
  justify-content: center;
  background: transparent !important;
}

.at-chat_previewfloat .at-groupuser_twoplus img {
  width: 18px;
  height: 18px;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 18px;
}

.at-groupuser_twoplus img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 20px;
}

.at-groupuser_twoplus img:nth-child(n + 3) {
  margin-top: 5.5px;
}

.at-chat_previewfloat .at-groupuser_twoplus .at-notify {
  width: 18px;
  min-width: 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.at-p-0 {
  padding: 0 !important;
}

.at-groupuserbar {
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
  transition: all 0.3s ease-in-out;
  margin: 0 0 0 0;
  width: auto;
}

.at-groupuserbar:hover {
  background: #f7f7f7;
}

.at-groupuserbar_title h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.at-remove {
  opacity: 0;
  outline: none;
  line-height: 1;
  font-size: 20px;
  box-shadow: none;
  margin-left: auto;
  visibility: hidden;
  color: #ef4444 !important;
}

.at-groupuserbar:hover .at-remove {
  visibility: visible;
  opacity: 1;
}

.at-addnewuser-btn {
  display: block;
  letter-spacing: 0.5px;
  font: 700 16px/26px var(--primchatfont);
}

.at-chatgroup-title {
  padding: 0 30px 10px;
}

.at-chatgroup-title a {
  letter-spacing: 0.5px;
  margin: 10px 0 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #3c57e5 !important;
}

.at-creategroup .at-modaldialog {
  max-width: 510px;
}

.at-creategroup .at-btnlist li {
  width: 100% !important;
  padding: 10px !important;
}

.at-creategroup .at-btnlist .at-btn[disabled="disabled"] {
  background: #ddd !important;
  color: var(--secguppycolor) !important;
}

.at-creategroup .at-disable-btn:before {
  border-color: var(--secguppycolor);
  border-right-color: transparent;
}

.at-popuptitle {
  background: var(--secguppycolor);
  box-shadow: inset 0 -1px 1px hsla(0, 0%, 100%, 0.3);
  border-radius: 10px 10px 0 0;
  padding: 19px 30px;
  display: flex;
  align-items: center;
  width: 100%;
}

.at-popuptitle + * {
  padding: 30px;
}

.at-popuptitle h2 {
  font-size: 20px;
  margin: 0;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
}

.at-popuptitle > a {
  margin: 0 -4px 0 auto;
  font-size: 28px;
  color: #fff;
  display: inline-flex;
}

.at-popuptitle > a:focus {
  background: transparent !important;
  outline: none !important;
}

.at-popupfooter {
  padding: 30px;
  box-shadow: inset 0 1px 0 #ddd;
}

.at-popupcontent {
  width: 100%;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}

.at-popupcontent .at-dropbox label {
  max-width: 240px;
}

.at-creategrouplist {
  width: 100%;
}

.at-grouplist {
  margin: 0;
  padding: 0;
  list-style: none;
}

.at-creategroup .at-grouplist {
  max-height: 320px;
  overflow: auto;
  padding: 0;
  margin: 10px 0 0;
}

.at-creategrouplist .at-empty-conversation {
  padding: 30px 10px;
  margin: 10px 0 0;
  min-height: 250px;
}

.at-creategrouplist .at-empty-conversation span {
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.at-groupuserbar input {
  display: none !important;
}

.at-groupuserbar_content {
  margin-bottom: 0 !important;
  cursor: pointer;
  width: 100%;
  padding: 10px 14px 10px 52px !important;
  display: flex !important;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.at-groupuserbar_content:hover {
  background: #f7f7f7;
}

.at-groupuserbar_content:before {
  display: none;
}

.at-groupuserbar_content:after {
  content: "";
  font-family: guppy-icomoon;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  width: 24px;
  line-height: 21px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 14px;
  border: 1.5px solid #ddd;
}

.at-groupuserbar
  input[type="checkbox"]:checked
  + .at-groupuserbar_content:after {
  background: #22c55e;
  content: "î¤«";
  border-color: #22c55e;
}

.at-groupuserbar_title {
  font: normal 700 14px/24px Urbanist, sans-serif;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secguppycolor);
}

.at-groupuserbar_profile {
  max-width: 30px;
  margin: 0 10px 0 0 !important;
}

.at-groupuserbar_profile img {
  border-radius: 50%;
  width: 100%;
  height: 30px;
  width: 30px;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

.at-message h5 {
  font: 700 15px/22px var(--primchatfont);
  color: var(--primguppycolor);
  margin: 0 0 4px;
}

.at-group-avatar,
.at-messages .at-group-avatar {
  width: 40px;
  height: 40px;
  margin-right: 14px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-chatgroup-sidebar {
  padding: 30px 0 20px;
}

.at-chatgroup-sidebar .at-groupuserbar {
  padding: 10px 30px;
}

.at-group-user-status {
  color: #999;
  font-size: 10px;
  padding: 4px 8px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 5px;
  line-height: 14px;
  border-radius: 3px;
  letter-spacing: 0.01em;
  background-color: #fff;
  border: 1px solid #ddd;
}

.at-message_sender .at-grp_receiver_msg {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.at-grp_receiver_msg {
  display: flex;
  width: 100%;
}

.at-message_sender .at-grp_receiver_msg + .at-message_time {
  padding-left: 0;
}

.at-grp_receiver_msg + .at-message_time {
  padding-left: 70px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messageoption
  .at-messageoption_btn {
  color: var(--terguppycolor);
}

.at-messages:not(.at-message_sender) .at-grp_receiver_msg .at-sendfile,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-sendpdffile
  .at-sendfile {
  padding-right: 20px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messageoption:before {
  display: none;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message-qoute_content
  .vue2leaflet-map {
  margin-right: 0;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-location
  + .at-messageoption
  .at-messageoption_btn {
  color: var(--terguppycolor) !important;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-images
  + .at-messageoption {
  width: auto;
  height: auto;
  line-height: normal;
  right: 0;
  background: transparent;
  position: absolute;
  top: -28px !important;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message-qoute_content {
  padding-right: 14px !important;
}

.at-group-disabled,
.at-leftgroupinfo {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.at-group-disabled {
  margin-top: auto;
  margin-bottom: 38px;
}

.at-group-disabled span,
.at-leftgroupinfo span {
  padding: 14px 22px;
  letter-spacing: 0.5px;
  color: #999;
  font-size: 15px;
  line-height: 26px;
  background: #fff;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-group-disabled span:empty,
.at-leftgroupinfo span:empty {
  display: none;
}

.at-group-disabled span strong,
.at-leftgroupinfo span strong {
  font-weight: 700;
  color: #999;
}

.at-leftgroupinfo + .at-message_time {
  align-self: center;
}

.at-leftgroupinfo + .at-message_time:before {
  display: none;
}

.at-groupuser_img {
  z-index: 0;
  width: 100%;
  flex: none;
  align-items: flex-start;
  flex-direction: column;
  background-color: transparent !important;
}

.at-groupuser_img img {
  margin: 0;
  width: 26px;
  height: 26px;
  min-height: 26px;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-chat_previewfloat .at-groupuser_img img {
  width: 24px;
  height: 24px !important;
  min-height: 24px;
}

.at-groupuser_img img:first-child {
  outline: 4px solid #fff;
}

.at-groupuser_img img + img {
  margin-top: -10px;
  margin-left: auto;
  z-index: -1;
}

.at-grouplist .at-inner-loaader {
  bottom: 0;
}

.at-messages:not(.at-message_sender) .at-grp_receiver_msg .at-messageoption {
  position: absolute;
  right: 18px;
  top: 18px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message_gallery
  > .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  .at-messageoption {
  top: 18px;
  right: 18px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-video
  + .at-messageoption {
  top: -28px;
  right: 0;
}

.at-messages:not(.at-message_sender) .at-grp_receiver_msg .at-message > h5 {
  padding-right: 20px;
}

.at-messages:not(.at-message_sender) .at-grp_receiver_msg .at-messagetext {
  padding: 20px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  > div:not(.at-messageoption) {
  margin-right: 0;
}

.at-linebtn {
  border: 1px solid #22c55e;
  background-color: #fff;
  color: var(--secguppycolor);
}

.at-linebtn:hover {
  background-color: #22c55e;
  color: #fff;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-audiosendfile
  .at-message-qoute
  .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  .at-message-qoute
  .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-location
  + .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-voice
  + .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  > .at-audiosendfile
  > .at-sendfile
  .at-messageoption,
.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  > .at-sendpdffile
  > .at-sendfile
  .at-messageoption {
  top: -28px;
  right: 0;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  > .at-messageoption {
  top: 18px;
  right: 18px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-images
  + .at-messageoption
  .at-messageoption_btn {
  margin: 0;
}

.at-chat640 .at-messages:not(.at-message_sender) .at-grp_receiver_msg {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

.at-chat640 .at-group-avatar,
.at-chat640 .at-messages .at-group-avatar {
  margin: 0 0 10px;
}

.at-chat640
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  + .at-message_time {
  padding-left: 0;
}

@media (max-width: 480px) {
  .at-popuptitle,
  .at-popuptitle + * {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.at-chat480 .at-del-msg {
  font-size: 14px;
}

.at-dropboxuploader .at-viewuploadimg {
  margin: 0 0 10px;
  width: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  min-height: auto;
  align-items: center;
  justify-content: center;
}

.at-dropboxuploader .at-viewuploadimg i,
.at-dropboxuploader .at-viewuploadimg img {
  margin-bottom: 0;
}

.at-grp_receiver_msg .at-deletemsg {
  flex-direction: column;
  align-items: flex-start;
}

.at-del-msg {
  display: flex;
  align-items: center;
}

.at-showmore {
  justify-content: center;
  text-align: center;
}

.at-showmore:hover {
  background: transparent;
}

.at-showmore a {
  font: 700 16px/26px var(--primchatfont);
  color: #3c57e5;
}

.video-js .vjs-tech {
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0.5;
}

.vjs-playing.video-js .vjs-tech {
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.at-replay_msg .emoji-mart-anchor,
.at-replay_msg .emoji-mart-emoji {
  background-color: #fff !important;
}

.at-userchat_tab {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 #eee;
}

.at-userchat_tab a {
  width: 100%;
  padding: 12px 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: left;
  color: var(--secguppycolor);
  font: 600 16px/32px var(--primchatfont);
}

.at-userchat_tab a > svg {
  margin-right: 12px;
}

.at-userchat_tab a > span {
  position: relative;
  font-size: 15px;
  display: inline-block;
  letter-spacing: 0.5px;
  color: #999;
}

.at-userchat_tab a .at-userchat_tab-noti {
  margin-left: 12px;
}

.at-userchat_tab a i {
  font-size: 20px;
  color: #999;
  font-weight: 400;
  margin-right: 12px;
}

.at-userchat_tab a.at-tabactive i,
.at-userchat_tab a.at-tabactive span {
  color: #0a0f26;
}

.at-userchat_tab-noti {
  color: #fff;
  padding: 0 8.5px;
  font-style: normal;
  border-radius: 12px;
  display: inline-block;
  letter-spacing: 0.01em;
  background: #ef4444;
  font: 700 12px/24px var(--secchatfont);
}

.at-chatfloatholder .at-userchat_tab-noti {
  padding: 0 6.5px;
  font: 700 12px/20px var(--secchatfont);
}

.at-userchat_tab .at-tabactive {
  background: #fff;
  box-shadow: inset 1px -3px 0 -1px #ff7300 !important;
}

.at-view-messages {
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
}

.at-view-messages .at-chatloader {
  transform: translateZ(0) scale(0.4);
  width: 40px;
  height: 40px;
  margin: 0;
}

.at-reply_chat.at-view-messages {
  overflow-y: auto;
}

.at-message span {
  white-space: pre-line;
}

.at-floatchat_content .at-view-messages {
  flex: auto;
  height: auto;
  overflow: auto;
  background-color: #f7f7f7;
}

.at-floatchat_content .video-js,
.at-guppyside-responsive .video-js {
  width: 200px;
  height: 200px;
}

.at-floatchat_content .at-message {
  max-width: 95%;
}

.at-floatchat_content .vue2leaflet-map,
.at-guppyside-responsive .vue2leaflet-map {
  width: 200px !important;
  height: 200px !important;
}

.at-floatchat_content .at-message-qoute_content .vue2leaflet-map,
.at-guppyside-responsive .at-message-qoute_content .vue2leaflet-map {
  width: 180px !important;
  height: 90px !important;
}

.at-floatchat_content .at-message,
.at-floatchat_content .at-sendfile > span,
.at-guppyside-responsive .at-message,
.at-guppyside-responsive .at-sendfile > span {
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.at-floatchat_content .at-sendfile > span em {
  padding-top: 2px;
}

.at-floatchat_content .at-messagetext {
  padding: 10px 35px 10px 16px;
}

.at-floatchat_content .at-audiosendfile,
.at-floatchat_content .at-locationmap,
.at-floatchat_content .at-message.at-video-message,
.at-floatchat_content .at-message_gallery,
.at-floatchat_content .at-sendpdffile {
  padding: 10px;
}

.at-floatchat_content .at-sendfile {
  padding: 10px 35px 10px 10px;
}

.at-chat_previewfloat .at-message_sender .at-message,
.at-floatchat_content .at-message_sender .at-message {
  border-radius: 10px 10px 0 10px;
}

.at-floatchat_content .at-messageoption {
  right: 12px;
  top: 12px;
}

.at-floatchat_content .at-messageoption_btn {
  font-size: 16px;
}

.at-floatchat_content .at-messageoption > a i {
  font-weight: 600;
}

.at-floatchat_content .at-video-message .at-messageoption {
  top: 20px;
  right: 23px;
}

.at-floatchat_content .at-video-message .at-reply-file + .at-messageoption {
  top: 0;
}

.at-floatchat_content .at-message_gallery .at-messageoption {
  top: 13px;
  right: 17px;
}

.at-floatchat_content .at-message_gallery .at-messageoption:before {
  right: -7px;
  width: 60px;
  height: 30px;
  top: -3px;
}

.at-floatchat_content .at-message_imgs {
  margin: -5px;
}

.at-floatchat_content .at-message_imgs li {
  padding: 5px;
  min-width: 70px;
  max-width: 50% !important;
}

.at-floatchat_content .at-message_imgs figure {
  width: 60px;
  height: 60px;
}

.at-floatchat_content .at-message_imgs figure img {
  display: block;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-floatchat_content .at-sendfile > i {
  font-size: 20px;
  line-height: 30px;
  margin-right: 16px;
}

.at-floatchat_content .at-sendfile > span {
  width: 100%;
}

.at-chat .at-floatchat_content .at-replay_msg input {
  height: 40px;
  font-size: 15px;
  border-radius: 3px;
}

.at-whatsappchat .at-emojibar {
  left: 1px !important;
}

.at-floatchat_content .at-replay_msg > .at-emojibar {
  left: 1px !important;
  width: 38px !important;
}

.at-floatchat_content .at-replay_msg > .at-emojibar img {
  width: 18px !important;
  height: 18px !important;
}

.at-emojibar .emoji-mart-anchor {
  outline: none;
  text-decoration: none;
}

.at-floatchat_content .at-chat_sidebar_footer {
  min-height: 54px;
}

.at-floatchat_content .at-replay_audio > a,
.at-floatchat_content .at-replay_upload > a {
  line-height: 54px;
  width: 100%;
  border: 0;
  border-radius: 0;
  color: #fff !important;
}

.at-floatchat_content .at-replay_audio.at-startrecording > a,
.at-floatchat_content .at-replay_audio > a:hover,
.at-floatchat_content .at-replay_upload.at-uploadoption_open > a,
.at-floatchat_content .at-replay_upload > a:hover {
  background-color: var(--primguppycolor) !important;
}

.at-chat_previewfloat .at-message,
.at-floatchat_content .at-message {
  border-radius: 0 10px 10px 10px;
}

.at-floatchat_content .at-grp_receiver_msg {
  flex-direction: column;
  align-items: flex-start;
}

.at-floatchat_content .at-message_sender .at-grp_receiver_msg {
  align-items: flex-end;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  + .at-message_time {
  padding-left: 0;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext {
  padding: 10px 34px 10px 16px;
}

.at-floatchat_content .at-messages {
  padding-right: 10px;
  padding-left: 10px;
}

.at-floatchat_content .at-inner-loaader {
  padding: 10px;
}

.at-floatchat_content .at-inner-loaader span {
  width: 40px;
  height: 40px;
}

.at-floatchat_content .at-inner-loaader i {
  width: 20px;
  height: 20px;
}

.at-floatchat_content .at-messages .at-group-avatar {
  margin: 0 0 6px;
  width: 30px;
  height: 30px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-110
  .at-messageoption_list,
.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-190
  .at-messageoption_list,
.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-270
  .at-messageoption_list {
  left: auto;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-110
  .at-messageoption_list {
  right: -140px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-190
  .at-messageoption_list {
  right: -60px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-270
  .at-messageoption_list {
  right: 0;
}

.at-floatchat_content .at-uploadoption a,
.at-floatchat_content .at-uploadoption label {
  width: 100%;
}

.at-floatchat_content .at-uploadoption a:hover {
  background-color: #f7f7f7 !important;
}

.at-chatblockuser {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 108px;
  padding: 0 20px;
}

.at-chatblockuser span {
  padding: 13px 22px;
  border-radius: 8px;
  border: 1px solid #ddd;
  letter-spacing: 0.5px;
  display: block;
  background-color: #fff;
}

.at-chatblockuser span,
.at-chatblockuser span strong {
  color: var(--terguppycolor);
  font-size: 15px;
  line-height: 28px;
}

.at-chatblockuser span strong {
  font-weight: 700;
}

.at-chatblockuser span a {
  color: #3c57e5;
}

.at-floatchat_content .at-chatblockuser {
  padding: 20px;
  min-height: auto;
  background: #f7f7f7;
  border-top: 1px solid #ddd;
}

.at-floatchat_content .at-message h5 {
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message
  > h5 {
  padding-right: 0;
}

.at-floatchat_content .at-sendfile .at-messageoption {
  top: 9px;
}

.at-floatchat_content .at-locationmap .at-messageoption {
  right: 24px;
  top: 19px;
}

.at-guppysidepopup .emoji-mart {
  left: 0 !important;
}

.at-floatchat_content .at-replay_message {
  background-color: #fff;
  margin-right: 0;
  margin-left: 0;
  padding: 10px;
}

.at-floatchat_content .at-replay_message .at-message-qoute_content {
  padding: 10px;
  background: #f7f7f7;
}

.at-floatchat_content .at-replay_message .at-sendfile {
  padding: 0;
}

.at-floatchat_content .at-remove-quotes {
  font-size: 24px;
  margin-left: auto;
  padding-left: 4px;
  display: flex;
}

.at-floatchat_content .at-messageoption_list a {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.at-floatchat_content .at-replay_message .at-sendfile {
  flex-wrap: nowrap;
}

.at-floatchat_content .at-replay_message .at-sendfile > i {
  line-height: 22px;
  margin-right: 10px;
}

.at-floatchat_content .at-replay_message .at-sendfile > span {
  width: auto;
}

.at-floatchat_content .at-messageoption_list a i {
  font-size: 16px;
}

.at-floatchat_content .at-message-qoute_content .vue2leaflet-map {
  width: 180px !important;
  height: 90px !important;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messageoption {
  right: 10px;
  top: 11px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  > .at-messageoption {
  top: 9px;
  right: 12px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  > .at-sendpdffile
  > .at-sendfile
  .at-messageoption {
  top: -25px;
}

.at-floatchat_content .at-audio-area {
  padding: 16px 10px;
  width: 100%;
}

.at-floatchat_content .operate {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.at-floatchat_content .operate > * {
  top: auto !important;
}

.at-floatchat_content .iconfont + .iconfont {
  margin-left: 3px;
}

.at-floatchat_content .operate > span:not(.iconfont) {
  padding-left: 6px !important;
  white-space: nowrap;
}

.at-floatchat_content .ready-player-1 {
  width: 100%;
}

.at-floatchat_content .vueAudioBetter {
  width: 100% !important;
}

.at-floatchat_content .at-audio-area .vueAudioBetter .slider {
  width: 35%;
}

.at-floatchat_content .at-audio-area .at-messageoption {
  top: 5px;
}

.at-floatchat_content .at-chatblockuser span {
  font-size: 14px;
  padding: 10px 20px;
  line-height: 24px;
}

.at-chat .at-floatchat_content .at-replay_msg textarea.at-form-control {
  height: 40px;
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 40px !important;
}

.at-chat .at-floatchat_content .at-replay_msg textarea.at-share-emoji {
  padding-left: 58px;
}

.at-floatchat_content .at-deletemsg,
.at-guppyside-responsive .at-deletemsg {
  padding: 10px 16px;
}

.at-floatchat_content .at-deletemsg i,
.at-guppyside-responsive .at-deletemsg i {
  font-size: 18px;
  margin-right: 8px;
}

.at-floatchat_content .at-leftgroup-footer {
  background: #f7f7f7;
  padding: 20px;
  border-top: 1px solid #ddd;
}

.at-floatchat_content .at-leftgroup-footer span {
  padding: 10px 16px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.at-leftgroupinfo span {
  font-size: 14px;
  line-height: 24px;
  padding: 10px 16px;
}

.at-radio-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.at-radio + .at-radio {
  margin-left: 30px;
}

.at-radio {
  position: relative;
}

.at-radio input {
  display: none;
}

.at-radio input:checked + label:before {
  border-color: #22c55e;
}

.at-radio input:checked + label:after {
  opacity: 1;
}

.at-radio label {
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0 !important;
}

.at-radio label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #ddd;
  background-color: #fff;
  margin-right: 10px;
}

.at-radio label:after,
.at-radio label:before {
  content: "";
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.at-radio label:after {
  position: absolute;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #22c55e;
  opacity: 0;
}

.at-floatchat_content .at-message .at-reply-images {
  padding: 10px;
  padding-right: 10px !important;
}

.at-floatchat_content .at-message-qoute .at-message_imgs li {
  max-width: 100% !important;
}

.at-floatchat_content .at-messages .at-reply-images + .at-messageoption {
  top: 10px;
  right: 10px;
}

.at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-reply-images
  + .at-messageoption {
  top: -10px;
}

.swal2-container {
  z-index: 9999999 !important;
}

@-webkit-keyframes at_scale {
  0% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes at_scale {
  0% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.at-chatbtn-wrap {
  width: 100%;
  margin: 20px 0 0;
}

.at-chatbtn {
  padding: 0 20px;
  text-align: center;
  border-radius: 3px;
  align-items: center;
  display: inline-flex;
  color: #fff !important;
  text-decoration: none !important;
  background: var(--primguppycolor) !important;
  font: 700 16px/40px var(--primchatfont);
}

.at-chatbtn i {
  margin-right: 10px;
}

.at-chatbtn.at-disable-btn:before {
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  margin: -8px 0 0 -8px;
  border-color: var(--secguppycolor);
  border-right-color: transparent;
}

.at-chatbtn.at-disable-btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.at-chatfloatholder {
  position: fixed;
  bottom: 44px;
  right: 44px;
  z-index: 10000;
}

.at-chatsidebar_float:not(.at-chatsidebar-widgetclose) + .at-chatfloatholder {
  z-index: 999;
}

.at-chatfloat > span {
  background-color: #f7f7f7;
  padding: 6px;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.24);
  width: 68px;
  height: 68px;
}

.at-chatfloat > span,
.at-chatfloat > span a {
  position: relative;
  z-index: 1;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.at-chatfloat > span a {
  padding: 2px;
  width: 100%;
  height: 100%;
  background: #fff;
}

.at-chatfloat > span a img {
  display: block;
  max-width: 35px;
  border-radius: 50%;
  height: 35px;
}

.at-chatfloat > span a i {
  color: var(--terguppycolor);
  font-size: 25px;
}

#at-float_widget_icon:not(.at-float-icon) i {
  display: none;
}

#at-float_widget_close i,
#at-float_widget_icon i {
  font-size: 22px;
}

.at-float-icon img {
  display: none !important;
}

.at-chatfloatlist {
  left: 0;
  margin: 0;
  padding: 0;
  bottom: 20px;
  position: relative;
  visibility: visible;
}

.at-chatfloatlist li {
  list-style: none;
  padding: 0 0;
  border-radius: 44.5px;
}

.at-chatfloatlist li + li {
  padding-top: 6px;
}

.at-chatfloatlist a {
  width: 80px;
  height: 158px;
  background: var(--primguppycolor);
  border-radius: 44.5px;
  align-items: flex-start;
  justify-content: center;
  font-size: 39px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
}

.at-chatfloatlist a i,
.at-chatfloatlist a img {
  margin-top: 22px;
}

.at-whatsapp_chat {
  background-color: #25d366 !important;
}

.at-chatfloatlist_circle {
  bottom: 100%;
}

.at-chatfloatlist_circle li {
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.at-chatfloatlist_circle li:first-child {
  margin-bottom: 14px;
}

.at-chatfloatlist_circle a {
  width: 61px;
  height: 61px;
  align-items: center;
  margin: 0 auto;
  font-size: 32px;
  border-radius: 50%;
}

.at-chatfloatlist_circle a img {
  max-width: 40px;
}

.at-chatfloatlist_circle a svg {
  max-width: 22px;
  max-height: 22px;
}

.at-chatfloatlist_circle a i,
.at-chatfloatlist_circle a img {
  margin-top: 0;
  font-size: 22px;
}

.at-chatsidebar_float {
  right: 40px;
  top: 20vh;
  bottom: 133px;
  opacity: 1;
  width: 100%;
  z-index: 999;
  height: auto;
  display: block;
  position: fixed;
  max-width: 400px;
  transform: scale(1);
  visibility: visible;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  border-radius: 20px;
}

.at-chatsidebar-widgetclose.at-chatsidebar_float {
  opacity: 0;
  right: -140px;
  bottom: -320px;
  visibility: hidden;
  transform: scale(0.1);
  transition: all 0.3s ease-in-out;
}

.at-chatsidebar_float + .at-floatchat {
  z-index: 10002;
}

.at-chatsidebar-widgetclose + .at-floatchat {
  z-index: 9999;
}

.at-chat_previewfloat {
  width: 100%;
  height: 100%;
}

.at-chat_previewfloat .at-chat_sidebar {
  height: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.at-closechat {
  color: #fff;
  display: none;
  font-size: 28px;
  margin-left: 20px;
}

.at-guppysidepopup .at-closechat {
  display: inline-block;
}

.at-closechat i {
  display: block;
  line-height: inherit;
}

.at-chatsidebar_float.at-chatsidebar-widgetclose + .at-floatchat {
  right: 154px;
}

.at-floatchat + .at-floatchat {
  right: 870px;
}

.at-chatsidebar_float.at-chatsidebar-widgetclose
  + .at-floatchat
  + .at-floatchat {
  right: 524px;
}

.at-floatchat + .at-floatchat + .at-floatchat {
  right: 1240px;
}

.at-chatsidebar_float.at-chatsidebar-widgetclose
  + .at-floatchat
  + .at-floatchat
  + .at-floatchat {
  right: 894px;
}

.at-floatimglist {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 99999;
}

.at-floatimglist > li {
  position: relative;
  list-style: none;
  padding-bottom: 20px;
  -webkit-animation: at_scale 0.1s linear both;
  animation: at_scale 0.1s linear both;
}

.at-floatimglist > li > a {
  border: 3px solid #fff;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
  border-radius: 50%;
  display: inline-block;
}

.at-floatimglist > li > a:hover > i {
  visibility: visible;
  opacity: 1;
}

.at-floatimglist > li > a:hover .at-infotolltip {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.at-floatimglist > li > a > em {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 18px;
  color: #999;
}

.at-floatimglist > li > a > em,
.at-floatimglist > li > a > i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.at-floatimglist > li > a > i {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 13px;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 35px;
  background-color: #ef4444;
  border: 2px solid #f7f7f7;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.at-floatimglist > li > a img {
  border-radius: inherit;
  width: 49px;
  height: 49px;
  display: block;
  transition: all 0.3s ease-in-out;
}

.at-floatimglist > li > a:hover img {
  transform: scale(1.2);
}

.at-floatimglist .at-userstatus {
  position: absolute;
  left: -5px;
  bottom: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-floatimglist .at-userstatus:before {
  width: 10px;
  height: 10px;
  margin: 0;
}

.at-floatdropdownholder {
  right: -20px;
  bottom: 80px;
  display: none;
  position: absolute;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
}

.at-floatdropdownholder:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 33px;
  width: 14px;
  height: 7px;
  background-color: #ef4444;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transform: translateY(100%);
}

.at-floatdropdown {
  max-height: 350px;
  overflow: auto;
  width: 320px;
  margin: 0;
  padding: 0;
}

.at-floatdropdown li {
  list-style: none;
}

.at-floatdropdown li:first-child a {
  border-radius: 3px 3px 0 0;
}

.at-floatdropdown li a {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}

.at-floatdropdown li a:hover {
  background-color: #f7f7f7;
}

.at-floatdropdown li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.at-floatdropdown li span {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
}

.at-floatdropdownholder > a {
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #fff;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ef4444;
  border-radius: 0 0 3px 3px;
}

.at-floatdropdownholder > a i {
  font-size: 16px;
  padding-right: 12px;
  font-weight: 600;
}

.at-floatimglist .at-infotolltip {
  position: absolute;
  margin: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  top: 50%;
  right: 100%;
  white-space: nowrap;
  align-items: center;
  transform: translateY(-50%);
  margin-right: 15px;
}

.at-floatimglist .at-infotolltip:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  background-color: #000;
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 50%);
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  right: 0;
  transform: translateX(100%);
}

.at-floatimglist .at-infotolltip em {
  position: static;
  opacity: 1;
  visibility: visible;
  transform: none;
  width: auto;
  margin: 0;
}

.at-floatimglist .at-infotolltip em:after {
  content: none;
}

.at-floatchat {
  bottom: 0;
  right: 500px;
  width: 350px;
  z-index: 9999;
  position: fixed;
  overflow: hidden;
  transition: all 0.3s linear;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
}

.at-floatchat_content {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 10px 10px 0 0;
}

.at-floatchat_content .at-userstatus {
  display: block;
  letter-spacing: 0.01em;
  font: 400 13px/21px Open Sans, sans-serif;
}

.at-floatchat_content .at-messagewrap {
  height: auto;
  overflow: auto;
  min-height: 100%;
  max-height: 350px;
  background-color: #f7f7f7;
}

.at-floatchat_content .at-messagewrap > div {
  margin-top: auto;
}

.at-minimizechat {
  transform: translateY(calc(100% - 75px));
}

.at-floatchat_content
  .at-message
  .at-message-qoute_content
  .video-player
  .video-js {
  width: 170px;
}

.at-floatchat_content .at-message_imgs figure span {
  font-size: 18px;
}

.at-messageoption_open .at-messageoption_btn {
  transform: scale(1);
}

.swal2-container .swal2-icon {
  margin: 0 auto;
  border: 0 !important;
  width: 90px;
  height: 90px;
}

.swal2-container .swal2-icon.swal2-error {
  background: rgba(255, 97, 103, 0.1);
}

.at-floatchat_content .at-message-qoute .at-sendfile {
  padding: 0;
}

.swal2-container .swal2-popup {
  padding: 40px 30px;
}

.swal2-container .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  top: 43px;
  width: 30px;
  height: 3px;
  background-color: #ff6167;
}

.swal2-container
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="right"] {
  right: 30px;
}

.swal2-container
  .swal2-icon.swal2-error
  [class^="swal2-x-mark-line"][class$="left"] {
  left: 30px;
}

.swal2-container .swal2-html-container {
  margin: 10px 0 0;
  font: 400 16px/26px var(--secchatfont);
}

.swal2-container .swal2-close {
  top: 20px;
  right: 20px;
  width: auto;
  height: auto;
  color: #ddd;
  line-height: 22px;
  position: absolute;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.swal2-container .swal2-close:hover {
  color: #ef4444 !important;
}

.swal2-container .swal2-title {
  display: block;
  padding: 0 0 0;
  color: #1c1c1c;
  margin: 30px 0 0;
  font: 600 28px/38px var(--primchatfont);
}

.at-floatchat .at-group-disabled {
  margin: 0;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
  margin-top: 0;
}

.at-floatchat .at-group-disabled span {
  font-size: 14px;
  padding: 14px 15px;
}

.at-chat_messages_group > .at-leftgroupinfo {
  margin-top: auto;
  margin-bottom: 36px;
}

.at-chatsidebar_float .at-userbar_profile:not(.at-groupuser_twoplus) img {
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-floatchat_title {
  width: 100%;
  z-index: 9999;
  display: flex;
  min-height: 54px;
  align-items: center;
  background: var(--secguppycolor);
  padding: 15px 20px;
  position: relative;
  border-radius: 8px 8px 0 0;
}

.at-floatchat_title h2 {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding-top: 0;
  padding-right: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.at-floatchat_title h2 em {
  overflow: hidden;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.at-floatchat_title h2 span {
  order: -1;
  margin-right: 10px;
}

.at-floatchat_title h2 span:before {
  margin: 0;
}

.at-floatchat_content .at-userstatus {
  display: flex;
  font: 400 0.8125rem/1.6153846154em Open Sans, sans-serif;
}

.at-floatchat_title + .at-messagewrap {
  padding: 0;
}

.at-floatchat_titlev2 {
  flex: none;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--whatsapp-bgcolor);
}

.at-floatchat_titlev2 > img {
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}

.at-whatsapptitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.at-floatchat_titlev2 .at-singleimg .at-userstatus {
  border-color: var(--whatsapp-bgcolor);
}

.at-whatsapptitle .at-floatchat_title-right {
  margin-left: auto;
}

.at-whatsapptitle > h2 {
  display: block;
}

.at-whatsapptitle .at-userstatus {
  margin: 0;
}

.at-whatsapptitle .at-userstatus:before {
  margin-right: 5px;
}

.at-send {
  width: 40px;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  margin-left: 10px;
  border-radius: 20px;
  background: var(--whatsapp-bgcolor);
}

.at-whatsappchat .at-replay_msg {
  padding: 0;
}

.at-floatchat_title-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.at-floatchat_title-right a {
  line-height: 1;
  font-size: 19px;
  color: #fff;
}

.at-floatchat_title-right a ~ a {
  margin-left: 16px;
}

.at-floatchat_title figure {
  margin: 0 10px 0 0;
  position: relative;
  flex: none;
}

.at-singleimg img {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
}

.at-singleimg .at-userstatus {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border: 3px solid var(--secguppycolor);
  border-radius: 50%;
}

.at-singleimg .at-userstatus:before {
  margin: 0;
}

.at-multipleimg {
  display: flex;
  align-items: center;
}

.at-floatchat_title .at-multipleimg,
.at-multipleimg {
  margin-top: -3px;
  margin-bottom: -3px;
}

.at-multipleimg img {
  width: 36px;
  height: 36px;
  margin-left: -3px;
  border: 3px solid var(--secguppycolor);
}

.at-multipleimg img ~ img {
  margin-left: -17px;
}

.at-multipleimg span {
  border-radius: 50px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  margin-left: -18px;
  display: inline-flex;
  background: #6366f1;
  letter-spacing: 0.01em;
}

.at-floatchatdrop,
.at-multipleimg span {
  align-items: center;
  justify-content: center;
}

.at-floatchatdrop {
  width: 28px;
  height: 28px;
  display: flex;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.16);
}

.at-floatchat_title-right .at-floatchatdrop {
  font-size: 18px;
  display: block;
  line-height: 28px;
  text-align: center;
}

.at-floatchat_title-right .at-floatchatdrop i {
  display: block;
  line-height: inherit;
}

.at-floatchatdropdownholder {
  position: relative;
}

.at-floatchatdropdown {
  position: absolute;
  right: -35px;
  top: 56px;
  width: 240px;
  background-color: #fff;
  z-index: 99;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
}

.at-floatchatdropdown:before {
  content: "";
  position: absolute;
  top: 0;
  right: 42px;
  transform: translateY(-100%);
  width: 14px;
  height: 7px;
  background: #fff;
  -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.at-floatchatdropdown .at-chat_sidebarsettingcontent > a {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-left: 0;
}

.at-floatchatdropdown .at-chat_sidebarsettingcontent > .at-disable-btn {
  display: flex;
  align-items: center;
}

.at-floatchatdropdown .at-chat_sidebarsettingcontent > .at-disable-btn:before {
  flex: none;
}

.at-floatchatdropdown .at-chat_sidebarsettingcontent {
  padding: 20px;
}

.at-floatchatdropdown .at-chat_sidebarsettingcontent h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.at-chat_sidebarsettingcontent.at-openmsg {
  padding-top: 8px;
  padding-bottom: 8px;
}

.at-chat_sidebarsettingcontent.at-openmsg a {
  display: flex;
  align-items: center;
  color: var(--secguppycolor) !important;
  margin: 0;
  line-height: 32px;
}

.at-chat_sidebarsettingcontent.at-openmsg a i {
  margin-right: 10px;
  color: #999;
  font-size: 18px;
}

.at-floatchat_title-right .at-floatchatdropdownholder ~ a {
  margin-left: 16px;
}

.at-floatsignin {
  padding: 20px;
}

.at-floatsignin .at-btn {
  width: 100%;
}

.at-floatsignin .at-btn.at-disable-btn {
  background-color: #fff !important;
}

.at-chatsidebar-widgetclose ~ .at-chatfloatholder .at-floatchat {
  right: 165px;
}

.at-chatsidebar-widgetclose ~ .at-chatfloatholder .at-floatchat:nth-child(2) {
  right: 535px;
}

.at-chatsidebar-widgetclose ~ .at-chatfloatholder .at-floatchat:nth-child(3) {
  right: 905px;
}

.at-chatsidebar-widgetclose
  ~ .at-chatfloatholder
  .at-floatimglist
  ~ .at-floatchat:nth-child(2) {
  right: 165px;
}

.at-chatsidebar-widgetclose
  ~ .at-chatfloatholder
  .at-floatimglist
  ~ .at-floatchat:nth-child(3) {
  right: 535px;
}

.at-chatsidebar-widgetclose
  ~ .at-chatfloatholder
  .at-floatimglist
  ~ .at-floatchat:nth-child(4) {
  right: 905px;
}

.at-floatchat_content .at-reply-voice + .at-messageoption,
.at-guppyside-responsive .at-message .at-reply-voice + .at-messageoption {
  top: 0;
  right: 10px;
}

.at-creategroup .croppie-container .cr-boundary {
  width: 407px !important;
  height: 330px !important;
}

.cr-slider-wrap .cr-slider {
  display: block;
  margin: 0 auto;
  height: auto;
  border: 0;
  outline-offset: 0;
  border-radius: 0;
  background: transparent;
}

.cr-slider-wrap input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0;
  height: 20px;
  margin-top: -8px;
  width: 20px;
  border-radius: 50%;
  background: var(--primguppycolor);
  cursor: pointer;
}

.at-cropimg {
  width: 100%;
  color: #fff !important;
  outline: none !important;
  padding: 0 20px;
  letter-spacing: 0.01em;
  transition: all 0.3s ease-in-out;
  font: 700 16px/40px var(--primchatfont);
  background-color: var(--primguppycolor) !important;
}

.at-signin .at-floatchat_content {
  height: auto;
}

.at-floatchat.at-signin {
  display: flex;
  align-items: flex-end;
}

.at-backtolist {
  background-color: transparent !important;
}

.at-guppysidepopup .at-chat_messages {
  flex: auto;
  max-width: 100%;
}

.at-guppysidepopup .at-backtolist {
  display: block;
}

.at-guppysidepopup .at-sendmsg {
  display: none;
}

.at-guppysidepopup .at-replay_audio > a,
.at-guppysidepopup .at-replay_upload > a,
.at-guppysidepopup .at-sendmsg-responsive {
  width: auto;
  border: 0;
  font-size: 22px;
  background-color: transparent !important;
}

.at-widgetreply {
  display: flex;
  align-items: center;
}

.at-widgetreply .at-sendmsg-responsive {
  line-height: 30px;
  margin-left: 10px;
}

.at-guppysidepopup .at-empty-conversation {
  padding-bottom: 0;
}

.at-userchat_tab + .at-userlist_tab .at-empty-conversation {
  padding: 0;
}

.at-guppyside-responsive .at-chat_messages,
.at-guppysidepopup .at-sidbarwrapper .at-userchat_tab + .at-userlist_tab {
  height: 100%;
}

.at-guppyside-responsive .at-message {
  max-width: 100%;
}

.at-guppyside-responsive .at-messages {
  padding-left: 10px;
  padding-right: 10px;
}

.at-guppyside-responsive .at-message_imgs figure img {
  max-width: 100%;
  max-height: 100%;
}

.at-guppyside-responsive .at-message_imgs {
  margin: -5px;
}

.at-guppyside-responsive
  .at-message_gallery
  .at-reply-images
  + .at-messageoption {
  top: 10px;
  right: 14px;
}

.at-chat_previewfloat .at-message_gallery .at-messageoption {
  top: 23px;
  right: 27px;
}

.at-chat_previewfloat .at-message_imgs figure span {
  font-size: 18px;
}

.at-chat_previewfloat .at-message_gallery .at-messageoption:before {
  right: -7px;
  width: 60px;
  height: 30px;
  top: -3px;
}

.at-guppyside-responsive .at-message_imgs li {
  padding: 5px;
  min-width: 70px;
  max-width: 50% !important;
}

.at-guppyside-responsive .at-message_imgs figure {
  width: 60px;
  height: 60px;
}

.at-guppyside-responsive .at-chat_previewfloat .at-replay_message {
  margin: 0 0 10px;
}

.at-guppyside-responsive .at-floatchat_content .at-messagetext {
  padding: 10px 35px 10px 16px;
}

.at-guppyside-responsive .at-replay_audio.at-startrecording a,
.at-guppyside-responsive .at-replay_audio:focus a {
  background: transparent !important;
  color: var(--terguppycolor) !important;
}

.at-guppyside-responsive .at-startrecording a:after {
  display: none;
}

@media (max-width: 1280px) {
  .at-chatsidebar_float {
    max-width: 400px;
  }

  .at-floatchat {
    right: 480px;
  }

  .at-floatchat + .at-floatchat {
    right: 840px;
  }
}

@media (max-width: 991px) {
  .at-chatsidebar_float {
    max-width: 380px;
  }

  .at-floatchat {
    right: 465px;
  }

  .at-creategroup .croppie-container .cr-boundary,
  .croppie-container .cr-boundary {
    width: 400px !important;
  }
}

@media (max-width: 576px) {
  .at-floatchat_content {
    height: 100%;
  }

  .at-floatchat {
    height: 100%;
    padding: 20px 20px 0;
    right: 0 !important;
    width: 100% !important;
  }

  .at-chat_previewfloat .operate > span:not(.iconfont) {
    padding-left: 5px !important;
  }

  .at-chat_previewfloat .vueAudioBetter {
    height: 54px !important;
    width: 100% !important;
    align-items: flex-start !important;
    flex-wrap: wrap;
  }

  .at-chat_previewfloat .operate {
    width: 100%;
  }

  .at-chat_previewfloat .vueAudioBetter .slider {
    width: 80% !important;
    margin-top: 7px;
    margin-left: 4px;
  }

  .at-chat_previewfloat .slider .thunk {
    top: -8px !important;
  }

  .at-chat_previewfloat .at-message .at-reply-voice {
    padding-right: 30px !important;
    padding-bottom: 20px;
  }

  .at-chat_previewfloat .at-message .at-reply-voice .slider {
    width: 75% !important;
  }

  .at-guppyside-responsive .at-message-qoute .at-message_imgs li figure {
    width: 40px;
    height: 40px;
  }
}

.at-replay .at-message-qoute .at-message_imgs figure {
  background: #f7f7f7;
}

.at-chat .vib-hidden {
  opacity: 1;
}

.at-chat .vib-container {
  z-index: 99999;
}

.at-chat .vib-thumbnail-wrapper {
  border-radius: 10px 10px 0 0;
  top: auto;
  bottom: 0;
}

.at-chat .vib-footer {
  z-index: -1;
  pointer-events: none;
}

.at-chat .vib-thumbnail,
.at-chat .vib-thumbnail-active {
  margin-left: 5px;
  margin-right: 5px;
}

.at-chat .vib-thumbnail-active {
  box-shadow: inset 0 0 0 2px var(--primguppycolor);
}

.at-chat .vib-arrow:hover svg {
  fill: var(--primguppycolor);
}

.at-groupdescription {
  display: block;
  margin-top: 10px;
  transition: none;
  letter-spacing: 0.5px;
  font: 500 16px/26px var(--primchatfont);
}

.at-guppysidepopup .at-leftgroupinfo span {
  font-size: 13px;
  line-height: 22px;
}

.at-chat .at-creategroup textarea.at-form-control {
  height: 110px;
  display: block;
}

.at-msgautotyper {
  display: inline-flex;
  padding: 9px 14px 14px;
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.at-msgautotyper img {
  display: block;
  max-width: 18px;
}

.at-userbar.at-notavailable {
  mix-blend-mode: luminosity;
}

.at-notavailable img {
  filter: grayscale(100%);
}

.at-newnotify {
  position: relative;
}

.at-newnotify:after {
  content: "";
  position: absolute;
  right: 10px;
  width: 5px;
  height: 5px;
  background-color: #ef4444;
  border-radius: 50%;
  top: 14px;
}

.at-userchat_tab a.at-newnotify:after {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 782px) {
  .at-sidbarwrapper.at-messanger-chat .at-userlist_tab .at-userlist_tab {
    height: 100vh;
  }

  .at-messanger-chat .at-userlist_tab .at-userlist_tab {
    height: calc(100vh - 14px);
  }

  .logged-in.admin-bar .at-chat_sidebarsettingarea {
    height: calc(100vh - 137px);
  }

  .logged-in.admin-bar
    .at-chat:not(.at-guppysidepopup)
    .at-sidebarhead
    .at-chat_sidebarsettingarea {
    height: 100vh;
  }

  .logged-in.admin-bar .at-chat:not(.at-guppysidepopup) {
    height: calc(100vh - 46px);
  }

  .logged-in.admin-bar .at-modaldialog {
    padding-top: 78px;
  }
}

@media (max-width: 640px) {
  .at-messages:not(.at-message_sender) .at-grp_receiver_msg {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .at-chat640 .at-group-avatar,
  .at-messages .at-group-avatar {
    margin: 0 0 10px;
  }
}

@media (max-width: 576px) {
  .at-chat .vib-thumbnail-wrapper {
    padding-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .at-creategroup .croppie-container .cr-boundary,
  .croppie-container .cr-boundary {
    width: 300px !important;
    height: 280px !important;
  }

  .at-chatsidebar_float {
    max-width: calc(100% - 76px);
    right: 37px;
  }
}

@media (max-width: 420px) {
  .at-creategroup .croppie-container .cr-boundary,
  .croppie-container .cr-boundary {
    width: 250px !important;
    height: 280px !important;
  }
}

@media (max-width: 380px) {
  .at-creategroup .croppie-container .cr-boundary {
    width: 210px !important;
  }
}

@media (max-height: 650px) {
  .at-floatchat_content {
    height: 100vh;
  }
}

.gp-whatsappchatfloat .at-empty-conversation {
  background-color: #fff;
}

.at-chat1080 .sv-newslots .at-viewuploadimg {
  align-items: flex-start;
}

.at-chat1080 .sv-newslots .at-uploadarea .at-btnareasetting {
  flex-direction: column;
}

.at-chat1080 .sv-newslots button,
.at-chat1080 .sv-newslots label {
  min-width: 100%;
}

.at-whatsappgp span {
  background: #25d366 !important;
}

.at-whatsappgp span a {
  background: transparent;
  width: 35px;
  height: 35px;
}

.at-dropboxuploader .at-viewuploadimg .croppie-container {
  width: 100%;
}

.at-chat991 .at-message-qoute_content .vueAudioBetter {
  width: 190px !important;
}

.at-chat991 .at-message-qoute_content .operate span:last-child {
  display: none;
}

.at-chat420 .at-message-qoute_content .vueAudioBetter {
  width: 160px !important;
}

.at-joinnow li {
  padding: 10px 0;
  text-align: center;
  box-shadow: inset 0 1px 0 #ddd;
}

.at-joinnow li span {
  font: 400 13px/26px var(--secchatfont);
  letter-spacing: 0.5px;
  display: inline-block;
  color: var(--secguppycolor);
}

.at-joinnow li span a {
  display: inline-block;
  font: 600 14px/26px var(--secchatfont);
  letter-spacing: 0.5px;
  color: #3c57e5;
}

.at-customer-support .at-chat_sidebar_footer li:last-child {
  margin: 0;
  border-top: 0;
}

.at-customer-support .at-userlist_tab .at-sidebarhead_searchv2 {
  padding: 8.5px 0;
  border-bottom: 1px solid #eee;
}

.at-chat_previewfloat
  .at-contacts_list.at-userlist_tab
  .at-userlist_tab.active
  .at-sidebarhead_searchv2,
.at-contacts_list.at-userlist_tab .at-sidebarhead_searchv2,
.at-customer-support
  .at-support-agents
  .at-userlist_tab
  .at-sidebarhead_searchv2 {
  padding: 9.5px 0;
}

.at-chatsidebar_float .at-sidebarhead_searchv2.at-support-chat,
.at-contacts_list.at-userlist_tab
  .at-userlist_tab.active
  .at-sidebarhead_searchv2 {
  padding: 7.5px 0;
}

.at-customer-support .at-userchat_tab {
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.at-customer-support .at-userchat_tab a {
  padding: 11.5px 10px;
}

.at-sidbarwrapper.at-support-agents {
  height: calc(100% - 70px);
}

@media (max-width: 767px) {
  .at-chat_previewfloat
    .at-contacts_list.at-userlist_tab
    .at-userlist_tab.active
    .at-sidebarhead_searchv2,
  .at-customer-support
    .at-support-agents
    .at-userlist_tab
    .at-sidebarhead_searchv2 {
    padding: 7.5px 0;
  }
}

.ready-player-1 .audio-player {
  height: auto;
  width: 100%;
}

.ready-player-1 .audio-player .controls {
  margin: 0;
}

.wpguppy-rtl .at-btn-link i,
.wpguppy-rtl .at-sendbtn i {
  margin-right: 3px;
  margin-left: 0;
}

.wpguppy-rtl .at-userinfo_title_img {
  margin-right: 0 !important;
}

.wpguppy-rtl .at-userstatus:before {
  margin-right: 0;
  margin-left: 0;
}

.wpguppy-rtl .at-chat_sidebarsettingopen {
  transform: translateX(0);
}

.wpguppy-rtl .at-rightswitcharea i {
  margin: 0 0 0 13px;
}

.wpguppy-rtl .at-chatsidebar_float:after {
  left: var(--widgetright);
  right: auto;
}

.wpguppy-rtl .at-messagetext > div:not(.at-messageoption) {
  margin-left: -24px;
}

.wpguppy-rtl .at-group-avatar,
.wpguppy-rtl .at-messages .at-group-avatar {
  margin-left: 14px;
  margin-right: 0;
}

.wpguppy-rtl .at-userbar_right em {
  margin-right: 8px;
  margin-left: 0;
}

.wpguppy-rtl .at-form-control {
  text-align: right;
}

.wpguppy-rtl .at-chatsidebar_float {
  right: auto;
  left: 37px;
}

.wpguppy-rtl.at-guppysidepopup
  .at-sidbarwrapper
  .at-userchat_tab
  + .at-.active {
  padding-left: 0;
  padding-right: 60px;
}

.at-guppysidepopup .at-sidbarwrapper .at-userchat_tab + .at-userlist_tab {
  padding-right: 0;
}

.wpguppy-rtl .at-minimizefloatchat .at-floatchat_content {
  transform: translateX(-100%);
}

.at-user-chat
  .wpguppy-rtl
  .at-chat_sidebar_footer
  li:not(:last-child)
  .at-infotolltip {
  left: -60px;
  right: auto;
}

.at-user-chat
  .wpguppy-rtl
  .at-chat_sidebar_footer
  li:not(:last-child)
  .at-infotolltip:after {
  left: 0;
  right: 3px;
  border-left: 10px solid transparent;
  border-right: 0;
}

.wpguppy-rtl .at-userchat_tab a > i,
.wpguppy-rtl .at-userchat_tab a > svg {
  margin-right: 0;
  margin-left: 12px;
}

.wpguppy-rtl .at-userpost_profile + .at-userbar_title {
  margin-right: 15px;
  margin-left: 0;
}

.wpguppy-rtl .at-userpost_profile img:not(.at-postuserimg) {
  margin-right: 0;
  margin-left: auto;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messagetext
  > div:not(.at-messageoption) {
  margin-left: -23px;
}

.wpguppy-rtl .at-chatsidebar_float .at-userlist_tab {
  padding-left: 0;
  padding-right: 60px;
}

.wpguppy-rtl .at-chatsidebar_float .at-userlist_tab .at-userlist_tab {
  padding-left: 0;
  padding-right: 0;
}

.at-messanger-chat.wpguppy-rtl .at-chat_sidebar_footer li,
.at-messanger-chat.wpguppy-rtl .at-nestedsetting li {
  border-left: 0;
}

.wpguppy-rtl .at-chat_sidebar_footer > li {
  box-shadow: inset 1px -1px 0 #eee;
}

.at-messanger-chat.wpguppy-rtl .at-userlist_tab {
  padding-left: 0;
  padding-right: 70px;
}

.at-messanger-chat.wpguppy-rtl .at-userlist_tab.active {
  padding-left: 0;
  padding-right: 0;
}

.at-messanger-chat.wpguppy-rtl .at-sidebarhead .at-chat_sidebarsetting {
  padding-left: 0;
}

.wpguppy-rtl .at-dropboxv2 .at-viewuploadimg img {
  margin-right: 0;
  margin-left: 10px;
}

.at-chat420
  .at-messanger-chat.wpguppy-rtl
  .at-sidebarhead
  .at-chat_sidebarsetting {
  padding-left: 0;
  padding-right: 50px;
}

.wpguppy-rtl .at-newcreategroup a span {
  margin-left: 0;
  margin-right: 6px;
}

@media (max-width: 1750px) {
  .wpguppy-rtl .at-dropboxv2 .at-viewuploadimg {
    flex-direction: column;
    justify-content: center;
  }

  .wpguppy-rtl .at-dropboxv2 .at-viewuploadimg .at-btnareasetting {
    justify-content: flex-start;
    justify-content: space-evenly;
  }
}

@media (max-width: 420px) {
  .at-messanger-chat.wpguppy-rtl .at-userlist_tab {
    padding-left: 0;
    padding-right: 70px;
  }

  .at-dropboxv2 .at-uploadarea .at-btnareasetting {
    flex-direction: column;
  }

  .at-dropboxv2 .at-btn.at-btnsm {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .wpguppy-rtl .at-imgresponsivearea .at-sidebar-item {
    right: 0 !important;
    left: auto;
    margin: 10px 10px 0 0;
  }

  .wpguppy-rtl .gp-chat_previewfloat .at-imgresponsivearea .at-sidebar-item {
    margin-right: 0;
    margin-left: 10px;
  }

  .wpguppy-rtl .at-imgresponsivearea {
    padding: 8px 20px;
    border: 0;
    border-bottom: 1px solid #eee;
  }

  .wpguppy-rtl .at-imgresponsivearea a {
    border: 0 !important;
  }

  .wpguppy-rtl
    .at-chat_previewfloat
    .at-contacts_list.at-userlist_tab
    .at-sidebarhead_searchv2 {
    padding: 7.5px 0 !important;
  }
}

@media (max-width: 320px) {
  .wpguppy-rtl .at-chatsidebar_float .at-userlist_tab {
    padding-right: 50px;
  }

  .wpguppy-rtl .at-chat_sidebar_footer li a {
    font-size: 20px;
  }
}

.wpguppy-rtl .at-chat_sidebarsetting {
  right: auto;
  left: 0;
}

.wpguppy-rtl .at-sidebarhead > .at-userlist_tab.at-userlist_tabv2 {
  right: 0;
  left: auto;
  transform: translateX(0);
}

.wpguppy-rtl .at-chat_sidebar_footer > li.active {
  box-shadow: inset -3px 0 0 var(--primguppycolor), inset 1px -1px 0 #eee;
}

.wpguppy-rtl .at-nestedsetting li.active {
  box-shadow: inset -3px 0 0 var(--primguppycolor), inset 1px 0 0 #eee;
  border-top: 1px solid #eee;
}

.wpguppy-rtl .at-userinfo_settings .at-redbtnwrapper {
  margin-right: 0;
  margin-left: 30px;
}

.wpguppy-rtl .at-sidebar-item {
  right: 60px;
  left: auto;
  padding: 20px 0 20px 20px;
}

.wpguppy-rtl .at-chat_previewfloat .at-chat_sidebar_footer {
  border-left: 1px solid #eee;
  right: 0;
  left: auto;
}

.wpguppy-rtl .at-chat_previewfloat .at-chat_sidebar_footer:hover {
  width: 270px;
}

.wpguppy-rtl .at-chat_previewfloat .at-chat_sidebar_footer li a {
  border-left: 1px solid #eee;
}

.wpguppy-rtl .at-chatsidebar_float .at-chat_sidebar_footer li a {
  width: 60px;
  border-left: 0;
}

.wpguppy-rtl.at-chat
  .at-floatchat_content
  .at-replay_msg
  textarea.at-share-emoji {
  padding-left: 20px;
  padding-right: 58px;
}

.wpguppy-rtl.at-messanger-chat .at-sidebar-item {
  right: 70px;
  left: 0;
}

.wpguppy-rtl.at-messanger-chat .at-chat_sidebar_footer {
  border-left: 1px solid #eee;
  right: 0;
  left: auto;
}

.wpguppy-rtl.at-customer-support .at-userchat_tab a > svg {
  margin-right: 0;
  margin-left: 12px;
}

.wpguppy-rtl
  .at-chat_previewfloat
  .at-contacts_list.at-userlist_tab
  .at-sidebarhead_searchv2 {
  padding: 9.5px 0;
}

.wpguppy-rtl
  .at-chat_previewfloat
  .at-contacts_list.at-userlist_tab
  .at-sidebarhead_searchv2.at-support-chat {
  padding: 7.5px 0;
}

.wpguppy-rtl .at-statusoffline:after,
.wpguppy-rtl .at-statusonline:after {
  right: auto;
  left: -3px;
}

.wpguppy-rtl .gp-floatchat_title h2 {
  padding-left: 20px;
  padding-right: 0;
}

.wpguppy-rtl .at-userchat_tab a.at-newnotify:after {
  right: 20px;
  transform: translateY(-50%);
  top: 50%;
}

.at-sidebarhead_search {
  width: 100%;
  padding: 20px;
  position: relative;
  background: #fff;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.at-sidebarhead_search h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: inherit;
}

.at-sidebarhead_search ~ .at-sidebarhead_search {
  box-shadow: inset 0 -1px 0 #eee, inset 0 1px 0 #eee;
}

.at-sidebarhead_search h3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 8px;
  font-weight: 600 !important;
}

.at-sidebarhead_search .at-form-group i {
  position: absolute;
  left: 18px;
  font-size: 18px;
  color: var(--terguppycolor);
}

.at-chat .at-sidebarhead_search .at-form-group input {
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 3px;
}

.at-chat_sidebarsettingopen {
  transform: translateX(0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.at-chat_sidebarsettingcontent {
  padding: 26px 30px;
}

.at-chat_sidebarsettingcontent h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-chat_sidebarsettingcontent > a {
  color: #3c57e5 !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: block;
  transition: none;
}

.at-chat_sidebarsettingcontent > a.at-danger {
  color: #ef4444 !important;
}

.at-chat_sidebarsettingcontent .at-themeform {
  padding-top: 10px;
}

.at-chat_sidebarsettingcontent .at-btnlist {
  margin: 0;
}

.at-chat_sidebarsettingcontent .at-btnlist li {
  width: 100% !important;
  padding: 0 !important;
}

.at-chat_sidebarsettingcontent ~ .at-chat_sidebarsettingcontent {
  border-top: 1px solid #ddd;
}

.at-chat_sidebarsettingarea {
  height: calc(100vh - 400px);
  overflow-y: auto;
}

.at-gallerylistwrap {
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}

.at-gallerylistwrap h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-gallerylist {
  margin: -5px;
  padding: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.at-gallerylist li {
  margin: 0;
  list-style: none;
  padding: 5px !important;
  width: 33.333% !important;
}

.at-gallerylist
  li
  a:focus:not(.wp-block-button__link):not(.wp-block-file__button)
  img,
.at-gallerylist li a:focus img {
  outline: none;
}

.at-gallerylist li:not(.at-loadmorecontent) a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  height: 100%;
  color: var(--primguppycolor);
}

.at-gallerylist li.at-loadmorecontent {
  width: 100% !important;
  margin-top: 10px;
}

.at-gallerylist li a i {
  display: inline-block;
  background: #fff;
  text-align: center;
  font-size: 24px;
}

.at-gallerylist li a img {
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.at-danger {
  color: #ef4444 !important;
}

.at-imgdownloader {
  outline: none !important;
  text-decoration: none !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-flex;
  vertical-align: middle;
  color: #3c57e5 !important;
  align-items: center;
}

.at-imgdownloader,
.at-imgdownloader.at-disable-btn {
  background-color: transparent !important;
}

.at-imgdownloader .at-msgload {
  margin: 0;
  position: static;
  display: inline-block;
  margin-right: 10px;
}

.at-imgdownloader .at-msgload i {
  border-right-color: #fff;
  display: block;
  width: 20px;
  height: 20px;
}

.at-newcreategroup {
  background: #f7f8fc;
  border-bottom: 1px solid #eee;
  padding: 5px 10px;
  text-align: center;
}

.at-newcreategroup a {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #1da1f2;
}

.at-newcreategroup a,
.at-newcreategroup a span {
  display: inline-block;
  font-family: var(--primchatfont);
}

.at-newcreategroup a span {
  color: #0a0f26;
  margin: 0 0 0 6px;
}

.at-nestedsetting > li a i.guppy-power {
  color: #ef4444;
}

.at-chat_previewfloat .at-chat_sidebar_footer {
  width: 60px;
}

.at-chat_previewfloat .at-sidebarhead .at-chat_sidebarsetting {
  left: 60px;
}

.at-contacts_list {
  height: calc(100% - 60px);
}

.at-chat991 .at-contacts_list,
.at-support-messages {
  height: 100%;
}

.at-chat_previewfloat .at-chat_sidebar_footer a img {
  max-width: 40px;
  height: 40px;
}

.at-chatsidebar_float:after {
  position: absolute;
  content: "";
  right: var(--widgetright);
  bottom: -9px;
  display: inline-block;
  z-index: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  box-shadow: inset 1px -1px 0 #eee;
}

.at-guppyside-responsive .at-chat_previewfloat .at-chat_sidebar {
  border-radius: 0;
}

.at-guppyside-responsive .at-chatsidebar_float {
  max-width: 100%;
  right: 0;
  top: 0;
  max-height: 100%;
  border-radius: 0;
  height: 100%;
}

.at-guppyside-responsive
  .at-chatsidebar_float:not(.at-chatsidebar-widgetclose)
  + .at-chatfloatholder {
  z-index: 1;
}

.at-guppyside-responsive .at-chat_sidebarsetting {
  height: 100%;
}

.at-guppyside-responsive .at-chat_sidebarsettingarea {
  height: calc(100vh - 100px) !important;
}

.at-guppyside-responsive .at-chat_profile {
  background: #fff;
  height: 100%;
  flex-direction: column;
  width: 100%;
}

.at-guppyside-responsive .at-overlay:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.at-chat_previewfloat .at-chat_sidebar > .at-userlist_tabv2 {
  max-height: 700px;
}

.at-form-group .at-dropbox {
  background: #f7f7f7;
  margin: 0;
}

.at-btn-sm.at-btn-link {
  color: #1da1f2;
  background: transparent;
  font-weight: 600;
  padding: 0;
  min-width: auto;
}

.at-btn-link:hover {
  color: #1da1f2;
}

.at-btn-link i,
.at-sendbtn i {
  font-size: 16px;
  display: inline-block;
  margin: 0 0 0 3px;
}

.at-btn-sm.at-sendbtn {
  color: #999;
  background: transparent;
  font-weight: 600;
  padding: 0;
  min-width: auto;
}

.at-sendbtn:hover {
  color: #999;
}

.at-messanger-chat
  .logged-in.admin-bar
  .at-chat:not(.at-guppysidepopup)
  .at-userlist_tab
  ul {
  height: calc(100% - 114px);
}

.at-messanger-chat .at-userchat_tab a {
  width: 100%;
  padding: 14px 20px;
}

.at-messanger-chat .at-userlist_tab .at-sidebarhead_searchv2 {
  padding: 9.5px 0;
}

.at-messanger-chat
  .at-contacts_list
  .at-userlist_tab.active
  .at-sidebarhead_searchv2 {
  padding: 9px 0;
}

.at-messanger-chat .at-userlist_tabv2 {
  max-height: 100%;
}

.at-messanger-chat .at-chat_sidebarsettingtitle {
  padding: 17px 20px;
}

.at-messanger-chat .at-chat_formwrapper {
  padding: 30px;
}

.at-messanger-chat .at-sidebarhead .at-chat_sidebarsetting {
  padding-left: 70px;
}

.at-messanger-chat .at-userbar_profile {
  max-width: 44px;
  height: 44px;
}

.at-messanger-chat .at-userlist_tab .at-userlist_tab {
  height: 100vh;
}

.at-messanger-chat .at-userlist_tab .at-userchat_tab ~ .at-userlist_tab {
  height: calc(100vh - 70px);
}

.at-chat_profile {
  padding: 20px 30px;
  height: 100vh;
  overflow: auto;
}

.at-messanger-chat .at-chat_profile {
  padding: 20px;
}

.at-chat991 .at-form-group .at-dropboxv2 {
  padding: 20px;
  align-items: flex-start;
}

.at-chat991 .at-btnareasetting {
  justify-content: flex-start;
}

.at-chat991 .at-form-group .at-dropboxv2 .at-btnareasetting {
  gap: 20px;
}

.at-chat480 .at-dropboxv2 .at-viewuploadimg {
  flex-direction: column;
}

.at-chat480 .at-dropboxv2 .at-viewuploadimg img {
  margin: 0 0 10px;
}

.at-chat480 .at-messanger-chat .at-userchat_tab a {
  padding: 14px 10px;
}

.at-guppyside-responsive .at-groupuser_twoplus .at-notify {
  margin-left: 0;
}

.at-chat480 .at-btnareasetting {
  flex-direction: column;
}

.at-chat480 .at-form-group .at-dropboxv2 .at-btnareasetting {
  gap: 10px;
}

.at-chat480 .at-btn.at-btnsm {
  min-width: 100%;
}

@media (max-width: 1366px) {
  .at-dropboxv2 .at-viewuploadimg {
    text-align: center;
    flex-direction: column;
  }

  .at-dropboxv2 .at-viewuploadimg .at-uploadarea h6 {
    text-align: center;
  }

  .at-dropboxv2 .at-viewuploadimg img {
    margin: 0 0 10px;
  }

  .at-dropboxv2 .at-uploadarea .at-btnareasetting {
    justify-content: center;
  }

  .at-chat_profile {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .at-onlineusers > li:first-child {
    margin-left: 0 !important;
  }

  .at-messanger-chat .at-userlist_tab .at-sidebarhead_searchv2 {
    padding: 9.5px 0;
  }

  .at-messanger-chat .at-userchat_tab a {
    padding: 14px 10px;
  }

  .at-messanger-chat .at-chat_sidebarsettingtitle {
    padding: 17px 20px;
  }
}

@media (max-width: 411px) {
  .at-chat_previewfloat .at-chat_sidebar > .at-userlist_tabv2 {
    max-height: 100%;
  }

  .at-chat_sidebar_footer li a {
    font-size: 17px;
  }

  .at-chat_previewfloat .at-onlineusers > li:first-child {
    margin-left: 0 !important;
  }

  .at-chatsidebar_float .at-chat_sidebar .at-userlist_tab {
    padding-left: 50px;
  }

  .at-chatsidebar_float .at-chat_sidebar .at-userlist_tab .at-userlist_tab,
  .at-chatsidebar_float .at-userlist_tab .at-userlist_tab.active {
    padding-left: 0;
  }

  .at-messanger-chat .at-sidebarhead .at-chat_sidebarsetting {
    padding-left: 60px;
  }

  .wpguppy-rtl .at-messanger-chat .at-sidebarhead .at-chat_sidebarsetting,
  .wpguppy-rtl .at-messanger-chat .at-userlist_tab:not(.active) {
    padding-left: 0;
    padding-right: 70px;
  }

  .wpguppy-rtl .at-chatsidebar_float .at-chat_sidebar .at-userlist_tab {
    padding-left: 0;
  }

  .at-settingbtns {
    flex-wrap: wrap;
  }

  .at-settingbtns .at-rightswitcharea {
    max-width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 320px) {
  .at-userlist_tab {
    padding-left: 50px;
  }
}

.at-chat480 .at-userbar {
  padding: 9px 10px !important;
}

.at-chat480 .at-userbar_title h3 {
  font-size: 15px;
  line-height: 19px;
}

@media (max-width: 540px) {
  .at-messanger-chat .at-chat_sidebar_footer li.at-hasimage a {
    padding: 13px 15px !important;
  }

  .at-guppysidepopup .at-userlist_tab li.at-userbar:last-child {
    margin-bottom: 0;
  }
}

.at-guppyside-responsive
  .at-chat_previewfloat
  .at-chat_sidebar
  > .at-userlist_tabv2 {
  max-height: 100%;
}

.tippy-popper[x-placement^="right"]
  [data-animation="shift-away"][data-state="visible"] {
  transform: translateX(-5px);
  background: var(--secguppycolor);
  border-radius: 0 4px 4px 0;
}

.tippy-popper[x-placement^="right"] .tippy-arrow {
  border-right: 10px solid var(--secguppycolor) !important;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  left: -10px;
  transform-origin: 100% 50%;
  top: 0 !important;
  margin: 0 !important;
}

.at-chat1700 .at-form-group .sv-newslots .at-viewuploadimg {
  background: #f7f7f7;
  margin: 0;
  flex-wrap: wrap;
}

.at-chat1700 .sv-newslots .at-viewuploadimg img {
  margin: 0 0 15px;
}

.at-chat1199 .at-dropboxv2 .at-uploadarea .at-btnareasetting {
  flex-direction: column;
}

.at-chat1199 .at-dropboxv2 .at-uploadarea .at-btnareasetting button,
.at-chat1199 .at-dropboxv2 .at-uploadarea .at-btnareasetting label {
  min-width: 100%;
}

.at-userbar_title-v2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.at-chat_transition_none {
  transition: none;
}

.at-chat_sidebar_footervtwo {
  background-color: var(--secguppycolor);
  position: static;
  display: flex;
  padding: 0;
  margin: 0;
}

.at-chat_sidebar_footervtwo li {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  background: transparent;
  border: none;
  justify-content: center;
}

.at-chat_sidebar_footervtwo li .at-replay_audio,
.at-chat_sidebar_footervtwo li .at-replay_upload {
  flex: 1;
}

.at-chat_sidebar_footervtwo li .at-recorddisabled a {
  background-color: var(--secguppycolor) !important;
}

.at-floatchat_content .at-replay_msg {
  padding: 0;
}

.at-replay_msg > a:not(.at-sendmsg) + input {
  padding-right: 60px;
}

.at-floatchat_content .at-replay_msg input {
  height: 40px;
  border-radius: 3px;
}

.at-floatchat_content .at-replay {
  display: block;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
}

.at-floatchat_content .at-replay_msg > a:not(.at-sendmsg) {
  right: 21px;
}

.at-floatchat_content .at-chat_sidebar_footer {
  position: static;
}

.at-floatchat_content .at-chat_sidebar_footer li {
  padding: 0 !important;
  width: 100% !important;
}

.at-floatchat_content .at-chat_sidebar_footer li > div {
  width: 100%;
}

.at-floatchat_content .at-chat_sidebar_footer a {
  padding: 18px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.at-floatchat_content .at-chat_sidebar_footer a.active {
  color: #fff;
  background-color: #ff7300;
}

.at-floatchat_content
  .at-chat_sidebar_footervtwo.at-replay_disable
  > li {
  filter: opacity(0.5);
}

.at-whatsappchat .at-messagewrap {
  height: 100%;
  padding: 30px;
  min-height: auto;
  max-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.at-whatsappchat .at-messages {
  padding: 0;
}

.at-whatsappchat .at-message {
  font-size: 15px;
  max-width: 100%;
  line-height: 22px;
  padding: 20px 20px 14px 20px;
}

.at-whatsappchat .at-message span {
  margin: 0;
}

.at-whatsappchat {
  height: 100vh;
  max-height: 427px;
}

.at-whatsappchat .at-messagewrap > img {
  padding: 30px;
  display: block;
}

.at-floatchat_titlev2 .at-floatchat_title-right a {
  font-size: 28px;
}

.at-replaywt {
  height: 80px;
  padding: 15px 20px 20px;
}

:root {
  --primchatfont: "Urbanist", sans-serif;
  --secchatfont: "Open Sans", sans-serif;
  --whatsapp-fontcolor: #25d366;
}

.gp-chat_sidebar {
  height: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  -ms-flex: 0 0 100%;
  -webkit-box-flex: 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.gp-chat ::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.gp-chat ::-webkit-scrollbar-thumb {
  visibility: hidden;
  opacity: 0;
  border-radius: 60px;
  background-color: var(--primguppycolor);
  border: 5px solid var(--primguppycolor);
}

.gp-chat :hover::-webkit-scrollbar-thumb {
  visibility: visible;
  opacity: 1;
}

.gp-chat .gp-form-control,
.gp-chat input[type="color"],
.gp-chat input[type="date"],
.gp-chat input[type="datetime-local"],
.gp-chat input[type="datetime"],
.gp-chat input[type="email"],
.gp-chat input[type="month"],
.gp-chat input[type="number"],
.gp-chat input[type="password"],
.gp-chat input[type="search"],
.gp-chat input[type="tel"],
.gp-chat input[type="text"],
.gp-chat input[type="time"],
.gp-chat input[type="url"],
.gp-chat input[type="week"],
.gp-chat select,
.uneditable-input {
  outline: none !important;
  padding: 0 20px;
  height: 40px;
  color: var(--secwhatsappcolor);
  font: 16px/26px var(--secchatfont);
  border-radius: 3px;
  background-clip: unset;
  caret-color: var(--secwhatsappcolor);
  letter-spacing: 0.01em;
  width: 100%;
  border: 1px solid #ddd;
  margin: 0;
  background-color: #fff !important;
  box-shadow: none;
  font-weight: 400 !important;
}

.gp-chat .gp-form-control::-moz-placeholder,
.gp-chat input[type="color"]::-moz-placeholder,
.gp-chat input[type="date"]::-moz-placeholder,
.gp-chat input[type="datetime-local"]::-moz-placeholder,
.gp-chat input[type="datetime"]::-moz-placeholder,
.gp-chat input[type="email"]::-moz-placeholder,
.gp-chat input[type="month"]::-moz-placeholder,
.gp-chat input[type="number"]::-moz-placeholder,
.gp-chat input[type="password"]::-moz-placeholder,
.gp-chat input[type="search"]::-moz-placeholder,
.gp-chat input[type="tel"]::-moz-placeholder,
.gp-chat input[type="text"]::-moz-placeholder,
.gp-chat input[type="time"]::-moz-placeholder,
.gp-chat input[type="url"]::-moz-placeholder,
.gp-chat input[type="week"]::-moz-placeholder {
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--terwhatsappcolor) !important;
}

.gp-chat .gp-form-control::placeholder,
.gp-chat input[type="color"]::placeholder,
.gp-chat input[type="date"]::placeholder,
.gp-chat input[type="datetime-local"]::placeholder,
.gp-chat input[type="datetime"]::placeholder,
.gp-chat input[type="email"]::placeholder,
.gp-chat input[type="month"]::placeholder,
.gp-chat input[type="number"]::placeholder,
.gp-chat input[type="password"]::placeholder,
.gp-chat input[type="search"]::placeholder,
.gp-chat input[type="tel"]::placeholder,
.gp-chat input[type="text"]::placeholder,
.gp-chat input[type="time"]::placeholder,
.gp-chat input[type="url"]::placeholder,
.gp-chat input[type="week"]::placeholder {
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--terwhatsappcolor) !important;
}

.gp-chat .gp-sidebarhead_search .gp-form-group input {
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 40px;
  border-radius: 3px;
}

.gp-replay_msg textarea.gp-form-control {
  resize: none;
  height: 40px !important;
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  max-height: 40px;
  overflow: auto !important;
}

.gp-chatfloatholder {
  position: fixed;
  bottom: 44px;
  right: 44px;
  z-index: 10000;
}

.gp-widgetclose.gp-chatsidebar_float {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  top: 50% !important;
}

.gp-chatsidebar_float {
  bottom: 0;
  right: 20px;
  opacity: 1;
  z-index: 999;
  display: block;
  position: fixed;
  max-width: 480px;
  transform: scale(1);
  visibility: visible;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.gp-chat_previewfloat,
.gp-chatsidebar_float {
  width: 100%;
  height: 100%;
}

.gp-whatsapp_chat {
  background-color: var(--primguppycolor) !important;
}

.gp-chatfloat > span {
  position: relative;
  z-index: 1;
  display: block;
  border-radius: 50%;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.24);
}

.gp-chatfloat > span a {
  align-items: center;
  justify-content: center;
  background: #fff;
  line-height: 1;
  width: 58px;
  height: 58px;
  border-radius: 60px;
  display: flex;
  flex-wrap: wrap;
}

.gp-chatfloat > span a img,
.gp-chatfloat > span a svg {
  display: block;
  max-width: 26px;
}

.gp-chatfloat > span a .gp-float-icon {
  max-width: 73px;
  border-radius: 50%;
  height: 73px;
}

.gp-chatfloat > span a i {
  color: var(--terwhatsappcolor);
  font-size: 25px;
}

.gp-chatsidebar_float:not(.gp-widgetclose) + .gp-chatfloatholder .gp-chatfloat {
  opacity: 0;
  height: 30px;
  display: none;
  visibility: hidden;
}

.gp-whatsappside-responsive .gp-chatsidebar_float {
  max-width: 540px;
}

.gp-whatsappside-responsive.gp-opnchatbox .gp-chat_sidebar {
  display: none !important;
}

@media (max-width: 1280px) {
  .gp-chatsidebar_float {
    max-width: 400px;
  }

  .gp-floatchat {
    right: 420px;
  }

  .gp-floatchat + .gp-floatchat {
    right: 790px;
  }
}

@media (max-width: 991px) {
  .gp-chatsidebar_float {
    max-width: 380px;
  }

  .gp-floatchat {
    right: 395px;
  }
}

.wpguppy-rtl .gp-chatfloatholder {
  right: auto;
  left: 44px;
}

.wpguppy-rtl .gp-chatsidebar_float {
  right: auto;
  left: 0;
}

.wpguppy-rtl .gp-sidebarhead > a {
  margin-left: 0;
  padding-right: 20px;
  padding-left: 0;
  margin-right: auto;
}

.wpguppy-rtl .gp-sidebarhead_search .gp-form-group i {
  left: auto;
  right: 18px;
}

.wpguppy-rtl.gp-chat .gp-sidebarhead_search .gp-form-group input {
  padding-right: 45px;
  padding-left: 20px;
}

.wpguppy-rtl .gp-userbar_right {
  margin-left: 0;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 0;
}

.wpguppy-rtl .gp-userbar_profile + .gp-userbar_title {
  margin-right: 15px;
  margin-left: 0;
}

.wpguppy-rtl .gp-userbar_profile .gp-userstatus {
  left: 0;
  right: auto;
}

.wpguppy-rtl .gp-whatsappbtn svg {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .gp-floatchat {
  right: auto;
  left: 500px;
}

.wpguppy-rtl .gp-widgetclose ~ .gp-chatfloatholder .gp-floatchat {
  right: auto;
  left: 165px;
}

.wpguppy-rtl .gp-floatchat_title figure {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .gp-singleimg .gp-userstatus {
  right: auto;
  left: -3px;
}

.wpguppy-rtl .gp-floatchat_title-right {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .gp-send {
  margin-left: 0;
  margin-right: 10px;
}

.gp-whatsappchat .gp-messagewrap {
  height: 100%;
  padding: 30px;
  min-height: auto;
  max-height: 100%;
  overflow: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.gp-whatsappchat .gp-messages {
  padding: 0;
}

.gp-whatsappchat .gp-message {
  font-size: 15px;
  max-width: 100%;
  line-height: 22px;
  padding: 20px 20px 14px 20px;
  letter-spacing: 0.5px;
}

.gp-whatsappchat .gp-message span {
  margin: 0;
}

.gp-whatsappchat .gp-messagewrap > img {
  padding: 30px;
  display: block;
}

.gp-messages {
  width: 100%;
  float: left;
  padding: 3px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gp-floatchat {
  bottom: 0;
  right: 500px;
  width: 350px;
  z-index: 9999;
  position: fixed;
  overflow: hidden;
  transition: all 0.3s linear;
  filter: drop-shadow(0 2px 4px rgba(40, 41, 61, 0.04))
    drop-shadow(0 8px 16px rgba(96, 97, 112, 0.16));
}

.gp-floatchat_title {
  width: 100%;
  z-index: 9999;
  display: flex;
  min-height: 54px;
  align-items: center;
  background: var(--secwhatsappcolor);
  padding: 15px 20px;
  position: relative;
  border-radius: 10px 10px 0 0;
}

.gp-floatchat_title h2 {
  color: #fff !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding-top: 0;
  padding-right: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.gp-floatchat_content {
  width: 100%;
  height: 100vh;
  max-height: 427px;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 10px 10px 0 0;
}

.gp-floatchat_title figure {
  margin: 0 10px 0 0;
  position: relative;
  flex: none;
}

.gp-floatchat_title figure.gp-notavailable .gp-userstatus.offline {
  filter: grayscale(1) !important;
}

.gp-singleimg img {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
}

.gp-whatsapptitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.gp-whatsapptitle > h2 {
  display: block;
}

.gp-floatchat_title-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.gp-floatchat_title-right a {
  line-height: 1;
  font-size: 28px;
  color: #fff;
  text-decoration: none;
}

.gp-floatchat_titlev2 {
  flex: none;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--primguppycolor);
}

.gp-floatchat_titlev2 > img {
  border-radius: 50%;
  max-width: 40px;
  max-height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}

.gp-chatblockuser span:not(.gp-message_time),
.gp-message,
.gp-userbar_loader span {
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.gp-chat_previewfloat .gp-message,
.gp-floatchat_content .gp-message {
  border-radius: 0 10px 10px 10px;
}

.gp-message {
  white-space: pre-wrap;
  max-width: 75%;
  word-break: break-word;
  position: relative;
  color: var(--secwhatsappcolor);
  border-radius: 0 20px 20px 20px;
  background-color: #fff;
  padding: 19px 20px;
  display: inline-block;
  margin: 0;
  font-size: 15px;
  line-height: 28px;
}

.gp-message_time {
  display: block;
  font-size: 13px;
  margin-top: 6px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: var(--terwhatsappcolor);
  white-space: pre-line;
}

.gp-replay {
  background-color: #fff;
  margin-top: auto;
  padding: 20px;
}

.gp-replay,
.gp-replay_msg {
  position: relative;
  display: flex;
  align-items: center;
}

.gp-replay_msg {
  flex: auto;
  padding: 0 10px;
}

.gp-whatsappchat .gp-replay_msg {
  padding: 0;
}

.gp-sendmsg {
  text-decoration: none;
}

.gp-send {
  width: 40px;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 40px !important;
  text-align: center;
  margin-left: 10px;
  border-radius: 20px;
  background: var(--primguppycolor);
}

.gp-msgautotyper {
  display: inline-flex;
  padding: 9px 14px 14px;
  background-color: #fff;
  border-radius: 0 15px 15px 15px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.gp-msgautotyper img {
  display: block;
  max-width: 18px;
}

.gp-widgetclose ~ .gp-chatfloatholder .gp-floatchat {
  right: 165px;
}

.gp-singleimg .gp-userstatus {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 3px solid var(--secwhatsappcolor);
  border-radius: 50%;
}

.gp-floatchat_titlev2 .gp-singleimg .gp-userstatus {
  border-color: var(--primguppycolor);
}

.gp-floatchat_content .gp-userstatus {
  display: flex;
  font: 400 0.8125rem/1.6153846154em Open Sans, sans-serif;
}

.gp-sidebarhead.gp-sidebarheadtwo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 16px 67px;
  border-radius: 20px 20px 0 0;
}

.gp-sidebarhead.gp-sidebarheadtwo svg {
  position: absolute;
  left: 0;
  opacity: 0.5;
}

.gp-sidebarhead.gp-sidebarheadtwo p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  font-family: var(--secchatfont);
}

.gp-sidebarhead.gp-sidebarheadtwo p span {
  font-weight: 700;
}

.gp-sidebarhead.gp-sidebarheadtwo h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 4px;
}

.gp-layout-one .gp-floatchat {
  right: 463px;
  overflow: visible;
}

.gp-layout-one .gp-replay_msg textarea.gp-form-control {
  padding: 8px 0 0 0;
  border: 0;
}

.gp-layout-one .gp-replay {
  border-radius: 0;
  padding: 10px 20px 10px 20px;
}

.gp-howtoassist {
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 14px;
  background: #fff;
  border-radius: 20px 20px 20px 0;
  position: relative;
}

.gp-howtoassist .gp-userbar_profile {
  max-width: 40px;
  height: 40px;
}

.gp-howtoassist .gp-userbar_title {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.gp-howtoassist .gp-userbar_profile img {
  max-width: 40px;
  height: 40px;
}

.gp-howtoassist .gp-notavailable {
  filter: grayscale(1);
}

.gp-howtoassist .gp-userbar_title p {
  font-size: 15px;
  color: #0a0f26;
  font-family: var(--secchatfont);
  white-space: pre-line;
}

.gp-howtoassist .gp-userbar_title span {
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #999;
  font-family: var(--secchatfont);
}

.gp-layout-one .gp-floatchat_content {
  background: transparent;
}

.gp-howtoassist:after {
  position: absolute;
  left: 0;
  content: "";
  bottom: -20px;
  display: inline-block;
  z-index: 1111;
  width: 0;
  height: 0;
  border-top: 20px solid #fff;
  border-right: 20px solid transparent;
}

.gp-removechat i {
  color: #fff;
  font-size: 20px;
  width: 30px;
  height: 30px;
  background: #ef4444;
  text-decoration: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.gp-layout-one .gp-iconclosered {
  display: flex;
  width: auto;
  justify-content: flex-end;
  top: -10px;
  position: relative;
}

.gp-layout-one .gp-iconclosered a {
  text-decoration: none;
}

.gp-widgetlayoutone {
  padding: 20px 20px 51px;
  height: 100%;
  min-height: 161px;
  max-height: 100%;
  overflow: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.gp-empty-conversation {
  text-align: center;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  top: -50px;
  position: relative;
}

.gp-empty-conversation i {
  color: var(--primguppycolor);
  font-size: 32px;
  display: block;
  margin-bottom: 10px;
}

.gp-empty-conversation span {
  color: var(--terwhatsappcolor);
  display: block;
  font: 500 20px/30px var(--primchatfont);
}

.at-inner-loaader,
.at-userbar_loader.at-inner-loaader {
  height: 134px;
  bottom: 0;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 59.37%);
}

.at-inner-loaader span {
  height: 64px;
}

.at-inner-loaader i {
  width: 24px;
  height: 24px;
  border: 3px solid #ddd;
  border-right-color: #fff;
  border-radius: 50px 50px 50px 50px;
  -webkit-animation: spinericon 1s linear infinite;
  animation: spinericon 1s linear infinite;
}

.at-userbar_loader {
  position: absolute;
  bottom: 0;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.at-userbar_loader .at-spinericon {
  display: inline-block;
  line-height: 64px;
}

.at-spinericon {
  color: var(--terguppycolor) !important;
  -webkit-animation: spinericon 2s linear infinite;
  animation: spinericon 2s linear infinite;
}

.at-userbar_loader span {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  line-height: 64px;
  background: #fff;
  font-size: 24px;
  border-radius: 40px;
}

@-webkit-keyframes spinericon {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes spinericon {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.at-chatloader {
  width: 52px;
  height: 52px;
  margin: 0 0 20px;
  position: relative;
  transform: translateZ(0) scale(0.52);
  transform-origin: 0 0;
}

.at-chatloader span {
  left: 49px;
  top: 0;
  position: absolute;
  -webkit-animation: at-chatloader 0.78125s linear infinite;
  animation: at-chatloader 0.78125s linear infinite;
  background: var(--terguppycolor);
  width: 5.8px;
  height: 25px;
  border-radius: 1.5px;
  transform-origin: 1px 49px;
  display: block;
}

.at-chatloader span:first-child {
  transform: rotate(0deg);
  -webkit-animation-delay: -0.68359375s;
  animation-delay: -0.68359375s;
}

.at-chatloader span:nth-child(2) {
  transform: rotate(45deg);
  -webkit-animation-delay: -0.5859375s;
  animation-delay: -0.5859375s;
}

.at-chatloader span:nth-child(3) {
  transform: rotate(90deg);
  -webkit-animation-delay: -0.48828125s;
  animation-delay: -0.48828125s;
}

.at-chatloader span:nth-child(4) {
  transform: rotate(135deg);
  -webkit-animation-delay: -0.390625s;
  animation-delay: -0.390625s;
}

.at-chatloader span:nth-child(5) {
  transform: rotate(180deg);
  -webkit-animation-delay: -0.29296875s;
  animation-delay: -0.29296875s;
}

.at-chatloader span:nth-child(6) {
  transform: rotate(225deg);
  -webkit-animation-delay: -0.1953125s;
  animation-delay: -0.1953125s;
}

.at-chatloader span:nth-child(7) {
  transform: rotate(270deg);
  -webkit-animation-delay: -0.09765625s;
  animation-delay: -0.09765625s;
}

.at-chatloader span:nth-child(8) {
  transform: rotate(315deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes at-chatloader {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes at-chatloader {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.at-userpost_profile {
  margin: 0;
  flex: none;
  width: 50px;
  height: 50px;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.at-userpost_profile + .at-userbar_title {
  margin-left: 15px;
}

.at-userpost_profile
  img:not(.at-postuserimg, .at-postuserimg-two) {
  width: 100%;
  max-width: 40px;
  margin-top: auto;
  border-radius: 50%;
  margin-left: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-postuserimg-two,
.at-postuserimg {
  margin: 0;
  z-index: 1;
  max-width: 22.5px;
  height: 22.5px;
  border-radius: 50%;
  position: absolute;
  vertical-align: top;
  display: inline-block;
  outline: 4px solid #fff;
}

.at-postuserimg img {
  max-width: 22.5px;
  border: 4px solid #fff;
  -o-object-fit: cover;
  object-fit: cover;
}

.at-postuserimg {
  top: 0;
  left: 1px;
}

.at-postuserimg-two {
  bottom: -8px;
  right: -9px;
}

.at-statusonline:after {
  background: #22c55e;
}

.at-statusoffline:after,
.at-statusonline:after {
  z-index: 1;
  right: -3px;
  content: "";
  bottom: -3px;
  width: 14px;
  height: 14px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #f7f7f7;
}

.at-statusoffline:after {
  background: #ddd;
}

.at-empty-conversation {
  text-align: center;
  background-color: #f7f7f7;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.at-empty-conversation i {
  color: var(--primguppycolor);
  font-size: 52px;
  display: block;
  margin-bottom: 20px;
}

.at-empty-conversation span {
  color: var(--terguppycolor);
  display: block;
  font: 500 18px/30px var(--primchatfont);
}

@media (max-width: 480px) {
  .at-empty-conversation i {
    font-size: 27px;
    margin: 0 0 12px;
  }
}

.at-admin-chat .at-chat_sidebar_footer {
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 9;
  width: 70px;
  height: 100%;
  overflow: auto;
  line-height: 30px;
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: flex-start;
  border-right: 1px solid #eee;
  transition: width 0.5s ease;
}

.at-admin-chat .at-chat_sidebar_footer li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  width: auto !important;
  background: transparent !important;
  border: none !important;
  box-shadow: inset 0 -1px 0 #eee;
}

.at-admin-chat.wpguppy-rtl .at-chat_sidebar_footer > li {
  box-shadow: inset 0 -1px 0 #eee;
}

.at-chat_sidebar_footer li.active {
  box-shadow: inset 3px 0 0 var(--primguppycolor), inset 0 -1px 0 #eee;
}

.at-admin-chat .at-chat_sidebar_footer a {
  font-size: 24px;
  color: #999;
  position: relative;
  display: inline-block;
  padding: 18px 22px !important;
  width: 100%;
  text-align: center;
}

.at-admin-chat .at-chat_sidebar_footer li.active a {
  color: var(--secguppycolor);
}

.at-admin-chat .at-chat_sidebar_footer a .at-notify {
  top: 0;
}

.at-admin-chat .at-chat_sidebar_footer:hover {
  width: 270px;
}

.at-admin-chat .at-chat_sidebar_footer:hover .at-sidebar-item {
  visibility: visible;
  opacity: 1;
}

.at-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 0;
  border-left: 0;
  max-width: 200px;
  width: 200px;
  visibility: hidden;
  position: absolute;
  left: 60px;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.at-admin-chat .at-sidebar-item h6 {
  display: inline-flex;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  margin: 0;
}

.at-admin-chat .at-sidebar-item h5 {
  margin: 0;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.at-admin-chat .at-sidebar-item span {
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--primchatfont);
}

.at-admin-chat .at-chat_sidebar_footer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.at-admin-chat .at-chat_sidebar_footer .at-nestedsetting {
  padding: 0;
}

.at-admin-chat .at-nestedsetting > li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  width: 260px;
  background: #fff;
}

.at-admin-chat .at-nestedsetting > li.active a:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
}

.at-admin-chat .at-chat_sidebar_footer li.at-hasimage a {
  padding: 8px 10px !important;
}

.at-admin-chat .at-chat_sidebar_footer li > a {
  font-size: 20px;
  color: #999;
  display: inline-flex;
  align-items: center;
}

.at-admin-chat .at-chat_sidebar_footer li a svg {
  margin: 0;
}

.at-admin-chat
  .at-admin-chat
  .at-chat_sidebar_footer
  li
  a
  svg {
  margin: 20px 25px;
}

.at-admin-chat .at-chat_sidebar_footer li > a > i {
  display: inline-block;
}

.at-admin-chat
  .at-admin-chat
  .at-chat_sidebar_footer
  > li
  > a {
  width: auto;
}

.at-admin-chat .at-chat_sidebar_footer a img {
  max-width: 44px;
  border-radius: 50%;
  height: 44px;
}

.at-admin-chat .at-chat_sidebar_footer > li.active a {
  color: var(--secguppycolor);
}

.at-admin-chat .at-chat_sidebar_footer a .at-notify {
  pointer-events: none;
  top: -10px;
  left: 100%;
  position: absolute;
  margin-left: -10px;
  box-shadow: 0 0 0 3px var(--secguppycolor);
  font-family: var(--secchatfont);
}

.at-admin-chat .at-nestedsetting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  transition: all 0.4s ease-in-out;
  background: #fff;
}

.at-admin-chat .at-nestedsetting li {
  box-shadow: inset 0 1px 0 #eee;
}

.at-admin-chat .at-nestedsetting li.active a {
  color: var(--secguppycolor);
}

.at-admin-chat .at-chat_sidebar_footer li.at-hasimage {
  border: 0 !important;
  justify-content: flex-start;
}

.at-admin-chat .at-chat_sidebar_footer li,
.at-admin-chat .at-nestedsetting > li {
  width: 70px;
}

.at-admin-chat .at-chat_sidebar_footer li a,
.at-admin-chat .at-nestedsetting > li a {
  padding: 0;
  width: 70px;
}

.at-admin-chat .at-chat_sidebar_footer:hover li,
.at-admin-chat .at-nestedsetting:hover li {
  width: 270px;
}

.at-admin-chat .at-nestedsetting li {
  width: 70px;
}

.at-admin-chat
  .at-chat_sidebar_footer
  .at-hasimage
  .at-sidebar-item {
  padding: 0 10px;
}

.at-admin-chat .at-chat_sidebar_footer > li {
  width: 270px;
}

.at-admin-chat .at-sidebar-item {
  left: 70px;
}

.at-admin-chat .at-responmenu {
  display: flex;
  width: 100%;
}

.at-admin-chat .at-responsivelist {
  display: flex;
  flex-direction: column;
  width: 60px;
  width: 70px;
}

.at-admin-chat .at-imgresponsivearea {
  display: flex;
  padding: 14px 20px;
  align-items: flex-start;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.at-admin-chat .at-imgresponsivearea > a {
  padding: 0 !important;
}

.at-admin-chat .at-imgresponsivearea a {
  display: flex;
  padding: 0 !important;
}

.at-admin-chat .at-imgresponsivearea .at-sidebar-item {
  padding: 0;
  border: 0;
  flex-direction: column;
  margin: 10px 0 0;
  position: relative;
  align-items: flex-start;
  left: 0;
  max-width: 100%;
  width: 100%;
}

.at-admin-chat .at-responsivelist a {
  font-size: 20px;
  color: #999;
  display: inline-flex;
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 -1px 0 #eee;
  padding: 18px 20px;
}

.at-admin-chat .at-responsivelist a:focus {
  box-shadow: inset 0 -1px 0 #eee !important;
}

.at-admin-chat .at-sidebarshow .at-menuicon {
  background: #f7f8fc !important;
}

.at-admin-chat .at-menuicon {
  box-shadow: inset 0 -1px 0 #eee;
}

.at-admin-chat .at-chat_sidebar_footer .at-listonrespon {
  box-shadow: none;
}

.at-admin-chat .at-sidebarshow {
  width: 260px !important;
}

.at-admin-chat .at-admin-chat .at-chat_sidebar_footer:hover {
  width: 270px;
}

@media (max-width: 767px) {
  .at-admin-chat .at-chat_sidebar_footer:hover {
    width: 70px;
  }

  .at-admin-chat .at-imgresponsivearea a {
    border: 0 !important;
  }

  .at-admin-chat .at-userchat_tab a {
    padding: 14px 10px;
  }

  .at-admin-chat .at-userlist_tab .at-sidebarhead_searchv2 {
    padding: 9.5px 0;
  }

  .at-chat_sidebar_footer .at-sidebar-item {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .at-admin-chat .at-imgresponsivearea .at-sidebar-item {
    margin: 10px 0 0 10px;
  }
}

@media (max-width: 480px) {
  .at-admin-chat .at-imgresponsivearea {
    padding: 7px 20px;
  }

  .at-admin-chat .at-empty-conversation i {
    font-size: 27px;
  }
}

.at-onlineusers {
  margin: 0;
  display: flex;
  overflow: auto;
  margin: 0 -20px;
  list-style: none;
  padding: 30px 0 10px;
  height: auto !important;
  width: calc(100% + 40px);
  flex-direction: row !important;
}

.at-onlineusers::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.at-onlineusers > li {
  border: 0;
  flex: none;
  display: block;
  cursor: pointer;
  max-width: 85px;
  list-style-type: none;
  padding: 0 !important;
  background: transparent;
  margin-bottom: 0 !important;
}

.at-onlineusers > li:first-child {
  margin-left: 20px;
}

.at-onlineusers > li:last-child {
  margin-right: 20px;
}

.at-onlineusers .at-userstatus {
  right: 0;
  bottom: 0;
  border: 2px solid #fff;
}

.at-onlineusers .at-userstatus:before {
  width: 8px;
  height: 8px;
}

.at-onlineusers figure {
  max-width: 50px;
  position: relative;
  margin: 0 auto !important;
}

.at-onlineusers .at-singleimg img {
  width: 50px;
  height: 50px;
}

.at-userlist_tab .at-sidebarhead_searchv2 {
  padding: 7.5px 0;
  border-bottom: 1px solid #eee;
}

.at-userlist_tab .at-sidebarhead_searchv2 .at-form-group input {
  border: 0;
  padding-left: 43px;
}

.at-admin-chat .at-sidebarhead_searchv2 .at-form-group input {
  height: 44px;
}

.at-userlist_tab {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  border-right: 1px solid #eee;
}

.at-chat_sidebar {
  position: relative;
  flex: 0 0 26.2%;
  max-width: 26.2%;
  overflow: hidden;
}

.at-chat_sidebar:before {
  width: 100%;
  position: absolute;
  content: "";
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.at-ovelay:before {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.6s ease-in-out;
}

.at-chat_sidebarsetting26 {
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.at-chat_sidebarsettingopen {
  transform: translateX(0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.at-chat_sidebarsettingcontent {
  padding: 26px 30px;
}

.at-chat_sidebarsettingcontent h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-chat_sidebarsettingcontent > a {
  color: #3c57e5 !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: block;
  transition: none;
}

.at-chat_sidebarsettingcontent > a.at-danger {
  color: #ef4444 !important;
}

.at-chat_sidebarsettingcontent .at-themeform {
  padding-top: 10px;
}

.at-chat_sidebarsettingcontent .at-btnlist {
  margin: 0;
}

.at-chat_sidebarsettingcontent .at-btnlist li {
  width: 100% !important;
  padding: 0 !important;
}

.at-chat_sidebarsettingcontent
  ~ .at-chat_sidebarsettingcontent {
  border-top: 1px solid #ddd;
}

.at-chat_sidebarsettingarea {
  height: calc(100vh - 91px);
  overflow-y: auto;
  background: #fff;
}

.at-gallerylistwrap {
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}

.at-gallerylistwrap h4 {
  letter-spacing: 0.5px;
  color: var(--terguppycolor) !important;
  line-height: 28px;
  font-size: 18px;
  margin: 0;
}

.at-gallerylist {
  margin: -5px;
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.at-gallerylist li {
  margin: 0;
  list-style: none;
  padding: 5px !important;
  width: 33.333% !important;
}

.at-gallerylist li:not(.at-loadmorecontent) a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  min-height: 73.33px;
  max-height: 73.33px;
  border: 1px solid #ddd;
  color: var(--primguppycolor);
}

.at-gallerylist li.at-loadmorecontent {
  width: 100% !important;
  margin-top: 10px;
}

.at-gallerylist li a i {
  display: inline-block;
  background: #fff;
  text-align: center;
  font-size: 24px;
}

.at-gallerylist li a img {
  max-width: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  border-radius: 5px;
  max-height: 70px;
  width: 100%;
}

.at-danger {
  color: #ef4444 !important;
}

.at-imgdownloader {
  outline: none !important;
  text-decoration: none !important;
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  margin-top: 10px;
  display: inline-flex;
  vertical-align: middle;
  color: #3c57e5 !important;
  align-items: center;
}

.at-imgdownloader.at-disable-btn,
.at-imgdownloader {
  background-color: transparent !important;
}

.at-imgdownloader .at-msgload {
  margin: 0;
  position: static;
  display: inline-block;
  margin-right: 10px;
}

.at-imgdownloader .at-msgload i {
  border-right-color: #fff;
  display: block;
  width: 20px;
  height: 20px;
}

.at-chat_sidebarsettingcontent.at-chatgroup-sidebar {
  padding: 30px 0;
}

.at-userinfo {
  padding: 16px 20px;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 #eee;
}

.at-userinfo,
.at-userinfo_title {
  display: flex;
  align-items: center;
}

.at-userinfo_title_name {
  display: flex;
  flex-wrap: wrap;
}

.at-userinfo_title_img {
  flex: none;
  margin: 0 15px 0 0 !important;
  max-width: 50px;
}

.at-userinfo_title_name h3 {
  width: 100%;
  word-break: break-word;
  margin: 0;
  font-size: 18px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.at-userinfo_title_name p {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #999;
  margin: 0;
}

.at-userinfo_title_name p > span {
  display: block;
}

.at-userinfo_title_name p a {
  color: #3c57e5 !important;
  outline: none !important;
}

.at-userinfo_settings {
  display: flex;
  margin-left: auto;
}

.at-userinfo_settings .at-redbtnwrapper {
  margin: 0 30px 0 0;
}

.at-userinfo_settings a {
  width: 42px;
  display: block;
  text-align: center;
  line-height: 57px;
  font-size: 24px;
  text-decoration: none;
}

.at-userinfo_settings a,
.at-userinfo_settings a:hover {
  color: var(--secguppycolor);
}

.at-userstatus {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.01em;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.at-userstatus:before {
  margin-right: 5px;
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.at-userstatus.online:before {
  background-color: #22c55e;
}

.at-userstatus.offline:before {
  background-color: #ddd;
}

.at-userstatus.away:before {
  background-color: #eab308;
}

.at-userstatus.notify:before {
  background-color: #ff7300;
}

.at-backtolist {
  font-size: 22px;
  color: #000;
  margin-right: 15px;
  line-height: 0.8;
  display: none;
}

.at-chat991 .at-backtolist {
  display: block;
}

.wpguppy-rtl .at-chat_sidebarsetting {
  left: auto;
  right: 100%;
  transform: translateX(-100%);
}

.audio-player {
  --player-button-width: 3em;
  --sound-button-width: 2em;
  --space: 0.5em;
  width: 100%;
  height: auto;
}

.icon-container {
  width: 100%;
  height: 100%;
  background-color: #de5e97;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-icon {
  width: 90%;
  height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 0;
}

.controls .player-button {
  background-color: transparent !important;
  border: 0;
  width: 30px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.controls .timeline {
  -webkit-appearance: none;
  width: calc(
    100% - var(--player-button-width) - var(--sound-button-width) - var(--space)
  );
  background-color: #ddd;
  height: 6px;
  border-radius: 5px;
  background-size: 0 100%;
  background-image: linear-gradient(#ff7300, #ff7300);
  background-repeat: no-repeat;
  margin-right: var(--space);
}

.controls .timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  background: #fff;
  border: 1.5px solid #ddd;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.controls .timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  background: #fff;
  border: 1.5px solid #ddd;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.controls .timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  -ms-transition: all 0.1s;
  transition: all 0.1s;
  background: #fff;
  border: 1.5px solid #ddd;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04),
    0 8px 16px rgba(96, 97, 112, 0.16);
}

.controls .timeline::-webkit-slider-thumb:hover {
  background-color: #fff;
  border: 1.5px solid #ddd;
}

.controls .timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.controls .timeline::-moz-range-thumb:hover {
  background-color: #fff;
  border: 1.5px solid #ddd;
}

.controls .timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.controls .timeline::-ms-thumb:hover {
  background-color: #fff;
  border: 1.5px solid #ddd;
}

.controls .timeline:hover::-ms-thumb {
  opacity: 1;
}

.controls .timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.controls .timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.controls .timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.controls .sound-button {
  background-color: transparent !important;
  border: 0;
  width: var(--sound-button-width);
  height: var(--sound-button-width);
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.controls .sound-button svg {
  fill: #ff7300;
  width: 20px;
}

.controls .player-button svg {
  fill: #ff7300;
  width: 30px;
}

.at-message_sender
  .at-grp_receiver_msg
  > .at-message
  > .ready-player-1
  > .vueAudioBetter,
.at-messages > .at-message > .ready-player-1 > .vueAudioBetter {
  margin: 0 24px 0 0 !important;
}

.operate > span:not(.iconfont) {
  color: var(--terguppycolor) !important;
  font-size: 13px !important;
  line-height: 30px;
  padding-left: 20px !important;
}

.iconfont {
  color: var(--primguppycolor) !important;
  font-size: 20px !important;
  top: 0 !important;
  left: 0 !important;
}

.iconfont.icon-notificationfill,
.iconfont.icon-notificationforbidfill {
  line-height: 20px;
  padding-left: 10px !important;
}

.wpguppy-rtl
  .at-message_sender
  .at-grp_receiver_msg
  > .at-message
  > .ready-player-1
  > .vueAudioBetter,
.wpguppy-rtl .at-messages > .at-message > .ready-player-1 > .vueAudioBetter {
  margin: 0 0 0 24px !important;
}

.wpguppy-rtl .operate > span:not(.iconfont) {
  padding-left: 0 !important;
  padding-right: 20px !important;
}

.wpguppy-rtl .iconfont {
  right: auto !important;
  left: 0 !important;
}

.wpguppy-rtl .operate {
  padding-right: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.wpguppy-rtl .operate .iconfont + .iconfont {
  padding-right: 5px;
}

.wpguppy-rtl .iconfont.icon-notificationfill,
.wpguppy-rtl .iconfont.icon-notificationforbidfill {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-chat-msg-220
  .at-messageoption_list {
  right: auto;
  left: 100%;
  margin-right: 0;
  margin-left: -30px;
}

.at-messages .at-groupuserbar.at-groupuserbarvtwo {
  left: 0;
  top: 20px;
  position: absolute;
}

.at-messages.at-message_sender .at-groupuserbarvtwo {
  top: 20px;
  left: auto;
}

.at-admin-chat .at-message_sender .at-message {
  border-radius: 20px 20px 0 20px;
}

.at-admin-chat .at-message_sender .at-message.at-deletemsg {
  margin: 0 20px 0 0;
}

.at-admin-chat .at-message_sender .at-message_time svg {
  margin-right: 7px;
}

.at-admin-chat .at-message_sender .at-message_time:before {
  content: none;
}

.at-locationmap {
  padding: 20px;
  position: relative;
}

.at-locationmap .vue2leaflet-map:before {
  left: 0;
  top: 0;
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  content: "";
  width: 100%;
  height: 100%;
}

.at-locationmap .at-messageoption {
  top: 30px;
  right: 36px;
  position: absolute;
  z-index: 1111;
}

.at-locationmap > .at-messageoption > .at-messageoption_btn {
  color: #fff;
}

.at-audiosendfile {
  padding: 19px 20px;
}

.at-message img {
  vertical-align: middle;
}

.at-message_imgs {
  list-style: none;
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.at-message_imgs li {
  margin: 0;
  padding: 5px;
  list-style-type: none;
  max-width: 25% !important;
  min-width: 130px;
}

.at-message_imgs figure {
  margin: 0;
  width: 120px;
  background: #f7f7f7;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.at-message_imgs figure img {
  margin: 0;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  max-height: 100%;
  border-radius: 3px;
}

.at-message_imgs figure span {
  border-radius: 3px;
  position: absolute;
  left: 0;
  color: #fff;
  font: 700 26px/30px var(--primchatfont);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.at-message_gallery {
  padding: 20px;
}

.at-message_gallery .at-messageoption {
  top: 30px;
  right: 36px;
  z-index: 1;
}

.at-message_gallery .at-messageoption > a {
  color: #fff;
}

.at-message_gallery .at-messageoption:before {
  position: absolute;
  content: "";
  top: -10px;
  border-radius: 3px 3px 0 0;
  right: -16px;
  width: 120px;
  height: 50px;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0,
    transparent 98%,
    transparent 99%
  );
}

.at-message_gallery .at-message-qoute_content + .at-messageoption:before {
  display: none;
}

.at-message_gallery .at-message-qoute_content + .at-messageoption > a {
  color: var(--terguppycolor);
}

.at-message.at-video-message {
  padding: 20px;
  vertical-align: middle;
}

.at-video-message .at-messageoption {
  top: 30px;
  right: 36px;
}

.at-video-message > .at-messageoption > a {
  color: #fff;
}

.video-js.vjs-fullscreen {
  padding: 0 !important;
}

.video-js {
  border-radius: 3px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.video-js .vjs-big-play-button {
  top: auto !important;
  left: auto !important;
  height: 58px !important;
  line-height: 58px !important;
  position: relative !important;
  border: 0 !important;
  border-radius: 50% !important;
  width: 58px !important;
}

.video-js .vjs-big-play-button,
.video-js:hover .vjs-big-play-button {
  background-color: #fff !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "\e9be";
  font-size: 22px;
  font-family: guppy-icomoon;
  color: var(--secguppycolor);
}

.at-sendfile,
.vjs-poster {
  border-radius: 3px;
}

.at-sendfile {
  position: relative;
  background-color: #f7f7f7;
  padding: 11px 42px 11px 20px;
  display: flex;
  align-items: center;
}

.at-message-qoute + .at-sendfile {
  display: inline-flex;
  padding-right: 20px !important;
}

.at-sendpdffile .at-sendfile {
  padding-right: 42px;
}

.at-sendfile > i {
  flex: none;
  align-self: flex-start;
  font-size: 24px;
  line-height: 38px;
  margin-right: 20px;
}

.at-sendfile > span {
  color: var(--secguppycolor);
  font-size: 15px;
  word-break: break-word;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.at-sendfile > span em {
  display: block;
  color: var(--terguppycolor);
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
}

.at-messagewrap {
  display: flex;
  overflow: auto;
  min-height: 280px;
  padding: 10px 20px 0;
  flex-direction: column;
  margin-top: auto;
}

.at-messagewrap > div {
  margin-top: auto;
}

.at-messagewrap .at-alert {
  margin: auto;
}

.at-messagewrap .at-userbar_loader {
  background: transparent;
  position: relative;
  height: auto;
  padding: 20px 0 30px;
}

.at-chat_messages {
  background-color: #f7f7f7;
  flex-direction: column;
  flex: 0 0 73.8%;
  max-width: 73.8%;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.at-chat_messagesslide {
  flex: 0 0 calc(73.8% - 300px);
  max-width: calc(73.8% - 300px);
}

.at-unknownuser {
  padding-left: 30px;
  padding-right: 30px;
}

.at-loadermsg {
  display: flex;
  align-items: center;
  padding: 20px;
  color: var(--terguppycolor);
  height: auto;
  bottom: auto;
}

.at-loadermsg + .at-message_time {
  display: none;
}

.at-loadermsg i {
  font-size: 24px;
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.at-chatseparation {
  width: 100%;
  position: relative;
  margin: 10px 0;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.at-chatseparation:before {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  background: #ddd;
}

.at-chatseparation span {
  color: var(--terguppycolor);
  background: #f7f7f7;
  padding: 0 20px;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.logged-in.admin-bar .at-chat:not(.at-guppysidepopup) {
  border-radius: 20px;
  height: calc(100vh - 32px);
}

.logged-in.admin-bar .at-modaldialog {
  padding-top: 62px;
}

.logged-in.admin-bar
  .at-chat:not(.at-guppysidepopup)
  .at-sidebarhead
  .at-chat_sidebarsettingarea {
  height: calc(100vh - 273px);
}

@media (max-width: 782px) {
  .logged-in.admin-bar .at-chat_sidebarsettingarea {
    height: calc(100vh - 137px);
  }

  .logged-in.admin-bar
    .at-chat:not(.at-guppysidepopup)
    .at-sidebarhead
    .at-chat_sidebarsettingarea {
    height: calc(100vh - 287px);
  }

  .logged-in.admin-bar .at-chat:not(.at-guppysidepopup) {
    height: calc(100vh - 46px);
  }

  .logged-in.admin-bar .at-modaldialog {
    padding-top: 78px;
  }
}

.at-admin-chat .at-form-group {
  position: relative;
  width: 100%;
  padding: 10px;
}

.at-admin-chat .at-themeform fieldset {
  margin: -10px;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.at-admin-chat .at-formtitle {
  font: 700 16px/26px var(--primchatfont);
  letter-spacing: 0.5px;
  color: var(--secguppycolor);
  margin: 0 0 10px;
  display: block;
}

.at-chat1080 .at-chat_sidebar {
  flex: 0 0 410px;
  max-width: 410px;
}

.at-chat_sidebar_footer .at-infotolltip,
.at-infotolltip em {
  margin-bottom: 28px;
  bottom: 100%;
}

.at-admin-chat .at-sidbarwrapper {
  display: flex;
  padding-bottom: 60px;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.at-groupuser_twoplus .at-notify {
  padding: 0;
  width: 20px;
  margin-top: 5.5px;
  font-size: 15px;
  font-weight: 700;
  background: #6366f1;
  line-height: 20px;
  margin-left: 0;
}

.at-admin-chat .at-groupuserbar_content:after {
  content: "";
  font-family: guppy-icomoon;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  width: 24px;
  line-height: 22px;
  color: #fff;
  background: #fff;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 14px;
  border: 1px solid #ddd;
}

.at-admin-chat .at-message_sender .at-grp_receiver_msg + .at-message_time {
  padding-right: 70px;
}

.at-multipleimg span {
  background: #06b6d4;
}

.at-admin-chat .at-guppyside-responsive .at-chatsidebar_float {
  max-width: 540px;
}

.at-chat_sidebarsettingcontent .at-groupdescription {
  margin: 0;
}

.at-admin-chat .at-sidebarhead_search {
  width: 100%;
  position: relative;
  padding: 7.5px 10px;
  background: #fff;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.at-admin-chat .at-sidebarhead_search h3 {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 14px;
  color: #fff;
}

.at-sidebarhead_search h3 a {
  color: #3c57e5;
  display: inline-block;
  margin-left: auto;
  font-size: 14px;
  line-height: 22px;
}

.at-sidebarhead_search .at-form-group {
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.at-admin-chat .at-sidebarhead_search .at-form-group input {
  font-size: 14px;
  padding-left: 45px;
  width: 100%;
  height: 44px;
  outline: none;
  border-radius: 3px;
}

.at-btnred {
  background-color: #ef4444 !important;
  width: 100%;
  font-weight: 600;
}

.at-btnred i {
  margin: 0 0 0 10px;
}

.at-deletecheck h6,
.at-groupuserbar_content h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 0 30px;
}

.at-deleteselected {
  padding: 17.5px 20px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 #eee;
  background: #fff;
  justify-content: space-between;
}

.at-groupuserbarvtwo .at-groupuserbar:hover,
.at-groupuserbarvtwo .at-groupuserbar_content:hover {
  background: #fff;
}

.at-deletecheck {
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: center;
}

.at-groupuserbarvtwo .at-groupuserbar_content {
  padding: 0 !important;
}

.at-groupuserbarvtwo .at-groupuserbar_content:after {
  left: 0;
  height: 18px;
  width: 18px;
  line-height: 17px;
}

.at-deletecheck a {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #999;
  font-family: var(--primchatfont);
}

.at-redbtnwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.at-userbar .at-groupuserbar {
  margin: 0 32px 0 0;
  width: auto;
}

.at-group-avatar,
.at-message_sender .at-group-avatar {
  margin-left: 14px;
}

.at-message_sender .at-message h5 {
  text-align: right;
}

.at-admin-chat .at-message_sender .at-message {
  border-radius: 20px 0 20px 20px;
  padding: 20px;
}

.at-admin-chat .at-chat_sidebar_footer .at-hasimage a {
  padding: 7.5px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.at-admin-chat .at-chat_sidebar_footer .at-hasimage {
  border: 0 !important;
}

.at-hasimage a img {
  max-width: 44px;
  height: 44px;
  border-radius: 50%;
}

.at-chat a:focus {
  box-shadow: none;
}

.at-admin-chat .at-userbar_profile {
  max-width: 44px;
  width: 44px;
  height: 44px;
}

.at-multiple-images img {
  height: 50px;
  width: 50px;
}

.at-multiple-images .at-userimage1,
.at-multiple-images .at-userimage2 {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #f7f7f7;
}

.at-multiple-images .at-userimage1 {
  margin-top: 0;
  margin-left: -10px;
  z-index: 1;
  outline: 4px solid #fff;
  width: 26px;
  min-height: 26px;
  height: 26px;
  border-radius: 50% !important;
}

.at-multiple-images .at-userimage2 {
  margin-top: -10px;
  margin-right: -7px;
  z-index: 0;
  height: 26px;
  width: 26px;
  min-height: 26px;
}

.at-userbar_profile.at-multiple-images {
  background-color: unset;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  flex-direction: column;
  margin-right: 10px;
}

.at-usertitle-multiple {
  display: flex;
  flex-direction: column;
  margin-right: 30px !important;
}

.at-usertitle-multiple img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  z-index: 1;
}

.at-usertitle-multiple img + img {
  margin-right: -17px;
  margin-top: -10px;
  z-index: 0;
}

.at-messagetext > div:not(.at-message-qoute) {
  margin-right: 0;
}

.at-admin-chat .at-view-messages {
  height: calc(100vh - 98px);
}

.at-chat_sidebar_footer {
  overflow: unset;
}

.at-chat_sidebar_footer .at-infotolltip,
.at-infotolltip em {
  margin-bottom: 18px;
  bottom: 0;
  transform: scale(0.5);
  position: absolute;
  right: 0;
  color: #fff;
  width: 178px;
  background: var(--secguppycolor);
  border-radius: 4px 4px 0 4px;
  padding: 5px 12px;
  letter-spacing: 0.5px;
  opacity: 0;
  visibility: hidden;
  font: 700 14px/22px var(--primchatfont);
  transition: all 0.3s ease-in-out;
}

.at-chat_sidebar_footer li .at-infotolltip {
  left: 60px;
  bottom: -2px;
  right: auto;
  margin-left: 0;
  border-radius: 4px;
}

.at-chat_sidebar_footer li .at-infotolltip:after {
  left: -19px;
  right: auto;
  border-top: 12px solid transparent;
  border-right: 9px solid var(--secguppycolor);
  border-bottom: 14px solid transparent;
  top: 3px;
}

.at-admin-chat .at-chat_sidebarsettingtitle h2 {
  padding: 0 !important;
  font-size: 24px !important;
  line-height: 38px !important;
}

.wpguppy-rtl {
  direction: rtl;
}

.wpguppy-rtl .at-chat_sidebarsettingarea,
.wpguppy-rtl .at-formtitle,
.wpguppy-rtl .at-message-qoute:before,
.wpguppy-rtl .at-messages,
.wpguppy-rtl .at-userbar_title,
.wpguppy-rtl .at-userinfo_title_name {
  text-align: right;
}

.wpguppy-rtl .at-remove-quotes {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .at-chatseparation:before,
.wpguppy-rtl .at-locationmap .vue2leaflet-map:before,
.wpguppy-rtl .at-message_sender .at-messageoption_list,
.wpguppy-rtl .at-sidebarhead_menu .at-uploadoption,
.wpguppy-rtl .at-userbar_profile .at-userstatus {
  left: 0;
  right: auto;
}

.wpguppy-rtl.at-chat .at-groupusersearch i + input {
  padding-left: 20px;
  padding-right: 45px;
}

.wpguppy-rtl .at-groupusersearch i {
  left: auto;
  right: 30px;
}

.wpguppy-rtl .at-messageoption_list {
  left: auto;
  right: 0;
}

.wpguppy-rtl .at-dropbox label span {
  display: inline-block;
}

.wpguppy-rtl .at-userinfo_settings {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .at-sidebarhead > img {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-sidebarhead > a {
  margin-left: 0;
  padding-right: 20px;
  padding-left: 0;
}

.wpguppy-rtl .at-sidebarhead_search .at-form-group i {
  left: auto;
  right: 18px;
}

.wpguppy-rtl.at-chat .at-sidebarhead_search .at-form-group input {
  padding-right: 45px;
  padding-left: 20px;
}

.wpguppy-rtl .at-userbar_profile + .at-userbar_title {
  margin-right: 15px;
  margin-left: 0;
}

.wpguppy-rtl .at-userbar_right {
  margin-left: 0;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 0;
}

.wpguppy-rtl .at-sidebarhead .at-chat_profilesettingopen {
  transform: translateX(0);
}

.wpguppy-rtl .at-chat_sidebarsettingtitle > a {
  margin-right: 0;
  margin-left: -4px;
}

.wpguppy-rtl .at-chat_mutenotify label span {
  padding-right: 0;
  padding-left: 20px;
}

.wpguppy-rtl .at-guppylogout a i {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-userinfo_title_img {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.wpguppy-rtl .at-message {
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
}

.wpguppy-rtl .at-message_gallery .at-messageoption {
  right: auto;
  left: 36px;
}

.wpguppy-rtl .at-message_gallery .at-messageoption:before {
  right: auto;
  left: -16px;
}

.wpguppy-rtl .at-chat .at-replay_msg input {
  border-radius: 0 3px 3px 0;
}

.wpguppy-rtl .at-replay_msg > .at-emojibar {
  right: 11px !important;
  left: auto !important;
  border-right: 0 !important;
  border-left: 1px solid #ddd;
}

.wpguppy-rtl .emoji-mart {
  left: auto !important;
  right: 0 !important;
}

.wpguppy-rtl.at-chat .at-replay_msg textarea.at-share-emoji {
  padding-right: 78px;
  padding-left: 20px;
}

.wpguppy-rtl .at-sendmsg {
  border-radius: 3px 0 0 3px;
}

.wpguppy-rtl .at-messagetext {
  padding-right: 20px;
  padding-left: 44px;
  text-align: right;
}

.wpguppy-rtl .at-messageoption {
  right: auto;
  left: 18px;
}

.wpguppy-rtl .at-message_sender .at-message {
  border-radius: 20px 20px 20px 0;
}

.wpguppy-rtl .at-message_sender .at-message_time:before,
.wpguppy-rtl .at-messageoption_list a i,
.wpguppy-rtl .at-uploadoption a i,
.wpguppy-rtl .at-uploadoption label i {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-chat_sidebarsettingopen {
  transform: translateX(100%);
  left: auto;
  right: 100%;
}

.wpguppy-rtl
  .at-message_sender
  .at-grp_receiver_msg
  > .at-message
  > .ready-player-1
  > .vueAudioBetter,
.wpguppy-rtl .at-messages > .at-message > .ready-player-1 > .vueAudioBetter {
  margin-right: 0 !important;
  margin-left: 24px !important;
}

.wpguppy-rtl .at-userbar_title span i {
  margin-right: 5px;
  margin-left: 5px;
}

.wpguppy-rtl .at-sidebarhead_search h3 a {
  margin-left: 0;
  margin-right: auto;
}

.wpguppy-rtl .at-blockuser > i {
  left: auto;
  right: -4px;
}

.wpguppy-rtl .at-message .at-message-qoute_content {
  padding-right: 14px !important;
  padding-left: 43px;
}

.wpguppy-rtl .at-messagetext > div:not(.at-messageoption) {
  margin-right: 0;
  margin-left: 0 !important;
}

.wpguppy-rtl .at-messages .at-message-qoute .at-messageoption {
  left: 17px;
  right: auto;
  margin-right: -18px;
  margin-left: 0;
}

.wpguppy-rtl .at-video-message .at-messageoption {
  left: 36px;
}

.wpguppy-rtl .at-replay_message {
  margin-right: 70px;
  margin-left: 130px;
}

.wpguppy-rtl .at-sendfile > i {
  margin-right: 0;
  margin-left: 20px;
}

.wpguppy-rtl .at-message-qoute .at-sendfile i {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-messages .at-message-qoute_content .vue2leaflet-map {
  margin-right: 0;
  margin-left: -33px;
}

.wpguppy-rtl .at-message_sender .at-message-qoute + .at-sendfile {
  float: left;
}

.wpguppy-rtl .at-sendfile {
  padding-right: 20px;
  padding-left: 42px;
}

.wpguppy-rtl .at-deletemsg i {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-message-qoute_content .at-sendfile {
  padding: 0;
}

.wpguppy-rtl .at-message .at-reply-video {
  padding-left: 14px !important;
}

.wpguppy-rtl .at-messages .at-reply-video + .at-messageoption {
  left: 26px;
}

.wpguppy-rtl .at-message-qoute + .at-sendfile {
  padding-left: 20px;
}

.wpguppy-rtl .at-messages .at-reply-images + .at-messageoption {
  left: 14px;
  margin: 0;
}

.wpguppy-rtl .at-messages .at-message_gallery .at-message-qoute {
  justify-content: flex-end;
}

.wpguppy-rtl .at-message .at-reply-images {
  padding-left: 14px !important;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-reply-images
  + .at-messageoption {
  margin-right: -20px;
  top: -15px;
}

.wpguppy-rtl .at-locationmap .at-messageoption {
  left: 36px;
  right: auto;
}

.wpguppy-rtl
  .at-messages
  .at-message-qoute
  .at-reply-location
  + .at-messageoption {
  right: auto;
  left: 26px;
  margin-left: 0;
  margin-right: -18px;
}

.wpguppy-rtl .at-disable-btn:before {
  margin-right: 0;
  margin-left: 10px;
  border-right-color: #ddd;
  border-left-color: transparent;
}

.wpguppy-rtl .at-loadermsg i {
  margin-right: 0;
  margin-left: 12px;
}

.wpguppy-rtl .at-backtolist {
  margin-right: 0;
  margin-left: 15px;
}

.wpguppy-rtl .at-backtolist .guppy-chevron-left:before {
  content: "\e930";
}

.wpguppy-rtl .at-msgload {
  right: -30px;
  left: 0;
}

.wpguppy-rtl.at-chat480 .at-groupuser_twoplus,
.wpguppy-rtl.at-chat480 .at-userinfo_title > a {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl.at-chat991 .at-userinfo {
  padding-right: 13px;
  padding-left: 20px;
}

.wpguppy-rtl.at-chat480 .at-userinfo {
  padding-left: 15px;
  padding-right: 8px;
}

.wpguppy-rtl.at-chat480.at-chat .at-replay_msg input {
  padding-left: 68px;
  padding-right: 20px;
}

.wpguppy-rtl.at-chat480 .at-replay_msg > div {
  left: auto !important;
  right: 0 !important;
  border-right: 0 !important;
  border-left: 1px solid #ddd;
}

.wpguppy-rtl.at-chat480 .at-sendmsg {
  right: auto;
  left: 30px;
}

.wpguppy-rtl .at-message-qoute {
  justify-content: flex-end;
}

.wpguppy-rtl .leaflet-left {
  left: auto;
  right: 0;
}

.wpguppy-rtl .leaflet-left .leaflet-control {
  margin-left: 0;
  margin-right: 10px;
}

.wpguppy-rtl .at-sidebarhead_menu {
  margin-right: auto;
  margin-left: 0;
  padding-right: 20px;
  padding-left: 0;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-audiosendfile
  .at-message-qoute
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message-qoute
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  .at-message-qoute
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-location
  + .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-voice
  + .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  > .at-audiosendfile
  > .at-sendfile
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  > .at-sendpdffile
  > .at-sendfile
  .at-messageoption {
  right: auto;
  left: 0;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message
  > h5 {
  padding-right: 0;
  padding-left: 20px;
}

.wpguppy-rtl .at-group-avatar,
.wpguppy-rtl .at-messages.at-message_sender .at-group-avatar {
  margin-right: 14px;
  margin-left: 0;
}

.wpguppy-rtl .at-messages .at-group-avatar {
  margin-right: 0;
  margin-left: 14px;
}

.wpguppy-rtl .at-messages .at-groupuserbar.at-groupuserbarvtwo {
  left: auto;
  right: 0;
}

.wpguppy-rtl .at-messages .at-groupuserbarvtwo .at-groupuserbar_content:after {
  left: auto;
  right: -10px;
}

.wpguppy-rtl
  .at-messages.at-message_sender
  .at-groupuserbar.at-groupuserbarvtwo {
  left: 0;
  right: auto;
}

.wpguppy-rtl
  .at-messages.at-message_sender
  .at-groupuserbarvtwo
  .at-groupuserbar_content:after {
  left: -10px;
  right: auto;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-reply-file
  .at-sendfile {
  padding-right: 0;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-sendfile,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-sendpdffile
  .at-sendfile {
  padding-left: 20px;
  padding-right: 20px;
}

.wpguppy-rtl .at-grp_receiver_msg + .at-message_time {
  padding-right: 54px;
  padding-left: 0;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message_gallery
  > .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  .at-messageoption,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  > .at-messageoption {
  right: auto;
  left: 18px;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message-qoute_content {
  padding-left: 14px;
}

.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message-qoute_content
  .vue2leaflet-map,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext
  > div:not(.at-messageoption) {
  margin-left: 0;
}

.wpguppy-rtl .at-groupuserbar_profile {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.wpguppy-rtl .at-group-user-status {
  margin-right: 10px;
  margin-left: 5px;
}

.wpguppy-rtl .at-makeadmin {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .at-makeadmin-check:before {
  margin-right: 0;
  margin-left: 4px;
}

.wpguppy-rtl .at-groupuserbar_content:after {
  left: auto;
  right: 14px;
}

.wpguppy-rtl .at-groupuserbar_content {
  padding-right: 52px !important;
  padding-left: 14px !important;
}

.wpguppy-rtl .at-btnlist .at-groupuserbar_content {
  padding-left: 0 !important;
  padding-right: 34px !important;
}

.wpguppy-rtl .at-btnlist .at-groupuserbar_content:after {
  right: 0;
  left: auto;
}

.wpguppy-rtl .at-popuptitle > a {
  margin-right: auto;
  margin-left: -4px;
}

.wpguppy-rtl .at-userinfo_title_name p span a {
  display: inline-block;
}

.wpguppy-rtl .at-remove {
  margin: 0;
  margin-right: auto;
}

.wpguppy-rtl .at-groupuser_img img + img {
  margin-left: 0;
  margin-right: auto;
}

.wpguppy-rtl.at-chat480 .at-sendfile .at-messageoption,
.wpguppy-rtl.at-chat480 .at-sendpdffile .at-messageoption,
.wpguppy-rtl.at-chat480 .at-video-message .at-messageoption {
  right: auto;
}

.wpguppy-rtl .at-grp_receiver_msg .at-message-qoute {
  justify-content: flex-start;
}

.wpguppy-rtl .at-select:before {
  left: 20px;
  right: auto;
}

.wpguppy-rtl .at-userchat_tab a .at-userchat_tab-noti {
  margin-left: 0;
  margin-right: 12px;
}

.wpguppy-rtl .at-replay_msg .at-sendmsg {
  margin-left: 0;
  margin-right: 10px;
}

.wpguppy-rtl .at-floatimglist .at-infotolltip {
  left: 100%;
  right: auto;
  margin-right: 0;
  margin-left: 15px;
}

.wpguppy-rtl .at-floatimglist > li > a > i {
  right: auto;
  left: -3px;
}

.wpguppy-rtl .at-infotolltip em {
  border-radius: 4px 4px 4px 0;
}

.wpguppy-rtl .at-floatimglist .at-infotolltip:after {
  transform: rotate(-180deg);
  left: -5px;
  right: auto;
}

.wpguppy-rtl .at-chat_sidebar_footer a .at-notify {
  left: auto;
  right: 100%;
  margin-right: -10px;
}

.swal2-rtl.swal2-container .swal2-close {
  right: auto;
  left: 20px;
}

.wpguppy-rtl .at-floatchatdropdown .at-chat_sidebarsettingcontent {
  text-align: right;
}

.wpguppy-rtl .at-chatsidebar-widgetclose.at-chatsidebar_float {
  transform: translateX(-150%);
}

.wpguppy-rtl .at-chatfloatholder {
  right: auto;
  left: 44px;
}

.wpguppy-rtl .at-closechat {
  left: auto;
  right: -70px;
}

.wpguppy-rtl .at-userchat_tab a i {
  margin-right: 0;
  margin-left: 12px;
}

.wpguppy-rtl .at-userbar_profile .at-userstatus:before {
  margin: 0;
}

.wpguppy-rtl .at-floatchat_content .at-replay_msg > div {
  right: 0 !important;
  left: auto !important;
}

.wpguppy-rtl .at-floatchat {
  right: auto;
  left: 500px;
}

.wpguppy-rtl .at-floatchat + .at-floatchat {
  left: 870px;
  right: auto;
}

.wpguppy-rtl .at-floatchat + .at-floatchat + .at-floatchat {
  left: 1240px;
  right: auto;
}

.wpguppy-rtl .at-floatchat_title figure {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-singleimg .at-userstatus {
  right: auto;
  left: -3px;
}

.wpguppy-rtl .at-floatchat_title h2 {
  padding-right: 0;
  padding-left: 20px;
}

.wpguppy-rtl .at-floatchat_title-right {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .at-floatchat_title-right .at-floatchatdropdownholder ~ a {
  margin-right: 16px;
  margin-left: 0;
}

.wpguppy-rtl .at-floatchatdropdown {
  right: auto;
  left: -35px;
}

.wpguppy-rtl .at-floatchatdropdown:before {
  right: auto;
  left: 42px;
}

.wpguppy-rtl .at-chat_sidebarsettingcontent.at-openmsg a i {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-floatchat_content .at-messageoption {
  right: auto;
  left: 12px;
}

.wpguppy-rtl .at-floatchat_content .at-messagetext {
  padding-right: 16px;
  padding-left: 35px;
}

.wpguppy-rtl .at-singleimg .at-userstatus:before {
  margin: 0;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-190
  .at-messageoption_list,
.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-270
  .at-messageoption_list {
  right: auto;
}

.wpguppy-rtl .at-floatchat_content .at-sendfile {
  padding-right: 10px;
  padding-left: 35px;
}

.wpguppy-rtl .at-floatchat_content .at-sendfile > i {
  margin-left: 16px;
}

.wpguppy-rtl .at-floatchat_content .at-video-message .at-messageoption {
  left: 23px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-message_gallery
  .at-messageoption:before {
  left: -7px;
}

.wpguppy-rtl .at-floatchat_content .at-message_gallery .at-messageoption {
  left: 17px;
}

.wpguppy-rtl .at-floatchat_content .at-locationmap .at-messageoption {
  left: 24px;
}

.wpguppy-rtl .at-floatchat_content .at-message_sender .at-message {
  border-radius: 10px 10px 10px 0;
}

.wpguppy-rtl .at-postuserimg {
  right: 3px;
  left: 0;
}

.wpguppy-rtl .at-userpost_profile + .at-userbar_title {
  margin-right: 15px;
  margin-left: 0;
}

.wpguppy-rtl .at-userpost_profile img:not(.at-postuserimg) {
  margin-right: auto;
  margin-left: 0;
}

.wpguppy-rtl .at-floatchat_content .operate {
  padding-right: 0;
  padding-left: 20px;
}

.wpguppy-rtl .at-floatchat_content .iconfont + .iconfont {
  margin-left: 0;
  margin-right: 3px;
  padding: 0;
}

.wpguppy-rtl .at-floatchat_content .operate > span:not(.iconfont) {
  padding-left: 0 !important;
  padding-right: 6px !important;
}

.wpguppy-rtl .at-floatchat_content .at-deletemsg i {
  margin-left: 8px;
  margin-right: 0;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messagetext {
  padding-right: 16px;
  padding-left: 34px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-message
  > h5 {
  padding-left: 0;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-messageoption {
  left: 10px;
}

.wpguppy-rtl .at-floatchat_content .at-message {
  border-radius: 10px;
  border-bottom-right-radius: 0;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  + .at-message_time {
  padding-right: 0;
}

.wpguppy-rtl .at-message_sender .at-grp_receiver_msg + .at-message_time {
  padding-right: 0;
  padding-left: 54px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-video-message
  > .at-messageoption {
  left: 12px;
}

.at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-sendpdffile
  .at-sendfile,
.wpguppy-rtl
  .at-messages:not(.at-message_sender)
  .at-grp_receiver_msg
  .at-sendfile {
  padding-right: 10px;
  padding-left: 35px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-110
  .at-messageoption_list,
.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-190
  .at-messageoption_list,
.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-270
  .at-messageoption_list {
  right: auto;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-110
  .at-messageoption_list {
  left: -140px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-190
  .at-messageoption_list {
  left: -60px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages:not(.at-message_sender)
  .at-chat-msg-270
  .at-messageoption_list {
  left: 0;
}

.wpguppy-rtl .at-multipleimg img {
  margin-right: -3px;
  margin-left: 0;
}

.wpguppy-rtl .at-multipleimg img ~ img {
  margin-right: -17px;
  margin-left: 0;
}

.wpguppy-rtl .at-multipleimg span {
  margin-right: -18px;
  margin-left: 0;
}

.wpguppy-rtl .at-floatchat_content .at-message .at-reply-images {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.wpguppy-rtl .at-floatchat_content .at-replay_message {
  margin-right: 0;
  margin-left: 0;
}

.wpguppy-rtl .at-floatchat_content .at-remove-quotes {
  padding-right: 4px;
  padding-left: 0;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages
  .at-reply-images
  + .at-messageoption {
  right: auto;
  left: 10px;
  top: -15px;
}

.wpguppy-rtl
  .at-floatchat_content
  .at-messages.at-message_sender
  .at-reply-images
  + .at-messageoption {
  top: 10px;
}

.wpguppy-rtl .at-widgetreply .at-sendmsg-responsive {
  margin-left: 0;
  margin-right: 10px;
}

.wpguppy-rtl .at-whatsappbtn svg {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-send {
  margin-left: 0;
  margin-right: 10px;
}

.wpguppy-rtl .at-floatchat_titlev2 > img {
  margin-right: 0;
  margin-left: 10px;
}

.wpguppy-rtl .at-whatsappfloat .at-floatchat_title-right a ~ a {
  margin-left: 0;
  margin-right: 16px;
}

.wpguppy-rtl .at-whatsappchat .at-closechat {
  margin-left: 0;
  margin-right: auto;
}

.wpguppy-rtl .at-chatsidebar-widgetclose ~ .at-chatfloatholder .at-floatchat {
  right: auto;
  left: 165px;
}

.wpguppy-rtl .at-userlist_tab {
  padding-right: 70px;
  padding-left: 0;
}

.wpguppy-rtl .at-userlist_tab .at-userlist_tab.active {
  padding-right: 0;
  padding-left: 0;
}

.wpguppy-rtl .at-groupuserbar {
  margin-right: 0;
}

.wpguppy-rtl .at-groupuserbar_content h6 {
  margin-right: 0;
  margin-left: 0;
}

.wpguppy-rtl .at-deletecheck {
  width: auto;
}

.wpguppy-rtl .at-groupuserbarvtwo .at-groupuserbar_content:after {
  left: auto;
  right: 0;
}

.wpguppy-rtl .at-groupuserbarvtwo .at-groupuserbar_content {
  padding-right: 31px !important;
  padding-left: 10px !important;
}

.at-admin-chat.wpguppy-rtl .at-chat_sidebar_footer li.active {
  box-shadow: inset -3px 0 0 var(--primguppycolor), inset 0 -1px 0 #eee;
}

.wpguppy-rtl .at-chat_sidebar {
  border-left: 1px solid #eee;
}

.wpguppy-rtl .at-nestedsetting > li.active a:after {
  border-left: 0;
}

.at-admin-chat.wpguppy-rtl .at-chat_sidebar_footer {
  border-left: 1px solid #eee;
}

.at-admin-chat.wpguppy-rtl .at-chatsidebar_float .at-chat_sidebar_footer {
  border-left: 0 !important;
}

.at-user-chat.wpguppy-rtl .at-dropboxv2 .at-viewuploadimg {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.wpguppy-rtl .at-dropboxv2 .at-viewuploadimg .at-uploadarea h6 {
  text-align: right;
}

.wpguppy-rtl .at-chat_sidebar_footer li .at-infotolltip {
  right: 59px;
  bottom: 0;
  left: auto;
}

.wpguppy-rtl .at-chat_sidebar_footer li .at-infotolltip:after {
  right: -8px;
  left: auto;
  border-left: 10px solid var(--secguppycolor);
  border-top: 14px solid transparent;
  border-right: 0 solid var(--secguppycolor);
  border-bottom: 18px solid transparent;
  top: 0;
}

.at-admin-chat.wpguppy-rtl .at-chat_sidebar_footer > li.active {
  border-left: 0;
}

.wpguppy-rtl .at-btn.at-btnplain i {
  margin-right: 6px;
  margin-left: 0;
}

@media (max-width: 1280px) {
  .wpguppy-rtl .at-floatchat {
    left: 420px;
  }

  .wpguppy-rtl .at-floatchat + .at-floatchat {
    left: 790px;
  }
}

@media (max-width: 991px) {
  .wpguppy-rtl .at-floatchat {
    left: 395px;
  }
}

.at-messanger-chat.wpguppy-rtl .at-userlist_tab .at-userlist_tab {
  padding-left: 0;
  padding-right: 0;
}

.wpguppy-rtl .at-btnred i {
  margin: 0 10px 0 0;
}
