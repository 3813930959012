

/** TABLE OF CONTENTS
------------------------------------------------------------/
1 - Abstracts
        1.1 - # Sass Variables
        1.2 - # Sass Functions
        1.3 - # Sass Placeholders

2 - Base
        2.1 - # Reset/normalize
        2.2 - # Typography rules
        2.3 - # Keyframes
        2.4 - # Globalclasses
        2.5 - # Socialicon

3 - Components
        3.1 - Buttons
        3.2 - Form
4 - layout
        4.1 - Header
        4.2 - Footer
5 - pages
        5.1 - index
        5.2 - indexv2
        5.3 - blog detail
        5.4 - search listing
        5.5 - how it work
        5.6 - package
        5.7 - profile setting
        5.8 - tutor detail
        5.8 - login
6 - Responsive
        6.1 - Responsive Sass file
/// END TABLE OF CONTENTS **/
/** ====== Sass Variables ====== **/
/** --- Solid color variables --- **/
/** --- opacity color variables --- **/
/** --- shadows variables --- **/
/** --- theme fonts variables --- **/
/** --- radius variables --- **/
/** --- theme color variables --- **/
/** ====== Sass function ====== **/
/** ====== Sass Placeholder ====== **/
/** --- Transition placeholder --- **/

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Outfit:wght@400;500;600;700&family=Gochi+Hand&display=swap");
.tu-posttag li a, .tu-serviceslist > li > a, .tu-accordionwrap i[aria-expanded=true]::before,
.tu-expinfo i[aria-expanded=true]::before,
.tu-faqswrap i[aria-expanded=true]::before, .tu-thumbnails_content > i, .tu-thumbnails_content figure, .tu-thumbnails_content, .tu-thumbnails_action, .tu-side-tabs .nav-item .nav-link::before, .tu-labels li span a, .tu-labels li span, .tu-planlist, .tu-listinginfo, .tu-readmorebtn, .tu-filter-btn a, .tu-articleitem, .tu-popsearchitem li::after, .tu-popsearchitem li a, .tu-featureitem, .tu-eduplatform a, .tu-categories_title::after, .tu-categories_title, .tu-categories_content, .tu-splidearrow .splide__arrow, .tu-splidedots .splide__pagination__page, .tu-instructors_footer-right a, .tu-instructors, .tu-footercopyright_list li a, .tu-footerlist li a em, .tu-footerlist li a, .menu-item-has-children > a:after, .sub-menu li a, .tu-navbar .navbar-collapse .navbar-nav > .nav-item > a::before, .tu-check label:before, .select2-container--default .select2-selection--single, .select2-results__option, .tu-primbtn::before,
.tu-secbtn::before,
.tu-yellowbtn::before,
.tu-primbtn-lg::before,
.tu-primbtn-sm::before,
.tu-primbtn-icon::before, .tu-primbtn,
.tu-secbtn,
.tu-yellowbtn,
.tu-primbtn-lg,
.tu-primbtn-sm,
.tu-primbtn-icon, .tu-pagination ul li a, .tu-vimeov2 i, .tu-behancev2 i, .tu-youtubev2 i, .tu-dribbblev2 i, .tu-linkedinv2 i, .tu-twitchv2 i, .tu-twitterv2 i, .tu-facebookv2 i, .tu-socialmedia a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/** --- Transition V2 placeholder --- **/
/** --- flex placeholder --- **/
.tu-explore-content, .tu-posttag, .tu-my-ratingholder, .tu-rating-stars, .tu-syncthumbnail__content, .tu-Joincommunity, .tu-listinfo li span, .tu-listinfo li, .tu-listinfo, .tu-featureinclude li, .tu-serviceslist, .tu-icanteach > li, .tu-branchdetail li, .tu-nav-tabs .nav-item .nav-link, .tu-profilelinksbtn, .tu-languages, .tu-thumbnails, .tu-btnarea-two, .tu-status-filter, .tu-labels, .tu-boxsmtitle, .tu-box, .tu-boxitem .tu-btnrea, .tu-boxitem, .tu-popularplantop, .tu-planperks li span, .tu-plandetail h3, .tu-pricinglist, .tu-howit-steps_info, .tu-iconheart, .tu-service-list li span, .tu-service-list, .tu-listinginfo-img, .tu-listinginfo_btn, .tu-listinginfo_title, .tu-filterbtns, .tu-searchtags, .tu-sort, .tu-listview, .tu-commentright, .tu-coomentareaauth, .tu-commentlist, .tu-articleauth, .tu-articleauthor, .tu-taglist, .tu-sorttags, .tu-blogfeatures, .tu-blogiteminfo, .tu-sortarea, .tu-blogtitle, .tu-taglinks, .tu-postgallary, .tu-commenteditem, .tu-categorieslist li p, .tu-popsearchitem, .tu-popularsearches, .tu-authorname h5, .tu-authordetail, .tu-categories_title, .tu-categories, .tu-rating, .tu-instructors_footer, .tu-instructors_service-list, .tu-stats, .tu-brand .tu-maintitle h4, .tu-brand_list, .tu-banner_list, .tu-footercopyright_list, .tu-footercopyright_content, .tu-footerdevice, .tu-footerlistholder, .tu-footerlist, .sub-menu li, .tu-afterlogin, .tu-checksm input[type=checkbox] + label .tu-featureRating, .tu-inputicon,
.tu-inputiconbtn, .tu-hasinputicon a, .tu-hasinputicon, .select2-container--default .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-selection--multiple .select2-selection__rendered, .select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple, .tu-righticon, .tu-select, .tu-calendar, .form-group, .form-group-wrap, .tu-themeform__wrap, .tu-pagination ul li a, .tu-pagination ul, .tu-pagination, .tu-socialmedia {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/** --- Heading placeholder --- **/
.tu-bannerinfo, .tu-banner_title > a, h1 {
  font: 700 3.25rem / 1.1153846154em "Outfit", sans-serif;
}

h2 {
  font: 700 2.25rem / 1.2777777778em "Outfit", sans-serif;
}

.tu-login-left_title span, .tu-plandetail h3, h3 {
  font: 700 1.75rem / 1.3571428571em "Outfit", sans-serif;
}

.tu-startingrate h4, .tu-plandetail h4, h4 {
  font: 700 1.375rem / 1.3636363636em "Outfit", sans-serif;
}

.tu-reviews label, .modal-header h5, .tu-commentauthor h6 span, .tu-quotoauthor a, .tu-taglinks li a, .litepicker .container__months .month-item-header div > .month-item-name, .litepicker .container__months .month-item-header div > .month-item-year, h5 {
  font: 700 1.125rem / 1.4444444444em "Outfit", sans-serif;
}

.tu-lost-password a, .tu-btn-signup, .tu-optioanl-or span, .tu-userurl > a, .tu-nav-tabs .nav-item .nav-link span, .tu-tutorreview li > span > em, .tu-tutorreview li > span i > span, .tu-tutorreview li > span i > em span, .tu-tutorreview li > span i > em, .tu-tutorreview li > span, .tu-popularplantop h6, .tu-filterbtns .tu-sb-sliver, .tu-footercopyright_list li a, .menu-item-has-children > a, .tu-navbar .navbar-collapse .navbar-nav > .nav-item > a, .tu-actionselect > span, .tu-label, .tu-primbtn span,
.tu-secbtn span,
.tu-yellowbtn span,
.tu-primbtn-lg span,
.tu-primbtn-sm span,
.tu-primbtn-icon span, .tu-primbtn,
.tu-secbtn,
.tu-yellowbtn,
.tu-primbtn-lg,
.tu-primbtn-sm,
.tu-primbtn-icon, .litepicker .container__days .day-item, .litepicker .container__months .month-item-weekdays-row > div, h6 {
  font: 700 1rem / 1.625em "Outfit", sans-serif;
}

/** --- Sub title placeholder --- **/
.tu-posttag li a, .tu-tippytooltip span, .tu-Joincommunity_content p, .tu-serviceslist > li > a, .tu-branchdetail li span, .tu-showmore, .tu-labels li span, .tu-boxsmtitle a, .tu-step-tag, .tu-listinginfo_service > h6, .tu-rangeslider span, .tu-readmorebtn, .tu-searchtags li span, .tu-articleauth span, .tu-articleauthor h6, .tu-taglinksm li a, .tu-taglist li a, .tu-commentdescp a h6, .tu-resultperson h6, .tu-primbtn em,
.tu-secbtn em,
.tu-yellowbtn em,
.tu-primbtn-lg em,
.tu-primbtn-sm em,
.tu-primbtn-icon em, .tu-pagination ul li a {
  font: 400 0.875rem / 1.5714285714em "Outfit", sans-serif;
}

.tu-commentdescp span {
  font: 400 0.8125rem / 1.6923076923em "Outfit", sans-serif;
}

.tu-featuretag, .tu-menutag, .tu-tag {
  font: 400 0.625rem / 1.6em "Outfit", sans-serif;
}

/** --- Paragraph placeholder --- **/
.tu-banner_title > p {
  font: 400 1.125rem / 1.5555555556em "Open Sans", sans-serif;
}

.tu-listinfo li h6 em, .tu-listinfo li a em, .tu-listinfo li h6, .tu-listinfo li a, .tu-tabswrapper > .tu-description p, .tu-protutordetail h5, .tu-alertcontent p, .tu-planperks li span em, .tu-pricingtop p, .tu-aside-holder .tu-placeholder span, .tu-popsearchitem li a, .tu-sucesstor_title blockquote, .tu-check label span, body {
  font: 400 1rem / 1.625em "Open Sans", sans-serif;
}

.tu-linkheart, .tu-languages > li, .tu-side-tabs .nav-item .nav-link > span, .tu-plandetail p, .tu-plandetail span, .tu-plandetail h3 span, .tu-listing-location > span, .tu-listinginfo_description > p, .tu-checksm .tu-totalreview > span em, .tu-checksm .tu-totalreview > span, .tu-filterselect .tu-placeholder span, .tu-sortby > span, .tu-articleinfo .tu-description p, .tu-blogiteminfo li i span, .tu-sortarea h6, .tu-categorieslist li p span, .tu-categorieslist li p, .tu-authorlist li em, .tu-authorlist li span, .tu-rating h6, .tu-instructors_price span, .tu-stats_info p, .tu-banner_explore p, .tu-footercopyright_content > p, .tu-footerlist li a, .tu-footerdescription, input.tu-showplaceholder::placeholder, .tu-check label, .tox.tox-tinymce, select[required] + .select2 .select2-selection--multiple .select2-search.select2-search--inline::after,
select[required] + .select2 .select2-selection__placeholder::after, .select2-container--default .select2-selection--single .select2-selection__placeholder, .select2-container--default .select2-search--inline .select2-search__field::placeholder, .select2-container--default .select2-search--inline .select2-search__field, .select2-container .select2-selection--single .select2-selection__rendered, .select2-results__option[aria-selected], .tu-placeholder, .form-control::placeholder,
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=datetime]::placeholder,
input[type=datetime-local]::placeholder,
input[type=date]::placeholder,
input[type=month]::placeholder,
input[type=time]::placeholder,
input[type=week]::placeholder,
input[type=number]::placeholder,
input[type=email]::placeholder,
input[type=url]::placeholder,
input[type=search]::placeholder,
input[type=tel]::placeholder,
input[type=color]::placeholder,
select::placeholder,
.uneditable-input::placeholder,
textarea::placeholder, .form-control,
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color],
select,
.uneditable-input,
textarea {
  font: 400 0.9375rem / 1.6em "Open Sans", sans-serif;
}

.tu-unlockfeature h6, .tu-featureinclude li > span i, .tu-startingrate span, .tu-uploadphoto p, .tu-uploadinfo h6, .tu-iconheart span, .tu-listing-location > address, .tu-listing-location > span > em, .tu-listinginfo_price > span, .tu-input-counter em, .tu-input-counter b, .tu-input-counter span, .tu-collapseitem p, .tu-authorname span, .tu-categories_title span, .tu-rating > span, .tu-instructors_title > span, .tu-footercontent p, .tu-footerlist .tu-footerlist-explore a {
  font: 400 0.875rem / 1.7142857143em "Open Sans", sans-serif;
}

.tu-service-list li span, .tu-instructors_service-list span, .tu-instructors_service > p {
  font: 400 0.8125rem / 1.6923076923em "Open Sans", sans-serif;
}

/** --- Readmore btn placeholder --- **/
/** --- Btn hover placeholder --- **/
/** --- Shadow placeholder --- **/
/** --- Border placeholder --- **/
/** --- Theme stars styling --- **/
.tu-stars span,
.tu-stars {
  width: 106px;
  height: 18px;
  line-height: 18px;
  position: relative;
}

.tu-stars span:after,
.tu-stars:before {
  top: 0;
  left: 0;
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Font Awesome 6 Free";
  color: #DDDDDD;
  content: "\f005\f005\f005\f005\f005";
}

.tu-stars span {
  width: 100%;
  overflow: hidden;
  display: block;
}

.tu-stars span:after {
  color: #FFD101;
}

/** ====== Normalize Sass ====== **/
*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  letter-spacing: 0.005em;
}

ul {
  margin-bottom: 0;
}

li {
  display: list-item;
  line-height: 28px;
  list-style: none;
}

ul ul {
  margin-left: 20px;
}

ol {
  float: none;
  list-style: decimal;
  padding-left: 15px;
}
ol li {
  list-style: decimal;
  width: 100%;
}

ol ol {
  margin-left: 20px;
}

figure {
  margin-bottom: 6px;
  position: relative;
}

a,
a:hover {
  text-decoration: none;
  color: #1DA1F2;
  outline: none;
}

p {
  margin: 0 0 20px;
  letter-spacing: 0.005em;
  line-height: 1.625em;
}

img {
  height: auto;
  max-width: 100%;
}

iframe {
  border: none;
}

.wp-caption.alignnone {
  width: 100%;
}

button {
  border: none;
}

/** --- thme table style --- **/
table {
  background-color: transparent;
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
  border-top: 1px solid #DDDDDD;
  border: 1px solid #DDDDDD;
  line-height: 2.5;
  padding-left: 3px;
  text-align: center;
  vertical-align: top;
}
table thead tr th {
  border-top: 1px solid #DDDDDD;
  text-align: center;
  text-transform: capitalize;
}
table > thead > tr > th {
  border-bottom: 2px solid #DDDDDD;
  vertical-align: bottom;
}

th {
  text-align: left;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #DDDDDD;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0;
}

table > tbody + tbody {
  border-top: 2px solid #DDDDDD;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 6px 10px;
}

p ins {
  color: #0A0F26;
}

dl dd {
  margin-left: 20px;
}

address {
  font-style: italic;
}

/** ====== Sass typography ====== **/
body {
  color: #1C1C1C;
  letter-spacing: 0.005em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 8px;
  color: #1C1C1C;
  letter-spacing: 0.005em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #1C1C1C;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #1C1C1C;
}

/** ====== Social icon ====== **/
.tu-socialmedia {
  gap: 10px;
  padding-left: 0;
}
.tu-socialmedia li {
  list-style: none;
  display: flex;
}
.tu-socialmedia li:hover a, .tu-socialmedia li.active a {
  background-color: #ffffff;
  border-color: #ffffff;
}
.tu-socialmedia li:hover.tu-facebookv3 > i, .tu-socialmedia li:hover.tu-facebookv3 > a, .tu-socialmedia li.active.tu-facebookv3 > i, .tu-socialmedia li.active.tu-facebookv3 > a {
  color: #3b5999;
}
.tu-socialmedia li:hover.tu-facebook-messengerv3 > a, .tu-socialmedia li:hover.tu-facebook-messengerv3 > i, .tu-socialmedia li.active.tu-facebook-messengerv3 > a, .tu-socialmedia li.active.tu-facebook-messengerv3 > i {
  color: #0084ff;
}
.tu-socialmedia li:hover.tu-twitterv3 > a, .tu-socialmedia li:hover.tu-twitterv3 > i, .tu-socialmedia li.active.tu-twitterv3 > a, .tu-socialmedia li.active.tu-twitterv3 > i {
  color: #55acee;
}
.tu-socialmedia li:hover.tu-twitchv3 > a, .tu-socialmedia li:hover.tu-twitchv3 > i, .tu-socialmedia li.active.tu-twitchv3 > a, .tu-socialmedia li.active.tu-twitchv3 > i {
  color: #9147ff;
}
.tu-socialmedia li:hover.tu-linkedinv3 > a, .tu-socialmedia li:hover.tu-linkedinv3 > i, .tu-socialmedia li.active.tu-linkedinv3 > a, .tu-socialmedia li.active.tu-linkedinv3 > i {
  color: #0077b5;
}
.tu-socialmedia li:hover.tu-skypev3 > a, .tu-socialmedia li:hover.tu-skypev3 > i, .tu-socialmedia li.active.tu-skypev3 > a, .tu-socialmedia li.active.tu-skypev3 > i {
  color: #00aff0;
}
.tu-socialmedia li:hover.tu-dropboxv3 > a, .tu-socialmedia li:hover.tu-dropboxv3 > i, .tu-socialmedia li.active.tu-dropboxv3 > a, .tu-socialmedia li.active.tu-dropboxv3 > i {
  color: #007ee5;
}
.tu-socialmedia li:hover.tu-vimeov3 > a, .tu-socialmedia li:hover.tu-vimeov3 > i, .tu-socialmedia li.active.tu-vimeov3 > a, .tu-socialmedia li.active.tu-vimeov3 > i {
  color: #1ab7ea;
}
.tu-socialmedia li:hover.tu-tumblrv3 > i, .tu-socialmedia li:hover.tu-tumblrv3 > a, .tu-socialmedia li.active.tu-tumblrv3 > i, .tu-socialmedia li.active.tu-tumblrv3 > a {
  color: #34465d;
}
.tu-socialmedia li:hover.tu-yahoov3 > a, .tu-socialmedia li:hover.tu-yahoov3 > i, .tu-socialmedia li.active.tu-yahoov3 > a, .tu-socialmedia li.active.tu-yahoov3 > i {
  color: #410093;
}
.tu-socialmedia li:hover.tu-googleplusv3 > a, .tu-socialmedia li:hover.tu-googleplusv3 > i, .tu-socialmedia li:hover.tu-googlev3 > a, .tu-socialmedia li:hover.tu-googlev3 > i, .tu-socialmedia li.active.tu-googleplusv3 > a, .tu-socialmedia li.active.tu-googleplusv3 > i, .tu-socialmedia li.active.tu-googlev3 > a, .tu-socialmedia li.active.tu-googlev3 > i {
  color: #dd4b39;
}
.tu-socialmedia li:hover.tu-pinterestpv3 > a, .tu-socialmedia li:hover.tu-pinterestpv3 > i, .tu-socialmedia li.active.tu-pinterestpv3 > a, .tu-socialmedia li.active.tu-pinterestpv3 > i {
  color: #bd081c;
}
.tu-socialmedia li:hover.tu-youtubev3 > a, .tu-socialmedia li:hover.tu-youtubev3 > i, .tu-socialmedia li.active.tu-youtubev3 > a, .tu-socialmedia li.active.tu-youtubev3 > i {
  color: #cd201f;
}
.tu-socialmedia li:hover.tu-stumbleuponv3 > a, .tu-socialmedia li:hover.tu-stumbleuponv3 > i, .tu-socialmedia li.active.tu-stumbleuponv3 > a, .tu-socialmedia li.active.tu-stumbleuponv3 > i {
  color: #eb4924;
}
.tu-socialmedia li:hover.tu-redditv3 > a, .tu-socialmedia li:hover.tu-redditv3 > i, .tu-socialmedia li.active.tu-redditv3 > a, .tu-socialmedia li.active.tu-redditv3 > i {
  color: #ff5700;
}
.tu-socialmedia li:hover.tu-quorav3 > a, .tu-socialmedia li:hover.tu-quorav3 > i, .tu-socialmedia li.active.tu-quorav3 > a, .tu-socialmedia li.active.tu-quorav3 > i {
  color: #b92b27;
}
.tu-socialmedia li:hover.tu-yelpv3 > a, .tu-socialmedia li:hover.tu-yelpv3 > i, .tu-socialmedia li.active.tu-yelpv3 > a, .tu-socialmedia li.active.tu-yelpv3 > i {
  color: #af0606;
}
.tu-socialmedia li:hover.tu-soundcloudv3 > a, .tu-socialmedia li:hover.tu-soundcloudv3 > i, .tu-socialmedia li.active.tu-soundcloudv3 > a, .tu-socialmedia li.active.tu-soundcloudv3 > i {
  color: #f30;
}
.tu-socialmedia li:hover.tu-whatsappv3 > a, .tu-socialmedia li:hover.tu-whatsappv3 > i, .tu-socialmedia li.active.tu-whatsappv3 > a, .tu-socialmedia li.active.tu-whatsappv3 > i {
  color: #25d366;
}
.tu-socialmedia li:hover.tu-wechatv3 > a, .tu-socialmedia li:hover.tu-wechatv3 > i, .tu-socialmedia li.active.tu-wechatv3 > a, .tu-socialmedia li.active.tu-wechatv3 > i {
  color: #09b83e;
}
.tu-socialmedia li:hover.tu-instagramv3 > a, .tu-socialmedia li:hover.tu-instagramv3 > i, .tu-socialmedia li.active.tu-instagramv3 > a, .tu-socialmedia li.active.tu-instagramv3 > i {
  color: #e4405f;
}
.tu-socialmedia li:hover.tu-dribbblev3 > a, .tu-socialmedia li:hover.tu-dribbblev3 > i, .tu-socialmedia li.active.tu-dribbblev3 > a, .tu-socialmedia li.active.tu-dribbblev3 > i {
  color: #ea4c89;
}
.tu-socialmedia li:hover.tu-flickrv3 > a, .tu-socialmedia li:hover.tu-flickrv3 > i, .tu-socialmedia li.active.tu-flickrv3 > a, .tu-socialmedia li.active.tu-flickrv3 > i {
  color: #ff0084;
}
.tu-socialmedia li:hover.tu-rssv3 > a, .tu-socialmedia li:hover.tu-rssv3 > i, .tu-socialmedia li.active.tu-rssv3 > a, .tu-socialmedia li.active.tu-rssv3 > i {
  color: #f60;
}
.tu-socialmedia li:hover.tu-behancev3 > a, .tu-socialmedia li:hover.tu-behancev3 > i, .tu-socialmedia li.active.tu-behancev3 > a, .tu-socialmedia li.active.tu-behancev3 > i {
  color: #131418;
}
.tu-socialmedia li:hover.tu-foursquarev3 > a, .tu-socialmedia li:hover.tu-foursquarev3 > i, .tu-socialmedia li.active.tu-foursquarev3 > a, .tu-socialmedia li.active.tu-foursquarev3 > i {
  color: #f94877;
}
.tu-socialmedia a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: #DDDDDD;
}

.tu-facebook > i,
.tu-facebook > a {
  color: #3b5999;
}

.tu-facebook-messenger > a,
.tu-facebook-messenger > i {
  color: #0084ff;
}

.tu-twitter > a,
.tu-twitter > i {
  color: #55acee;
}

.tu-twitch > a,
.tu-twitch > i {
  color: #9147ff;
}

.tu-linkedin > a,
.tu-linkedin > i {
  color: #0077b5;
}

.tu-skype > a,
.tu-skype > i {
  color: #00aff0;
}

.tu-dropbox > a,
.tu-dropbox > i {
  color: #007ee5;
}

.tu-vimeo > a,
.tu-vimeo > i {
  color: #1ab7ea;
}

.tu-tumblr > i,
.tu-tumblr > a {
  color: #34465d;
}

.tu-yahoo > a,
.tu-yahoo > i {
  color: #410093;
}

.tu-googleplus > a,
.tu-googleplus > i,
.tu-google > a,
.tu-google > i {
  color: #dd4b39;
}

.tu-pinterestp > a,
.tu-pinterestp > i {
  color: #bd081c;
}

.tu-youtube > a,
.tu-youtube > i {
  color: #cd201f;
}

.tu-stumbleupon > a,
.tu-stumbleupon > i {
  color: #eb4924;
}

.tu-reddit > a,
.tu-reddit > i {
  color: #ff5700;
}

.tu-quora > a,
.tu-quora > i {
  color: #b92b27;
}

.tu-yelp > a,
.tu-yelp > i {
  color: #af0606;
}

.tu-soundcloud > a,
.tu-soundcloud > i {
  color: #f30;
}

.tu-whatsapp > a,
.tu-whatsapp > i {
  color: #25d366;
}

.tu-wechat > a,
.tu-wechat > i {
  color: #09b83e;
}

.tu-instagram > a,
.tu-instagram > i {
  color: #e4405f;
}

.tu-dribbble > a,
.tu-dribbble > i {
  color: #ea4c89;
}

.tu-flickr > a,
.tu-flickr > i {
  color: #ff0084;
}

.tu-rss > a,
.tu-rss > i {
  color: #f60;
}

.tu-behance > a,
.tu-behance > i {
  color: #131418;
}

.tu-copy > a,
.tu-copy > i {
  color: #999999;
}

.tu-foursquare > a,
.tu-foursquare > i {
  color: #f94877;
}

.tu-facebookv2:hover i {
  color: #1877f2;
}

.tu-twitterv2:hover i {
  color: #1d9bf0;
}

.tu-twitchv2:hover i {
  color: #9147ff;
}

.tu-linkedinv2:hover i {
  color: #0a66c2;
}

.tu-dribbblev2:hover i {
  color: #ea4c89;
}

.tu-youtubev2:hover i {
  color: #cd201f;
}

.tu-behancev2:hover i {
  color: #131418;
}

.tu-vimeov2:hover i {
  color: #1ab7ea;
}

/** ====== Sass keyframes ====== **/
/** --- theme loader keyframes --- **/
@-webkit-keyframes load1 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load1 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/** ====== global classes ====== **/
.tu-main-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.tu-tag {
  padding: 1px 8px;
  background-color: #F97316;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px 10px 0px 10px;
  display: inline-block;
}

.tu-bggreen {
  background-color: #22C55E !important;
}

.tu-maintitle {
  padding-bottom: 30px;
}
.tu-maintitle.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tu-maintitle img {
  margin-bottom: 20px;
}
.tu-maintitle h4 {
  font-weight: 400;
  margin-bottom: 0;
}
.tu-maintitle h4 span {
  font-weight: 600;
  color: #6A307D;
  padding: 0 6px;
}
.tu-maintitle h2 {
  font-weight: 600;
  margin-bottom: 0;
  max-width: 550px;
}
.tu-maintitle h2 span {
  color: #1DA1F2;
}
.tu-maintitle h2 + p {
  padding-top: 20px;
}
.tu-maintitle p {
  max-width: 800px;
  margin-bottom: 0;
}
.tu-maintitle > a {
  margin-top: 30px;
}

.tu-mainbtn {
  text-align: center;
  padding-top: 40px;
}

.tu-green {
  color: #22C55E !important;
}

.tu-blue {
  color: #1DA1F2 !important;
}

.tu-orange {
  color: #F97316 !important;
}

/** --- pagination styling --- **/
.tu-pagination {
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}
.tu-pagination ul {
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: -5px;
  padding-left: 0;
}
.tu-pagination ul li {
  padding: 5px;
  line-height: inherit;
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
}
.tu-pagination ul li a {
  width: 44px;
  height: 44px;
  font-weight: 600;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  border-radius: 4px;
  color: #999999;
  border: 1px solid #eeeeee;
}
.tu-pagination ul li i {
  font-size: 24px;
}
.tu-pagination ul li a:hover, .tu-pagination ul li.active a {
  color: #fff;
  border-color: #6A307D;
  background-color: #6A307D;
}

.tu-pagination-prev {
  padding-right: 45px !important;
}

.tu-pagination-next {
  padding-left: 45px !important;
}

/** --- calendar global styling --- **/
.litepicker .container__days .day-item, .litepicker .container__months .month-item-weekdays-row > div {
  color: #1C1C1C;
}

.litepicker .container__months .month-item-weekdays-row > div {
  box-shadow: inset 0px -1px 0px #DDDDDD;
}

.litepicker .container__months .month-item-header div > .month-item-name, .litepicker .container__months .month-item-header div > .month-item-year {
  font-weight: 600;
}

.litepicker .container__months .month-item-header .button-previous-month > svg, .litepicker .container__months .month-item-header .button-next-month > svg {
  color: #1C1C1C;
  fill: #1C1C1C;
}

.litepicker .container__days .day-item.is-end-date,
.litepicker .container__days .day-item.is-start-date {
  background: #6A307D;
  color: #ffffff;
}

.litepicker .container__days .day-item.is-start-date {
  border-radius: 25px 0px 0px 25px;
}

.litepicker .container__days .day-item.is-end-date {
  border-radius: 0px 25px 25px 0px;
}

.litepicker .container__days .day-item.is-in-range {
  background: #E1D6E5;
}

.litepicker .container__days .day-item:hover {
  box-shadow: inset 0 0 0 2px #6A307D;
  color: #1C1C1C;
  border-radius: 25px;
}

.litepicker .container__months, .litepicker .container__months .month-item {
  width: 337px;
  padding: 0;
}

.litepicker .container__days > div, .litepicker .container__days > a {
  padding: 11px 0;
  min-width: 48px;
}

.litepicker .container__days .day-item.is-today {
  box-shadow: inset 0px -2px 0px #6A307D;
  color: #1C1C1C;
  border-radius: 0;
}

.litepicker .container__months .month-item-header {
  padding: 16px;
}

.litepicker .container__days .day-item.is-start-date.is-end-date {
  border-radius: 25px;
}

/** --- preloader styling --- **/
.tu-preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  background: #fff;
}
.tu-preloader_holder {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  margin: -25px 0 0 -25px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}
.tu-preloader_holder img {
  position: absolute;
  z-index: 1;
  margin: 0 0 0 -6px;
  max-width: 20px;
}

.tu-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  -webkit-animation: load1 0.8s infinite linear;
  animation: load1 0.8s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  background: #6A307D;
  background: -moz-linear-gradient(left, #6A307D 10%, rgba(255, 88, 81, 0) 42%);
  background: -webkit-linear-gradient(left, #6A307D 10%, rgba(255, 88, 81, 0) 42%);
  background: -o-linear-gradient(left, #6A307D 10%, rgba(255, 88, 81, 0) 42%);
  background: -ms-linear-gradient(left, #6A307D 10%, rgba(255, 88, 81, 0) 42%);
  background: linear-gradient(to right, #6A307D 10%, rgba(255, 88, 81, 0) 42%);
}
.tu-loader:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 92%;
  height: 92%;
  content: "";
  margin: auto;
  border-radius: 50%;
  background: #fff;
  position: absolute;
}

/** ====== button components ====== **/
/** --- theme buttons --- **/
.tu-primbtn,
.tu-secbtn,
.tu-yellowbtn,
.tu-primbtn-lg,
.tu-primbtn-sm,
.tu-primbtn-icon {
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-weight: 600;
  color: #ffffff;
  background-color: #6A307D;
  border-radius: 4px;
  padding: 0 24px;
  z-index: 0;
}
.tu-primbtn:hover::before,
.tu-secbtn:hover::before,
.tu-yellowbtn:hover::before,
.tu-primbtn-lg:hover::before,
.tu-primbtn-sm:hover::before,
.tu-primbtn-icon:hover::before {
  opacity: 1;
}
.tu-primbtn::before,
.tu-secbtn::before,
.tu-yellowbtn::before,
.tu-primbtn-lg::before,
.tu-primbtn-sm::before,
.tu-primbtn-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
}
.tu-primbtn span,
.tu-secbtn span,
.tu-yellowbtn span,
.tu-primbtn-lg span,
.tu-primbtn-sm span,
.tu-primbtn-icon span {
  font-weight: 600;
}
.tu-primbtn span + em,
.tu-secbtn span + em,
.tu-yellowbtn span + em,
.tu-primbtn-lg span + em,
.tu-primbtn-sm span + em,
.tu-primbtn-icon span + em {
  padding-left: 10px;
}
.tu-primbtn span + i,
.tu-secbtn span + i,
.tu-yellowbtn span + i,
.tu-primbtn-lg span + i,
.tu-primbtn-sm span + i,
.tu-primbtn-icon span + i {
  margin-left: 10px;
}
.tu-primbtn img,
.tu-secbtn img,
.tu-yellowbtn img,
.tu-primbtn-lg img,
.tu-primbtn-sm img,
.tu-primbtn-icon img {
  margin: 0 10px 0 0;
}
.tu-primbtn em,
.tu-secbtn em,
.tu-yellowbtn em,
.tu-primbtn-lg em,
.tu-primbtn-sm em,
.tu-primbtn-icon em {
  font-style: normal;
  color: #1DA1F2;
}
.tu-primbtn em + i,
.tu-secbtn em + i,
.tu-yellowbtn em + i,
.tu-primbtn-lg em + i,
.tu-primbtn-sm em + i,
.tu-primbtn-icon em + i {
  margin-left: 10px;
}
.tu-primbtn i,
.tu-secbtn i,
.tu-yellowbtn i,
.tu-primbtn-lg i,
.tu-primbtn-sm i,
.tu-primbtn-icon i {
  font-size: 20px;
}
.tu-primbtn i + span,
.tu-secbtn i + span,
.tu-yellowbtn i + span,
.tu-primbtn-lg i + span,
.tu-primbtn-sm i + span,
.tu-primbtn-icon i + span {
  padding-left: 10px;
}
.tu-primbtn[disabled],
.tu-secbtn[disabled],
.tu-yellowbtn[disabled],
.tu-primbtn-lg[disabled],
.tu-primbtn-sm[disabled],
.tu-primbtn-icon[disabled] {
  background-color: #FCFCFC;
  color: #999999;
  border: 0;
  pointer-events: none;
}
.tu-primbtn[disabled] i,
.tu-secbtn[disabled] i,
.tu-yellowbtn[disabled] i,
.tu-primbtn-lg[disabled] i,
.tu-primbtn-sm[disabled] i,
.tu-primbtn-icon[disabled] i {
  color: #999999;
}
.tu-primbtn:hover, .tu-primbtn:focus,
.tu-secbtn:hover,
.tu-secbtn:focus,
.tu-yellowbtn:hover,
.tu-yellowbtn:focus,
.tu-primbtn-lg:hover,
.tu-primbtn-lg:focus,
.tu-primbtn-sm:hover,
.tu-primbtn-sm:focus,
.tu-primbtn-icon:hover,
.tu-primbtn-icon:focus {
  outline: none;
  color: #ffffff;
}

.tu-secbtn {
  padding: 0 22px;
  color: #1C1C1C;
  background-color: #ffffff;
  border: 2px solid #DDDDDD;
}
.tu-secbtn::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: transparent;
}
.tu-secbtn:hover, .tu-secbtn:focus {
  background-color: #ffffff;
  color: #1C1C1C;
}

.tu-primbtn-lg {
  height: 48px;
}

.tu-primbtn-sm {
  height: 32px;
}

button.tu-primbtn, button.tu-primbtn-lg, button.tu-secbtn, button.tu-primbtn-icon {
  cursor: pointer;
}

.tu-primbtn-icon {
  height: 48px;
  width: 48px;
  padding: 0;
}
.tu-primbtn-icon i {
  font-size: 18px;
}

.tu-primbtn-orange {
  background-color: #F97316;
}

.tu-primbtn-gradient {
  background: linear-gradient(105.69deg, #EAB308 0%, #EF4444 43.23%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06), 0px 16px 24px rgba(0, 0, 0, 0.08);
}

.tu-yellowbtn {
  background-color: #FFD101;
  color: #1C1C1C;
}
.tu-yellowbtn::before {
  background-color: transparent;
}
.tu-yellowbtn:hover, .tu-yellowbtn:focus {
  background-color: #FFD101;
  color: #1C1C1C;
}

.tu-menu {
  padding: 0;
  width: 40px;
  border: none;
  outline: none;
  display: none;
  font-size: 24px;
  margin-left: auto;
  line-height: 40px;
  border-radius: 4px;
  background-color: #6A307D;
}
.tu-menu i {
  color: #fff;
  display: block;
  line-height: inherit;
}

.tu-secbtnvtwo {
  border: 2px solid #DDDDDD;
  background-color: #ffffff;
  color: #999999;
  padding: 0 25px;
}
.tu-secbtnvtwo::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.tu-secbtnvtwo:hover, .tu-secbtnvtwo:focus {
  background-color: #ffffff;
  color: #1C1C1C;
}

/** ====== form components ====== **/
/** --- theme form --- **/
.tu-themeform fieldset {
  margin: -10px;
}
.tu-themeform__btn {
  margin-top: 10px;
}

.form-group-wrap {
  width: 100%;
}
.form-group-wrap .tu-label {
  margin-bottom: 10px;
  font-weight: 600;
}

.form-group {
  position: relative;
  width: 100%;
  padding: 10px;
  align-items: center;
  margin-bottom: 0;
}
.form-group .tu-popupbtnarea {
  padding-top: 10px;
}
.form-group .tu-thumbnails {
  padding-top: 20px;
}

.form-group-half {
  width: 50%;
}

.form-group-3half {
  width: 33.3333333333%;
}

.tu-label {
  font-weight: 600;
}

.valid-feedback {
  font: 500 0.875rem, 1.7142857143em "Outfit", sans-serif;
  letter-spacing: 0.5px;
  color: #0A0F26;
  padding-top: 6px;
}

/** --- form control style --- **/
.form-control,
input[type=text],
input[type=password],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color],
select,
.uneditable-input,
textarea {
  padding: 0 19px;
  height: 48px;
  color: #1C1C1C;
  font-weight: 600;
  border: 1.5px solid #e1e1e1;
  border-radius: 4px;
  background-clip: unset;
  background-color: #fff;
  caret-color: #1C1C1C;
}
.form-control::placeholder,
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=datetime]::placeholder,
input[type=datetime-local]::placeholder,
input[type=date]::placeholder,
input[type=month]::placeholder,
input[type=time]::placeholder,
input[type=week]::placeholder,
input[type=number]::placeholder,
input[type=email]::placeholder,
input[type=url]::placeholder,
input[type=search]::placeholder,
input[type=tel]::placeholder,
input[type=color]::placeholder,
select::placeholder,
.uneditable-input::placeholder,
textarea::placeholder {
  color: #999999;
  font-weight: 600;
}
.form-control:placeholder-shown + .tu-placeholder,
input[type=text]:placeholder-shown + .tu-placeholder,
input[type=password]:placeholder-shown + .tu-placeholder,
input[type=datetime]:placeholder-shown + .tu-placeholder,
input[type=datetime-local]:placeholder-shown + .tu-placeholder,
input[type=date]:placeholder-shown + .tu-placeholder,
input[type=month]:placeholder-shown + .tu-placeholder,
input[type=time]:placeholder-shown + .tu-placeholder,
input[type=week]:placeholder-shown + .tu-placeholder,
input[type=number]:placeholder-shown + .tu-placeholder,
input[type=email]:placeholder-shown + .tu-placeholder,
input[type=url]:placeholder-shown + .tu-placeholder,
input[type=search]:placeholder-shown + .tu-placeholder,
input[type=tel]:placeholder-shown + .tu-placeholder,
input[type=color]:placeholder-shown + .tu-placeholder,
select:placeholder-shown + .tu-placeholder,
.uneditable-input:placeholder-shown + .tu-placeholder,
textarea:placeholder-shown + .tu-placeholder {
  display: block;
}
.form-control.is-invalid,
input[type=text].is-invalid,
input[type=password].is-invalid,
input[type=datetime].is-invalid,
input[type=datetime-local].is-invalid,
input[type=date].is-invalid,
input[type=month].is-invalid,
input[type=time].is-invalid,
input[type=week].is-invalid,
input[type=number].is-invalid,
input[type=email].is-invalid,
input[type=url].is-invalid,
input[type=search].is-invalid,
input[type=tel].is-invalid,
input[type=color].is-invalid,
select.is-invalid,
.uneditable-input.is-invalid,
textarea.is-invalid {
  border-color: #EF4444;
}
.form-control.is-invalid:nth-child(n),
input[type=text].is-invalid:nth-child(n),
input[type=password].is-invalid:nth-child(n),
input[type=datetime].is-invalid:nth-child(n),
input[type=datetime-local].is-invalid:nth-child(n),
input[type=date].is-invalid:nth-child(n),
input[type=month].is-invalid:nth-child(n),
input[type=time].is-invalid:nth-child(n),
input[type=week].is-invalid:nth-child(n),
input[type=number].is-invalid:nth-child(n),
input[type=email].is-invalid:nth-child(n),
input[type=url].is-invalid:nth-child(n),
input[type=search].is-invalid:nth-child(n),
input[type=tel].is-invalid:nth-child(n),
input[type=color].is-invalid:nth-child(n),
select.is-invalid:nth-child(n),
.uneditable-input.is-invalid:nth-child(n),
textarea.is-invalid:nth-child(n) {
  color: #EF4444;
}
.form-control.is-valid,
input[type=text].is-valid,
input[type=password].is-valid,
input[type=datetime].is-valid,
input[type=datetime-local].is-valid,
input[type=date].is-valid,
input[type=month].is-valid,
input[type=time].is-valid,
input[type=week].is-valid,
input[type=number].is-valid,
input[type=email].is-valid,
input[type=url].is-valid,
input[type=search].is-valid,
input[type=tel].is-valid,
input[type=color].is-valid,
select.is-valid,
.uneditable-input.is-valid,
textarea.is-valid {
  border-color: #22C55E;
}
.form-control.is-valid:nth-child(n),
input[type=text].is-valid:nth-child(n),
input[type=password].is-valid:nth-child(n),
input[type=datetime].is-valid:nth-child(n),
input[type=datetime-local].is-valid:nth-child(n),
input[type=date].is-valid:nth-child(n),
input[type=month].is-valid:nth-child(n),
input[type=time].is-valid:nth-child(n),
input[type=week].is-valid:nth-child(n),
input[type=number].is-valid:nth-child(n),
input[type=email].is-valid:nth-child(n),
input[type=url].is-valid:nth-child(n),
input[type=search].is-valid:nth-child(n),
input[type=tel].is-valid:nth-child(n),
input[type=color].is-valid:nth-child(n),
select.is-valid:nth-child(n),
.uneditable-input.is-valid:nth-child(n),
textarea.is-valid:nth-child(n) {
  color: #22C55E;
}
.form-control[disabled],
input[type=text][disabled],
input[type=password][disabled],
input[type=datetime][disabled],
input[type=datetime-local][disabled],
input[type=date][disabled],
input[type=month][disabled],
input[type=time][disabled],
input[type=week][disabled],
input[type=number][disabled],
input[type=email][disabled],
input[type=url][disabled],
input[type=search][disabled],
input[type=tel][disabled],
input[type=color][disabled],
select[disabled],
.uneditable-input[disabled],
textarea[disabled] {
  pointer-events: none;
  background: #FCFCFC;
  border-color: #e1e1e1;
}
.form-control[disabled] ~ .tu-placeholder em,
input[type=text][disabled] ~ .tu-placeholder em,
input[type=password][disabled] ~ .tu-placeholder em,
input[type=datetime][disabled] ~ .tu-placeholder em,
input[type=datetime-local][disabled] ~ .tu-placeholder em,
input[type=date][disabled] ~ .tu-placeholder em,
input[type=month][disabled] ~ .tu-placeholder em,
input[type=time][disabled] ~ .tu-placeholder em,
input[type=week][disabled] ~ .tu-placeholder em,
input[type=number][disabled] ~ .tu-placeholder em,
input[type=email][disabled] ~ .tu-placeholder em,
input[type=url][disabled] ~ .tu-placeholder em,
input[type=search][disabled] ~ .tu-placeholder em,
input[type=tel][disabled] ~ .tu-placeholder em,
input[type=color][disabled] ~ .tu-placeholder em,
select[disabled] ~ .tu-placeholder em,
.uneditable-input[disabled] ~ .tu-placeholder em,
textarea[disabled] ~ .tu-placeholder em {
  color: #999999;
}
.form-control:hover, .form-control:focus,
input[type=text]:hover,
input[type=text]:focus,
input[type=password]:hover,
input[type=password]:focus,
input[type=datetime]:hover,
input[type=datetime]:focus,
input[type=datetime-local]:hover,
input[type=datetime-local]:focus,
input[type=date]:hover,
input[type=date]:focus,
input[type=month]:hover,
input[type=month]:focus,
input[type=time]:hover,
input[type=time]:focus,
input[type=week]:hover,
input[type=week]:focus,
input[type=number]:hover,
input[type=number]:focus,
input[type=email]:hover,
input[type=email]:focus,
input[type=url]:hover,
input[type=url]:focus,
input[type=search]:hover,
input[type=search]:focus,
input[type=tel]:hover,
input[type=tel]:focus,
input[type=color]:hover,
input[type=color]:focus,
select:hover,
select:focus,
.uneditable-input:hover,
.uneditable-input:focus,
textarea:hover,
textarea:focus {
  box-shadow: unset;
  border-color: #888888;
}

.form-control-sm {
  height: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  resize: none;
  height: 236px;
  padding-top: 12px;
}

.tu-select, .tu-calendar {
  align-items: center;
  position: relative;
  width: 100%;
}
.tu-select:before, .tu-calendar:before {
  content: "\e92e";
  font-family: icomoon;
  right: 20px;
  z-index: 1;
  position: absolute;
  font-size: 20px;
  line-height: 1;
  pointer-events: none;
  color: #999999;
}
.tu-select select, .tu-calendar select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 40px;
  width: 100%;
}
.tu-select select:focus, .tu-calendar select:focus {
  outline: 0;
}
.tu-select .form-control, .tu-calendar .form-control {
  padding-right: 49px;
}

.tu-calendar:before {
  content: "\e927";
}

.tu-inputicon > *:first-child {
  position: absolute;
  left: 20px;
  font-size: 20px;
  color: #999999;
  z-index: 1;
}
.tu-inputicon .form-control {
  padding-left: 49px;
}
.tu-inputicon .tu-placeholder {
  left: 50px;
}

.tu-inputbtn {
  display: flex;
  align-items: center;
  position: relative;
}
.tu-inputbtn input {
  width: auto;
  flex: auto;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
  caret-color: #ffffff;
  color: #ffffff;
}
.tu-inputbtn input:focus, .tu-inputbtn input:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
}
.tu-inputbtn a {
  flex: none;
  margin-left: 10px;
}

label {
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
}

form p span i {
  top: 13px;
  left: 16px;
  color: #474747;
  position: absolute;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f7f7;
}

.tu-locationtag {
  position: relative;
}
.tu-locationtag .form-control {
  padding-right: 130px;
}
.tu-locationtag a {
  bottom: 0;
  right: 20px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  color: #0A0F26;
  font-size: 0.75rem;
  line-height: 1.6666666667em;
}
.tu-locationtag a i {
  margin-right: 5px;
}

.tu-searchntn {
  width: 50px;
  display: block;
  line-height: 50px;
  color: #6A307D;
  text-align: center;
  font-size: 1.125rem;
  background: #6A307D;
}
.tu-searchntn:hover, .tu-searchntn:focus {
  color: #6A307D;
}

.tu-righticon {
  position: relative;
  align-items: center;
  width: 100%;
}
.tu-righticon .form-control {
  padding-right: 80px;
}
.tu-righticon > a {
  position: absolute;
  right: 20px;
  font-size: 12px;
  color: #0A0F26;
}
.tu-righticon > a + a {
  right: 49px;
}

.tu-placeholderholder {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  z-index: 0;
}
.tu-placeholderholder ~ .tu-check {
  padding-top: 16px;
}
.tu-placeholderholder [required] + .tu-placeholder em {
  display: inline;
}
.tu-placeholderholder textarea + .tu-placeholder {
  top: 12px;
  white-space: normal;
  left: 19px;
}

.tu-placeholder {
  position: absolute;
  left: 20px;
  white-space: nowrap;
  pointer-events: none;
  font-weight: 600;
}
.tu-placeholder span {
  color: #999999;
}
.tu-placeholder em {
  font-style: normal;
  color: #DD0430;
  font-weight: 400;
  display: none;
}

/** --- mCustomScrollbar --- **/
.mCSB_scrollTools .mCSB_draggerContainer {
  width: 6px;
  border-radius: 60px;
  background-color: #FCFCFC;
  left: auto;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #999999 !important;
  width: 6px;
  border-radius: 60px;
  margin: 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  margin: 0;
  background: #F7F8FC;
  width: 6px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 16px;
}

.mCSB_scrollTools {
  opacity: 1;
}

/** --- select2 simple --- **/
.select2-container--open .select2-dropdown--below,
.select2-dropdown {
  border: 0;
  background: #fff;
  border-radius: 4px;
}

.select2-search--dropdown {
  padding: 0;
  margin: 0 0 10px;
  position: relative;
}
.select2-search--dropdown::after {
  position: absolute;
  content: "\e9d0";
  font-family: "icomoon";
  left: 20px;
  top: 11px;
  font-size: 20px;
  color: #DDDDDD;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e1e1e1;
  outline: none;
  height: 48px;
  padding: 0 20px 0 53px;
}

.select2-container--open .select2-dropdown--below {
  padding: 10px;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: transparent;
}

.select2-results__option[aria-selected] {
  padding: 10px 20px;
  color: #0A0F26;
  font-weight: 600;
}

.select2-results__option {
  line-height: 21px;
  padding: 11.5px 15px;
  font-family: "Outfit", sans-serif;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #1C1C1C;
  background-color: #F7F8FC;
}

.select2-selection__arrow {
  display: none;
}

.select2-container--default .select2-selection--single {
  height: auto;
  color: #1C1C1C;
  border-radius: 4px;
  border: 1.5px solid #e1e1e1;
}
.select2-container--default .select2-selection--single:focus {
  outline: 0;
  border-color: #888888;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  line-height: 46px;
}

.select2-container {
  min-height: auto;
  height: 48px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 20px;
  padding-right: 40px;
  color: #1C1C1C;
  font-weight: 600;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

[multiple] + .select2 .select2-search__field {
  height: 38px;
}

.select2-dropdown--above {
  -webkit-box-shadow: 0 10px 30px 0 rgba(18, 54, 82, 0.2);
  box-shadow: 0 10px 30px 0 rgba(18, 54, 82, 0.2);
  border: 0;
  background: #fff;
  border-radius: 6px;
  margin-top: -10px;
  padding: 10px;
}

.select2-results__option {
  list-style: none;
}

.select2-container--default .select2-results__group {
  font: 700 15px "Outfit", sans-serif;
  color: #1C1C1C;
  position: relative;
  padding: 14px 16px 11px;
}
.select2-container--default .select2-results__group:before {
  content: "-";
  font: 700 15px "Outfit", sans-serif;
  color: #1C1C1C;
  padding-right: 9px;
}
.select2-container--default .select2-results__group + .select2-results__options .select2-results__option {
  position: relative;
  padding-left: 38px;
}
.select2-container--default .select2-results__group + .select2-results__options .select2-results__option:before {
  content: "\f0c8";
  position: absolute;
  left: 0;
  color: #dddddd;
  margin-left: 17px;
  font-size: 14px;
  font-family: "Font Awesome 6 Free";
}
.select2-container--default .select2-results__group + .select2-results__options .select2-results__option[aria-selected=true]:before {
  content: "\f14a";
  font-weight: 900;
  color: #6A307D;
}

/** --- select2 multipe --- **/
.select2-selection__choice {
  display: none !important;
}

.select2-dropdown {
  z-index: 1055;
}

[aria-multiselectable=true] .select2-results__option {
  position: relative;
  display: flex;
  align-items: center;
}
[aria-multiselectable=true] .select2-results__option[role=alert] {
  padding-left: 0;
}
[aria-multiselectable=true] .select2-results__option[role=alert]:before {
  display: none;
}
[aria-multiselectable=true] .select2-results__option:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1.5px solid #DDDDDD;
  margin-right: 10px;
  border-radius: 4px;
}
[aria-multiselectable=true] .select2-results__option[aria-selected=true]:before {
  content: "\e92b";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #22C55E;
  border-color: #22C55E;
  font-size: 14px;
  font-family: "icomoon";
}

.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  min-height: initial;
  border-radius: 4px;
  padding-bottom: 5px;
}

.select2-container--default .select2-selection--multiple {
  border-color: #e1e1e1;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #888888;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  position: relative;
  align-items: center;
  padding: 0 40px 0 20px;
  height: inherit;
}

.select2-container--default .select2-search--inline .select2-search__field {
  caret-color: #1C1C1C;
  color: #1C1C1C;
  width: auto !important;
}
.select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: #999999;
  opacity: 1;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  color: #6A307D;
  position: absolute;
  right: 35px;
  top: 0;
  display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-color: #e1e1e1;
  padding: 0 10px;
  background-color: #f7f7f7;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #1C1C1C;
  margin-left: 9px;
  order: 2;
}

.select2-container,
.select2-container .select2-search--inline {
  flex: auto;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
  padding-left: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true]:before {
  content: none;
}

/** --- Bootstrap Select --- **/
.bootstrap-select .dropdown-menu li {
  list-style: none;
}

.tu-actionselect {
  display: flex;
  align-items: center;
}
.tu-actionselect .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 48px;
}
.tu-actionselect > span {
  color: #0A0F26;
  flex: none;
  padding-right: 20px;
}
.tu-actionselect .form-control {
  font-size: 14px;
  color: #999999;
  height: 44px;
}

.tu-radiolist {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}
.tu-radiolist label {
  margin: 0;
  display: block;
  position: relative;
  font: 400 0.8125rem/1.1538461538em "Outfit", sans-serif;
  cursor: pointer;
  color: #0A0F26;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.tu-radiolist input[type=radio] {
  display: none;
}

.form-vertical {
  flex-direction: column;
  margin: 0;
  align-items: initial;
}
.form-vertical .tu-titleinput {
  align-self: initial;
}
.form-vertical:first-child .tu-titleinput {
  padding-top: 0;
  margin-top: -3px;
}
.form-vertical.form-group-half .tu-titleinput, .form-vertical.form-group-3half .tu-titleinput {
  padding-top: 7px;
  margin-top: 0;
}
.form-vertical .tu-titleinput {
  flex: none;
  margin: 0 0 5px;
  padding-top: 7px;
}

/** --- select2 styling --- **/
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999999;
  font-weight: 600;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 264px;
}

.tu-actionselect .tu-selectv ~ .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 46px 0 20px;
}

select[required] + .select2 .select2-selection--multiple .select2-search.select2-search--inline::after,
select[required] + .select2 .select2-selection__placeholder::after {
  content: " *";
  color: #DD0430;
}
select[required] + .select2 .select2-selection--multiple .select2-search.select2-search--inline::after {
  content: "*";
  margin-left: -10px;
}
select[required] + .select2 .select2-selection__choice + .select2-search.select2-search--inline::after {
  content: none;
}

/** --- tinymce styling --- **/
.tu-tinymceeditor {
  width: 100%;
}
.tu-tinymceeditor .mce-panel {
  border-color: #DDDDDD;
  background-color: #fff;
}
.tu-tinymceeditor .mce-btn {
  background-color: #fff;
}

.tox.tox-tinymce {
  width: 100%;
  height: 275px;
  border: 1.5px solid #e1e1e1;
  border-radius: 4px;
  color: #1C1C1C;
  background-color: #fff;
  caret-color: #1C1C1C;
}
.tox.tox-tinymce:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right-color: #e1e1e1;
}
.tox.tox-tinymce .tox-toolbar__primary {
  border-bottom: 1.5px solid #e1e1e1;
  background-image: none;
}

.tu-hasinputicon {
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}
.tu-hasinputicon .form-control {
  border-radius: 3px 0px 0px 3px;
}
.tu-hasinputicon a {
  background: #6A307D;
  border-radius: 0px 3px 3px 0px;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}
.tu-hasinputicon a i {
  font-size: 20px;
  display: inline-block;
  color: #1C1C1C;
}

.tu-inputiconbtn .form-control {
  padding-right: 50px;
  height: 50px;
}
.tu-inputiconbtn .tu-btn-solid-sm {
  height: 32px;
  width: 32px;
  padding: 0;
}
.tu-inputiconbtn .tu-btn-solid-sm i {
  font-size: 15px;
  padding: 0;
  line-height: 0.8;
}
.tu-inputiconbtn a {
  right: 10px;
}

.tu-inputicon,
.tu-inputiconbtn {
  position: relative;
  width: 100%;
  align-items: center;
}
 .tu-inputicon span,
 .tu-inputiconbtn span {
  right: 10px;
  font-size: 16px;
  position: absolute;
  line-height: 1;
  color: #999999;
}

.tu-check input {
  display: none;
}
.tu-check input[type=checkbox] + label::before {
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.tu-check input[type=checkbox]:checked + label::before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-color: #22C55E;
}
.tu-check input:checked + label::before {
  border: 5px solid #22C55E;
}
.tu-check label {
  margin: 10px;
  display: flex;
  align-items: center;
  color: #1C1C1C;
  cursor: pointer;
}
.tu-check label:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1.5px solid #DDDDDD;
  border-radius: 60px;
  margin-right: 10px;
  background-color: #ffffff;
}
.tu-check label span {
  color: #1C1C1C;
}
.tu-check label > a {
  margin: 0 0 0 10px;
}

.tu-checksm input[type=checkbox] + label::before {
  width: 20px;
  height: 20px;
  font-size: 10px;
}
.tu-checksm input[type=checkbox] + label .tu-featureRating {
  align-items: center;
}
.tu-checksm input[type=checkbox] + label .tu-featureRating .tu-totalreview {
  margin: 0 0 0 10px;
}

.tu-placeholderholder input.form-control:not(:placeholder-shown) + .tu-placeholder, .tu-placeholderholder input.form-control:-webkit-autofill + .tu-placeholder, .tu-placeholderholder textarea.form-control:not(:placeholder-shown) + .tu-placeholder, .tu-placeholderholder textarea.form-control:-webkit-autofill + .tu-placeholder {
  display: none;
}
.tu-placeholderholder input.form-control::placeholder, .tu-placeholderholder textarea.form-control::placeholder {
  color: transparent;
}

.tu-inputiconbtn .tu-placeholder {
  left: 50px;
}

input.tu-showplaceholder::placeholder {
  color: #999999;
  font-weight: 600;
}

.tu-selectvtwo {
  width: 156px;
  height: 30px;
}
.tu-selectvtwo:before {
  top: 5px;
}
.tu-selectvtwo .select2-selection.select2-selection--single {
  background: transparent;
  border: 0;
}
.tu-selectvtwo .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  line-height: 30px;
}
.tu-selectvtwo .select2-container {
  height: 30px;
}

/** ====== header layout ====== **/
.tu-navbar {
  padding: 0 8px;
  position: relative;
  box-shadow: inset 0px -1px 0px #eeeeee;
}
.tu-navbar .navbar-brand {
  padding: 25px 0;
  margin: 0;
  display: block;
  max-width: 133px;
}
.tu-navbar .navbar-collapse {
  margin-left: auto;
  flex: initial;
}
.tu-navbar .navbar-collapse .navbar-nav > .nav-item > a {
  position: relative;
  font-weight: 400;
  padding: 27px 18px;
}
.tu-navbar .navbar-collapse .navbar-nav > .nav-item > a .tu-tag {
  position: absolute;
  top: 9px;
  right: 18px;
}
.tu-navbar .navbar-collapse .navbar-nav > .nav-item > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #F97316;
}
.tu-navbar .navbar-collapse .navbar-nav > .nav-item > a:hover::before, .tu-navbar .navbar-collapse .navbar-nav > .nav-item > a.active::before {
  width: 100%;
}
.tu-navbar .navbar-collapse .navbar-nav > .nav-item:not(.tu-loginlink) a {
  color: #1C1C1C;
}

.tu-navbar.tu-navbarvtwo .navbar-nav > .menu-item-has-children > a, .tu-navbar.tu-navbarvtwo .navbar-nav > .nav-item > a {
  padding: 37px 18px;
}

.tu-loginlink a {
  color: #1DA1F2;
}
.tu-loginlink a:hover, .tu-loginlink a:focus {
  color: #1DA1F2;
}

.tu-headerbtn {
  flex: none;
  padding-left: 20px;
}

.tu-headerv2 {
  z-index: 9;
  position: relative;
}
.tu-headerv2 .tu-menu {
  background-color: #F97316;
}
.tu-headerv2 .tu-navbar {
  padding: 0 18px;
  border-bottom: 0;
  box-shadow: none;
}
.tu-headerv2 .line {
  stroke: #ffffff;
}
.tu-headerv2 .menu-item-has-children strong a {
  padding: 30px 0;
}
.tu-headerv2 .tu-afterlogin > li > a:hover,
.tu-headerv2 .tu-navbar .navbar-collapse > .navbar-nav > .nav-item:not(.tu-loginlink) > a,
.tu-headerv2 .tu-afterlogin li > a,
.tu-headerv2 .navbar-nav > .menu-item-has-children > a,
.tu-headerv2 .tu-navbar .navbar-collapse .nav-item a {
  color: #ffffff;
}
.tu-headerv2 .tu-headerbtn a {
  background-color: #F97316;
}

.tu-navbar.tu-navbarvtwo .navbar-collapse .nav-item a::before {
  background-color: #EAB308;
  height: 5px;
}

.tu-afterlogin {
  padding-left: 0;
  align-items: center;
}
.tu-afterlogin .menu-item-has-children > .sub-menu > li > a {
  display: flex;
  padding: 11px 20px;
  align-items: center;
}
.tu-afterlogin > li {
  margin: 0 10px;
}
.tu-afterlogin > li strong > a {
  padding: 19px 0;
}
.tu-afterlogin > li strong > a img {
  max-width: 40px;
  display: inline-block;
  border-radius: 50%;
}
.tu-afterlogin > li > a {
  padding: 0;
  display: flex;
  min-width: 33px;
  font-size: 24px;
  position: relative;
  align-items: center;
  color: #1C1C1C;
}
.tu-afterlogin > li > a span:before {
  display: block;
  margin-top: 10px;
}
.tu-afterlogin > li > a:hover {
  color: #1C1C1C;
}

.tu-messagenoti {
  top: 0;
  right: 0;
  color: #ffffff;
  padding: 0 8px;
  min-width: auto;
  background: #EF4444;
  font-style: normal;
  text-align: center;
  position: absolute;
  border-radius: 13px;
  display: inline-block;
  font: 700 11px/22px "Outfit", sans-serif;
}

.sub-menu {
  right: 0;
  display: none;
  margin-left: 0;
  padding: 0;
  width: 265px;
  z-index: 1051;
  background: #ffffff;
  position: absolute;
  border-radius: 4px;
  font-family: "Outfit", sans-serif;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #F7F8FC;
}
.sub-menu li {
  width: 100%;
  position: relative;
}
.sub-menu li:hover > a {
  background: #f7f7f7;
}
.sub-menu li .sub-menu {
  right: 100%;
}
.sub-menu li a {
  width: 100%;
  display: block;
  padding: 14px 20px;
  color: #0A0F26;
  margin: 0;
}
.sub-menu li a i {
  font-size: 1.25rem;
  padding-right: 10px;
  color: #999999;
}
.sub-menu .sv-menudropdown {
  position: relative;
}
.sub-menu .sv-menudropdown .sub-menu {
  right: 100%;
  top: 100%;
  margin-left: 10px;
}
.sub-menu .menu-item-has-children > a:after {
  content: "\e930";
  margin-left: auto;
}

.menu-item-has-children {
  position: relative;
}
.menu-item-has-children > a {
  display: flex;
  font-weight: 400;
  color: #1C1C1C;
  align-items: center;
  padding: 27px 18px;
}
.menu-item-has-children > a:after {
  right: 30px;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  font-family: "icomoon" !important;
}
.menu-item-has-children > .sub-menu > li > a {
  width: 100%;
  color: #0A0F26;
}
.menu-item-has-children > .sub-menu > li > a:nth-last-child(2) {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-nav .menu-item-has-children.nav-item .sub-menu li a {
  padding: 12px 20px;
}

.tu-menutag {
  color: #ffffff;
  font-weight: 600;
  padding: 1px 8px;
  margin-left: 10px;
  font-style: normal;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  background-color: #F97316;
}

@media (min-width: 1199px) {
  .menu-item-has-children > a:after {
    content: "\e92e";
  }
}
/** ====== footer layout ====== **/
.tu-footer {
  background-color: #F7F8FC;
  color: #1C1C1C;
  padding: 60px 0;
}
.tu-footer_maintitle {
  padding-bottom: 30px;
}
.tu-footer_maintitle h5 {
  font-weight: 400;
  margin: 0;
}
.tu-footer_maintitle h3 {
  font-weight: 600;
  margin: 0;
}
.tu-footer .tu-footerlist li a::before {
  background-color: rgba(0, 0, 0, 0.2);
}
.tu-footer_row {
  row-gap: 30px;
}

.tu-footerdark {
  color: #eeeeee;
  background-color: #2a1332;
  padding-top: 60px;
}
.tu-footerdark .tu-socialmedia {
  margin-top: 20px;
}

.tu-footerlogo {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.tu-footertitle {
  font-weight: 600;
  color: inherit;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.tu-footertitle .tu-tag {
  margin-left: 10px;
  border-radius: 10px 10px 10px 0;
}

.tu-footerlist {
  gap: 10px 0;
  padding-left: 0;
}
.tu-footerlist li {
  list-style: none;
  flex: max(200px, 25%);
}
.tu-footerlist li a {
  display: flex;
  color: inherit;
  align-items: center;
}
.tu-footerlist li a:hover {
  color: #1DA1F2;
}
.tu-footerlist li a::before {
  flex: none;
  width: 3px;
  height: 3px;
  content: "";
  border-radius: 50%;
  margin: 12px 14px 0 0;
  align-self: flex-start;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.4);
}
.tu-footerlist li a em {
  font-style: normal;
  padding-right: 14px;
  color: #eeeeee;
}
.tu-footerlist li a em:hover {
  color: #1DA1F2;
}
.tu-footerlist li a span {
  color: #999999;
}
.tu-footerlist .tu-footerlist-explore {
  padding-left: 17px;
}
.tu-footerlist .tu-footerlist-explore a {
  font-weight: 600;
  color: #1DA1F2;
  padding-left: 1px;
}
.tu-footerlist .tu-footerlist-explore a::before {
  content: none;
}

.tu-footericonlist {
  display: block;
  margin: -5px;
}
.tu-footericonlist li {
  padding: 5px;
}
.tu-footericonlist li:first-child a i {
  font-size: 20px;
}
.tu-footericonlist li a {
  flex-wrap: wrap;
}
.tu-footericonlist li a::before {
  content: none;
}
.tu-footericonlist li a i {
  min-width: 18px;
  text-align: center;
  margin-right: 14px;
  font-size: 16px;
  color: #DDDDDD;
}

.tu-seperator {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 60px;
  margin-top: 60px;
  padding-bottom: 60px;
}
.tu-seperator ~ .tu-seperator {
  margin-top: 0;
}

.tu-footerlistholder {
  gap: 30px 0;
}
.tu-footerlistholder .tu-footercontent {
  width: 61%;
}
.tu-footerlistholder .tu-footercontent-two {
  width: 39%;
}

.tu-footercontent {
  position: relative;
}
.tu-footercontent .tu-footernewsletter p {
  font-size: 0.9375rem;
  line-height: 1.6em;
}
.tu-footercontent p {
  margin: 0;
  color: #eeeeee;
}
.tu-footercontent_title {
  max-width: 240px;
  margin: 0 23px 0 20px;
}
.tu-footercontent .tu-inputbtn {
  margin-top: 20px;
}
.tu-footercontent-two ul.tu-footerlist {
  gap: 10px 20px;
}

.tu-footerdevice {
  gap: 10px;
  padding: 20px 0 0;
  flex-wrap: nowrap;
}
.tu-footerdevice li {
  list-style: none;
}

.tu-footernewsletter {
  flex: auto;
  width: 100%;
}

.tu-footercopyright {
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.tu-footercopyright_content {
  justify-content: space-between;
  align-items: center;
  gap: 10px 0;
}
.tu-footercopyright_content > p {
  margin: 0;
  color: #eeeeee;
}
.tu-footercopyright_list {
  gap: 5px 20px;
  padding-left: 0;
}
.tu-footercopyright_list li {
  list-style-type: none;
  line-height: inherit;
}
.tu-footercopyright_list li a {
  display: block;
  font-weight: 400;
  color: #eeeeee;
}
.tu-footercopyright_list li a:hover, .tu-footercopyright_list li a:focus {
  color: #1DA1F2;
}

/** ====== index page style ====== **/
.tu-banner {
  padding: 80px 0;
}
.tu-banner_title h1 {
  line-height: 1.3076923077em;
  margin-bottom: 10px;
}
.tu-banner_title h1 span {
  color: #1DA1F2;
}
.tu-banner_title > a {
  padding: 0 16px;
  height: 100%;
}
.tu-banner_title > p {
  margin: 20px 0 0;
  max-width: 636px;
}
.tu-banner_list {
  gap: 10px 20px;
  padding: 30px 0 0;
}
.tu-banner_list li {
  list-style: none;
  position: relative;
}
.tu-banner_list li a {
  height: 54px;
}
.tu-banner_list li a span {
  font-size: 1.125rem;
  line-height: 1.4444444444em;
}
.tu-banner_list li a.tu-secbtn span {
  font-weight: 400;
}
.tu-banner_explore {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.tu-banner_explore i {
  font-size: 20px;
  flex: none;
  margin-right: 10px;
  color: #999999;
}
.tu-banner_explore p {
  color: #676767;
  margin: 0;
}
.tu-bannerv3 .tu-banner_title h1 {
  margin: 0;
}

.tu-starthere {
  top: -27px;
  left: -30px;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-end;
  transform: translateX(-100%);
}
.tu-starthere span {
  font: 400 1rem/1.1875em "Gochi Hand", cursive;
  color: #64748B;
  letter-spacing: 0.5px;
}
.tu-starthere img {
  margin-top: 7px;
}

.tu-brand {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F7F8FC;
}
.tu-brand_list {
  padding: 0;
  margin: -15px;
  align-items: center;
  justify-content: space-between;
}
.tu-brand_list li {
  padding: 15px;
  list-style: none;
}
.tu-brand_list li img {
  max-width: 97px;
}
.tu-brand .tu-maintitle h4 {
  align-items: center;
  justify-content: center;
}

.tu-stats {
  margin: 0 -12px;
  padding: 40px 0;
  align-items: center;
  border-radius: 10px;
  background-color: #F7F8FC;
}

.tu-stats li img {
  max-width: 54px;
  margin-bottom: 20px;
  width: 100%;
  height: 54px;
}
.tu-stats_info h4 {
  font-weight: 600;
  margin-bottom: 0;
}
.tu-stats_info p {
  margin: 0;
  max-width: 80%;
}

.tu-cardtag {
  bottom: 0;
  right: 20px;
  width: 12px;
  height: 17px;
  position: absolute;
  background-color: #EF4444;
  transform: translateY(100%);
}
.tu-cardtag::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 6px;
  width: 100%;
  background-color: #ffffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.tu-instructors {
  border-radius: 4px;
}
.tu-instructors:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-instructors figure {
  position: relative;
  margin-bottom: 0;
}
.tu-instructors figure img {
  border-radius: 4px 4px 0 0;
  width: 100%;
}
.tu-instructors_content {
  border-radius: 0 0 4px 4px;
  box-shadow: inset 1px 0px 0px #eeeeee, inset -1px 0px 0px #eeeeee, inset 0px -1px 0px #eeeeee;
}
.tu-instructors_header {
  padding: 20px;
  display: flex;
  align-items: center;
}
.tu-instructors_header > img {
  border-radius: 50%;
  max-width: 46px;
  margin-right: 10px;
}
.tu-instructors_title h5 {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 0;
}
.tu-instructors_title h5 span {
  padding-right: 6px;
}
.tu-instructors_title h5 i {
  color: #22C55E;
  font-size: 17px;
}
.tu-instructors_title > span {
  color: #484848;
  display: block;
}
.tu-instructors_price {
  padding: 14px 20px;
  background-color: #FCFCFC;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  box-shadow: inset 0px 1px 0px #eeeeee, inset 0px -1px 0px #eeeeee;
}
.tu-instructors_price span {
  padding-right: 3px;
  display: inline-block;
}
.tu-instructors_price h5 {
  font-weight: 600;
  color: #1DA1F2;
  margin-bottom: 0;
  display: inline-block;
}
.tu-instructors_service {
  text-align: center;
  padding: 20px 19px;
  box-shadow: inset 0px -1px 0px #eeeeee;
}
.tu-instructors_service > p {
  color: #676767;
  margin-bottom: 0;
}
.tu-instructors_service > p + .tu-instructors_service-list {
  padding-top: 14px;
}
.tu-instructors_service-list {
  padding: 0;
  margin: 0 -15px;
}
.tu-instructors_service-list li {
  flex: auto;
  padding: 0 15px;
}
.tu-instructors_service-list li ~ li {
  border-left: 1px solid #DDDDDD;
}
.tu-instructors_service-list i {
  display: block;
  font-size: 18px;
  margin-bottom: 4px;
}
.tu-instructors_service-list span {
  display: block;
}
.tu-instructors_footer {
  justify-content: space-between;
}
.tu-instructors_footer-right a {
  width: 40px;
  height: 40px;
  display: flex;
  color: #999999;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #eeeeee;
}
.tu-instructors_footer-right a:hover, .tu-instructors_footer-right a.active {
  color: #EF4444;
}
.tu-instructors_footer-right a:hover i::before, .tu-instructors_footer-right a.active i::before {
  content: "\f004";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.tu-rating {
  align-items: center;
  padding: 8px 0 8px 20px;
}
.tu-rating h6 {
  font-weight: 600;
  margin-bottom: 0;
}
.tu-rating h6 + i {
  padding-left: 6px;
}
.tu-rating > i {
  font-size: 14px;
  color: #FFD101;
}
.tu-rating > i + span {
  padding-left: 6px;
}
.tu-rating > i + h6 {
  padding: 0 6px;
}
.tu-rating > span {
  color: #484848;
}

.tu-splidedots .splide__pagination {
  margin: 0;
  position: static;
  padding-top: 40px;
}
.tu-splidedots .splide__pagination li {
  display: flex;
}
.tu-splidedots .splide__pagination li ~ li {
  padding-left: 10px;
}
.tu-splidedots .splide__pagination__page {
  width: 10px;
  height: 4px;
  background-color: #DDDDDD;
  border-radius: 2px;
  margin: 0;
  opacity: 1;
  transform: none;
}
.tu-splidedots .splide__pagination__page.is-active {
  width: 20px;
  background-color: #1C1C1C;
}

.tu-sucesstorslider .splide__track {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.08));
}

.tu-sucesstor {
  display: flex;
  align-items: center;
}
.tu-sucesstor_img {
  flex: none;
  margin-right: 67px;
}
.tu-sucesstor_img figure {
  position: relative;
  max-width: 593px;
  overflow: hidden;
  margin: 0;
  border-radius: 4px;
}
.tu-sucesstor_img figure > img {
  border-radius: inherit;
  width: 100%;
}
.tu-sucesstor_img figure figcaption {
  position: absolute;
  left: -30px;
  bottom: -30px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #A9E7EE;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tu-sucesstor_img figure figcaption > img {
  padding: 48px 43px 60px 58px;
}
.tu-sucesstor_title h3 {
  font-weight: 600;
  margin-bottom: 0;
}
.tu-sucesstor_title h3 + blockquote {
  padding-top: 20px;
}
.tu-sucesstor_title blockquote {
  margin-bottom: 0;
}
.tu-sucesstor_title h4 {
  font-weight: 600;
  margin: 20px 0 0;
}
.tu-sucesstor_title h4 span {
  display: block;
  font: 400 1rem/1.625em "Outfit", sans-serif;
}

.tu-splidearrow .splide__arrows {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  margin-bottom: 46px;
}
.tu-splidearrow .splide__arrows svg {
  display: none;
}
.tu-splidearrow .splide__arrow {
  transform: none;
  position: static;
  width: 60px;
  height: 60px;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999999;
  opacity: 1;
}
.tu-splidearrow .splide__arrow::before {
  font-family: "icomoon";
}
.tu-splidearrow .splide__arrow:hover {
  background-color: #6A307D;
  border-color: #6A307D;
  color: #ffffff;
}
.tu-splidearrow .splide__arrow--prev::before {
  content: "\e92f";
}
.tu-splidearrow .splide__arrow--next::before {
  content: "\e930";
}

.tu-success-stories {
  padding: 80px 0;
  position: relative;
}
.tu-success-stories .container {
  position: relative;
}
.tu-success-stories .splide__arrows {
  right: 0;
  margin: 0;
  top: -106px;
  position: absolute;
}
.tu-success-stories::after {
  top: 0;
  right: 0;
  width: 61%;
  z-index: -1;
  content: "";
  height: 100%;
  position: absolute;
  background-color: #F7F8FC;
  border-radius: 10px 0px 0px 10px;
}

.tu-sucesstor_pattren {
  top: -80px;
  z-index: -1;
  left: -160px;
  position: absolute;
}

.tu-categories {
  padding-left: 0;
  margin: -12px;
}
.tu-categories li {
  list-style: none;
  width: 20%;
  padding: 12px;
}
.tu-categories_content {
  display: block;
}
.tu-categories_content:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-categories_content:hover .tu-categories_title::after {
  color: #1DA1F2;
}
.tu-categories_content img {
  border-radius: 4px 4px 0 0;
  width: 100%;
}
.tu-categories_title {
  position: relative;
  align-items: center;
  padding: 20px 50px 20px 20px;
  border-radius: 0 0 4px 4px;
  box-shadow: inset 1px 0px 0px #eeeeee, inset -1px 0px 0px #eeeeee, inset 0px -1px 0px #eeeeee;
}
.tu-categories_title h6 {
  margin: 0;
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tu-categories_title span {
  color: #484848;
  width: 100%;
}
.tu-categories_title::after {
  content: "\e930";
  position: absolute;
  right: 20px;
  font-family: "icomoon";
  font-size: 20px;
  color: #999999;
}
.tu-categories .tu-categories-maintitle {
  align-self: center;
  width: 40%;
}
.tu-categories .tu-categories-maintitle .tu-maintitle {
  padding-bottom: 0;
}

.tu-bannerv1_img {
  display: flex;
  justify-content: flex-end;
}

.tu-betterresult {
  position: relative;
}
.tu-betterresult > figure {
  margin: 0;
  width: 100%;
  max-width: 416px;
}
.tu-betterresult > img {
  position: absolute;
  right: 110px;
  width: 368px;
  top: 0;
  z-index: -1;
}
.tu-betterresult .tu-resultperson {
  right: 24px;
  width: 171px;
  position: absolute;
  transform: translate(0, -100%);
}

.tu-resultperson h6 {
  margin: 0;
}
.tu-resultperson h5 {
  margin: 0;
  font-weight: 600;
}

.tu-bannerinfo {
  color: #fff;
  padding: 5px 16px;
  overflow: hidden;
  min-height: 78px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 4px;
  line-height: 1.3076923077em;
  background-color: #6A307D;
}

.tu-letters {
  display: flex;
  line-height: 62px;
}

/** ====== index v2 page style ====== **/
.tu-bannerv2 {
  padding: 180px 0 242px;
  position: relative;
  margin-top: -100px;
}
.tu-bannerv2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6A307D;
  width: 100%;
  height: 100%;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
}
.tu-bannerv2 .row {
  flex-wrap: nowrap;
}
.tu-bannerv2 .tu-banner_title h1,
.tu-bannerv2 .tu-banner_title > p,
.tu-bannerv2 .tu-starthere span,
.tu-bannerv2 .tu-banner_explore p,
.tu-bannerv2 .tu-banner_explore p a {
  color: #ffffff;
}
.tu-bannerv2 .tu-banner_title > span {
  background-color: #1DA1F2;
}
.tu-bannerv2 .tu-secbtn {
  background-color: transparent;
  color: #ffffff;
}
.tu-bannerv2 .tu-banner_title h1 span {
  color: #F97316;
}
.tu-bannerv2_img img {
  max-width: 1042px;
}
.tu-bannerv2 .tu-starthere {
  top: -18px;
}
.tu-bannerv2 .tu-starthere img {
  margin-top: 8px;
}

.tu-bannerv2_img {
  right: 0;
  top: 160px;
  position: absolute;
}

.tu-eduplatform a {
  padding: 29px;
  display: block;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}
.tu-eduplatform a:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-eduplatform a img {
  max-width: 60px;
  margin-bottom: 20px;
}
.tu-eduplatform a h5 {
  font-weight: 600;
  margin-bottom: 0;
}
.tu-eduplatform a h5 + p {
  padding-top: 10px;
}
.tu-eduplatform a p {
  margin: 0;
  color: #1C1C1C;
}

.tu-statsholder {
  background-color: #F7F8FC;
}

.tu-sucesstorieslider .splide__arrows {
  top: -96px;
}
.tu-sucesstorieslider .splide__track {
  margin: -10px;
  padding: 10px !important;
}

.tu-sucesstories {
  padding: 29px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-sucesstories blockquote {
  font-size: 0.9375rem;
  line-height: 1.6em;
}
.tu-sucesstories > img {
  border-radius: 50%;
  margin-bottom: 10px;
}
.tu-sucesstories_comma {
  position: absolute;
  top: 10px;
  right: 10px;
}
.tu-sucesstories h4 {
  font-size: 1.125rem;
  line-height: 1.4444444444em;
}
.tu-sucesstories h4 span {
  font-size: 0.875rem;
  line-height: 1.5714285714em;
}
.tu-sucesstories h5 {
  font-weight: 600;
  margin: 0 0 20px;
}

.tu-featureitem:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-featureitem > figure {
  margin: 0;
}
.tu-featureitem > figure img {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  height: 309px;
}

.tu-authorinfo {
  background: #ffffff;
  box-shadow: inset 1px 0px 0px #EEEEEE, inset -1px 0px 0px #EEEEEE, inset 0px -1px 0px #EEEEEE;
  border-radius: 0px 0px 4px 4px;
}

.tu-authordetail {
  padding: 20px;
  align-items: center;
  box-shadow: inset 0px -1px 0px #eeeeee;
}
.tu-authordetail figure {
  max-width: 46px;
  margin: 0 10px 0 0;
}
.tu-authordetail figure img {
  border-radius: 50%;
}
.tu-authordetail .tu-authorlist {
  margin: 20px 0 0;
}

.tu-authorname h5 {
  margin: 0;
  font-weight: 600;
  align-items: center;
}
.tu-authorname h5 i {
  font-size: 17px;
  line-height: 22px;
  margin: 0 0 0 6px;
  vertical-align: middle;
  display: inline-block;
}
.tu-authorname span {
  color: #484848;
  display: block;
}

.tu-authorlist {
  width: 100%;
  padding: 0;
}
.tu-authorlist li {
  line-height: 24px;
  list-style-type: none;
}
.tu-authorlist li span {
  display: inline-flex;
  color: #484848;
  justify-content: space-between;
  width: 100%;
}
.tu-authorlist li em {
  color: #1C1C1C;
  font-weight: 600;
}
.tu-authorlist li ~ li {
  margin: 10px 0 0;
}

.tu-featuretag {
  left: 0;
  top: 10px;
  color: #ffffff;
  font-weight: 700;
  padding: 1px 8px;
  position: absolute;
  background-color: #EF4444;
  border-radius: 0px 4px 4px 0px;
}

.tu-bannerv3 {
  padding: 200px 0 305px;
  position: relative;
  margin-top: -100px;
  overflow: hidden;
}
.tu-bannerv3::before {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  background: #0A0F26;
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
}
.tu-bannerv3 .row {
  flex-wrap: nowrap;
}
.tu-bannerv3 .tu-banner_title h1,
.tu-bannerv3 .tu-banner_title > p,
.tu-bannerv3 .tu-starthere span,
.tu-bannerv3 .tu-banner_explore p,
.tu-bannerv3 .tu-banner_explore p a {
  color: #ffffff;
}
.tu-bannerv3 .tu-banner_title > a {
  background-color: #1DA1F2;
}
.tu-bannerv3 .tu-secbtn {
  background-color: transparent;
  color: #ffffff;
}
.tu-bannerv3 .tu-banner_title h1 span {
  color: #F97316;
}
.tu-bannerv3_img img {
  max-width: initial;
}
.tu-bannerv3 .tu-bannerv2_img {
  top: 174px;
}
.tu-bannerv3 .tu-bannerv2_img img {
  max-width: 940px;
}
.tu-bannerv3 .tu-starthere {
  top: -20px;
}

.tu-dottedimage {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -2;
  max-width: 508px;
}

.tu-linedimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0% 100%);
}
.tu-linedimage img {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 215px;
}

.tu-popularsearches {
  flex-wrap: wrap;
  margin: 20px 0 0;
}
.tu-popularsearches h5 {
  margin: 0 10px 0 0;
  color: #ffffff;
  font-weight: 600;
}

.tu-popsearchitem {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tu-popsearchitem li {
  list-style-type: none;
  line-height: 26px;
  position: relative;
}
.tu-popsearchitem li a {
  display: inline-block;
  line-height: inherit;
  color: #ffffff;
}
.tu-popsearchitem li a:hover {
  color: #1DA1F2;
}
.tu-popsearchitem li + li {
  margin: 0 0 0 10px;
}
.tu-popsearchitem li:hover::after {
  color: #1DA1F2;
}
.tu-popsearchitem li:last-child::after {
  content: "";
}
.tu-popsearchitem li::after {
  content: ",";
  color: #ffffff;
}

.tu-success-storiesvtwo::after {
  width: 72.7%;
}

.tu-featurelist .splide__track,
.tu-instructorslider .splide__track,
.tu-categoriesslider .splide__track {
  margin: -20px;
  padding: 20px !important;
}

.tu-categoriesslider .tu-categories_title {
  box-shadow: none;
  padding: 26px 40px 14px 14px;
}
.tu-categoriesslider .tu-categories_title::after {
  right: 15px;
}
.tu-categoriesslider .tu-categories_content {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
}
.tu-categoriesslider .tu-categories_content img {
  border-radius: 4px;
}

.tu-banner-search .tu-appendinput {
  max-width: 746px;
}
.tu-banner-search .tu-appendinput .tu-inputicon .form-control {
  width: 100%;
  padding-left: 40px;
}
.tu-banner-search .tu-appendinput .tu-select, .tu-banner-search .tu-appendinput .tu-calendar {
  width: 227px;
}
.tu-banner-search .tu-searcbar > a {
  width: 142px;
}

.tu-bannervtwo {
  z-index: 1;
  position: relative;
  margin-top: -100px;
  padding: 200px 0 100px;
  background-color: #6A307D;
}
.tu-bannervtwo_img {
  margin: 0;
}
.tu-bannervtwo_img img {
  width: 100%;
  display: block;
}
.tu-bannervtwo .tu-banner_title h1, .tu-bannervtwo .tu-banner_title > p {
  color: #ffffff;
}
.tu-bannervtwo .tu-banner_title > span {
  background-color: #1DA1F2;
}
.tu-bannervtwo .tu-banner_list .tu-secbtn {
  color: #ffffff;
  background-color: transparent;
}
.tu-bannervtwo .tu-particles {
  z-index: -1;
  pointer-events: none;
}
.tu-bannervtwo .tu-starthere span,
.tu-bannervtwo .tu-banner_explore p,
.tu-bannervtwo .tu-banner_explore p a {
  color: #ffffff;
}
.tu-bannervtwo .tu-banner_title > span .tu-secbtn {
  background-color: transparent;
  color: #ffffff;
}
.tu-bannervtwo .tu-banner_title h1 span {
  color: #F97316;
}
.tu-bannervtwo .tu-starthere {
  top: -38px;
}
.tu-bannervtwo .tu-starthere img {
  margin-top: 8px;
}

.tu-bannervthree {
  z-index: 1;
  position: relative;
  margin-top: -100px;
  background: #0A0F26;
  padding: 200px 0 100px;
  overflow: hidden;
}
.tu-bannervthree .tu-banner_title > p, .tu-bannervthree .tu-banner_title h1 {
  color: #ffffff;
}
.tu-bannervthree .tu-banner_title > a {
  background-color: #1DA1F2;
}
.tu-bannervthree .tu-starthere span p,
.tu-bannervthree .tu-starthere span p a, .tu-bannervthree_explore p,
.tu-bannervthree_explore p a {
  color: #ffffff;
}
.tu-bannervthree .tu-secbtn {
  color: #ffffff;
  background-color: transparent;
}
.tu-bannervthree .tu-banner_title h1 span {
  color: #F97316;
}
.tu-bannervthree .tu-starthere {
  top: -20px;
}
.tu-bannervthree .tu-particles {
  z-index: -1;
  pointer-events: none;
}
.tu-bannervthree .tu-linedimage {
  clip-path: none;
  pointer-events: none;
}
.tu-bannervthree .tu-linedimage,
.tu-bannervthree .tu-dottedimage {
  opacity: 0.5;
}

/** ====== blog details page style ====== **/
.tu-bgmain {
  background-color: #F7F8FC;
}

.tu-asidewrapper {
  padding: 19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-asidewrapper .tu-asideitem ~ .tu-asideitem {
  margin: 30px 0 0;
}

.tu-asideitem h5 {
  font-weight: 600;
  position: relative;
  margin: 0;
  cursor: pointer;
}
.tu-asideitem h5::after {
  content: "\e9a3";
  right: 0;
  top: 4px;
  position: absolute;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: #0a0f26;
  font-family: "icomoon" !important;
}
.tu-asideitem [aria-expanded=false]::after {
  content: "\e9c0";
}

.tu-categorieslist {
  padding: 0;
}
.tu-categorieslist li {
  list-style-type: none;
}
.tu-categorieslist li p {
  margin: 0;
  color: #1C1C1C;
  justify-content: space-between;
}
.tu-categorieslist li p span {
  color: #999999;
  display: inline-block;
}
.tu-categorieslist li ~ li {
  margin: 10px 0 0;
}

.tu-collapseitem > *:first-child {
  padding: 10px 0 0;
}
.tu-collapseitem .tu-commenteditem ~ .tu-commenteditem {
  padding: 16px 0 0;
  margin: 16px 0 0;
  border-top: 1px solid #eeeeee;
}
.tu-collapseitem .tu-primbtn {
  width: 100%;
  margin: 10px 0 0;
}
.tu-collapseitem p {
  margin-bottom: 10px;
}

.tu-commenteditem {
  flex-wrap: nowrap;
  align-items: center;
}
.tu-commenteditem figure {
  margin: 0 14px 0 0;
  max-width: 90px;
  flex: none;
}
.tu-commenteditem figure img {
  width: 100%;
  border-radius: 4px;
}

.tu-commentdescp a {
  display: block;
}
.tu-commentdescp a h6 {
  margin: 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tu-commentdescp span {
  display: block;
  color: #999999;
  font-weight: 600;
}

.tu-inputiconbtn.tu-inputiconbtnright span {
  left: 20px;
  right: auto;
}
.tu-inputiconbtn.tu-inputiconbtnright .form-control {
  padding: 0 20px 0 50px;
}

.tu-postgallary {
  list-style: none;
  margin: -5px;
}
.tu-postgallary li {
  list-style-type: none;
  padding: 5px;
  width: 25%;
}
.tu-postgallary li a {
  display: block;
}
.tu-postgallary li a img {
  width: 100%;
}

.tu-blogwrapper .tu-blogtitle {
  margin: 0 0 10px;
}
.tu-blogwrapper > figure {
  margin: 0 0 30px;
}
.tu-blogwrapper > figure img {
  width: 100%;
  border-radius: 4px;
}
.tu-blogwrapper > .tu-description {
  margin: 30px 0 0;
}
.tu-blogwrapper > .tu-description p {
  margin: 0;
}
.tu-blogwrapper > .tu-description p ~ p {
  margin: 26px 0 0;
}
.tu-blogwrapper .tu-blogfeatures {
  margin: 26px 0 0;
}
.tu-blogwrapper .tu-overviewtitle {
  margin: 30px 0 0;
}
.tu-blogwrapper .tu-boxlg ~ .tu-boxlg {
  margin-top: 40px;
}

.tu-relatedatricles {
  margin: 40px 0;
}
.tu-relatedatricles .tu-blogtitle {
  margin: 0;
}
.tu-relatedatricles .tu-blogtitle h3 {
  margin: 0;
}

.tu-taglinks {
  padding: 0;
  margin: 0;
}
.tu-taglinks li {
  list-style-type: none;
  position: relative;
  margin: 0 8px 0 0;
  line-height: 26px;
}
.tu-taglinks li a {
  display: inline-block;
  font-weight: 400;
  line-height: inherit;
}
.tu-taglinks li + li::after {
  position: absolute;
  content: ",";
  left: -8px;
  font-size: 18px;
  color: #1DA1F2;
}

.tu-blogtitle {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.tu-blogtitle h3 {
  margin: 0;
  font-weight: 600;
}

.tu-sortarea {
  align-items: center;
}
.tu-sortarea h6 {
  font-weight: 600;
  color: #999999;
  margin: 0;
}

.tu-blogiteminfo {
  padding: 0;
  gap: 12px 24px;
}
.tu-blogiteminfo li {
  line-height: 24px;
}
.tu-blogiteminfo li i {
  color: #999999;
  display: block;
  font-size: 20px;
  line-height: inherit;
}
.tu-blogiteminfo li i span {
  display: inline-block;
  margin: 0 0 0 10px;
  color: #676767;
  line-height: inherit;
  vertical-align: top;
}

.tu-mainlist {
  padding: 0;
  margin: 0;
}
.tu-mainlist + .tu-main-description {
  padding-top: 27px;
}
.tu-mainlist li {
  position: relative;
  padding-left: 28px;
  line-height: 1.625em;
}
.tu-mainlist li::after {
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  color: #ffffff;
  content: "\f00c";
  position: absolute;
  text-align: center;
  border-radius: 50%;
  background: #22c55e;
  font: 700 0.75rem/1.6666666667em "Font Awesome 6 Free";
}
.tu-mainlist li + li {
  margin-top: 16px;
}

.tu-featurelist .tu-mainlist {
  margin: 10px 0 0;
}

.tu-blogfeatures {
  flex-wrap: nowrap;
  align-items: center;
}
.tu-blogfeatures figure {
  flex: none;
  margin: 0 24px 0 0;
  max-width: 416px;
}
.tu-blogfeatures figure img {
  width: 100%;
  border-radius: 4px;
  display: block;
}
.tu-blogfeatures .tu-featurelist .tu-mainlist {
  margin: 0;
}
.tu-blogfeatures .tu-description p {
  margin: 0 0 26px;
}

.tu-overviewtitle h4 {
  font-weight: 600;
  margin: 0 0 10px;
}
.tu-overviewtitle > .tu-description p {
  margin: 0;
}
.tu-overviewtitle > .tu-description p ~ p {
  margin: 26px 0 0;
}
.tu-overviewtitle > .tu-description ~ .tu-description {
  margin: 30px 0 0;
}
.tu-overviewtitle .tu-quototitle {
  margin-top: 30px;
}

.tu-quototitle {
  width: 100%;
  margin: 0 auto;
  max-width: 640px;
}
.tu-quototitle blockquote {
  margin: 0 0 10px;
}
.tu-quototitle h3 {
  font-weight: 600;
  margin: 0 0 10px;
}
.tu-quototitle h3 q::before {
  content: none;
}

.tu-quotoauthor a {
  display: inline-block;
  font-weight: 600;
  position: relative;
}
.tu-quotoauthor a::after {
  position: absolute;
  content: "";
  right: -54px;
  width: 40px;
  height: 2px;
  background: #DDDDDD;
  top: 50%;
}
.tu-quotoauthor a::before {
  position: absolute;
  content: "";
  left: -54px;
  width: 40px;
  height: 2px;
  background: #DDDDDD;
  top: 50%;
}

.tu-videoimg figure {
  margin: 0 0 30px;
}
.tu-videoimg figure img {
  width: 100%;
}

.tu-videoimg {
  margin: 30px 0;
  max-height: 450px;
}

.tu-sorttags {
  align-items: center;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  padding: 29px 0;
  margin: 30px 0 0;
}
.tu-sorttags h6 {
  font-weight: 600;
  margin: 0 20px 0 0;
}

.tu-taglist {
  padding: 0;
  gap: 16px;
}
.tu-taglist li {
  list-style-type: none;
}
.tu-taglist li a {
  display: inline-block;
  color: #999999;
  font-weight: 600;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 5px 14px;
}

.tu-articleitem {
  background: #ffffff;
  border-radius: 0 0 4px 4px;
  margin: 20px 0 0;
}
.tu-articleitem figure {
  margin: 0;
  border-radius: 4px 4px 0 0;
}
.tu-articleitem figure img {
  width: 100%;
  border-radius: inherit;
}
.tu-articleitem:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}

.tu-taglinksm li {
  line-height: 21px;
}
.tu-taglinksm li a {
  line-height: inherit;
}

.tu-articleinfo {
  padding: 20px 19px 20px;
  border: 1px solid #eeeeee;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}
.tu-articleinfo .tu-description {
  padding-top: 0;
}
.tu-articleinfo .tu-description p {
  margin: 0;
  color: #676767;
}
.tu-articleinfo .tu-articleauth {
  margin: 20px 0 0;
}

.tu-arrticltitle h5 {
  overflow: hidden;
  font-weight: 600;
  margin: 0 0 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.tu-articleauthor {
  align-items: center;
}
.tu-articleauthor figure {
  max-width: 30px;
  margin: 0 10px 0 0;
  flex: none;
}
.tu-articleauthor figure img {
  width: 100%;
  border-radius: 50%;
}
.tu-articleauthor h6 {
  margin: 0;
  font-weight: 600;
}

.tu-articleauth {
  align-items: center;
  justify-content: space-between;
}
.tu-articleauth span {
  font-weight: 600;
  color: #999999;
}

.tu-boxlg {
  padding: 29px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tu-boxtitle h3 {
  font-weight: 600;
  margin: 0 0 30px;
}

.tu-commentlist {
  flex-wrap: nowrap;
  align-items: flex-start;
}
.tu-commentlist figure {
  flex: none;
  max-width: 60px;
  margin: 0 20px 0 0;
}
.tu-commentlist figure img {
  max-width: 60px;
  border-radius: 4px;
}

.tu-commentauthor h6 {
  margin: 0;
  font-weight: 400;
  color: #676767;
}
.tu-commentauthor h6 span {
  font-weight: 600;
  padding-right: 10px;
  display: inline-block;
  color: #1C1C1C;
}
.tu-commentauthor h5 {
  font-weight: 600;
  margin: 0;
}

.tu-coomentareaauth {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tu-coomentareaauth .tu-description {
  margin: 20px 0 30px;
  word-break: break-word;
}
.tu-coomentareaauth .tu-description p {
  margin: 0;
}
.tu-coomentareaauth .tu-description p ~ p {
  margin: 15px 0 0;
}

.tu-commentright {
  width: 100%;
  padding: 4px 0;
  align-items: center;
  justify-content: space-between;
}

.tu-commentarea .tu-commentlist {
  box-shadow: inset 0px -1px 0px #EEEEEE;
}
.tu-commentarea .tu-commentlist ~ .tu-commentlist {
  padding: 30px 0 0;
}
.tu-commentarea .tu-commentchild {
  padding-left: 80px;
  margin: 30px 0 0;
}
.tu-commentarea .show-more .tu-readmorebtn {
  margin-top: -1px;
  line-height: 30px;
}

.tu-input-counter {
  width: 100%;
  display: flex;
  color: #999999;
  text-align: right;
  padding-top: 10px;
  justify-content: flex-end;
}
.tu-input-counter span {
  font-weight: 600;
  margin-right: 4px;
  color: #1C1C1C;
}
.tu-input-counter b {
  font-weight: 600;
  margin-right: 4px;
  color: #1C1C1C;
}
.tu-input-counter em {
  color: #999999;
  font-weight: 600;
  margin-left: 4px;
  font-style: normal;
}
.tu-input-counter > * {
  display: inline-block;
}

.tu-formspacebtw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.tu-formspacebtw .tu-check {
  padding-right: 20px;
}

.tu-listview {
  padding: 0;
  margin: 0 -5px;
}
.tu-listview li {
  list-style-type: none;
  padding: 0 5px;
}
.tu-listview li a {
  display: inline-block;
  padding: 12px;
  max-width: 48px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid transparent;
}
.tu-listview li a i {
  display: inline-block;
  font-size: 24px;
  color: #999999;
}
.tu-listview .active a {
  border-radius: 4px;
  border: 1px solid #eeeeee;
  background: #ffffff;
}

/** ====== search listing page style ====== **/
.tu-listing-wrapper {
  margin-bottom: 40px;
}

.tu-sort {
  align-items: center;
  justify-content: space-between;
}
.tu-sort h3 {
  font-weight: 400;
  margin-bottom: 0;
}
.tu-sort h3 span {
  font-weight: 600;
  padding: 0 6px;
}

.tu-sortby {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.tu-sortby > span {
  flex: none;
  color: #999999;
  font-weight: 600;
}
.tu-sortby .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 0 0 10px;
}
.tu-sortby .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: none;
}
.tu-sortby .tu-select::before, .tu-sortby .tu-calendar::before {
  right: 5px;
}

.tu-sort-right-area {
  display: flex;
  align-items: center;
}

.tu-filter-btn {
  flex: none;
  display: flex;
  align-items: center;
}
.tu-filter-btn a {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 4px;
  color: #999999;
  background: transparent;
  border: 1px solid #eeeeee;
  border-color: transparent;
}
.tu-filter-btn a:hover, .tu-filter-btn a.active {
  color: #999999;
  background: #ffffff;
  border-color: #eeeeee;
}

.tu-appendinput {
  width: 100%;
  display: flex;
  padding: 9px;
  max-width: 1076px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-appendinput .form-control {
  border: 0;
  height: 48px;
}
.tu-appendinput .select2-container--default .select2-selection--single {
  border: 0;
  padding-left: 30px;
}
.tu-appendinput .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 48px;
}
.tu-appendinput .select2-container {
  height: 48px;
}
.tu-appendinput .select2-container--focus {
  box-shadow: none;
}
.tu-appendinput .tu-inputicon {
  flex: auto;
}
.tu-appendinput .tu-inputicon .form-control {
  padding-left: 40px;
}
.tu-appendinput .tu-inputicon > i,
.tu-appendinput .tu-inputicon a {
  left: 10px;
  right: auto;
}
.tu-appendinput .tu-inputicon > i i,
.tu-appendinput .tu-inputicon a i {
  display: block;
}
.tu-appendinput .tu-select, .tu-appendinput .tu-calendar {
  width: 300px;
  flex: none;
}
.tu-appendinput .tu-select::before, .tu-appendinput .tu-calendar::before {
  font-size: 20px;
}
.tu-appendinput .tu-select > i, .tu-appendinput .tu-calendar > i {
  position: absolute;
  left: 20px;
  font-size: 20px;
  color: #999999;
  z-index: 1;
  pointer-events: none;
}

.tu-searcbar {
  width: 100%;
  display: flex;
  align-items: center;
}
.tu-searcbar > a {
  flex: none;
  border-radius: 0px 4px 4px 0px;
}

.tu-searchbar-wrapper {
  display: flex;
  margin-top: 20px;
  align-items: center;
  position: relative;
}

.tu-listing-search {
  display: flex;
  align-items: center;
  flex: none;
}
.tu-listing-search figure {
  margin-bottom: 0;
  padding: 0 15px 0 24px;
}
.tu-listing-search span {
  font-size: 16px;
  line-height: 19px;
  color: #676767;
  font-weight: normal;
  letter-spacing: 0.5px;
  font-family: Gochi Hand;
}

.tu-searchtags {
  gap: 10px;
  padding: 0;
  margin: 20px 0 0;
  align-items: center;
}
.tu-searchtags li {
  background: #ffffff;
  padding: 5.5px 12px;
  border-radius: 4px;
  border: 1.5px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-searchtags li span {
  display: flex;
  align-items: center;
  color: #484848;
  font-weight: 600;
}
.tu-searchtags li span a {
  color: #484848;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  margin-left: 6px;
}
.tu-searchtags li span a:hover {
  color: #484848;
}

.tu-dbsidenav {
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tu-asidetitle h5 {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 600;
}
.tu-asidetitle h5::after {
  content: "\e9a3";
  right: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  line-height: 15px;
  color: #0A0F26;
  font-family: "icomoon" !important;
}
.tu-asidetitle[aria-expanded=false] h5:after {
  content: "\e9c0";
}

.tu-aside-content {
  padding-top: 10px;
}
.tu-aside-content .select2-container {
  height: 50px;
}
.tu-aside-content .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 47px;
}
.tu-aside-content .tu-filterselect ~ .tu-distanceholder .tu-rangeslider {
  padding: 19px 20px 31px;
}

.tu-aside-holder ~ .tu-aside-holder {
  margin-top: 30px;
}

.tu-search-icon {
  right: 20px;
  display: flex;
  position: absolute;
}
.tu-search-icon i {
  color: #999999;
  font-size: 20px;
}

.tu-filterselect {
  margin-bottom: 10px;
  position: relative;
}
.tu-filterselect h6 {
  margin-bottom: 14px;
  font-weight: 600;
}
.tu-filterselect:last-child {
  margin-bottom: 0;
}
.tu-filterselect .tu-placeholder span {
  font-weight: 600;
}

.tu-categoriesfilter {
  padding-left: 0;
}
.tu-categoriesfilter li + li {
  padding-top: 14px;
}
.tu-categoriesfilter li:last-child {
  padding-bottom: 0;
}
.tu-categoriesfilter .form-check-label span {
  color: #0A0F26;
}

.tu-readmorebtn {
  width: 100%;
  padding: 5px;
  display: flex;
  color: #999999;
  margin-top: 10px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background-color: #F7F8FC;
}
.tu-readmorebtn span {
  display: inline-flex;
  align-items: center;
}
.tu-readmorebtn:hover {
  color: #1C1C1C;
}
.tu-readmorebtn > i {
  bottom: 45px;
  color: #999999;
  font-size: 20px;
  position: absolute;
  pointer-events: none;
  animation: spin 1.8s infinite linear;
}

.tu-rangevalue {
  display: flex;
  flex-direction: column;
}
.tu-rangevalue .form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.noUi-target {
  border: 0;
  height: 3px;
  margin-top: 18px;
  border-radius: 3px;
  background: #f7f7f7;
}

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  top: 0px;
}

.noUi-handle {
  border-radius: 50%;
  background: #63d594;
  border: 2px solid #fff;
  top: 1px;
  transform: translateY(-50%);
  outline: none;
  border: none;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}

.noUi-connect {
  background: #22C55E;
}

.noUi-handle:after,
.noUi-handle:before {
  content: none;
}

.tu-distanceholder .tu-distance {
  box-shadow: none;
}
.tu-distanceholder .tu-btnholder .tu-btn {
  min-width: 60px;
  height: 36px;
  font-size: 14px;
}

.tu-areasizebox {
  gap: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tu-areasizebox .form-control {
  height: 50px;
  background: #ffffff;
}

.tu-rangeslider {
  margin: 10px 0 0;
  box-shadow: none;
  background: #ffffff;
  position: relative;
  border-radius: 4px;
  padding: 27px 19px 35px 19px;
  border: 1px solid #eeeeee;
}
.tu-rangeslider span {
  display: block;
  font-weight: 600;
}
.tu-rangeslider span em {
  font-style: normal;
  float: right;
}
.tu-rangeslider span span {
  float: right;
}

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  top: 2px;
  background: #22C55E;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.noUi-connects {
  height: 3px;
  border-radius: 60px;
  background: #DDDDDD;
}

.noUi-horizontal .noUi-handle {
  right: -12px;
}

.tu-tooltiparrow:after {
  content: " ";
  position: absolute;
  border: 1px solid #eeeeee !important;
  transform: rotate(135deg) !important;
  height: 12px;
  width: 12px;
  border-top: 0 !important;
  border-right: 0 !important;
  background: #fff;
  top: -6px;
  left: 68px;
}

.tu-dbmenu {
  top: 150px;
  left: -41px;
  width: 40px;
  color: #ffffff;
  display: none;
  font-size: 20px;
  line-height: 40px;
  position: absolute;
  text-align: center;
  background: #6A307D;
  border-radius: 10px 0 0 10px;
}
.tu-dbmenu i {
  display: block;
  line-height: inherit;
}
.tu-dbmenu:hover {
  color: #ffffff;
}

.tu-checksm .tu-totalreview {
  margin-left: 10px;
}
.tu-checksm .tu-totalreview > span {
  font-weight: 600;
  color: #1C1C1C;
}
.tu-checksm .tu-totalreview > span em {
  font-weight: 600;
  font-style: normal;
  color: #999999;
}
.tu-checksm .tu-totalreview > span em + em {
  margin: 0 0 0 6px;
  display: inline-block;
}

.tu-fourstar span {
  width: 80%;
}

.tu-threestar span {
  width: 60%;
}

.tu-twostar span {
  width: 40%;
}

.tu-onestar span {
  width: 20%;
}

.tu-filterbtns {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
}
.tu-filterbtns a {
  width: 100%;
}
.tu-filterbtns a + a {
  margin-top: 10px;
}
.tu-filterbtns .tu-sb-sliver {
  font-weight: 600;
  padding: 7px 24px;
  color: #999999;
  background-color: #F7F8FC;
  border-radius: 4px;
}

.tu-listinginfo-holder .tu-listinginfo + .tu-listinginfo {
  margin-top: 20px;
}

.tu-listinginfo {
  width: 100%;
  background: #ffffff;
  position: relative;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
}
.tu-listinginfo:hover {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.04), 0px 3px 16px rgba(0, 0, 0, 0.04);
}
.tu-listinginfo .tu-cardtag {
  top: 0;
  transform: unset;
}
.tu-listinginfo_title {
  align-items: center;
  justify-content: space-between;
}
.tu-listinginfo_price > span {
  display: block;
  text-align: right;
  color: #676767;
}
.tu-listinginfo_price > h4 {
  margin-bottom: 0;
  color: #1DA1F2;
  font-weight: 600;
}
.tu-listinginfo_description {
  margin-top: 20px;
}
.tu-listinginfo_description > p {
  margin-bottom: 0;
  color: #676767;
}
.tu-listinginfo_service {
  margin-top: 20px;
}
.tu-listinginfo_service > h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #1C1C1C;
}
.tu-listinginfo_btn {
  align-items: center;
  padding: 20px 20px 19px;
  gap: 2px;
  justify-content: space-between;
}
.tu-listinginfo_wrapper {
  padding: 19px;
  border-bottom: 1px dashed #DDDDDD;
}

.tu-listinginfo-img {
  align-items: center;
}
.tu-listinginfo-img figure {
  flex: none;
  margin: 0 14px 0 0;
}
.tu-listinginfo-img figure img {
  border-radius: 4px;
}

.tu-listing-heading h5 {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.tu-listing-heading h5 i {
  font-size: 17px;
  padding-left: 6px;
  line-height: inherit;
}

.tu-listing-location {
  display: flex;
  align-items: center;
}
.tu-listing-location > span {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}
.tu-listing-location > span > i {
  padding-left: 6px;
  color: #FFD101;
  font-size: 0.875rem;
}
.tu-listing-location > span > em {
  font-style: normal;
  color: #484848;
  padding-left: 6px;
}
.tu-listing-location > address {
  margin: 0;
  font-style: normal;
  color: #484848;
  display: flex;
  align-items: center;
}
.tu-listing-location > address > i {
  font-size: 1rem;
  color: #999999;
  padding-right: 6px;
}

.tu-service-list {
  margin: -5px;
  align-items: center;
  padding: 10px 0 0 0;
}
.tu-service-list li {
  width: 100%;
  padding: 5px;
  max-width: 210px;
}
.tu-service-list li span {
  color: #1C1C1C;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background: #F7F8FC;
  padding: 10px;
}
.tu-service-list li span > i {
  font-size: 1.125rem;
  padding-right: 10px;
}

.tu-greenclr {
  color: #22C55E;
}

.tu-blueclr {
  color: #1DA1F2;
}

.tu-orangeclr {
  color: #F97316;
}

.tu-iconheart {
  align-items: center;
  cursor: pointer;
}
.tu-iconheart i {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #999999;
  padding-right: 6px;
}
.tu-iconheart span {
  color: #676767;
  display: inline-block;
}

.tu-colorred {
  color: #EF4444 !important;
}
.tu-colorred::before {
  content: "\f004";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.tu-btnarea {
  display: flex;
  align-items: center;
}
.tu-btnarea a + a {
  margin-left: 10px;
}
.tu-btnarea .tu-secbtn {
  color: #999999;
  padding: 0 22.5px;
}

.tu-listinginfo_two {
  display: flex;
  padding: 19px;
}
.tu-listinginfo_two .tu-service-list {
  flex-wrap: nowrap;
}
.tu-listinginfo_two .tu-listinginfo_price > h4 {
  font-size: 18px;
  line-height: 26px;
}
.tu-listinginfo_two .tu-listinginfo_wrapper {
  border: 0;
  padding: 0;
}
.tu-listinginfo_two .tu-listinginfo_wrapper .tu-serviceslist {
  padding: 0;
  margin-top: 15px;
}
.tu-listinginfo_two .tu-listinginfo-img figure {
  margin-right: 10px;
}
.tu-listinginfo_two .tu-listinginfo-img figure img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.tu-listinginfo_two .tu-listing-location > span {
  margin-left: 20px;
}

.tu-listing-slider {
  max-width: 310px;
  padding-right: 20px;
}
.tu-listing-slider .splide__slide {
  border: 0 !important;
}
.tu-listing-slider div[class*=tusyncthumbnail_] {
  margin-top: 10px;
}

.tu-sync__content {
  margin-bottom: 0;
}
.tu-sync__content a {
  display: block;
}
.tu-sync__content a img {
  width: 100%;
  display: block;
  min-height: 226px;
  border-radius: 4px;
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 5px solid #ffffff;
}

.tusyncthumbnail__content {
  margin-bottom: 0;
}
.tusyncthumbnail__content img {
  border-radius: 4px;
}

.tusyncthumbnail_one {
  margin: 10px 0 0;
}
.tusyncthumbnail_one .splide__slide {
  border: 0 !important;
}

.vbox-close {
  top: 10px;
  right: 20px;
  font-size: 40px;
}

/** ====== how it work page style ====== **/
.tu-howit-steps {
  padding: 10px 0 0;
}
.tu-howit-steps_info {
  padding: 29px;
  align-items: flex-start;
  flex-direction: column;
}
.tu-howit-steps_info > h5 {
  margin-bottom: 10px;
  font-weight: 600;
}
.tu-howit-steps_info > p {
  margin-bottom: 0;
}
.tu-howit-steps_content {
  display: flex;
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  align-items: flex-start;
  flex-direction: column;
  flex: max(416px, 25%);
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-howit-steps_content figure {
  margin: 0;
  width: 100%;
  text-align: center;
}
.tu-howit-steps_content figure img {
  width: 100%;
}

.tu-step-tag {
  display: block;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 4px 10.1px;
  border-radius: 4px;
  background-color: black;
}

.tu-orange-bgclr {
  background-color: #F97316;
}

.tu-green-bgclr {
  background-color: #22C55E;
}

.tu-blue-bgclr {
  background-color: #1DA1F2;
}

.tu-purple-bgclr {
  background-color: #6366F1;
}

.tu-processing-holder {
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.tu-processing-content {
  width: 50%;
  flex: auto;
  padding: 50px;
}
.tu-processing-content > .tu-maintitle, .tu-processing-content > .tu-processing-list {
  max-width: 650px;
}

.tu-processing-list {
  padding-left: 0;
}
.tu-processing-list li p {
  margin: 0 0 0 78px;
}
.tu-processing-list li + li {
  margin-top: 20px;
}

.tu-processing-img {
  width: 50%;
}
.tu-processing-img figure {
  margin-bottom: 0;
}
.tu-processing-img figure img {
  min-height: 718px;
  object-fit: cover;
}

.tu-processinglist-info {
  display: flex;
  align-items: center;
}
.tu-processinglist-info i {
  flex: none;
  width: 60px;
  height: 60px;
  display: flex;
  font-size: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.tu-processinglist-info h4 {
  margin: 0 0 0 16px;
  font-weight: 600;
}

.tu-iconpurple_bgclr {
  background-color: rgba(115, 87, 251, 0.1);
  color: #7357FB;
}

.tu-icongreen_bgclr {
  background: rgba(24, 185, 155, 0.1);
  color: #18B99B;
}

.tu-iconorange_bgclr {
  background: rgba(255, 97, 103, 0.1);
  color: #FF6167;
}

.tu-banner_list-two {
  padding-top: 10px;
  justify-content: center;
}

/** ====== package page style ====== **/
.tu-pricingtop {
  text-align: center;
  max-width: 856px;
  margin: 0 auto;
}
.tu-pricingtop h4 {
  margin: 0;
  font-weight: 400;
}
.tu-pricingtop h2 {
  font-weight: 600;
  margin: 0 0 20px;
}
.tu-pricingtop p {
  margin: 0 auto;
  max-width: 750px;
}

.tu-pricinglist {
  margin: 40px 0 0;
  padding: 0;
  gap: 10px;
  flex-wrap: nowrap;
}
.tu-pricinglist > li {
  flex: max(316px, 20%);
}

.tu-plandetail h4 {
  font-weight: 600;
  margin: 0;
}
.tu-plandetail h3 {
  margin: 0;
  gap: 0 10px;
  color: #F97316;
  font-weight: 600;
  align-items: center;
}
.tu-plandetail h3 span {
  color: #1C1C1C;
}
.tu-plandetail > span {
  display: block;
}
.tu-plandetail p {
  margin: 20px 0 0;
}

.tu-planlist {
  background: #ffffff;
  padding: 19px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-planlist .tu-btnarea {
  margin: 20px 0 0;
}
.tu-planlist .tu-btnarea a {
  width: 100%;
}
.tu-planlist:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.tu-planperks {
  padding: 0;
  list-style: none;
  margin: 20px 0 0;
}
.tu-planperks li {
  list-style-type: none;
}
.tu-planperks li span {
  font-size: 1rem;
  align-items: center;
  line-height: 1.625em;
  justify-content: space-between;
}
.tu-planperks li span i {
  font-size: 16px;
}
.tu-planperks li span i.fa-times {
  color: #EF4444;
}
.tu-planperks li span em {
  font-style: normal;
  font-weight: 600;
}
.tu-planperks li + li {
  margin-top: 10px;
}

.tu-popularplan {
  border: 1px solid #0A0F26;
  border-radius: 10px;
  transform: translate(0, -44px);
}
.tu-popularplan .tu-planlist {
  border-radius: 0 0 10px 10px;
  border: 0;
}

.tu-popularplantop {
  background: #0A0F26;
  padding: 7px 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}
.tu-popularplantop h6 {
  color: #ffffff;
  margin: 0;
}
.tu-popularplantop h6 i {
  display: inline-block;
  font-size: 20px;
  margin: 0 10px 0 0;
}

.tu-btnorange {
  background-color: #F97316 !important;
}

.tu-btnplain {
  border: 2px solid #eeeeee;
  background: transparent;
  color: #1C1C1C;
}
.tu-btnplain:hover, .tu-btnplain:focus {
  background: #F97316;
  color: #ffffff;
  border-color: transparent;
}
.tu-btnplain:hover::before, .tu-btnplain:focus::before {
  opacity: 0;
}

/** ====== profile setting page style ====== **/
.tu-asider-holder {
  overflow: hidden;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tu-asidebox {
  padding: 29px 29px 30px;
}
.tu-asidebox figure {
  max-width: 150px;
  margin: 0 auto 10px auto;
}
.tu-asidebox figure figcaption {
  position: absolute;
  top: 0;
  right: 0;
}
.tu-asidebox figure img {
  width: 100%;
  border-radius: 50%;
}

.tu-uploadimage input {
  display: none;
}
.tu-uploadimage label {
  display: inline-block;
  cursor: pointer;
}
.tu-uploadimage label i {
  font-size: 18px;
  color: #999999;
  height: 42px;
  width: 42px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 6px solid #fff;
  background: #fff;
}

.tu-uploadinfo h6 {
  color: #676767;
  margin: 0;
}
.tu-uploadinfo a {
  margin: 10px 0 0;
  width: 100%;
}

.tu-boxitem {
  padding: 28px;
  flex-wrap: nowrap;
  border-radius: 4px;
  align-items: center;
  border: 2px solid #F97316;
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  background: linear-gradient(0deg, rgb(225 118 40 / 8%), rgb(197 124 34 / 8%)), #FFFFFF;
}
.tu-boxitem .tu-btnrea {
  flex: none;
  align-items: center;
}

.tu-alertcontent {
  margin: 0 40px 0 0;
}
.tu-alertcontent h4 {
  margin: 0 0 10px;
  font-weight: 600;
}
.tu-alertcontent p {
  margin: 0;
}

.tu-box {
  padding: 29px;
  flex-wrap: nowrap;
  background: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-box h3 {
  font-weight: 600;
  margin: 0 0 30px;
}
.tu-box .tu-googlelink {
  margin: 0 0 30px;
}

.tu-boxsm {
  padding: 20px 29px;
}

.tu-boxarea {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
}
.tu-boxarea .tu-box {
  box-shadow: none;
  border: 0;
  border-top: 1px solid #eeeeee;
  border-radius: 0 0 4px 4px;
}
.tu-boxarea .tu-boxsm {
  border-radius: 4px 4px 0 0;
  border-top: 1px solid #eeeeee;
}



.tu-boxsmtitle {
  align-items: center;
  justify-content: space-between;
}
.tu-boxsmtitle h4 {
  font-weight: 600;
  margin: 0;
}
.tu-boxsmtitle a {
  color: #1DA1F2;
  font-weight: 600;
}

.tu-profilewrapper .tu-boxwrapper {
  margin: 30px 0 0;
}
.tu-profilewrapper .tu-accordioneduc p {
  max-width: 100%;
  margin: 20px 0 0;
}
.tu-profilewrapper .tu-expinfo > i {
  padding-left: 16px;
}
.tu-profilewrapper .tu-icanteach > li h6 {
  padding-top: 0;
}

.tu-labels {
  margin: -5px;
  padding: 10px 0 0;
}
.tu-labels li {
  list-style: none;
  padding: 5px;
}
.tu-labels li span {
  height: 28px;
  display: flex;
  padding: 0 12px;
  font-weight: 600;
  align-items: center;
  border-radius: 4px;
  background-color: #F7F8FC;
  color: #999999;
}
.tu-labels li span:hover {
  background-color: #EF4444;
  color: #ffffff;
}
.tu-labels li span:hover a {
  color: #ffffff;
}
.tu-labels li span a {
  display: flex;
  color: #999999;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
}

.tu-status-filter {
  align-items: center;
  padding-left: 0;
  width: 100%;
  gap: 0 20px;
}
.tu-status-filter li {
  width: 100%;
  max-width: 288px;
}

.tu-status-contnent label {
  color: #999999;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1.5px solid #e1e1e1;
  background-color: #FCFCFC;
}
.tu-status-contnent input:checked + label {
  color: #1C1C1C;
  background-color: #fff;
}

.form-group-wrap .tu-status-contnent .tu-check {
  padding-top: 0;
}

.tu-boxwrapper .tu-boxarea + .tu-boxarea {
  margin-top: 30px;
}

.tu-btnarea-two {
  margin-top: 30px;
  align-items: center;
  justify-content: flex-end;
}
.tu-btnarea-two span {
  padding-right: 30px;
}

.tu-side-tabs {
  padding-left: 0;
}
.tu-side-tabs .nav-item {
  width: 100%;
  background: #ffffff;
}
.tu-side-tabs .nav-item .nav-link {
  box-shadow: inset 0px 1px 0px #EEEEEE;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-align: left;
  color: #1C1C1C;
  position: relative;
  padding: 12px 29px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tu-side-tabs .nav-item .nav-link::before {
  height: 0;
  width: 4px;
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  background-color: #6A307D;
}
.tu-side-tabs .nav-item .nav-link > i {
  font-size: 18px;
  padding-right: 10px;
  color: #999999;
}
.tu-side-tabs .nav-item .nav-link > span {
  display: inline-block;
}
.tu-side-tabs .nav-item .nav-link:hover, .tu-side-tabs .nav-item .nav-link.active {
  background: #FCFCFC;
}
.tu-side-tabs .nav-item .nav-link:hover::before, .tu-side-tabs .nav-item .nav-link.active::before {
  height: 100%;
}

.modal-dialog {
  max-width: 636px;
}

.modal-content {
  border-radius: 10px;
  border: 0;
}

.modal-header {
  border: 0;
  border-bottom: 1px solid #DDDDDD;
  padding: 22px 30px;
}
.modal-header h5 {
  font-weight: 600;
  margin-bottom: 0;
}

.tu-close {
  font-size: 30px;
  color: #1C1C1C;
}
.tu-close:hover {
  color: #1C1C1C;
}

.modal-body {
  padding: 30px;
}

.tu-formbtn {
  padding-top: 10px;
}
.tu-formbtn [class^=tu-primbtn] {
  width: 100%;
}

.tu-deleteclr {
  color: #EF4444;
}

.tu-editclr {
  color: #22C55E;
}

.tu-icon-holder {
  display: flex;
  align-items: center;
  line-height: 18px;
  margin-left: auto;
}
.tu-icon-holder a > i {
  display: block;
  font-size: 18px;
}
.tu-icon-holder a + a {
  padding-left: 16px;
}
.tu-icon-holder > i {
  padding-left: 16px;
}

.tu-uploadphoto {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  background-color: #F7F8FC;
  width: 100%;
}
.tu-uploadphoto svg {
  position: absolute;
  fill: transparent;
  stroke: #999999;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: visible;
  z-index: -1;
  padding: 0 1px;
  width: 100%;
  height: 100%;
}
.tu-uploadphoto svg rect {
  stroke-width: 1px;
  stroke-dasharray: 10, 10;
  rx: 10px;
}
.tu-uploadphoto > i {
  color: #DDDDDD;
  font-size: 30px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #ffffff;
  border: 1px solid #f7f7f7;
  margin-bottom: 10px;
}
.tu-uploadphoto img {
  border-radius: 10px;
  margin-bottom: 20px;
}
.tu-uploadphoto h5 {
  font-weight: 600;
  padding: 2px 0;
  margin-bottom: 0;
}
.tu-uploadphoto h5 input {
  display: none;
}
.tu-uploadphoto h5 label {
  color: #1DA1F2;
  margin: 0;
  display: inline-block;
  cursor: copy;
  font-weight: inherit;
}
.tu-uploadphoto p {
  margin: 10px 0 0;
  max-width: 430px;
}
.tu-uploadphoto .tu-tpbtn {
  margin-top: 20px;
  color: #EF4444;
  height: 40px;
}

.tu-thumbnails {
  padding-left: 0;
  margin: -5px;
}
.tu-thumbnails_action {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tu-thumbnails_action span {
  font-size: 26px;
  color: #ffffff;
  cursor: pointer;
}
.tu-thumbnails_action img {
  padding: 10px;
  margin-top: 10px;
}
.tu-thumbnails_content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.tu-thumbnails_content figure {
  margin: 0;
  border-radius: 10px;
}
.tu-thumbnails_content figure img {
  border-radius: 10px;
  width: 107.2px;
  height: 99.23px;
}
.tu-thumbnails_content > i {
  position: absolute;
  font-size: 14px;
  color: #1C1C1C;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
}
.tu-thumbnails_content:hover {
  background-color: #EF4444;
}
.tu-thumbnails_content:hover .tu-thumbnails_action {
  visibility: visible;
  opacity: 1;
}
.tu-thumbnails_content:hover figure,
.tu-thumbnails_content:hover > i {
  opacity: 0;
}
.tu-thumbnails li {
  list-style: none;
  padding: 5px;
}
.tu-thumbnails .tu-thumbnails_loading {
  background-color: #f7f7f7;
}
.tu-thumbnails .tu-thumbnails_loading:hover {
  background-color: #f7f7f7;
}
.tu-thumbnails .tu-thumbnails_loading figure {
  opacity: 0;
}
.tu-thumbnails .tu-thumbnails_loading::before {
  width: 20px;
  height: 20px;
  position: absolute;
  animation: spin 1.2s infinite linear;
  animation-name: fa-spin;
  /* content: url("./../images/circle-loader.png"); */
}
.tu-thumbnails .tu-thumbnails_loading .tu-thumbnails_action {
  visibility: hidden;
  opacity: 0;
}

.tu-appendbtn {
  padding: 6.5px 7px 6.5px 0;
  display: flex;
  align-items: center;
  border: 1.5px solid #e1e1e1;
  border-radius: 4px;
  width: 100%;
}
.tu-appendbtn .form-control {
  border: 0;
  height: 34px;
}
.tu-appendbtn a {
  flex: none;
}

.splide__pagination {
  position: relative;
  bottom: 0;
  margin-top: 20px;
}
.splide__pagination__page {
  width: 10px;
  height: 10px;
  display: block;
  background: #DDDDDD;
  opacity: 1;
  margin: 0 5px;
}
.splide__pagination__page.is-active {
  background: #6A307D;
  transform: none;
}

.tu-uploadimgbtn {
  margin: 10px 0 0;
}
.tu-uploadimgbtn input[type=file] {
  display: none;
}
.tu-uploadimgbtn label {
  margin: 0;
  width: 100%;
}

.tu-dhbform .tu-inputicon i {
  display: block;
  line-height: inherit;
}

.tu-accordionedu {
  width: 100%;
}

/** ====== tutor detail page style ====== **/
.tu-protutordetail {
  align-self: flex-start;
  width: 100%;
}
.tu-protutordetail > a {
  display: block;
}
.tu-protutordetail > a ~ a {
  margin: 10px 0 0;
}
.tu-protutordetail > a > i {
  font-size: 18px;
  color: #999999;
  margin: 0 10px 0 0;
  display: inline-block;
}
.tu-protutordetail h3 {
  margin: 0;
  align-items: center;
  font-weight: 600;
  display: inline-flex;
}
.tu-protutordetail h3 > i {
  font-size: 18px;
  display: inline-block;
}
.tu-protutordetail h3 > i:first-child {
  margin: 0 0 0 14px;
}
.tu-protutordetail h3 > i + i {
  margin: 0 0 0 10px;
}
.tu-protutordetail h3 > i ~ i {
  margin: 0 0 0 10px;
}
.tu-protutordetail h3 > a {
  display: inline-block;
}
.tu-protutordetail h3 i {
  display: inline-block;
  margin: 0 10px 0 0;
}
.tu-protutordetail h5 {
  color: #999999;
  margin: 0 0 10px;
}
.tu-protutordetail h4 i {
  font-size: 18px;
  display: inline-block;
  margin: 0 10px 0 0;
}

.tu-icongreen {
  color: #22c55e;
}

.tu-profileview {
  display: flex;
  padding: 19px;
  align-items: center;
}
.tu-profileview figure {
  margin: 0 20px 0 0;
  max-width: 200px;
  flex: none;
}
.tu-profileview figure img {
  width: 100%;
  border-radius: 5px;
}

.tu-tutorprofilewrapp {
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  border: 1px solid #eeeeee;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-tutorprofilewrapp .tu-cardtag {
  top: 0;
  right: 30px;
  transform: translate(0);
}
.tu-tutorprofilewrapp ~ .tu-detailstabs {
  margin: 30px 0 0;
}

.tu-detailitem {
  width: 100%;
}

.tu-detailitem h6 {
  color: #999999;
  margin: 0 0 6px;
  font-weight: 600;
}

.tu-languages {
  padding: 0;
  margin: 0;
  gap: 5px 13px;
  flex-wrap: wrap;
}
.tu-languages > li {
  position: relative;
  padding: 0;
  font-weight: 600;
}
.tu-languages > li::after {
  content: ",";
  position: absolute;
  right: -5px;
  top: 0;
  color: #1c1c1c;
}
.tu-languages > li:last-child::after {
  display: none;
}
.tu-languages .tu-posttag {
  min-width: 180px;
}

.tu-showmore {
  display: inline-block;
  background-color: #ffffff;
  color: #1da1f2;
  font-weight: 600;
}
.tu-showmore:hover {
  border-color: transparent;
}

.tu-tutorreview {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 20px;
  gap: 5px 20px;
}
.tu-tutorreview li > span {
  font-weight: 600;
  display: flex;
  color: #1C1C1C;
}
.tu-tutorreview li > span i {
  display: flex;
  color: #999999;
  font-size: 16px;
  margin: 0 6px 0 0;
  align-items: center;
}
.tu-tutorreview li > span i:before {
  font-size: 18px;
}
.tu-tutorreview li > span i > em {
  font-weight: 600;
  display: inline-block;
  color: #1C1C1C;
  margin: 0 0 0 10px;
}
.tu-tutorreview li > span i > em span {
  font-weight: 600;
  color: #999999;
}
.tu-tutorreview li > span i > span {
  font-weight: 400;
  display: inline-block;
  color: #999999;
  margin: 0 0 0 10px;
}
.tu-tutorreview li > span > em {
  font-weight: 400;
  display: inline-block;
  color: #999999;
}
.tu-tutorreview li > span > em + em {
  margin: 0 0 0 6px;
}

.tu-coloryellow {
  color: #FFD101 !important;
}

.tu-colorgreen {
  color: #22C55E !important;
}

.tu-protutorinfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tu-startingrate span {
  display: inline-block;
  color: #676767;
  text-align: right;
  width: 100%;
}
.tu-startingrate h4 {
  font-weight: 600;
  color: #1DA1F2;
}

.tu-actionbts {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.tu-actionbts::after {
  position: absolute;
  content: "";
  right: 0;
  height: 1px;
  background-image: linear-gradient(90deg, #DDDDDD, #DDDDDD 61%, transparent 61%, transparent 100%);
  background-size: 10px 1px;
  border: none;
  top: 0;
  width: 100%;
}

.tu-profilelinksbtn {
  padding: 0;
  margin: 0;
  gap: 10px;
  flex: none;
  align-items: center;
}
.tu-profilelinksbtn .tu-secbtn {
  margin-left: 10px;
}

.tu-linkheart {
  display: flex;
  align-items: center;
  color: #676767;
}
.tu-linkheart i {
  font-size: 18px;
  margin: 0 6px 0 0;
  color: #999999;
  vertical-align: text-top;
}
.tu-linkheart:hover {
  color: #676767;
}

.tu-nav-tabs {
  border-bottom: 1px solid #eeeeee;
}
.tu-nav-tabs .nav-item {
  border-right: 1px solid #eeeeee;
}
.tu-nav-tabs .nav-item .nav-link.active {
  box-shadow: inset 0px -3px 0px #FFD101;
  position: relative;
}
.tu-nav-tabs .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #FFD101;
  bottom: 0;
  left: 0;
}
.tu-nav-tabs .nav-item .nav-link.active i {
  color: #1C1C1C;
}
.tu-nav-tabs .nav-item .nav-link.active span {
  color: #1C1C1C;
}
.tu-nav-tabs .nav-item .nav-link {
  border: 0;
  border-radius: 0;
  transition: none;
  align-items: center;
  padding: 16px 29.5px;
  color: #1C1C1C;
}
.tu-nav-tabs .nav-item .nav-link:hover {
  border-color: #eeeeee;
}
.tu-nav-tabs .nav-item .nav-link i {
  color: #999999;
  font-size: 20px;
  display: inline-block;
}
.tu-nav-tabs .nav-item .nav-link span {
  color: #999999;
  font-weight: 400;
  margin: 0 0 0 12px;
  display: inline-block;
}

.tu-detailstabs {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
}
.tu-detailstabs .tu-commentright {
  padding: 5px 0;
}

.tu-tabswrapper {
  padding: 30px;
}
.tu-tabswrapper > .tu-description {
  margin: 10px 0 0;
}
.tu-tabswrapper > .tu-description p {
  margin: 0;
}
.tu-tabswrapper > .tu-description p + p {
  margin-top: 26px;
}
.tu-tabswrapper .tu-blogfeatures {
  padding: 26px 0 16px;
}
.tu-tabswrapper .tu-tabstitle + div {
  margin: 10px 0 0;
}
.tu-tabswrapper ~ .tu-tabswrapper {
  box-shadow: inset 0px 1px 0px #EEEEEE;
}
.tu-tabswrapper .tu-icanteach {
  margin: 10px 0 0;
}

.tu-tabstitle h4 {
  margin: 0;
  font-weight: 600;
}

.tu-expinfo h5 {
  font-weight: 600;
  margin: 0 0 10px;
  display: inline-block;
  cursor: pointer;
}

.tu-branchdetail {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px 30px;
}
.tu-branchdetail li {
  color: #999999;
  align-items: center;
}
.tu-branchdetail li i {
  display: inline-block;
  font-size: 18px;
  color: inherit;
  margin: 0 10px 0 0;
}
.tu-branchdetail li span {
  display: inline-block;
  color: inherit;
  font-weight: 600;
}

.tu-accordionwrap,
.tu-expinfo,
.tu-faqswrap {
  position: relative;
  width: 100%;
}
.tu-accordionwrap > i,
.tu-expinfo > i,
.tu-faqswrap > i {
  display: block;
  font-size: 24px;
  padding-left: 10px;
  color: #1C1C1C;
}
.tu-accordionwrap i[aria-expanded=true],
.tu-expinfo i[aria-expanded=true],
.tu-faqswrap i[aria-expanded=true] {
  cursor: pointer;
}
.tu-accordionwrap i[aria-expanded=true]::before,
.tu-expinfo i[aria-expanded=true]::before,
.tu-faqswrap i[aria-expanded=true]::before {
  display: inline-block;
  content: "\e9a3";
  font-size: 24px;
  color: #1C1C1C;
}

.tu-expinfo,
.tu-faqswrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tu-accordioneduc p {
  margin: 20px 0 0;
  max-width: 876px;
}

.tu-accordionedu .tu-accordion-item ~ .tu-accordion-item {
  position: relative;
  margin: 30px 0 0;
  padding: 30px 0 0;
}
.tu-accordionedu .tu-accordion-item ~ .tu-accordion-item::after {
  position: absolute;
  content: "";
  height: 1px;
  background-image: linear-gradient(90deg, #DDDDDD, #DDDDDD 61%, transparent 61%, transparent 100%);
  background-size: 15px 1px;
  border: none;
  top: 0;
  width: 100%;
  left: 0;
}
.tu-accordionedu .tu-accordion-item:last-child {
  padding-bottom: 0;
}

.tu-icanteach {
  padding: 0;
  margin: 0;
}
.tu-icanteach > li {
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.tu-icanteach > li h6 {
  margin: 0;
  flex: none;
  font-weight: 600;
  min-width: 166px;
  padding: 2px 0 10px;
}
.tu-icanteach > li ~ li {
  margin: 40px 0 0;
}

.tu-serviceslist {
  padding: 0;
  margin: -5px;
  list-style: none;
  padding-left: 24px;
}
.tu-serviceslist > li {
  padding: 5px;
  list-style-type: none;
}
.tu-serviceslist > li > a {
  color: #999999;
  display: block;
  font-weight: 600;
  padding: 2px 11px;
  border-radius: 3px;
  background: #f7f7f7;
  border: 1px solid transparent;
}
.tu-serviceslist > li > a.tu-tippytooltip, .tu-serviceslist > li > a:hover {
  background: #ffffff;
  border-color: #eeeeee;
}

.tu-asidedetail {
  background: #ffffff;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.tu-asideinfo {
  padding: 20px 30px;
}
.tu-asideinfo h6 {
  font-weight: 400;
  margin: 0;
}

.tu-featureinclude {
  padding: 0;
  margin: 0;
  background: #FCFCFC;
}
.tu-featureinclude li {
  padding: 10px 30px;
  align-items: center;
  list-style-type: none;
  justify-content: space-between;
  box-shadow: inset 0 1px 0 #eeeeee;
}
.tu-featureinclude li > span {
  display: inline-flex;
  align-items: center;
  font-family: "icomoon";
  font-size: 18px;
}
.tu-featureinclude li > span i {
  display: inline-block;
  color: #1C1C1C;
  margin: 0 0 0 10px;
}
.tu-featureinclude li em {
  font-size: 16px;
}

.tu-colorblue {
  color: #1DA1F2 !important;
}

.tu-colororange {
  color: #F97316 !important;
}

.tu-contactbox {
  padding: 30px;
  box-shadow: inset 0px 1px 0px #EEEEEE;
}
.tu-contactbox > h6 {
  margin: 0;
  font-weight: 600;
}

.tu-listinfo {
  padding: 0;
  margin: 8px -8px -8px;
  display: contents;
}
.tu-listinfo li {
  padding: 8px;
  align-items: center;
}
.tu-listinfo li span {
  flex: none;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin: 0 10px 0 0;
  align-items: center;
  justify-content: center;
  background-color: #EC407A;
}
.tu-listinfo li span i {
  color: #ffffff;
  font-size: 18px;
}
.tu-listinfo li h6, .tu-listinfo li a {
  margin: 0;
  font-weight: 600;
  word-break: break-word;
}
.tu-listinfo li h6 em, .tu-listinfo li a em {
  color: #999999;
}

.tu-bg-maroon {
  background-color: #EC407A !important;
}

.tu-bg-voilet {
  background-color: #AB47BC !important;
}

.tu-bg-blue {
  background-color: #42A5F5 !important;
}

.tu-bg-green {
  background-color: #25D366 !important;
}

.tu-bg-orange {
  background-color: #FF7043 !important;
}

.tu-unlockfeature {
  padding: 30px;
  border-top: 1px solid #eeeeee;
}
.tu-unlockfeature h6 {
  margin: 0;
}
.tu-unlockfeature a {
  width: 100%;
  margin: 10px 0 0;
}

.tu-btngreen {
  background-color: #22C55E !important;
}

.tu-boxtitle > h4 {
  margin-bottom: 20px;
  font-weight: 600;
}

.tu-listing-location.tu-ratingstars > span {
  margin-right: 6px;
}

.tu-sm-stars,
.tu-sm-stars span {
  width: 80px;
  height: 12px;
  line-height: 12px;
}

.tu-sm-stars span {
  width: 100%;
}

.tu-sm-stars span:after,
.tu-sm-stars:before {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 3px;
}

.tu-Joincommunity {
  z-index: 1;
  width: 100%;
  padding: 20px;
  max-width: 790px;
  margin: 30px auto;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background: #1DA1F2;
  justify-content: space-between;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}
.tu-Joincommunity_content h4, .tu-Joincommunity_content p {
  color: #ffffff;
  margin-bottom: 0;
}
.tu-Joincommunity_content h4 {
  font-weight: 600;
}
.tu-particles {
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
}
.tu-particles > div {
  width: 100%;
  height: 100%;
}

.tu-explore-title h3 {
  font-weight: 600;
  margin-bottom: 20px;
}

.tu-servicesvideo {
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  display: flex;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  background: #FFFFFF;
  justify-content: center;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
}
.tu-servicesvideo:after {
  z-index: 1;
  font-size: 24px;
  content: "\f04b";
  font-weight: 900;
  color: #1C1C1C;
  font-family: "Font Awesome 5 Free";
}

.tu-syncthumbnail {
  display: flex;
  padding: 0 73px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.tu-syncthumbnail__content {
  cursor: pointer;
  margin-bottom: 0;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background-color: #DDDDDD;
}
.tu-syncthumbnail__content img {
  width: 100%;
  display: block;
  border-radius: 4px;
}
.tu-syncthumbnail.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 0;
}
.tu-syncthumbnail > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active,
.tu-syncthumbnail > .splide__track > .splide__list > .splide__slide.is-active {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
}
/* .tu-syncthumbnail .splide__arrow {
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 0;
  background: #ffffff;
} */
.tu-syncthumbnail .splide__arrow svg {
  fill: #1C1C1C;
}
.tu-syncthumbnail .splide__arrow--prev {
  left: 33px;
}
.tu-syncthumbnail .splide__arrow--next {
  right: 33px;
}
.tu-syncthumbnail .splide__arrow[disabled] svg {
  fill: #999999 !important;
}
.tu-syncthumbnail .tu-servicesvideo {
  width: 46px;
  height: 46px;
}
.tu-syncthumbnail .tu-servicesvideo::after {
  font-size: 16px;
}

.tu-iframe {
  width: 100%;
  height: 450px;
  max-width: 966px;
  border-radius: 4px;
}

.tu-reviews {
  width: 100%;
  padding: 20px;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  border-radius: 4px;
  background: #F7F8FC;
  justify-content: space-between;
}
.tu-reviews label {
  font-weight: 600;
  margin-bottom: 0 !important;
}

.tu-rating-stars {
  padding-left: 0;
  align-items: center;
}

.tu-my-ratingholder {
  align-items: center;
}
.tu-my-ratingholder h6 {
  font-weight: 600;
  margin-bottom: 0;
  padding-right: 10px;
}

.tu-addreview {
  display: flex;
  align-items: center;
}

.tu-rating-stars .tu-star i {
  font-size: 24px;
  display: inline-block;
}
.tu-rating-stars .tu-star i::before {
  content: "\f005";
  font-weight: 700;
  font-family: "Font Awesome 6 Free";
}
.tu-rating-stars .tu-star.active i {
  color: #EAB308;
}

.tu-tippytooltip span {
  color: #ffffff;
}

.tu-tippytooltip + div .tippy-box,
.tu-tippytooltipholder em + div .tippy-box {
  border-radius: 3px;
  background-color: #fff;
  max-width: 400px !important;
  border: 1px solid #eeeeee;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08));
}
.tu-tippytooltip + div .tippy-content,
.tu-tippytooltipholder em + div .tippy-content {
  padding: 0;
}

.tu-tippytooltipholder em + div .tippy-box {
  max-width: 430px !important;
}

.tu-tippytooltip + div [data-placement=bottom] .tippy-arrow:before,
.tu-tippytooltip + div [data-placement=bottom-start] .tippy-arrow:before,
.tu-tippytooltip + div [data-placement=bottom-end] .tippy-arrow:before,
.tu-achievementtoolholder + div [data-placement=bottom] .tippy-arrow:before,
.tu-achievementtoolholder + div [data-placement=bottom-start] .tippy-arrow:before,
.tu-achievementtoolholder + div [data-placement=bottom-end] .tippy-arrow:before,
.tu-tippytooltipholder em + div [data-placement=bottom] .tippy-arrow:before,
.tu-tippytooltipholder em + div [data-placement=bottom-start] .tippy-arrow:before,
.tu-tippytooltipholder em + div [data-placement=bottom-end] .tippy-arrow:before {
  border: 1px solid #eee !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}
.tu-tippytooltip + div .tippy-arrow,
.tu-achievementtoolholder + div .tippy-arrow,
.tu-tippytooltipholder em + div .tippy-arrow {
  color: #fff;
  width: 10px;
  height: 10px;
  z-index: 1;
}
.tu-tippytooltip + div .tippy-arrow:before,
.tu-achievementtoolholder + div .tippy-arrow:before,
.tu-tippytooltipholder em + div .tippy-arrow:before {
  width: 10px;
  height: 10px;
  background: #fff;
  transform: rotate(-45deg) !important;
  border: 1px solid #eee !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.tu-posttag {
  align-items: center;
  margin: -3px;
  padding: 10px;
  min-width: 208px;
  border-radius: 3px;
}
.tu-posttag li {
  padding: 3px;
}
.tu-posttag li a {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 3px;
  color: #999999;
  padding: 3px 11px;
  border: 1px solid transparent;
  font-weight: 600;
}
.tu-posttag li a:hover {
  background: #ffffff;
  color: #1C1C1C;
  border-color: #eeeeee;
}

.tu-productorder-content {
  display: flex;
  align-items: flex-start;
}
.tu-productorder-content > figure {
  display: none;
  margin-right: 20px;
  margin-bottom: 15px;
}
.tu-productorder-content > figure img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}

.tu-listinginfo_price {
  margin-left: auto;
}

.tu-slider-holder {
  width: 100%;
}

.tu-userurl {
  display: flex;
  align-items: center;
}
.tu-userurl > a {
  display: inline-block;
  font-weight: 400;
  word-break: break-all;
}
.tu-userurl > a > i {
  color: #999999;
  font-size: 18px;
  margin: 0 0 0 6px;
  vertical-align: text-top;
}
.tu-userurl > i {
  font-size: 18px;
  margin: 0 10px 0 0;
  color: #999999;
  vertical-align: text-top;
}
.tu-userurl > i + i {
  margin: 0 0 0 6px;
}

/** ====== login page style ====== **/
.tu-main-login {
  width: 100%;
  display: flex;
  min-height: 100vh;
}

.tu-login-left {
  width: 50%;
  display: flex;
  padding: 50px 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #0A0F26;
}
.tu-login-left figure {
  margin: 116px 0 0 0;
}
.tu-login-left_title {
  text-align: center;
  padding-top: 80px;
}
.tu-login-left_title h2 {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 600;
}
.tu-login-left_title span {
  font-weight: 400;
  color: #FCCF14;
}

.tu-login-right {
  width: 50%;
  display: flex;
  padding: 50px 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tu-login-right_title {
  text-align: center;
}
.tu-login-right_title h2 {
  margin-bottom: 0;
  color: #6A307D;
}
.tu-login-right_title h3 {
  margin: 10px 0 0 0;
}

.tu-login-form .form-group-wrap {
  width: 100%;
  max-width: 436px;
  margin-top: 40px;
}
.tu-login-form .form-group > a {
  width: 100%;
}

.tu-optioanl-or {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  position: relative;
}
.tu-optioanl-or span {
  color: #999999;
  background-color: #ffffff;
  position: relative;
  padding: 0 10px;
}
.tu-optioanl-or::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #DDDDDD;
}

.tu-btn-signup {
  border: 1.5px solid #DDDDDD;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0A0F26;
}
.tu-btn-signup img {
  padding-right: 10px;
}
.tu-btn-signup:hover, .tu-btn-signup:focus {
  background-color: #fff;
  color: #0A0F26;
}

.tu-lost-password {
  justify-content: space-between;
}
.tu-lost-password a {
  width: auto !important;
  color: #6A307D;
}
.tu-lost-password .tu-password-clr_light {
  color: #999999;
}
.tu-lost-password .tu-password-clr_light:hover {
  color: #999999;
}

.tu-signup-check {
  padding: 10px 0;
}
.tu-signup-check label span {
  color: #999999;
}



/** ====== responsive ====== **/
@media (max-width: 1399px) and (min-width: 1200px) {
  .tu-actionbts {
    flex-wrap: wrap;
  }



  .tu-blogfeatures {
    flex-wrap: wrap;
  }
  .tu-blogfeatures figure {
    margin: 0 auto 10px;
  }

  .tu-categories li:nth-child(n+8) {
    display: none;
  }
}
@media (max-width: 1680px) {
  .tu-bannerv2_img img {
    max-width: 850px;
  }

  .tu-bannerv2_img {
    top: 235px;
  }

  .tu-bannerv3 .tu-bannerv2_img img {
    max-width: 750px;
  }

  .tu-bannerv3 .tu-bannerv2_img {
    top: 235px;
  }
}
@media (max-width: 1575px) {
  .tu-starthere {
    display: none;
  }
}
@media (max-width: 1400px) {
  .tu-listinginfo_two .tu-service-list {
    flex-wrap: wrap;
  }

  .tu-service-list li {
    max-width: 180px;
  }

  .tu-bannerv2_img {
    top: 288px;
  }

  .tu-bannerv2_img img {
    max-width: 700px;
  }

  .tu-status-filter {
    margin: -5px;
  }
  .tu-status-filter li {
    padding: 5px;
    max-width: 48.5%;
  }

  .tu-syncthumbnail .splide__arrow--prev {
    left: -13px;
  }

  .tu-syncthumbnail .splide__arrow--next {
    right: -18px;
  }

  .tu-sorttags {
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .tu-listinginfo-holder {
    /* display: flex; */
    flex-wrap: wrap;
    gap: 24px;
  }

  .tu-listinginfo_two {
    align-items: flex-start;
    flex-direction: column;
    padding: 19px;
    max-width: calc(50% - 12px);
  }
  .tu-listinginfo_two .tu-listing-location {
    flex-wrap: wrap;
  }
  .tu-listinginfo_two .tu-listinginfo-img {
    margin-top: 20px;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .tu-listinginfo_two .tu-listinginfo_price {
    width: 100%;
    margin-top: 20px;
  }
  .tu-listinginfo_two .tu-listinginfo_price span {
    text-align: left;
  }

  .tu-listinginfo-holder .tu-listinginfo + .tu-listinginfo {
    margin-top: 0;
  }

  .tu-listing-slider {
    max-width: 100%;
    padding-right: 0;
  }

  .tu-listinfo li {
    flex-wrap: nowrap;
  }
  .tu-listinfo li > a {
    word-break: break-all;
  }

  .tu-bannerv3 .tu-bannerv2_img img {
    max-width: 600px;
  }

  .tu-bannerv3 .tu-bannerv2_img {
    top: 335px;
  }
}
@media (max-width: 1399px) {
  .tu-relatedatricles .tu-arrticltitle h5,
.tu-bloggridwrapper .tu-arrticltitle h5 {
    -webkit-line-clamp: 1;
  }

  .tu-listinginfo_two .tu-listinginfo_description {
    display: none;
  }
  .tu-listinginfo_two .tu-listinginfo_price {
    width: 100%;
    display: flex;
    margin: 20px 0 0;
    padding: 10px 20px;
    background: #F7F8FC;
    align-items: center;
    justify-content: space-between;
  }

  .tu-stats_info p {
    max-width: 100%;
  }

  .tu-footer_row .tu-footerlist li {
    flex: 0 0 100%;
  }

  .tu-categories li {
    width: 25%;
  }
  .tu-categories .tu-categories-maintitle {
    width: 50%;
  }

  .tu-footerlistholder .tu-footercontent-two,
.tu-footerlistholder .tu-footercontent {
    width: 100%;
  }

  .tu-footercontent-two ul.tu-footerlist {
    gap: 10px 0;
  }

  .tu-footerlist li {
    flex: 0 0 33.33%;
  }

  .tu-footercontent_title {
    margin: 0;
    max-width: calc(33.33% - 12px);
  }

  .tu-footernewsletter {
    width: auto;
    padding-left: 12px;
  }
}
@media (max-width: 991px) and (min-width: 992px) {
  .tu-categories li:nth-child(n+9) {
    display: none;
  }
}
@media (max-width: 1199px) and (min-width: 576px) {
  .tu-unlockfeature {
    display: flex;
    align-items: center;
  }
  .tu-unlockfeature a {
    width: auto;
    flex: none;
    margin: 0 0 0 auto;
  }
  .tu-unlockfeature h6 {
    padding-right: 20px;
    text-align: left;
  }
}
@media (max-width: 1199px) and (min-width: 991px) {
  .tu-betterresult .tu-resultperson {
    right: 0;
    width: auto;
  }
}
@media (max-width: 1199px) {
  .tu-open-menu > a:after {
    transform: rotate(90deg);
  }

  .tu-banner-search .tu-appendinput {
    max-width: 100%;
  }

  .tu-processing-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tu-footercontent.d-xl-flex {
    display: flex;
  }

  .tu-listinfo li {
    width: 33.33%;
  }

  .tu-howit-steps > div:last-child:before {
    display: none;
  }

  .tu-headerbtn {
    padding-left: 10px;
  }

  .tu-asidewrapper {
    top: 0;
    right: 0;
    padding: 0;
    width: 100%;
    z-index: 999;
    height: 100%;
    position: fixed;
    border-radius: 0;
    max-width: 330px;
    transform: translateX(100%);
    transition: all 0.6s ease-in-out;
  }

  .tu-opendbmenu {
    transform: translateX(0);
    box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.2);
  }
  .tu-opendbmenu .tu-dbmenu .icon-chevron-left:before {
    content: "\ea15";
  }

  .tu-dbmenu {
    display: block;
  }

  .tu-aside-menu {
    overflow: auto;
    height: 100%;
    padding: 20px;
  }

  .tu-pricinglist {
    flex-wrap: wrap;
  }

  .tu-pricinglistm {
    flex-wrap: wrap;
  }

  .tu-themenav {
    left: 0;
    top: 100%;
    transition: 0.3s all ease-in-out;
    width: 100%;
    z-index: 9;
    background: #ffffff;
    position: absolute;
    box-shadow: rgba(165, 165, 165, 0.5) 0px 9px 20px 0px;
  }

  .tu-navbar .navbar-collapse .navbar-nav > .nav-item > a {
    padding: 10px 20px;
  }

  .tu-menu {
    display: block;
    margin-right: 10px;
  }

  .tu-navbar .navbar-collapse .navbar-nav > .nav-item > a .tu-tag {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 5px;
    border-bottom-right-radius: 10px;
  }

  .tu-navbar .navbar-collapse .nav-item a {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
  }

  .tu-navbar .navbar-collapse .nav-item a::before {
    display: none;
  }

  .tu-sucesstor {
    flex-direction: column;
  }
  .tu-sucesstor_img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .tu-success-stories::after {
    width: 100%;
    border-radius: 0;
  }

  .tu-categories .tu-categories-maintitle {
    width: 66.6%;
  }

  .tu-categories li {
    width: 33.33%;
  }

  .tu-themenav .sub-menu {
    width: 100%;
    box-shadow: none;
    position: relative;
    border: 0;
  }
  .tu-themenav .sub-menu li a {
    padding-left: 40px;
  }
  .tu-themenav .sub-menu li .sub-menu {
    margin: 0;
    right: auto;
  }
  .tu-themenav .sub-menu li .sub-menu li a {
    padding-left: 40px !important;
  }

  .tu-bannerv2_img {
    display: none;
  }

  .tu-bannerv2, .tu-bannerv3 {
    padding-bottom: 80px;
  }
  .tu-bannerv2::before, .tu-bannerv3::before {
    clip-path: none;
  }

  .tu-linedimage {
    top: 60px;
  }

  .tu-headerv2 .tu-navbar .navbar-collapse .nav-item:not(.tu-loginlink) a,
.tu-headerv2 .navbar-nav > .menu-item-has-children > a,
.tu-headerv2 .tu-navbar .navbar-collapse .nav-item a {
    color: #1C1C1C !important;
  }

  .tu-navbar.tu-navbarvtwo .navbar-collapse .nav-item a::before {
    background-color: #EAB308;
    height: 0px;
    width: 5px;
  }

  .tu-navbar.tu-navbarvtwo .navbar-nav > .menu-item-has-children > a,
.tu-navbar.tu-navbarvtwo .navbar-nav > .nav-item > a,
.tu-navbar .navbar-collapse .nav-item a,
.menu-item-has-children > a {
    padding: 14px 20px;
  }

  .tu-status-filter {
    margin-left: 0;
    margin-right: 0;
  }
  .tu-status-filter li {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .tu-expinfo,
.tu-faqswrap {
    align-items: flex-start;
  }

  .tu-profileview > figure {
    display: none;
  }

  .tu-productorder-content > figure {
    display: block;
  }

  .tu-productorder-content {
    align-items: center;
  }

  .menu-item-has-children > a:after {
    margin-left: auto;
    content: "\e930";
  }
}
@media (max-width: 991px) and (min-width: 421px) {
  .tu-asidebox {
    display: flex;
    align-items: center;
  }
  .tu-asidebox figure {
    flex: none;
    margin: 0 20px 0 0;
  }

  .tu-uploadinfo {
    text-align: left !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .tu-listinginfo_two .tu-listinginfo-img {
    flex-wrap: wrap;
  }
  .tu-listinginfo_two .tu-listing-heading {
    margin: 10px 0 0;
  }
  .tu-listinginfo_two .tu-service-list li {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .tu-brand_list {
    justify-content: center;
  }
  .tu-brand_list li {
    flex: 0 0 20%;
    text-align: center;
  }

  .tu-actionbts {
    flex-wrap: wrap;
  }

  .tu-footercontent.d-xl-flex {
    flex-wrap: wrap;
  }

  .tu-footercontent_title {
    max-width: 100%;
  }

  .tu-footernewsletter {
    width: 100%;
    padding: 30px 0 0;
  }

  .tu-betterresult > figure {
    max-width: 100%;
  }
  .tu-betterresult > figure img {
    display: block;
    margin: 0 auto;
  }

  .tu-stats {
    margin: 0;
    padding: 0;
    padding: 0;
  }
  .tu-stats li {
    display: flex;
    padding: 30px 0;
  }
  .tu-stats li img {
    flex: none;
    margin: 0 20px 0 0;
  }

  .tu-service-list {
    flex-wrap: nowrap;
  }
  .tu-service-list li {
    flex: auto;
    max-width: 100%;
  }

  .tu-Joincommunity {
    flex-direction: column;
    align-items: flex-start;
  }
  .tu-Joincommunity_btn {
    margin-top: 15px;
  }

  .tu-listinfo li {
    width: 50%;
  }

  .tu-footerlist li {
    flex: 0 0 50%;
  }

  .tu-profilelinksbtn {
    width: 100%;
    margin-top: 15px;
  }

  .tu-categories .tu-categories-maintitle {
    width: 100%;
    padding-bottom: 18px;
  }
  .tu-categories li {
    width: 50%;
  }

  .tu-asider-holder {
    margin: 0 0 30px;
  }

  .tu-blogfeatures {
    flex-wrap: wrap;
  }
  .tu-blogfeatures figure {
    margin: 0 auto 10px;
  }

  .tu-footerdark .tu-socialmedia {
    margin-bottom: 30px;
  }

  .tu-footerlistholder .tu-footercontent-two,
.tu-footerlistholder .tu-footercontent {
    width: 100%;
  }

  .tu-processing-img {
    display: none;
  }
  .tu-processing-content {
    width: 100%;
    padding: 80px 50px;
  }
  .tu-processing-content > .tu-maintitle, .tu-processing-content > .tu-processing-list {
    max-width: 100%;
  }

  .tu-bannerv1_img {
    margin-left: 0;
  }

 
  .tu-stats li ~ li {
    border-top: 2px solid #eeeeee;
  }

  .tu-sucesstorslider_title {
    position: relative;
  }

  .tu-cardtag::after {
    bottom: -1px;
    height: 5px;
  }

  .tu-syncthumbnail .splide__arrow--next {
    right: 12px;
  }

  .tu-syncthumbnail .splide__arrow--prev {
    left: 12px;
  }

  .tu-sortarea {
    width: 100%;
  }

  .tu-sort-right-area {
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
  }

  .tu-listing-search {
    display: none;
  }
}
@media (max-width: 767px) {
  .tu-listinginfo_description {
    display: none;
  }

  .tu-brand_list li {
    flex: auto;
  }

  .tu-betterresult .tu-resultperson {
    width: auto;
    float: right;
    transform: none;
    margin: 10px 0 0;
    position: relative;
  }

  .tu-listinginfo_price {
    width: 100%;
    display: flex;
    margin: 20px 0 0;
    padding: 10px 20px;
    align-items: center;
    background: #F7F8FC;
    justify-content: space-between;
  }

  .tu-brand .tu-maintitle h4 {
    display: inline;
  }

  .tu-footercontent_title {
    margin: 0;
    max-width: 100%;
  }

  .tu-footercontent.d-xl-flex {
    flex-direction: column;
  }

  .tu-howit-steps > div + div:before {
    display: none;
  }

  .tu-listinfo li {
    width: 100%;
  }

  .tu-reviews {
    flex-wrap: wrap;
  }

  .tu-appendinput .tu-inputicon > *:first-child {
    left: 10px;
  }
  .tu-appendinput .tu-inputicon .form-control {
    padding-left: 40px;
  }
  .tu-appendinput .tu-select > i, .tu-appendinput .tu-calendar > i, .tu-appendinput .tu-appendinput .tu-calendar > i {
    left: 10px;
  }
  .tu-appendinput .select2-container--default .select2-selection--single {
    padding: 0;
  }
  .tu-appendinput .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 40px;
  }

  .tu-blogwrapper > figure img {
    height: 300px;
    object-fit: cover;
  }

  .tu-tutorprofilewrapp {
    margin-top: 50px;
  }

  .tu-sync__content a img {
    min-height: 300px;
    object-fit: cover;
  }

  .tu-footerlistholder > div {
    width: 50%;
  }

  .tu-instructors_header {
    padding-left: 12px;
    padding-right: 12px;
  }

  .tu-banner-search .tu-searcbar > a,
.tu-banner-search .tu-appendinput .tu-select,
.tu-banner-search .tu-appendinput .tu-calendar {
    width: 100%;
  }

  .tu-searcbar {
    flex-direction: column;
  }
  .tu-searcbar .tu-inputicon {
    border: 1px solid #eeeeee;
    border-radius: 4px 4px 0 0;
  }
  .tu-searcbar .tu-select, .tu-searcbar .tu-calendar {
    width: 100%;
    border: 1px solid #eeeeee;
    border-top: 0;
  }
  .tu-searcbar > a {
    width: 100%;
    border-radius: 0 0 4px 4px;
  }

  .tu-dottedimage {
    display: none;
  }

  .tu-linedimage {
    top: 100px;
    clip-path: none;
  }

  .tu-alertcontent {
    margin: 0;
  }

  .tu-boxitem {
    flex-wrap: wrap;
  }
  .tu-boxitem .tu-btnrea {
    padding-top: 15px;
  }

  .tu-themeform__wrap .form-group-half,
.tu-themeform__wrap .form-group-3half {
    width: 100%;
  }

  .tu-icanteach > li {
    flex-wrap: wrap;
  }
  .tu-icanteach > li ~ li {
    margin-top: 20px;
  }
  .tu-icanteach > li h6 {
    padding: 0;
  }

  .tu-tech-title {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .tu-serviceslist {
    padding: 15px 0 0;
  }

  .tu-syncthumbnail {
    display: none;
  }

  .tu-sorttags {
    flex-wrap: wrap;
  }

  .tu-taglist {
    margin-top: 15px;
  }

  .tu-formspacebtw {
    flex-direction: column;
    align-items: flex-start;
  }
  .tu-formspacebtw .tu-check {
    padding: 0;
  }
  .tu-formspacebtw .tu-primbtn-lg,
.tu-formspacebtw > a {
    margin-top: 20px;
  }

  .tu-listinginfo_two {
    max-width: 100%;
  }

  .tu-productorder-content > figure {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-right: 0;
    transform: translateX(-50%);
  }

  .tu-productorder-content {
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-start;
  }
  .tu-productorder-content .tu-listinginfo_price span {
    text-align: left;
  }

  .tu-tutorreview {
    gap: 10px 20px;
    margin-top: 20px;
  }

  .tu-my-ratingholder {
    margin-top: 10px;
  }

  .tu-main-login {
    flex-direction: column;
  }

  .tu-login-left,
.tu-login-right {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 640px) {
  .tu-processing-content {
    padding: 80px 30px;
  }
}
@media (max-width: 576px) {
  .tu-service-list {
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .tu-banner_list li {
    width: 100%;
  }
  .tu-banner_list li a {
    width: 100%;
  }

  .tu-featurelist .splide__track,
.tu-instructorslider .splide__track,
.tu-categoriesslider .splide__track {
    margin: -12px;
    padding: 12px !important;
  }

  .tu-footerlistholder > div {
    width: 100%;
  }

  .tu-pagination-prev {
    padding-right: 5px !important;
  }

  .tu-pagination-next {
    padding-left: 5px !important;
  }

  .tu-sucesstorslider .splide__track {
    padding: 0;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tu-btnarea-two {
    flex-direction: column;
    align-items: flex-end;
  }
  .tu-btnarea-two span {
    padding-right: 0;
    padding-bottom: 10px;
    text-align: right;
  }

  .tu-commentarea .tu-commentchild {
    padding-left: 50px;
  }

  .tu-listinginfo_title {
    flex-direction: column;
    align-items: flex-start;
  }

  .tu-listinginfo_price span {
    text-align: left;
  }
}
@media (max-width: 480px) {
  .tu-sync__content a img {
    min-height: 150px;
  }

  .tu-headerv2 .tu-navbar {
    padding: 20px 0;
  }

  .tu-footerlist li {
    flex: 0 0 100%;
  }

  .litepicker .container__months, .litepicker .container__months .month-item {
    width: 280px;
  }

  .litepicker .container__days > div, .litepicker .container__days > a {
    min-width: 40px;
  }

  .tu-brand_list {
    margin: -10px;
  }
  .tu-brand_list li {
    padding: 10px;
    flex: 0 0 50%;
  }

  .tu-afterlogin {
    margin-left: auto;
  }

  .tu-sucesstor_img figure figcaption {
    width: 100px;
    height: 100px;
  }
  .tu-sucesstor_img figure figcaption img {
    max-width: 30px;
    margin: 0 0 10px 10px;
  }

  .tu-navbar,
.tu-afterlogin > li strong > a {
    padding: 0;
  }

  .tu-navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .tu-login-left,
.tu-login-right,
.tu-reviews,
.tu-unlockfeature,
.tu-featureinclude li,
.tu-contactbox,
.tu-boxitem,
.modal-body,
.tu-asidebox,
.tu-actionbts,
.modal-header,
.tu-articleinfo,
.tu-tabswrapper,
.tu-profileview,
.tu-Joincommunity,
.tu-howit-steps li,
.tu-boxarea .tu-box,
.tu-boxarea .tu-boxsm,
.tu-processing-content,
.tu-instructors_service,
.tu-boxlg {
    padding-left: 12px;
    padding-right: 12px;
  }

  .tu-header nav {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tu-header nav strong {
    width: 100%;
  }
  .tu-header nav strong .navbar-brand {
    padding-top: 0;
  }

  .tu-menu {
    margin-left: 0;
  }

  .tu-headerbtn {
    margin-left: auto;
  }

  .tu-bannervtwo, .tu-bannervthree {
    margin-top: -135px;
    padding-top: 235px;
  }

  .tu-bannerv2, .tu-bannerv3 {
    padding-top: 215px;
    margin-top: -135px;
  }

  .tu-headerv2 .menu-item-has-children strong a {
    padding-top: 0;
    padding-bottom: 0;
  }

  .tu-accodion-holder {
    padding-right: 10px;
  }

  .tu-branchdetail li {
    flex: none;
  }

  .tu-commentarea .tu-commentchild {
    padding-left: 20px;
  }

  .tu-blogfeatures figure {
    flex: auto;
  }

  .tu-listinginfo_btn,
.tu-service-list,
.tu-btnarea {
    flex-direction: column;
    align-items: flex-start;
  }

  .tu-btnarea {
    width: 100%;
    padding-top: 10px;
  }
  .tu-btnarea a {
    width: 100%;
  }
  .tu-btnarea a + a {
    margin: 10px 0 0 0;
  }

  .tu-service-list li {
    max-width: 100%;
  }

  .tu-productorder-content {
    flex-wrap: wrap;
  }

  .tu-profilelinksbtn {
    flex-direction: column;
    width: 100%;
  }
  .tu-profilelinksbtn li {
    width: 100%;
  }
  .tu-profilelinksbtn li a {
    width: 100%;
  }

  .tu-banner_title h1 {
    font-size: 2.625rem;
  }

  .tu-bannerinfo {
    min-height: 60px;
    line-height: 50px;
    font-size: 1.875rem;
  }

  .tu-letters {
    line-height: 45px;
  }

  .tu-categories li {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .tu-listinginfo-img figure {
    margin-bottom: 10px;
  }

  .tu-my-ratingholder {
    flex-direction: column;
  }
  .tu-my-ratingholder .tu-addreview {
    margin: 5px 0 0;
  }

  .tu-sortarea {
    width: 100%;
  }

  .tu-sort-right-area {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .tu-sort-right-area .tu-sortby {
    padding: 0;
    width: 100%;
  }

  .tu-filter-btn {
    align-self: flex-end;
  }

  .tu-banner_list li {
    width: 100%;
  }
  .tu-banner_list li a {
    width: 100%;
  }

  .tu-commentlist {
    flex-direction: column;
    align-items: flex-start;
  }

  .tu-commentauthor {
    padding: 10px 10px 0 0;
  }
}
@media (max-width: 375px) {
  .tu-asidewrapper {
    max-width: 280px;
  }

  .tu-listinginfo-img {
    flex-direction: column;
    align-items: flex-start;
  }
  .tu-listinginfo-img figure {
    margin: 0 0 10px 0;
  }

  .tu-nav-tabs .nav-item {
    width: 100%;
    border-right: 0;
  }
  .tu-nav-tabs .nav-item + .nav-item {
    border-top: 1px solid #eee;
  }

  .tu-nav-tabs .nav-item .nav-link {
    width: 100%;
  }
}






